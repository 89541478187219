/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanChangeVariableCostFromMarginPercentRequestDto
 */
export interface MonthPlanChangeVariableCostFromMarginPercentRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanChangeVariableCostFromMarginPercentRequestDto
   */
  fundId: string;
  /**
   *
   * @type {number}
   * @memberof MonthPlanChangeVariableCostFromMarginPercentRequestDto
   */
  percent: number;
}

export function MonthPlanChangeVariableCostFromMarginPercentRequestDtoFromJSON(
  json: any
): MonthPlanChangeVariableCostFromMarginPercentRequestDto {
  return MonthPlanChangeVariableCostFromMarginPercentRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanChangeVariableCostFromMarginPercentRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanChangeVariableCostFromMarginPercentRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fundId: json["fundId"],
    percent: json["percent"],
  };
}

export function MonthPlanChangeVariableCostFromMarginPercentRequestDtoToJSON(
  value?: MonthPlanChangeVariableCostFromMarginPercentRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fundId: value.fundId,
    percent: value.percent,
  };
}
