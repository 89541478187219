import logrocket from "application/intergrations/logrocket";
import { AppProps } from "next/app";
import { compose } from "shared/lib";
import { withModals, withSentryIdentify, withThemeDetector, withToast } from "./providers";

logrocket.init();

const MyApp = (props: AppProps) => <props.Component {...props.pageProps} />;

export const Application = compose(withThemeDetector, withModals, withToast, withSentryIdentify)(MyApp);
