/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  ExpendituresOnFundsAndExpenseItemsReportDto,
  ExpendituresOnFundsAndExpenseItemsReportDtoFromJSON,
  ExpendituresOnFundsAndExpenseItemsReportDtoToJSON,
  FundHistoryOperationType,
  FundHistoryOperationTypeFromJSON,
  FundHistoryOperationTypeToJSON,
  LoansReportDto,
  LoansReportDtoFromJSON,
  LoansReportDtoToJSON,
  MovementOnFundsReportDto,
  MovementOnFundsReportDtoFromJSON,
  MovementOnFundsReportDtoToJSON,
  OpenedFundingRequestReportDto,
  OpenedFundingRequestReportDtoFromJSON,
  OpenedFundingRequestReportDtoToJSON,
  ReportBalancesOnFundGroupsAndFundsDto,
  ReportBalancesOnFundGroupsAndFundsDtoFromJSON,
  ReportBalancesOnFundGroupsAndFundsDtoToJSON,
} from "../models";

export interface ReportBalancesOnFundGroupsAndFundsRequest {
  companyIdOrSlug: string;
}

export interface ReportExpendituresOnFundsAndExpenseItemsRequest {
  companyIdOrSlug: string;
  from: Date;
  to: Date;
  fundIds: Array<string>;
  expenseItemIds: Array<string>;
}

export interface ReportLoansRequest {
  companyIdOrSlug: string;
  fundIds: Array<string>;
  from: Date;
  to: Date;
}

export interface ReportMovementOnFundsRequest {
  companyIdOrSlug: string;
  from: Date;
  to: Date;
  fundIds: Array<string>;
  hideWithoutMovements: boolean;
  operations?: FundHistoryOperationType;
}

export interface ReportOpenedFundingRequestsRequest {
  companyIdOrSlug: string;
  fundIds: Array<string>;
}

/**
 *
 */
export class ReportApi extends runtime.BaseAPI {
  /**
   */
  protected reportBalancesOnFundGroupsAndFundsRequestOpts = (
    requestParameters: ReportBalancesOnFundGroupsAndFundsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling reportBalancesOnFundGroupsAndFunds."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/report/balances-on-fund-groups-and-funds`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected reportBalancesOnFundGroupsAndFundsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ReportBalancesOnFundGroupsAndFundsDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReportBalancesOnFundGroupsAndFundsDtoFromJSON(jsonValue)
    );
  };

  /**
   */
  protected reportBalancesOnFundGroupsAndFundsRaw = async (
    requestParameters: ReportBalancesOnFundGroupsAndFundsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ReportBalancesOnFundGroupsAndFundsDto>> => {
    const context = this.reportBalancesOnFundGroupsAndFundsRequestOpts(requestParameters);
    return this.reportBalancesOnFundGroupsAndFundsFetch(context, initOverrides);
  };

  /**
   */
  reportBalancesOnFundGroupsAndFunds = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<ReportBalancesOnFundGroupsAndFundsDto> => {
    const response = await this.reportBalancesOnFundGroupsAndFundsRaw(
      { companyIdOrSlug: companyIdOrSlug },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useReportBalancesOnFundGroupsAndFunds = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<ReportBalancesOnFundGroupsAndFundsDto>
  ): SWRResponse<ReportBalancesOnFundGroupsAndFundsDto> => {
    return useSWR<ReportBalancesOnFundGroupsAndFundsDto>(
      () => this.reportBalancesOnFundGroupsAndFundsRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.reportBalancesOnFundGroupsAndFundsFetch(context)),
      config
    );
  };

  /**
   */
  protected reportExpendituresOnFundsAndExpenseItemsRequestOpts = (
    requestParameters: ReportExpendituresOnFundsAndExpenseItemsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling reportExpendituresOnFundsAndExpenseItems."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling reportExpendituresOnFundsAndExpenseItems."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling reportExpendituresOnFundsAndExpenseItems."
      );
    }

    if (requestParameters.fundIds === null || requestParameters.fundIds === undefined) {
      throw new runtime.RequiredError(
        "fundIds",
        "Required parameter requestParameters.fundIds was null or undefined when calling reportExpendituresOnFundsAndExpenseItems."
      );
    }

    if (requestParameters.expenseItemIds === null || requestParameters.expenseItemIds === undefined) {
      throw new runtime.RequiredError(
        "expenseItemIds",
        "Required parameter requestParameters.expenseItemIds was null or undefined when calling reportExpendituresOnFundsAndExpenseItems."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.fundIds) {
      queryParameters["fundIds"] = requestParameters.fundIds;
    }

    if (requestParameters.expenseItemIds) {
      queryParameters["expenseItemIds"] = requestParameters.expenseItemIds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/report/expenditures-on-funds-and-expense-items`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected reportExpendituresOnFundsAndExpenseItemsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ExpendituresOnFundsAndExpenseItemsReportDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExpendituresOnFundsAndExpenseItemsReportDtoFromJSON(jsonValue)
    );
  };

  /**
   */
  protected reportExpendituresOnFundsAndExpenseItemsRaw = async (
    requestParameters: ReportExpendituresOnFundsAndExpenseItemsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ExpendituresOnFundsAndExpenseItemsReportDto>> => {
    const context = this.reportExpendituresOnFundsAndExpenseItemsRequestOpts(requestParameters);
    return this.reportExpendituresOnFundsAndExpenseItemsFetch(context, initOverrides);
  };

  /**
   */
  reportExpendituresOnFundsAndExpenseItems = async (
    companyIdOrSlug: string,
    from: Date,
    to: Date,
    fundIds: Array<string>,
    expenseItemIds: Array<string>,
    initOverrides?: RequestInit
  ): Promise<ExpendituresOnFundsAndExpenseItemsReportDto> => {
    const response = await this.reportExpendituresOnFundsAndExpenseItemsRaw(
      { companyIdOrSlug: companyIdOrSlug, from: from, to: to, fundIds: fundIds, expenseItemIds: expenseItemIds },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useReportExpendituresOnFundsAndExpenseItems = (
    requestParameters: ReportExpendituresOnFundsAndExpenseItemsRequest,
    config?: SWRConfiguration<ExpendituresOnFundsAndExpenseItemsReportDto>
  ): SWRResponse<ExpendituresOnFundsAndExpenseItemsReportDto> => {
    return useSWR<ExpendituresOnFundsAndExpenseItemsReportDto>(
      () => this.reportExpendituresOnFundsAndExpenseItemsRequestOpts(requestParameters),
      (context) => this.swrFetch(this.reportExpendituresOnFundsAndExpenseItemsFetch(context)),
      config
    );
  };

  /**
   */
  protected reportLoansRequestOpts = (requestParameters: ReportLoansRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling reportLoans."
      );
    }

    if (requestParameters.fundIds === null || requestParameters.fundIds === undefined) {
      throw new runtime.RequiredError(
        "fundIds",
        "Required parameter requestParameters.fundIds was null or undefined when calling reportLoans."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling reportLoans."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling reportLoans."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fundIds) {
      queryParameters["fundIds"] = requestParameters.fundIds;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/report/loans`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected reportLoansFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<LoansReportDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => LoansReportDtoFromJSON(jsonValue));
  };

  /**
   */
  protected reportLoansRaw = async (
    requestParameters: ReportLoansRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<LoansReportDto>> => {
    const context = this.reportLoansRequestOpts(requestParameters);
    return this.reportLoansFetch(context, initOverrides);
  };

  /**
   */
  reportLoans = async (
    companyIdOrSlug: string,
    fundIds: Array<string>,
    from: Date,
    to: Date,
    initOverrides?: RequestInit
  ): Promise<LoansReportDto> => {
    const response = await this.reportLoansRaw(
      { companyIdOrSlug: companyIdOrSlug, fundIds: fundIds, from: from, to: to },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useReportLoans = (
    requestParameters: ReportLoansRequest,
    config?: SWRConfiguration<LoansReportDto>
  ): SWRResponse<LoansReportDto> => {
    return useSWR<LoansReportDto>(
      () => this.reportLoansRequestOpts(requestParameters),
      (context) => this.swrFetch(this.reportLoansFetch(context)),
      config
    );
  };

  /**
   */
  protected reportMovementOnFundsRequestOpts = (
    requestParameters: ReportMovementOnFundsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling reportMovementOnFunds."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling reportMovementOnFunds."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling reportMovementOnFunds."
      );
    }

    if (requestParameters.fundIds === null || requestParameters.fundIds === undefined) {
      throw new runtime.RequiredError(
        "fundIds",
        "Required parameter requestParameters.fundIds was null or undefined when calling reportMovementOnFunds."
      );
    }

    if (requestParameters.hideWithoutMovements === null || requestParameters.hideWithoutMovements === undefined) {
      throw new runtime.RequiredError(
        "hideWithoutMovements",
        "Required parameter requestParameters.hideWithoutMovements was null or undefined when calling reportMovementOnFunds."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.operations !== undefined) {
      queryParameters["operations"] = requestParameters.operations;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.fundIds) {
      queryParameters["fundIds"] = requestParameters.fundIds;
    }

    if (requestParameters.hideWithoutMovements !== undefined) {
      queryParameters["hideWithoutMovements"] = requestParameters.hideWithoutMovements;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/report/movement-on-funds`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected reportMovementOnFundsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MovementOnFundsReportDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => MovementOnFundsReportDtoFromJSON(jsonValue));
  };

  /**
   */
  protected reportMovementOnFundsRaw = async (
    requestParameters: ReportMovementOnFundsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MovementOnFundsReportDto>> => {
    const context = this.reportMovementOnFundsRequestOpts(requestParameters);
    return this.reportMovementOnFundsFetch(context, initOverrides);
  };

  /**
   */
  reportMovementOnFunds = async (
    companyIdOrSlug: string,
    from: Date,
    to: Date,
    fundIds: Array<string>,
    hideWithoutMovements: boolean,
    operations?: FundHistoryOperationType,
    initOverrides?: RequestInit
  ): Promise<MovementOnFundsReportDto> => {
    const response = await this.reportMovementOnFundsRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        from: from,
        to: to,
        fundIds: fundIds,
        hideWithoutMovements: hideWithoutMovements,
        operations: operations,
      },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useReportMovementOnFunds = (
    requestParameters: ReportMovementOnFundsRequest,
    config?: SWRConfiguration<MovementOnFundsReportDto>
  ): SWRResponse<MovementOnFundsReportDto> => {
    return useSWR<MovementOnFundsReportDto>(
      () => this.reportMovementOnFundsRequestOpts(requestParameters),
      (context) => this.swrFetch(this.reportMovementOnFundsFetch(context)),
      config
    );
  };

  /**
   */
  protected reportOpenedFundingRequestsRequestOpts = (
    requestParameters: ReportOpenedFundingRequestsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling reportOpenedFundingRequests."
      );
    }

    if (requestParameters.fundIds === null || requestParameters.fundIds === undefined) {
      throw new runtime.RequiredError(
        "fundIds",
        "Required parameter requestParameters.fundIds was null or undefined when calling reportOpenedFundingRequests."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fundIds) {
      queryParameters["fundIds"] = requestParameters.fundIds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/report/opened-funding-requests`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected reportOpenedFundingRequestsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OpenedFundingRequestReportDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => OpenedFundingRequestReportDtoFromJSON(jsonValue));
  };

  /**
   */
  protected reportOpenedFundingRequestsRaw = async (
    requestParameters: ReportOpenedFundingRequestsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OpenedFundingRequestReportDto>> => {
    const context = this.reportOpenedFundingRequestsRequestOpts(requestParameters);
    return this.reportOpenedFundingRequestsFetch(context, initOverrides);
  };

  /**
   */
  reportOpenedFundingRequests = async (
    companyIdOrSlug: string,
    fundIds: Array<string>,
    initOverrides?: RequestInit
  ): Promise<OpenedFundingRequestReportDto> => {
    const response = await this.reportOpenedFundingRequestsRaw(
      { companyIdOrSlug: companyIdOrSlug, fundIds: fundIds },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useReportOpenedFundingRequests = (
    companyIdOrSlug: string,
    fundIds: Array<string>,
    config?: SWRConfiguration<OpenedFundingRequestReportDto>
  ): SWRResponse<OpenedFundingRequestReportDto> => {
    return useSWR<OpenedFundingRequestReportDto>(
      () => this.reportOpenedFundingRequestsRequestOpts({ companyIdOrSlug: companyIdOrSlug, fundIds: fundIds }),
      (context) => this.swrFetch(this.reportOpenedFundingRequestsFetch(context)),
      config
    );
  };
}
