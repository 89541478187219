/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  FundGroupCreateUpdateRequestDto,
  FundGroupCreateUpdateRequestDtoFromJSON,
  FundGroupCreateUpdateRequestDtoToJSON,
  FundGroupDto,
  FundGroupDtoFromJSON,
  FundGroupDtoToJSON,
} from "../models";

export interface FundGroupArchiveRequest {
  companyIdOrSlug: string;
  fundGroupId: string;
}

export interface FundGroupCreateRequest {
  companyIdOrSlug: string;
  fundGroupCreateUpdateRequestDto: FundGroupCreateUpdateRequestDto;
}

export interface FundGroupListRequest {
  companyIdOrSlug: string;
}

export interface FundGroupRenameRequest {
  companyIdOrSlug: string;
  fundGroupId: string;
  fundGroupCreateUpdateRequestDto: FundGroupCreateUpdateRequestDto;
}

/**
 *
 */
export class FundGroupApi extends runtime.BaseAPI {
  /**
   */
  protected fundGroupArchiveRequestOpts = (requestParameters: FundGroupArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundGroupArchive."
      );
    }

    if (requestParameters.fundGroupId === null || requestParameters.fundGroupId === undefined) {
      throw new runtime.RequiredError(
        "fundGroupId",
        "Required parameter requestParameters.fundGroupId was null or undefined when calling fundGroupArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund-group/{fundGroupId}/archive`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundGroupId"}}`, encodeURIComponent(String(requestParameters.fundGroupId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundGroupArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundGroupArchiveRaw = async (
    requestParameters: FundGroupArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundGroupArchiveRequestOpts(requestParameters);
    return this.fundGroupArchiveFetch(context, initOverrides);
  };

  /**
   */
  fundGroupArchive = async (
    companyIdOrSlug: string,
    fundGroupId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundGroupArchiveRaw({ companyIdOrSlug: companyIdOrSlug, fundGroupId: fundGroupId }, initOverrides);
  };

  /**
   */
  protected fundGroupCreateRequestOpts = (requestParameters: FundGroupCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundGroupCreate."
      );
    }

    if (
      requestParameters.fundGroupCreateUpdateRequestDto === null ||
      requestParameters.fundGroupCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundGroupCreateUpdateRequestDto",
        "Required parameter requestParameters.fundGroupCreateUpdateRequestDto was null or undefined when calling fundGroupCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund-group`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundGroupCreateUpdateRequestDtoToJSON(requestParameters.fundGroupCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected fundGroupCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundGroupCreateRaw = async (
    requestParameters: FundGroupCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundGroupCreateRequestOpts(requestParameters);
    return this.fundGroupCreateFetch(context, initOverrides);
  };

  /**
   */
  fundGroupCreate = async (
    companyIdOrSlug: string,
    fundGroupCreateUpdateRequestDto: FundGroupCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundGroupCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, fundGroupCreateUpdateRequestDto: fundGroupCreateUpdateRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected fundGroupListRequestOpts = (requestParameters: FundGroupListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundGroupList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund-group`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundGroupListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundGroupDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundGroupDtoFromJSON));
  };

  /**
   */
  protected fundGroupListRaw = async (
    requestParameters: FundGroupListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundGroupDto>>> => {
    const context = this.fundGroupListRequestOpts(requestParameters);
    return this.fundGroupListFetch(context, initOverrides);
  };

  /**
   */
  fundGroupList = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<Array<FundGroupDto>> => {
    const response = await this.fundGroupListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useFundGroupList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<FundGroupDto>>
  ): SWRResponse<Array<FundGroupDto>> => {
    return useSWR<Array<FundGroupDto>>(
      () => this.fundGroupListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.fundGroupListFetch(context)),
      config
    );
  };

  /**
   */
  protected fundGroupRenameRequestOpts = (requestParameters: FundGroupRenameRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundGroupRename."
      );
    }

    if (requestParameters.fundGroupId === null || requestParameters.fundGroupId === undefined) {
      throw new runtime.RequiredError(
        "fundGroupId",
        "Required parameter requestParameters.fundGroupId was null or undefined when calling fundGroupRename."
      );
    }

    if (
      requestParameters.fundGroupCreateUpdateRequestDto === null ||
      requestParameters.fundGroupCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundGroupCreateUpdateRequestDto",
        "Required parameter requestParameters.fundGroupCreateUpdateRequestDto was null or undefined when calling fundGroupRename."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund-group/{fundGroupId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundGroupId"}}`, encodeURIComponent(String(requestParameters.fundGroupId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundGroupCreateUpdateRequestDtoToJSON(requestParameters.fundGroupCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected fundGroupRenameFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundGroupRenameRaw = async (
    requestParameters: FundGroupRenameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundGroupRenameRequestOpts(requestParameters);
    return this.fundGroupRenameFetch(context, initOverrides);
  };

  /**
   */
  fundGroupRename = async (
    companyIdOrSlug: string,
    fundGroupId: string,
    fundGroupCreateUpdateRequestDto: FundGroupCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundGroupRenameRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        fundGroupId: fundGroupId,
        fundGroupCreateUpdateRequestDto: fundGroupCreateUpdateRequestDto,
      },
      initOverrides
    );
  };
}
