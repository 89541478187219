/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyCourseInviteActivateRequestDto
 */
export interface CompanyCourseInviteActivateRequestDto {
  /**
   *
   * @type {string}
   * @memberof CompanyCourseInviteActivateRequestDto
   */
  companyId: string;
}

export function CompanyCourseInviteActivateRequestDtoFromJSON(json: any): CompanyCourseInviteActivateRequestDto {
  return CompanyCourseInviteActivateRequestDtoFromJSONTyped(json, false);
}

export function CompanyCourseInviteActivateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyCourseInviteActivateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    companyId: json["companyId"],
  };
}

export function CompanyCourseInviteActivateRequestDtoToJSON(value?: CompanyCourseInviteActivateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    companyId: value.companyId,
  };
}
