/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinancialPlanFundAfterDto
 */
export interface FinancialPlanFundAfterDto {
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  incomes: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  loansFrom: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  loansTo: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  transfersTo: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  transfersFrom: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  loanRepaymentsTo: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  loanRepaymentsFrom: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  fundingRequestsApproved: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  balance: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  reserved: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundAfterDto
   */
  total: number;
}

export function FinancialPlanFundAfterDtoFromJSON(json: any): FinancialPlanFundAfterDto {
  return FinancialPlanFundAfterDtoFromJSONTyped(json, false);
}

export function FinancialPlanFundAfterDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanFundAfterDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    incomes: json["incomes"],
    loansFrom: json["loansFrom"],
    loansTo: json["loansTo"],
    transfersTo: json["transfersTo"],
    transfersFrom: json["transfersFrom"],
    loanRepaymentsTo: json["loanRepaymentsTo"],
    loanRepaymentsFrom: json["loanRepaymentsFrom"],
    fundingRequestsApproved: json["fundingRequestsApproved"],
    balance: json["balance"],
    reserved: json["reserved"],
    total: json["total"],
  };
}

export function FinancialPlanFundAfterDtoToJSON(value?: FinancialPlanFundAfterDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    incomes: value.incomes,
    loansFrom: value.loansFrom,
    loansTo: value.loansTo,
    transfersTo: value.transfersTo,
    transfersFrom: value.transfersFrom,
    loanRepaymentsTo: value.loanRepaymentsTo,
    loanRepaymentsFrom: value.loanRepaymentsFrom,
    fundingRequestsApproved: value.fundingRequestsApproved,
    balance: value.balance,
    reserved: value.reserved,
    total: value.total,
  };
}
