/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  LoansReportFundDto,
  LoansReportFundDtoFromJSON,
  LoansReportFundDtoFromJSONTyped,
  LoansReportFundDtoToJSON,
} from "./LoansReportFundDto";

/**
 *
 * @export
 * @interface LoansReportDto
 */
export interface LoansReportDto {
  /**
   *
   * @type {Date}
   * @memberof LoansReportDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof LoansReportDto
   */
  to: Date;
  /**
   *
   * @type {Array<LoansReportFundDto>}
   * @memberof LoansReportDto
   */
  funds: Array<LoansReportFundDto>;
}

export function LoansReportDtoFromJSON(json: any): LoansReportDto {
  return LoansReportDtoFromJSONTyped(json, false);
}

export function LoansReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoansReportDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    funds: (json["funds"] as Array<any>).map(LoansReportFundDtoFromJSON),
  };
}

export function LoansReportDtoToJSON(value?: LoansReportDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    funds: (value.funds as Array<any>).map(LoansReportFundDtoToJSON),
  };
}
