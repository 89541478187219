/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CompanyNotificationType,
  CompanyNotificationTypeFromJSON,
  CompanyNotificationTypeFromJSONTyped,
  CompanyNotificationTypeToJSON,
} from "./CompanyNotificationType";

/**
 *
 * @export
 * @interface AdminCompanyNotificationCreateRequestDto
 */
export interface AdminCompanyNotificationCreateRequestDto {
  /**
   *
   * @type {CompanyNotificationType}
   * @memberof AdminCompanyNotificationCreateRequestDto
   */
  type: CompanyNotificationType;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyNotificationCreateRequestDto
   */
  email: string;
}

export function AdminCompanyNotificationCreateRequestDtoFromJSON(json: any): AdminCompanyNotificationCreateRequestDto {
  return AdminCompanyNotificationCreateRequestDtoFromJSONTyped(json, false);
}

export function AdminCompanyNotificationCreateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminCompanyNotificationCreateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: CompanyNotificationTypeFromJSON(json["type"]),
    email: json["email"],
  };
}

export function AdminCompanyNotificationCreateRequestDtoToJSON(
  value?: AdminCompanyNotificationCreateRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: CompanyNotificationTypeToJSON(value.type),
    email: value.email,
  };
}
