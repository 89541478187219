import { MonthPlanPatchedApi } from "shared/api/back/apis";
import {
  AdminApi,
  AdminCompanyApi,
  AdminCompanyCourseInviteApi,
  AdminCompanyNotificationApi,
  AdminCourseApi,
  CompanyApi,
  CompanyCourseInviteApi,
  CompanyEmployeeApi,
  CompanyEmployeeInviteApi,
  CompanyInviteApi,
  CompanyRbacApi,
  DeferredIncomeApi,
  DepositTypeApi,
  EmployeeInviterApi,
  ExpenseItemApi,
  FileApi,
  FinanceSettingsApi,
  FinancialPlanApi,
  FinancialPlanCommonApi,
  FundApi,
  FundGroupApi,
  FundingRequestApi,
  FundingRequestPayoutApi,
  FundLoanApi,
  FundTransferApi,
  ImageApi,
  IncomeTypeApi,
  ReportApi,
  UserAuthApi,
  UserProfileApi,
} from "./_generated";
import { BackApiConfig } from "./config";

export class BackApi {
  constructor(private config: BackApiConfig = new BackApiConfig()) {}

  readonly admin = new AdminApi(this.config);
  readonly adminCompany = new AdminCompanyApi(this.config);
  readonly adminCompanyCourseInvite = new AdminCompanyCourseInviteApi(this.config);
  readonly adminCompanyNotification = new AdminCompanyNotificationApi(this.config);
  readonly adminCourse = new AdminCourseApi(this.config);

  readonly company = new CompanyApi(this.config);
  readonly companyCourseInvite = new CompanyCourseInviteApi(this.config);
  readonly companyEmployee = new CompanyEmployeeApi(this.config);
  readonly companyEmployeeInvite = new CompanyEmployeeInviteApi(this.config);
  readonly companyInvite = new CompanyInviteApi(this.config);
  readonly companyRbac = new CompanyRbacApi(this.config);
  readonly deferredIncome = new DeferredIncomeApi(this.config);
  readonly depositType = new DepositTypeApi(this.config);
  readonly employeeInviter = new EmployeeInviterApi(this.config);
  readonly expenseItem = new ExpenseItemApi(this.config);
  readonly file = new FileApi(this.config);
  readonly financeSettings = new FinanceSettingsApi(this.config);
  readonly financialPlan = new FinancialPlanApi(this.config);
  readonly financialPlanCommon = new FinancialPlanCommonApi(this.config);
  readonly fund = new FundApi(this.config);
  readonly fundGroup = new FundGroupApi(this.config);
  readonly fundingRequest = new FundingRequestApi(this.config);
  readonly fundingRequestPayout = new FundingRequestPayoutApi(this.config);
  readonly fundLoan = new FundLoanApi(this.config);
  readonly fundTransfer = new FundTransferApi(this.config);
  readonly image = new ImageApi(this.config);
  readonly incomeType = new IncomeTypeApi(this.config);
  readonly monthPlan = new MonthPlanPatchedApi(this.config);
  readonly report = new ReportApi(this.config);
  readonly userAuth = new UserAuthApi(this.config);
  readonly userProfile = new UserProfileApi(this.config);
}
