/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { FundTransferDto, FundTransferDtoFromJSON, FundTransferDtoToJSON } from "../models";

export interface FundTransferListRequest {
  companyIdOrSlug: string;
}

/**
 *
 */
export class FundTransferApi extends runtime.BaseAPI {
  /**
   */
  protected fundTransferListRequestOpts = (requestParameters: FundTransferListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundTransferList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund-transfer`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundTransferListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundTransferDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundTransferDtoFromJSON));
  };

  /**
   */
  protected fundTransferListRaw = async (
    requestParameters: FundTransferListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundTransferDto>>> => {
    const context = this.fundTransferListRequestOpts(requestParameters);
    return this.fundTransferListFetch(context, initOverrides);
  };

  /**
   */
  fundTransferList = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<Array<FundTransferDto>> => {
    const response = await this.fundTransferListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useFundTransferList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<FundTransferDto>>
  ): SWRResponse<Array<FundTransferDto>> => {
    return useSWR<Array<FundTransferDto>>(
      () => this.fundTransferListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.fundTransferListFetch(context)),
      config
    );
  };
}
