/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CompanyRolePermission,
  CompanyRolePermissionFromJSON,
  CompanyRolePermissionFromJSONTyped,
  CompanyRolePermissionToJSON,
} from "./CompanyRolePermission";

/**
 *
 * @export
 * @interface CompanyEmployeeRoleChangePermissionsRequestDto
 */
export interface CompanyEmployeeRoleChangePermissionsRequestDto {
  /**
   *
   * @type {Array<CompanyRolePermission>}
   * @memberof CompanyEmployeeRoleChangePermissionsRequestDto
   */
  permissions: Array<CompanyRolePermission>;
}

export function CompanyEmployeeRoleChangePermissionsRequestDtoFromJSON(
  json: any
): CompanyEmployeeRoleChangePermissionsRequestDto {
  return CompanyEmployeeRoleChangePermissionsRequestDtoFromJSONTyped(json, false);
}

export function CompanyEmployeeRoleChangePermissionsRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyEmployeeRoleChangePermissionsRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    permissions: (json["permissions"] as Array<any>).map(CompanyRolePermissionFromJSON),
  };
}

export function CompanyEmployeeRoleChangePermissionsRequestDtoToJSON(
  value?: CompanyEmployeeRoleChangePermissionsRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    permissions: (value.permissions as Array<any>).map(CompanyRolePermissionToJSON),
  };
}
