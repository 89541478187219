/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CompanyNotificationType {
  ENDS_AFTER_10_DAYS = "ACCESS_ENDS_AFTER_10_DAYS",
  ENDED_7_DAYS_AGO = "ACCESS_ENDED_7_DAYS_AGO",
}

export function CompanyNotificationTypeFromJSON(json: any): CompanyNotificationType {
  return CompanyNotificationTypeFromJSONTyped(json, false);
}

export function CompanyNotificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyNotificationType {
  return json as CompanyNotificationType;
}

export function CompanyNotificationTypeToJSON(value?: CompanyNotificationType | null): any {
  return value as any;
}
