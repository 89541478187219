/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DepositTypeDto,
  DepositTypeDtoFromJSON,
  DepositTypeDtoFromJSONTyped,
  DepositTypeDtoToJSON,
} from "./DepositTypeDto";
import { FundDto, FundDtoFromJSON, FundDtoFromJSONTyped, FundDtoToJSON } from "./FundDto";
import {
  FundingRequestPayoutDtoCreator,
  FundingRequestPayoutDtoCreatorFromJSON,
  FundingRequestPayoutDtoCreatorFromJSONTyped,
  FundingRequestPayoutDtoCreatorToJSON,
} from "./FundingRequestPayoutDtoCreator";

/**
 *
 * @export
 * @interface DepositDto
 */
export interface DepositDto {
  /**
   *
   * @type {string}
   * @memberof DepositDto
   */
  id: string;
  /**
   *
   * @type {DepositTypeDto}
   * @memberof DepositDto
   */
  depositType: DepositTypeDto;
  /**
   *
   * @type {Date}
   * @memberof DepositDto
   */
  createdAt: Date;
  /**
   *
   * @type {number}
   * @memberof DepositDto
   */
  amount: number;
  /**
   *
   * @type {FundDto}
   * @memberof DepositDto
   */
  fund: FundDto;
  /**
   *
   * @type {FundingRequestPayoutDtoCreator}
   * @memberof DepositDto
   */
  creator: FundingRequestPayoutDtoCreator | null;
}

export function DepositDtoFromJSON(json: any): DepositDto {
  return DepositDtoFromJSONTyped(json, false);
}

export function DepositDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    depositType: DepositTypeDtoFromJSON(json["depositType"]),
    createdAt: new Date(json["createdAt"]),
    amount: json["amount"],
    fund: FundDtoFromJSON(json["fund"]),
    creator: FundingRequestPayoutDtoCreatorFromJSON(json["creator"]),
  };
}

export function DepositDtoToJSON(value?: DepositDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    depositType: DepositTypeDtoToJSON(value.depositType),
    createdAt: value.createdAt.toISOString(),
    amount: value.amount,
    fund: FundDtoToJSON(value.fund),
    creator: FundingRequestPayoutDtoCreatorToJSON(value.creator),
  };
}
