/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CompanyEmployeeDto,
  CompanyEmployeeDtoFromJSON,
  CompanyEmployeeDtoFromJSONTyped,
  CompanyEmployeeDtoToJSON,
} from "./CompanyEmployeeDto";

/**
 *
 * @export
 * @interface FundingRequestHistoryDto
 */
export interface FundingRequestHistoryDto {
  /**
   *
   * @type {string}
   * @memberof FundingRequestHistoryDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FundingRequestHistoryDto
   */
  fundingRequestId: string;
  /**
   *
   * @type {string}
   * @memberof FundingRequestHistoryDto
   */
  type: FundingRequestHistoryDtoTypeEnum;
  /**
   *
   * @type {CompanyEmployeeDto}
   * @memberof FundingRequestHistoryDto
   */
  employee: CompanyEmployeeDto;
  /**
   *
   * @type {Date}
   * @memberof FundingRequestHistoryDto
   */
  createdAt: Date;
  /**
   *
   * @type {number}
   * @memberof FundingRequestHistoryDto
   */
  amount: number | null;
}

/**
 * @export
 * @enum {string}
 */
export type FundingRequestHistoryDtoTypeEnum =
  | "WAIT_ADMIT"
  | "CREATED"
  | "UPDATED"
  | "ADMITTED"
  | "ADMIT_ROLLBACK"
  | "DECLINED"
  | "PAYOUT"
  | "APPROVED"
  | "CLOSED"
  | "CANCELED";

export function FundingRequestHistoryDtoFromJSON(json: any): FundingRequestHistoryDto {
  return FundingRequestHistoryDtoFromJSONTyped(json, false);
}

export function FundingRequestHistoryDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundingRequestHistoryDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fundingRequestId: json["fundingRequestId"],
    type: json["type"],
    employee: CompanyEmployeeDtoFromJSON(json["employee"]),
    createdAt: new Date(json["createdAt"]),
    amount: json["amount"],
  };
}

export function FundingRequestHistoryDtoToJSON(value?: FundingRequestHistoryDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fundingRequestId: value.fundingRequestId,
    type: value.type,
    employee: CompanyEmployeeDtoToJSON(value.employee),
    createdAt: value.createdAt.toISOString(),
    amount: value.amount,
  };
}
