/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FundingRequestPayoutEditCommentRequestDto
 */
export interface FundingRequestPayoutEditCommentRequestDto {
  /**
   *
   * @type {string}
   * @memberof FundingRequestPayoutEditCommentRequestDto
   */
  comment: string | null;
}

export function FundingRequestPayoutEditCommentRequestDtoFromJSON(
  json: any
): FundingRequestPayoutEditCommentRequestDto {
  return FundingRequestPayoutEditCommentRequestDtoFromJSONTyped(json, false);
}

export function FundingRequestPayoutEditCommentRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundingRequestPayoutEditCommentRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    comment: json["comment"],
  };
}

export function FundingRequestPayoutEditCommentRequestDtoToJSON(
  value?: FundingRequestPayoutEditCommentRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    comment: value.comment,
  };
}
