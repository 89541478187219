/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DeferredIncomeHistoryOperationType {
  INCOME_DEFFER = "INCOME_DEFFER",
  INCOME_RETURN = "INCOME_RETURN",
  LOAN = "LOAN",
  LOAN_REPAYMENT = "LOAN_REPAYMENT",
  FUNDING_REQUEST_RESERVATION = "FUNDING_REQUEST_RESERVATION",
  FUNDING_REQUEST_UN_RESERVATION = "FUNDING_REQUEST_UN_RESERVATION",
  FUNDING_REQUEST_PAYOUT = "FUNDING_REQUEST_PAYOUT",
}

export function DeferredIncomeHistoryOperationTypeFromJSON(json: any): DeferredIncomeHistoryOperationType {
  return DeferredIncomeHistoryOperationTypeFromJSONTyped(json, false);
}

export function DeferredIncomeHistoryOperationTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeferredIncomeHistoryOperationType {
  return json as DeferredIncomeHistoryOperationType;
}

export function DeferredIncomeHistoryOperationTypeToJSON(value?: DeferredIncomeHistoryOperationType | null): any {
  return value as any;
}
