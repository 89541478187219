/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanFundingRequestDecisionType,
  FinancialPlanFundingRequestDecisionTypeFromJSON,
  FinancialPlanFundingRequestDecisionTypeFromJSONTyped,
  FinancialPlanFundingRequestDecisionTypeToJSON,
} from "./FinancialPlanFundingRequestDecisionType";
import {
  FundingRequestDto,
  FundingRequestDtoFromJSON,
  FundingRequestDtoFromJSONTyped,
  FundingRequestDtoToJSON,
} from "./FundingRequestDto";

/**
 *
 * @export
 * @interface FinancialPlanFundingRequestDecisionDto
 */
export interface FinancialPlanFundingRequestDecisionDto {
  /**
   *
   * @type {FinancialPlanFundingRequestDecisionType}
   * @memberof FinancialPlanFundingRequestDecisionDto
   */
  decisionType: FinancialPlanFundingRequestDecisionType;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanFundingRequestDecisionDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundingRequestDecisionDto
   */
  lastApprovedAmount: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundingRequestDecisionDto
   */
  approvedAmount: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundingRequestDecisionDto
   */
  totalApprovedAmount: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFundingRequestDecisionDto
   */
  paidAmount: number;
  /**
   *
   * @type {FundingRequestDto}
   * @memberof FinancialPlanFundingRequestDecisionDto
   */
  fundingRequest: FundingRequestDto;
}

export function FinancialPlanFundingRequestDecisionDtoFromJSON(json: any): FinancialPlanFundingRequestDecisionDto {
  return FinancialPlanFundingRequestDecisionDtoFromJSONTyped(json, false);
}

export function FinancialPlanFundingRequestDecisionDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanFundingRequestDecisionDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    decisionType: FinancialPlanFundingRequestDecisionTypeFromJSON(json["decisionType"]),
    id: json["id"],
    lastApprovedAmount: json["lastApprovedAmount"],
    approvedAmount: json["approvedAmount"],
    totalApprovedAmount: json["totalApprovedAmount"],
    paidAmount: json["paidAmount"],
    fundingRequest: FundingRequestDtoFromJSON(json["fundingRequest"]),
  };
}

export function FinancialPlanFundingRequestDecisionDtoToJSON(
  value?: FinancialPlanFundingRequestDecisionDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    decisionType: FinancialPlanFundingRequestDecisionTypeToJSON(value.decisionType),
    id: value.id,
    lastApprovedAmount: value.lastApprovedAmount,
    approvedAmount: value.approvedAmount,
    totalApprovedAmount: value.totalApprovedAmount,
    paidAmount: value.paidAmount,
    fundingRequest: FundingRequestDtoToJSON(value.fundingRequest),
  };
}
