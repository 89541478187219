/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  AdminCompanyCourseInviteCreateRequestDto,
  AdminCompanyCourseInviteCreateRequestDtoFromJSON,
  AdminCompanyCourseInviteCreateRequestDtoToJSON,
  AdminCompanyCourseInviteDto,
  AdminCompanyCourseInviteDtoFromJSON,
  AdminCompanyCourseInviteDtoToJSON,
  AdminCompanyDto,
  AdminCompanyDtoFromJSON,
  AdminCompanyDtoToJSON,
} from "../models";

export interface AdminCompanyCourseInviteCompaniesRequest {
  id: string;
}

export interface AdminCompanyCourseInviteCreateRequest {
  adminCompanyCourseInviteCreateRequestDto: AdminCompanyCourseInviteCreateRequestDto;
}

/**
 *
 */
export class AdminCompanyCourseInviteApi extends runtime.BaseAPI {
  /**
   */
  protected adminCompanyCourseInviteCompaniesRequestOpts = (
    requestParameters: AdminCompanyCourseInviteCompaniesRequest
  ): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling adminCompanyCourseInviteCompanies."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company-course-invite/{id}/companies`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyCourseInviteCompaniesFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminCompanyDtoFromJSON));
  };

  /**
   */
  protected adminCompanyCourseInviteCompaniesRaw = async (
    requestParameters: AdminCompanyCourseInviteCompaniesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyDto>>> => {
    const context = this.adminCompanyCourseInviteCompaniesRequestOpts(requestParameters);
    return this.adminCompanyCourseInviteCompaniesFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyCourseInviteCompanies = async (
    id: string,
    initOverrides?: RequestInit
  ): Promise<Array<AdminCompanyDto>> => {
    const response = await this.adminCompanyCourseInviteCompaniesRaw({ id: id }, initOverrides);
    return await response.value();
  };

  /**
   */
  useAdminCompanyCourseInviteCompanies = (
    id: string,
    config?: SWRConfiguration<Array<AdminCompanyDto>>
  ): SWRResponse<Array<AdminCompanyDto>> => {
    return useSWR<Array<AdminCompanyDto>>(
      () => this.adminCompanyCourseInviteCompaniesRequestOpts({ id: id }),
      (context) => this.swrFetch(this.adminCompanyCourseInviteCompaniesFetch(context)),
      config
    );
  };

  /**
   */
  protected adminCompanyCourseInviteCreateRequestOpts = (
    requestParameters: AdminCompanyCourseInviteCreateRequest
  ): runtime.RequestOpts => {
    if (
      requestParameters.adminCompanyCourseInviteCreateRequestDto === null ||
      requestParameters.adminCompanyCourseInviteCreateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyCourseInviteCreateRequestDto",
        "Required parameter requestParameters.adminCompanyCourseInviteCreateRequestDto was null or undefined when calling adminCompanyCourseInviteCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company-course-invite`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyCourseInviteCreateRequestDtoToJSON(requestParameters.adminCompanyCourseInviteCreateRequestDto),
    };
  };

  /**
   */
  protected adminCompanyCourseInviteCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyCourseInviteCreateRaw = async (
    requestParameters: AdminCompanyCourseInviteCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyCourseInviteCreateRequestOpts(requestParameters);
    return this.adminCompanyCourseInviteCreateFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyCourseInviteCreate = async (
    adminCompanyCourseInviteCreateRequestDto: AdminCompanyCourseInviteCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyCourseInviteCreateRaw(
      { adminCompanyCourseInviteCreateRequestDto: adminCompanyCourseInviteCreateRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyCourseInviteListRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company-course-invite`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyCourseInviteListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyCourseInviteDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminCompanyCourseInviteDtoFromJSON));
  };

  /**
   */
  protected adminCompanyCourseInviteListRaw = async (
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyCourseInviteDto>>> => {
    const context = this.adminCompanyCourseInviteListRequestOpts();
    return this.adminCompanyCourseInviteListFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyCourseInviteList = async (initOverrides?: RequestInit): Promise<Array<AdminCompanyCourseInviteDto>> => {
    const response = await this.adminCompanyCourseInviteListRaw(initOverrides);
    return await response.value();
  };

  /**
   */
  useAdminCompanyCourseInviteList = (
    config?: SWRConfiguration<Array<AdminCompanyCourseInviteDto>>
  ): SWRResponse<Array<AdminCompanyCourseInviteDto>> => {
    return useSWR<Array<AdminCompanyCourseInviteDto>>(
      () => this.adminCompanyCourseInviteListRequestOpts(),
      (context) => this.swrFetch(this.adminCompanyCourseInviteListFetch(context)),
      config
    );
  };
}
