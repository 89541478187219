/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  ExpenseItemCreateUpdateRequestDto,
  ExpenseItemCreateUpdateRequestDtoFromJSON,
  ExpenseItemCreateUpdateRequestDtoToJSON,
  ExpenseItemDto,
  ExpenseItemDtoFromJSON,
  ExpenseItemDtoToJSON,
} from "../models";

export interface ExpenseItemArchiveRequest {
  companyIdOrSlug: string;
  expenseItemId: string;
}

export interface ExpenseItemCreateRequest {
  companyIdOrSlug: string;
  expenseItemCreateUpdateRequestDto: ExpenseItemCreateUpdateRequestDto;
}

export interface ExpenseItemListRequest {
  companyIdOrSlug: string;
  fundId?: string;
}

export interface ExpenseItemUpdateRequest {
  companyIdOrSlug: string;
  expenseItemId: string;
  expenseItemCreateUpdateRequestDto: ExpenseItemCreateUpdateRequestDto;
}

/**
 *
 */
export class ExpenseItemApi extends runtime.BaseAPI {
  /**
   */
  protected expenseItemArchiveRequestOpts = (requestParameters: ExpenseItemArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling expenseItemArchive."
      );
    }

    if (requestParameters.expenseItemId === null || requestParameters.expenseItemId === undefined) {
      throw new runtime.RequiredError(
        "expenseItemId",
        "Required parameter requestParameters.expenseItemId was null or undefined when calling expenseItemArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/expense-item/{expenseItemId}/archive`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"expenseItemId"}}`, encodeURIComponent(String(requestParameters.expenseItemId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected expenseItemArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected expenseItemArchiveRaw = async (
    requestParameters: ExpenseItemArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.expenseItemArchiveRequestOpts(requestParameters);
    return this.expenseItemArchiveFetch(context, initOverrides);
  };

  /**
   */
  expenseItemArchive = async (
    companyIdOrSlug: string,
    expenseItemId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.expenseItemArchiveRaw({ companyIdOrSlug: companyIdOrSlug, expenseItemId: expenseItemId }, initOverrides);
  };

  /**
   */
  protected expenseItemCreateRequestOpts = (requestParameters: ExpenseItemCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling expenseItemCreate."
      );
    }

    if (
      requestParameters.expenseItemCreateUpdateRequestDto === null ||
      requestParameters.expenseItemCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "expenseItemCreateUpdateRequestDto",
        "Required parameter requestParameters.expenseItemCreateUpdateRequestDto was null or undefined when calling expenseItemCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/expense-item`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ExpenseItemCreateUpdateRequestDtoToJSON(requestParameters.expenseItemCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected expenseItemCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ExpenseItemDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => ExpenseItemDtoFromJSON(jsonValue));
  };

  /**
   */
  protected expenseItemCreateRaw = async (
    requestParameters: ExpenseItemCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ExpenseItemDto>> => {
    const context = this.expenseItemCreateRequestOpts(requestParameters);
    return this.expenseItemCreateFetch(context, initOverrides);
  };

  /**
   */
  expenseItemCreate = async (
    companyIdOrSlug: string,
    expenseItemCreateUpdateRequestDto: ExpenseItemCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<ExpenseItemDto> => {
    const response = await this.expenseItemCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, expenseItemCreateUpdateRequestDto: expenseItemCreateUpdateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected expenseItemListRequestOpts = (requestParameters: ExpenseItemListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling expenseItemList."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fundId !== undefined) {
      queryParameters["fundId"] = requestParameters.fundId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/expense-item`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected expenseItemListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ExpenseItemDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExpenseItemDtoFromJSON));
  };

  /**
   */
  protected expenseItemListRaw = async (
    requestParameters: ExpenseItemListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ExpenseItemDto>>> => {
    const context = this.expenseItemListRequestOpts(requestParameters);
    return this.expenseItemListFetch(context, initOverrides);
  };

  /**
   */
  expenseItemList = async (
    companyIdOrSlug: string,
    fundId?: string,
    initOverrides?: RequestInit
  ): Promise<Array<ExpenseItemDto>> => {
    const response = await this.expenseItemListRaw({ companyIdOrSlug: companyIdOrSlug, fundId: fundId }, initOverrides);
    return await response.value();
  };

  /**
   */
  useExpenseItemList = (
    companyIdOrSlug: string,
    fundId?: string,
    config?: SWRConfiguration<Array<ExpenseItemDto>>
  ): SWRResponse<Array<ExpenseItemDto>> => {
    return useSWR<Array<ExpenseItemDto>>(
      () => this.expenseItemListRequestOpts({ companyIdOrSlug: companyIdOrSlug, fundId: fundId }),
      (context) => this.swrFetch(this.expenseItemListFetch(context)),
      config
    );
  };

  /**
   */
  protected expenseItemUpdateRequestOpts = (requestParameters: ExpenseItemUpdateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling expenseItemUpdate."
      );
    }

    if (requestParameters.expenseItemId === null || requestParameters.expenseItemId === undefined) {
      throw new runtime.RequiredError(
        "expenseItemId",
        "Required parameter requestParameters.expenseItemId was null or undefined when calling expenseItemUpdate."
      );
    }

    if (
      requestParameters.expenseItemCreateUpdateRequestDto === null ||
      requestParameters.expenseItemCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "expenseItemCreateUpdateRequestDto",
        "Required parameter requestParameters.expenseItemCreateUpdateRequestDto was null or undefined when calling expenseItemUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/expense-item/{expenseItemId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"expenseItemId"}}`, encodeURIComponent(String(requestParameters.expenseItemId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ExpenseItemCreateUpdateRequestDtoToJSON(requestParameters.expenseItemCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected expenseItemUpdateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected expenseItemUpdateRaw = async (
    requestParameters: ExpenseItemUpdateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.expenseItemUpdateRequestOpts(requestParameters);
    return this.expenseItemUpdateFetch(context, initOverrides);
  };

  /**
   */
  expenseItemUpdate = async (
    companyIdOrSlug: string,
    expenseItemId: string,
    expenseItemCreateUpdateRequestDto: ExpenseItemCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.expenseItemUpdateRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        expenseItemId: expenseItemId,
        expenseItemCreateUpdateRequestDto: expenseItemCreateUpdateRequestDto,
      },
      initOverrides
    );
  };
}
