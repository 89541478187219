/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FundHistoryDtoDeposit
 */
export interface FundHistoryDtoDeposit {
  /**
   *
   * @type {string}
   * @memberof FundHistoryDtoDeposit
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDtoDeposit
   */
  number: number;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryDtoDeposit
   */
  createdAt: Date;
  /**
   *
   * @type {string}
   * @memberof FundHistoryDtoDeposit
   */
  depositTypeName: string;
}

export function FundHistoryDtoDepositFromJSON(json: any): FundHistoryDtoDeposit {
  return FundHistoryDtoDepositFromJSONTyped(json, false);
}

export function FundHistoryDtoDepositFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundHistoryDtoDeposit {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    number: json["number"],
    createdAt: new Date(json["createdAt"]),
    depositTypeName: json["depositTypeName"],
  };
}

export function FundHistoryDtoDepositToJSON(value?: FundHistoryDtoDeposit | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    number: value.number,
    createdAt: value.createdAt.toISOString(),
    depositTypeName: value.depositTypeName,
  };
}
