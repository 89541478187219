/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  IncomeTypeCreateUpdateRequestDto,
  IncomeTypeCreateUpdateRequestDtoFromJSON,
  IncomeTypeCreateUpdateRequestDtoToJSON,
  IncomeTypeDto,
  IncomeTypeDtoFromJSON,
  IncomeTypeDtoToJSON,
} from "../models";

export interface IncomeTypeArchiveRequest {
  companyIdOrSlug: string;
  incomeTypeId: string;
}

export interface IncomeTypeCreateRequest {
  companyIdOrSlug: string;
  incomeTypeCreateUpdateRequestDto: IncomeTypeCreateUpdateRequestDto;
}

export interface IncomeTypeListRequest {
  companyIdOrSlug: string;
}

export interface IncomeTypeRenameRequest {
  companyIdOrSlug: string;
  incomeTypeId: string;
  incomeTypeCreateUpdateRequestDto: IncomeTypeCreateUpdateRequestDto;
}

/**
 *
 */
export class IncomeTypeApi extends runtime.BaseAPI {
  /**
   */
  protected incomeTypeArchiveRequestOpts = (requestParameters: IncomeTypeArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling incomeTypeArchive."
      );
    }

    if (requestParameters.incomeTypeId === null || requestParameters.incomeTypeId === undefined) {
      throw new runtime.RequiredError(
        "incomeTypeId",
        "Required parameter requestParameters.incomeTypeId was null or undefined when calling incomeTypeArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/income-type/{incomeTypeId}/archive`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"incomeTypeId"}}`, encodeURIComponent(String(requestParameters.incomeTypeId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected incomeTypeArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected incomeTypeArchiveRaw = async (
    requestParameters: IncomeTypeArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.incomeTypeArchiveRequestOpts(requestParameters);
    return this.incomeTypeArchiveFetch(context, initOverrides);
  };

  /**
   */
  incomeTypeArchive = async (
    companyIdOrSlug: string,
    incomeTypeId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.incomeTypeArchiveRaw({ companyIdOrSlug: companyIdOrSlug, incomeTypeId: incomeTypeId }, initOverrides);
  };

  /**
   */
  protected incomeTypeCreateRequestOpts = (requestParameters: IncomeTypeCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling incomeTypeCreate."
      );
    }

    if (
      requestParameters.incomeTypeCreateUpdateRequestDto === null ||
      requestParameters.incomeTypeCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "incomeTypeCreateUpdateRequestDto",
        "Required parameter requestParameters.incomeTypeCreateUpdateRequestDto was null or undefined when calling incomeTypeCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/income-type`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IncomeTypeCreateUpdateRequestDtoToJSON(requestParameters.incomeTypeCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected incomeTypeCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<IncomeTypeDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => IncomeTypeDtoFromJSON(jsonValue));
  };

  /**
   */
  protected incomeTypeCreateRaw = async (
    requestParameters: IncomeTypeCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<IncomeTypeDto>> => {
    const context = this.incomeTypeCreateRequestOpts(requestParameters);
    return this.incomeTypeCreateFetch(context, initOverrides);
  };

  /**
   */
  incomeTypeCreate = async (
    companyIdOrSlug: string,
    incomeTypeCreateUpdateRequestDto: IncomeTypeCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<IncomeTypeDto> => {
    const response = await this.incomeTypeCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, incomeTypeCreateUpdateRequestDto: incomeTypeCreateUpdateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected incomeTypeListRequestOpts = (requestParameters: IncomeTypeListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling incomeTypeList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/income-type`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected incomeTypeListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<IncomeTypeDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IncomeTypeDtoFromJSON));
  };

  /**
   */
  protected incomeTypeListRaw = async (
    requestParameters: IncomeTypeListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<IncomeTypeDto>>> => {
    const context = this.incomeTypeListRequestOpts(requestParameters);
    return this.incomeTypeListFetch(context, initOverrides);
  };

  /**
   */
  incomeTypeList = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<Array<IncomeTypeDto>> => {
    const response = await this.incomeTypeListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useIncomeTypeList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<IncomeTypeDto>>
  ): SWRResponse<Array<IncomeTypeDto>> => {
    return useSWR<Array<IncomeTypeDto>>(
      () => this.incomeTypeListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.incomeTypeListFetch(context)),
      config
    );
  };

  /**
   */
  protected incomeTypeRenameRequestOpts = (requestParameters: IncomeTypeRenameRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling incomeTypeRename."
      );
    }

    if (requestParameters.incomeTypeId === null || requestParameters.incomeTypeId === undefined) {
      throw new runtime.RequiredError(
        "incomeTypeId",
        "Required parameter requestParameters.incomeTypeId was null or undefined when calling incomeTypeRename."
      );
    }

    if (
      requestParameters.incomeTypeCreateUpdateRequestDto === null ||
      requestParameters.incomeTypeCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "incomeTypeCreateUpdateRequestDto",
        "Required parameter requestParameters.incomeTypeCreateUpdateRequestDto was null or undefined when calling incomeTypeRename."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/income-type/{incomeTypeId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"incomeTypeId"}}`, encodeURIComponent(String(requestParameters.incomeTypeId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IncomeTypeCreateUpdateRequestDtoToJSON(requestParameters.incomeTypeCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected incomeTypeRenameFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected incomeTypeRenameRaw = async (
    requestParameters: IncomeTypeRenameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.incomeTypeRenameRequestOpts(requestParameters);
    return this.incomeTypeRenameFetch(context, initOverrides);
  };

  /**
   */
  incomeTypeRename = async (
    companyIdOrSlug: string,
    incomeTypeId: string,
    incomeTypeCreateUpdateRequestDto: IncomeTypeCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.incomeTypeRenameRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        incomeTypeId: incomeTypeId,
        incomeTypeCreateUpdateRequestDto: incomeTypeCreateUpdateRequestDto,
      },
      initOverrides
    );
  };
}
