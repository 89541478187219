/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeferredIncomeCreateRequestDto
 */
export interface DeferredIncomeCreateRequestDto {
  /**
   *
   * @type {string}
   * @memberof DeferredIncomeCreateRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DeferredIncomeCreateRequestDto
   */
  incomeTypeId: string;
}

export function DeferredIncomeCreateRequestDtoFromJSON(json: any): DeferredIncomeCreateRequestDto {
  return DeferredIncomeCreateRequestDtoFromJSONTyped(json, false);
}

export function DeferredIncomeCreateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeferredIncomeCreateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    incomeTypeId: json["incomeTypeId"],
  };
}

export function DeferredIncomeCreateRequestDtoToJSON(value?: DeferredIncomeCreateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    incomeTypeId: value.incomeTypeId,
  };
}
