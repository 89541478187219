/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserProfileDtoAvatarImage
 */
export interface UserProfileDtoAvatarImage {
  /**
   *
   * @type {string}
   * @memberof UserProfileDtoAvatarImage
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDtoAvatarImage
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDtoAvatarImage
   */
  blur: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDtoAvatarImage
   */
  width: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDtoAvatarImage
   */
  height: string;
}

export function UserProfileDtoAvatarImageFromJSON(json: any): UserProfileDtoAvatarImage {
  return UserProfileDtoAvatarImageFromJSONTyped(json, false);
}

export function UserProfileDtoAvatarImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserProfileDtoAvatarImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    url: json["url"],
    blur: json["blur"],
    width: json["width"],
    height: json["height"],
  };
}

export function UserProfileDtoAvatarImageToJSON(value?: UserProfileDtoAvatarImage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    url: value.url,
    blur: value.blur,
    width: value.width,
    height: value.height,
  };
}
