/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FundTransferDto
 */
export interface FundTransferDto {
  /**
   *
   * @type {string}
   * @memberof FundTransferDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FundTransferDto
   */
  fromFundId: string;
  /**
   *
   * @type {string}
   * @memberof FundTransferDto
   */
  toFundId: string;
  /**
   *
   * @type {number}
   * @memberof FundTransferDto
   */
  amount: number;
  /**
   *
   * @type {Date}
   * @memberof FundTransferDto
   */
  createdAt: Date;
  /**
   *
   * @type {string}
   * @memberof FundTransferDto
   */
  comment: string;
}

export function FundTransferDtoFromJSON(json: any): FundTransferDto {
  return FundTransferDtoFromJSONTyped(json, false);
}

export function FundTransferDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundTransferDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fromFundId: json["fromFundId"],
    toFundId: json["toFundId"],
    amount: json["amount"],
    createdAt: new Date(json["createdAt"]),
    comment: json["comment"],
  };
}

export function FundTransferDtoToJSON(value?: FundTransferDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fromFundId: value.fromFundId,
    toFundId: value.toFundId,
    amount: value.amount,
    createdAt: value.createdAt.toISOString(),
    comment: value.comment,
  };
}
