/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { ImageDto, ImageDtoFromJSON, ImageDtoToJSON } from "../models";

export interface ImageImageRequest {
  id: string;
}

export interface ImageUploadImageRequest {
  file?: Blob;
}

/**
 *
 */
export class ImageApi extends runtime.BaseAPI {
  /**
   */
  protected imageImageRequestOpts = (requestParameters: ImageImageRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling imageImage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    return {
      path: `/api/image/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected imageImageFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ImageDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => ImageDtoFromJSON(jsonValue));
  };

  /**
   */
  protected imageImageRaw = async (
    requestParameters: ImageImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ImageDto>> => {
    const context = this.imageImageRequestOpts(requestParameters);
    return this.imageImageFetch(context, initOverrides);
  };

  /**
   */
  imageImage = async (id: string, initOverrides?: RequestInit): Promise<ImageDto> => {
    const response = await this.imageImageRaw({ id: id }, initOverrides);
    return await response.value();
  };

  /**
   */
  useImageImage = (id: string, config?: SWRConfiguration<ImageDto>): SWRResponse<ImageDto> => {
    return useSWR<ImageDto>(
      () => this.imageImageRequestOpts({ id: id }),
      (context) => this.swrFetch(this.imageImageFetch(context)),
      config
    );
  };

  /**
   */
  protected imageUploadImageRequestOpts = (requestParameters: ImageUploadImageRequest): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    return {
      path: `/api/image`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    };
  };

  /**
   */
  protected imageUploadImageFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ImageDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => ImageDtoFromJSON(jsonValue));
  };

  /**
   */
  protected imageUploadImageRaw = async (
    requestParameters: ImageUploadImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ImageDto>> => {
    const context = this.imageUploadImageRequestOpts(requestParameters);
    return this.imageUploadImageFetch(context, initOverrides);
  };

  /**
   */
  imageUploadImage = async (file?: Blob, initOverrides?: RequestInit): Promise<ImageDto> => {
    const response = await this.imageUploadImageRaw({ file: file }, initOverrides);
    return await response.value();
  };
}
