declare global {
  interface String {
    toUpperCaseFirst(): string;
  }
}

String.prototype.toUpperCaseFirst = function () {
  return this[0].toUpperCase() + this.substring(1);
};

export {};
