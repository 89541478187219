/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { AdminRole, AdminRoleFromJSON, AdminRoleFromJSONTyped, AdminRoleToJSON } from "./AdminRole";

/**
 *
 * @export
 * @interface AdminCreateRequestDto
 */
export interface AdminCreateRequestDto {
  /**
   *
   * @type {AdminRole}
   * @memberof AdminCreateRequestDto
   */
  role: AdminRole;
  /**
   *
   * @type {string}
   * @memberof AdminCreateRequestDto
   */
  email: string;
}

export function AdminCreateRequestDtoFromJSON(json: any): AdminCreateRequestDto {
  return AdminCreateRequestDtoFromJSONTyped(json, false);
}

export function AdminCreateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCreateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: AdminRoleFromJSON(json["role"]),
    email: json["email"],
  };
}

export function AdminCreateRequestDtoToJSON(value?: AdminCreateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: AdminRoleToJSON(value.role),
    email: value.email,
  };
}
