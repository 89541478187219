/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinanceSettingsChangeRequest
 */
export interface FinanceSettingsChangeRequest {
  /**
   *
   * @type {number}
   * @memberof FinanceSettingsChangeRequest
   */
  financialPlanningWeekDay: number;
}

export function FinanceSettingsChangeRequestFromJSON(json: any): FinanceSettingsChangeRequest {
  return FinanceSettingsChangeRequestFromJSONTyped(json, false);
}

export function FinanceSettingsChangeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinanceSettingsChangeRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    financialPlanningWeekDay: json["financialPlanningWeekDay"],
  };
}

export function FinanceSettingsChangeRequestToJSON(value?: FinanceSettingsChangeRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    financialPlanningWeekDay: value.financialPlanningWeekDay,
  };
}
