/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { CourseDto, CourseDtoFromJSON, CourseDtoFromJSONTyped, CourseDtoToJSON } from "./CourseDto";

/**
 *
 * @export
 * @interface CompanyCourseInviteDto
 */
export interface CompanyCourseInviteDto {
  /**
   *
   * @type {string}
   * @memberof CompanyCourseInviteDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyCourseInviteDto
   */
  invite: string;
  /**
   *
   * @type {CourseDto}
   * @memberof CompanyCourseInviteDto
   */
  course: CourseDto;
  /**
   *
   * @type {Date}
   * @memberof CompanyCourseInviteDto
   */
  accessUntil: Date;
  /**
   *
   * @type {Date}
   * @memberof CompanyCourseInviteDto
   */
  expiresAt: Date;
}

export function CompanyCourseInviteDtoFromJSON(json: any): CompanyCourseInviteDto {
  return CompanyCourseInviteDtoFromJSONTyped(json, false);
}

export function CompanyCourseInviteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCourseInviteDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    invite: json["invite"],
    course: CourseDtoFromJSON(json["course"]),
    accessUntil: new Date(json["accessUntil"]),
    expiresAt: new Date(json["expiresAt"]),
  };
}

export function CompanyCourseInviteDtoToJSON(value?: CompanyCourseInviteDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    invite: value.invite,
    course: CourseDtoToJSON(value.course),
    accessUntil: value.accessUntil.toISOString(),
    expiresAt: value.expiresAt.toISOString(),
  };
}
