/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MonthPlanStatus {
  DRAFT = "DRAFT",
  ON_APPROVAL = "ON_APPROVAL",
  APPROVED = "APPROVED",
  APPROVED_AND_HAS_FINANCIAL_PLAN = "APPROVED_AND_HAS_FINANCIAL_PLAN",
}

export function MonthPlanStatusFromJSON(json: any): MonthPlanStatus {
  return MonthPlanStatusFromJSONTyped(json, false);
}

export function MonthPlanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanStatus {
  return json as MonthPlanStatus;
}

export function MonthPlanStatusToJSON(value?: MonthPlanStatus | null): any {
  return value as any;
}
