/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FundHistoryOperationType {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  TRANSFER_FROM = "TRANSFER_FROM",
  TRANSFER_TO = "TRANSFER_TO",
  INCOME = "INCOME",
  LOAN_TO = "LOAN_TO",
  LOAN_FROM = "LOAN_FROM",
  LOAN_REPAYMENT_TO = "LOAN_REPAYMENT_TO",
  LOAN_REPAYMENT_FROM = "LOAN_REPAYMENT_FROM",
  FUNDING_REQUEST_RESERVATION = "FUNDING_REQUEST_RESERVATION",
  FUNDING_REQUEST_UN_RESERVATION = "FUNDING_REQUEST_UN_RESERVATION",
  FUNDING_REQUEST_PAYOUT = "FUNDING_REQUEST_PAYOUT",
}

export function FundHistoryOperationTypeFromJSON(json: any): FundHistoryOperationType {
  return FundHistoryOperationTypeFromJSONTyped(json, false);
}

export function FundHistoryOperationTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundHistoryOperationType {
  return json as FundHistoryOperationType;
}

export function FundHistoryOperationTypeToJSON(value?: FundHistoryOperationType | null): any {
  return value as any;
}
