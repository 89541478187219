/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FundingRequestDto,
  FundingRequestDtoFromJSON,
  FundingRequestDtoFromJSONTyped,
  FundingRequestDtoToJSON,
} from "./FundingRequestDto";
import {
  FundingRequestPayoutDtoCreator,
  FundingRequestPayoutDtoCreatorFromJSON,
  FundingRequestPayoutDtoCreatorFromJSONTyped,
  FundingRequestPayoutDtoCreatorToJSON,
} from "./FundingRequestPayoutDtoCreator";

/**
 *
 * @export
 * @interface FundingRequestPayoutDto
 */
export interface FundingRequestPayoutDto {
  /**
   *
   * @type {string}
   * @memberof FundingRequestPayoutDto
   */
  id: string;
  /**
   *
   * @type {FundingRequestDto}
   * @memberof FundingRequestPayoutDto
   */
  fundingRequest: FundingRequestDto;
  /**
   *
   * @type {number}
   * @memberof FundingRequestPayoutDto
   */
  amount: number;
  /**
   *
   * @type {Date}
   * @memberof FundingRequestPayoutDto
   */
  createdAt: Date;
  /**
   *
   * @type {number}
   * @memberof FundingRequestPayoutDto
   */
  number: number;
  /**
   *
   * @type {FundingRequestPayoutDtoCreator}
   * @memberof FundingRequestPayoutDto
   */
  creator: FundingRequestPayoutDtoCreator | null;
  /**
   *
   * @type {string}
   * @memberof FundingRequestPayoutDto
   */
  comment: string | null;
}

export function FundingRequestPayoutDtoFromJSON(json: any): FundingRequestPayoutDto {
  return FundingRequestPayoutDtoFromJSONTyped(json, false);
}

export function FundingRequestPayoutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundingRequestPayoutDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fundingRequest: FundingRequestDtoFromJSON(json["fundingRequest"]),
    amount: json["amount"],
    createdAt: new Date(json["createdAt"]),
    number: json["number"],
    creator: FundingRequestPayoutDtoCreatorFromJSON(json["creator"]),
    comment: json["comment"],
  };
}

export function FundingRequestPayoutDtoToJSON(value?: FundingRequestPayoutDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fundingRequest: FundingRequestDtoToJSON(value.fundingRequest),
    amount: value.amount,
    createdAt: value.createdAt.toISOString(),
    number: value.number,
    creator: FundingRequestPayoutDtoCreatorToJSON(value.creator),
    comment: value.comment,
  };
}
