/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { CompanyDto, CompanyDtoFromJSON, CompanyDtoFromJSONTyped, CompanyDtoToJSON } from "./CompanyDto";
import {
  CompanyEmployeeRoleDto,
  CompanyEmployeeRoleDtoFromJSON,
  CompanyEmployeeRoleDtoFromJSONTyped,
  CompanyEmployeeRoleDtoToJSON,
} from "./CompanyEmployeeRoleDto";
import {
  FundingRequestPayoutDtoCreator,
  FundingRequestPayoutDtoCreatorFromJSON,
  FundingRequestPayoutDtoCreatorFromJSONTyped,
  FundingRequestPayoutDtoCreatorToJSON,
} from "./FundingRequestPayoutDtoCreator";

/**
 *
 * @export
 * @interface EmployeeInviterCompanyInviteDto
 */
export interface EmployeeInviterCompanyInviteDto {
  /**
   *
   * @type {CompanyDto}
   * @memberof EmployeeInviterCompanyInviteDto
   */
  company: CompanyDto;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeInviterCompanyInviteDto
   */
  activated: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeInviterCompanyInviteDto
   */
  deleted: boolean;
  /**
   *
   * @type {string}
   * @memberof EmployeeInviterCompanyInviteDto
   */
  email: string;
  /**
   *
   * @type {CompanyEmployeeRoleDto}
   * @memberof EmployeeInviterCompanyInviteDto
   */
  role: CompanyEmployeeRoleDto;
  /**
   *
   * @type {FundingRequestPayoutDtoCreator}
   * @memberof EmployeeInviterCompanyInviteDto
   */
  creator: FundingRequestPayoutDtoCreator | null;
  /**
   *
   * @type {FundingRequestPayoutDtoCreator}
   * @memberof EmployeeInviterCompanyInviteDto
   */
  employee: FundingRequestPayoutDtoCreator | null;
}

export function EmployeeInviterCompanyInviteDtoFromJSON(json: any): EmployeeInviterCompanyInviteDto {
  return EmployeeInviterCompanyInviteDtoFromJSONTyped(json, false);
}

export function EmployeeInviterCompanyInviteDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmployeeInviterCompanyInviteDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    company: CompanyDtoFromJSON(json["company"]),
    activated: json["activated"],
    deleted: json["deleted"],
    email: json["email"],
    role: CompanyEmployeeRoleDtoFromJSON(json["role"]),
    creator: FundingRequestPayoutDtoCreatorFromJSON(json["creator"]),
    employee: FundingRequestPayoutDtoCreatorFromJSON(json["employee"]),
  };
}

export function EmployeeInviterCompanyInviteDtoToJSON(value?: EmployeeInviterCompanyInviteDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    company: CompanyDtoToJSON(value.company),
    activated: value.activated,
    deleted: value.deleted,
    email: value.email,
    role: CompanyEmployeeRoleDtoToJSON(value.role),
    creator: FundingRequestPayoutDtoCreatorToJSON(value.creator),
    employee: FundingRequestPayoutDtoCreatorToJSON(value.employee),
  };
}
