/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanAddVariableCostFromMarginRequestDto
 */
export interface MonthPlanAddVariableCostFromMarginRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanAddVariableCostFromMarginRequestDto
   */
  fundId: string;
}

export function MonthPlanAddVariableCostFromMarginRequestDtoFromJSON(
  json: any
): MonthPlanAddVariableCostFromMarginRequestDto {
  return MonthPlanAddVariableCostFromMarginRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanAddVariableCostFromMarginRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanAddVariableCostFromMarginRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fundId: json["fundId"],
  };
}

export function MonthPlanAddVariableCostFromMarginRequestDtoToJSON(
  value?: MonthPlanAddVariableCostFromMarginRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fundId: value.fundId,
  };
}
