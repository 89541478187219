/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  CompanyEmployeeInviteDto,
  CompanyEmployeeInviteDtoFromJSON,
  CompanyEmployeeInviteDtoToJSON,
  CompanyEmployeeInviteGroupCreateDto,
  CompanyEmployeeInviteGroupCreateDtoFromJSON,
  CompanyEmployeeInviteGroupCreateDtoToJSON,
} from "../models";

export interface CompanyEmployeeInviteCreateRequest {
  companyIdOrSlug: string;
  companyEmployeeInviteGroupCreateDto: CompanyEmployeeInviteGroupCreateDto;
}

export interface CompanyEmployeeInviteDeleteRequest {
  companyIdOrSlug: string;
  companyEmployeeInviteId: string;
}

export interface CompanyEmployeeInviteListRequest {
  companyIdOrSlug: string;
}

export interface CompanyEmployeeInviteResendEmailRequest {
  companyIdOrSlug: string;
  companyEmployeeInviteId: string;
}

/**
 *
 */
export class CompanyEmployeeInviteApi extends runtime.BaseAPI {
  /**
   */
  protected companyEmployeeInviteCreateRequestOpts = (
    requestParameters: CompanyEmployeeInviteCreateRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeInviteCreate."
      );
    }

    if (
      requestParameters.companyEmployeeInviteGroupCreateDto === null ||
      requestParameters.companyEmployeeInviteGroupCreateDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyEmployeeInviteGroupCreateDto",
        "Required parameter requestParameters.companyEmployeeInviteGroupCreateDto was null or undefined when calling companyEmployeeInviteCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee-invite`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyEmployeeInviteGroupCreateDtoToJSON(requestParameters.companyEmployeeInviteGroupCreateDto),
    };
  };

  /**
   */
  protected companyEmployeeInviteCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeInviteCreateRaw = async (
    requestParameters: CompanyEmployeeInviteCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeInviteCreateRequestOpts(requestParameters);
    return this.companyEmployeeInviteCreateFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeInviteCreate = async (
    companyIdOrSlug: string,
    companyEmployeeInviteGroupCreateDto: CompanyEmployeeInviteGroupCreateDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeInviteCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, companyEmployeeInviteGroupCreateDto: companyEmployeeInviteGroupCreateDto },
      initOverrides
    );
  };

  /**
   */
  protected companyEmployeeInviteDeleteRequestOpts = (
    requestParameters: CompanyEmployeeInviteDeleteRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeInviteDelete."
      );
    }

    if (requestParameters.companyEmployeeInviteId === null || requestParameters.companyEmployeeInviteId === undefined) {
      throw new runtime.RequiredError(
        "companyEmployeeInviteId",
        "Required parameter requestParameters.companyEmployeeInviteId was null or undefined when calling companyEmployeeInviteDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee-invite/{companyEmployeeInviteId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(
          `{${"companyEmployeeInviteId"}}`,
          encodeURIComponent(String(requestParameters.companyEmployeeInviteId))
        ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeInviteDeleteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeInviteDeleteRaw = async (
    requestParameters: CompanyEmployeeInviteDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeInviteDeleteRequestOpts(requestParameters);
    return this.companyEmployeeInviteDeleteFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeInviteDelete = async (
    companyIdOrSlug: string,
    companyEmployeeInviteId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeInviteDeleteRaw(
      { companyIdOrSlug: companyIdOrSlug, companyEmployeeInviteId: companyEmployeeInviteId },
      initOverrides
    );
  };

  /**
   */
  protected companyEmployeeInviteListRequestOpts = (
    requestParameters: CompanyEmployeeInviteListRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeInviteList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee-invite`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeInviteListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<CompanyEmployeeInviteDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyEmployeeInviteDtoFromJSON));
  };

  /**
   */
  protected companyEmployeeInviteListRaw = async (
    requestParameters: CompanyEmployeeInviteListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<CompanyEmployeeInviteDto>>> => {
    const context = this.companyEmployeeInviteListRequestOpts(requestParameters);
    return this.companyEmployeeInviteListFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeInviteList = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<Array<CompanyEmployeeInviteDto>> => {
    const response = await this.companyEmployeeInviteListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyEmployeeInviteList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<CompanyEmployeeInviteDto>>
  ): SWRResponse<Array<CompanyEmployeeInviteDto>> => {
    return useSWR<Array<CompanyEmployeeInviteDto>>(
      () => this.companyEmployeeInviteListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.companyEmployeeInviteListFetch(context)),
      config
    );
  };

  /**
   */
  protected companyEmployeeInviteResendEmailRequestOpts = (
    requestParameters: CompanyEmployeeInviteResendEmailRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeInviteResendEmail."
      );
    }

    if (requestParameters.companyEmployeeInviteId === null || requestParameters.companyEmployeeInviteId === undefined) {
      throw new runtime.RequiredError(
        "companyEmployeeInviteId",
        "Required parameter requestParameters.companyEmployeeInviteId was null or undefined when calling companyEmployeeInviteResendEmail."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee-invite/{companyEmployeeInviteId}/resend-email`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(
          `{${"companyEmployeeInviteId"}}`,
          encodeURIComponent(String(requestParameters.companyEmployeeInviteId))
        ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeInviteResendEmailFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeInviteResendEmailRaw = async (
    requestParameters: CompanyEmployeeInviteResendEmailRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeInviteResendEmailRequestOpts(requestParameters);
    return this.companyEmployeeInviteResendEmailFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeInviteResendEmail = async (
    companyIdOrSlug: string,
    companyEmployeeInviteId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeInviteResendEmailRaw(
      { companyIdOrSlug: companyIdOrSlug, companyEmployeeInviteId: companyEmployeeInviteId },
      initOverrides
    );
  };
}
