/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DeferredIncomeDto,
  DeferredIncomeDtoFromJSON,
  DeferredIncomeDtoFromJSONTyped,
  DeferredIncomeDtoToJSON,
} from "./DeferredIncomeDto";
import {
  MonthPlanStateIncomeTypeDto,
  MonthPlanStateIncomeTypeDtoFromJSON,
  MonthPlanStateIncomeTypeDtoFromJSONTyped,
  MonthPlanStateIncomeTypeDtoToJSON,
} from "./MonthPlanStateIncomeTypeDto";
import {
  MonthPlanStateWeekItemDto,
  MonthPlanStateWeekItemDtoFromJSON,
  MonthPlanStateWeekItemDtoFromJSONTyped,
  MonthPlanStateWeekItemDtoToJSON,
} from "./MonthPlanStateWeekItemDto";

/**
 *
 * @export
 * @interface MonthPlanStateDeferredIncomeDto
 */
export interface MonthPlanStateDeferredIncomeDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanStateDeferredIncomeDto
   */
  id: string;
  /**
   *
   * @type {DeferredIncomeDto}
   * @memberof MonthPlanStateDeferredIncomeDto
   */
  deferredIncome: DeferredIncomeDto;
  /**
   *
   * @type {MonthPlanStateIncomeTypeDto}
   * @memberof MonthPlanStateDeferredIncomeDto
   */
  incomeType: MonthPlanStateIncomeTypeDto;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateDeferredIncomeDto
   */
  plan: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateDeferredIncomeDto
   */
  fact: number;
  /**
   *
   * @type {Array<MonthPlanStateWeekItemDto>}
   * @memberof MonthPlanStateDeferredIncomeDto
   */
  weeks: Array<MonthPlanStateWeekItemDto>;
}

export function MonthPlanStateDeferredIncomeDtoFromJSON(json: any): MonthPlanStateDeferredIncomeDto {
  return MonthPlanStateDeferredIncomeDtoFromJSONTyped(json, false);
}

export function MonthPlanStateDeferredIncomeDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanStateDeferredIncomeDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    deferredIncome: DeferredIncomeDtoFromJSON(json["deferredIncome"]),
    incomeType: MonthPlanStateIncomeTypeDtoFromJSON(json["incomeType"]),
    plan: json["plan"],
    fact: json["fact"],
    weeks: (json["weeks"] as Array<any>).map(MonthPlanStateWeekItemDtoFromJSON),
  };
}

export function MonthPlanStateDeferredIncomeDtoToJSON(value?: MonthPlanStateDeferredIncomeDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    deferredIncome: DeferredIncomeDtoToJSON(value.deferredIncome),
    incomeType: MonthPlanStateIncomeTypeDtoToJSON(value.incomeType),
    plan: value.plan,
    fact: value.fact,
    weeks: (value.weeks as Array<any>).map(MonthPlanStateWeekItemDtoToJSON),
  };
}
