/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanRemoveIncomeRequestDto
 */
export interface MonthPlanRemoveIncomeRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanRemoveIncomeRequestDto
   */
  incomeTypeId: string;
}

export function MonthPlanRemoveIncomeRequestDtoFromJSON(json: any): MonthPlanRemoveIncomeRequestDto {
  return MonthPlanRemoveIncomeRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanRemoveIncomeRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanRemoveIncomeRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    incomeTypeId: json["incomeTypeId"],
  };
}

export function MonthPlanRemoveIncomeRequestDtoToJSON(value?: MonthPlanRemoveIncomeRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    incomeTypeId: value.incomeTypeId,
  };
}
