/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CompanyRolePermission {
  FINANCIAL_PLAN_READ = "FINANCIAL_PLAN_READ",
  FINANCIAL_PLAN_CONDUCT = "FINANCIAL_PLAN_CONDUCT",
  FINANCIAL_PLAN_APPROVE = "FINANCIAL_PLAN_APPROVE",
  CLAIM_READ_ALL = "CLAIM_READ_ALL",
  CLAIM_APPROVE = "CLAIM_APPROVE",
  CLAIM_ALLOW_FOR_FINANCIAL_PLANNING = "CLAIM_ALLOW_FOR_FINANCIAL_PLANNING",
  CLAIM_PAY_OFF = "CLAIM_PAY_OFF",
  FUND_WITHDRAW = "FUND_WITHDRAW",
  FUND_DEPOSIT = "FUND_DEPOSIT",
  FUND_SECURED_READ = "FUND_SECURED_READ",
}

export function CompanyRolePermissionFromJSON(json: any): CompanyRolePermission {
  return CompanyRolePermissionFromJSONTyped(json, false);
}

export function CompanyRolePermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRolePermission {
  return json as CompanyRolePermission;
}

export function CompanyRolePermissionToJSON(value?: CompanyRolePermission | null): any {
  return value as any;
}
