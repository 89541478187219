/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  DeferredIncomeCreateRequestDto,
  DeferredIncomeCreateRequestDtoFromJSON,
  DeferredIncomeCreateRequestDtoToJSON,
  DeferredIncomeDto,
  DeferredIncomeDtoFromJSON,
  DeferredIncomeDtoToJSON,
  DeferredIncomeExistsDto,
  DeferredIncomeExistsDtoFromJSON,
  DeferredIncomeExistsDtoToJSON,
  DeferredIncomeHistoryDto,
  DeferredIncomeHistoryDtoFromJSON,
  DeferredIncomeHistoryDtoToJSON,
  DeferredIncomeRenameRequestDto,
  DeferredIncomeRenameRequestDtoFromJSON,
  DeferredIncomeRenameRequestDtoToJSON,
} from "../models";

export interface DeferredIncomeCreateRequest {
  companyIdOrSlug: string;
  deferredIncomeCreateRequestDto: DeferredIncomeCreateRequestDto;
}

export interface DeferredIncomeExistsRequest {
  companyIdOrSlug: string;
}

export interface DeferredIncomeHistoryRequest {
  companyIdOrSlug: string;
  id: string;
}

export interface DeferredIncomeListRequest {
  companyIdOrSlug: string;
  from?: Date;
  to?: Date;
  statuses?: Array<DeferredIncomeListStatusesEnum>;
}

export interface DeferredIncomeOneRequest {
  id: string;
  companyIdOrSlug: string;
}

export interface DeferredIncomeRenameRequest {
  id: string;
  companyIdOrSlug: string;
  deferredIncomeRenameRequestDto: DeferredIncomeRenameRequestDto;
}

/**
 *
 */
export class DeferredIncomeApi extends runtime.BaseAPI {
  /**
   */
  protected deferredIncomeCreateRequestOpts = (requestParameters: DeferredIncomeCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling deferredIncomeCreate."
      );
    }

    if (
      requestParameters.deferredIncomeCreateRequestDto === null ||
      requestParameters.deferredIncomeCreateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "deferredIncomeCreateRequestDto",
        "Required parameter requestParameters.deferredIncomeCreateRequestDto was null or undefined when calling deferredIncomeCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deferred-income`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DeferredIncomeCreateRequestDtoToJSON(requestParameters.deferredIncomeCreateRequestDto),
    };
  };

  /**
   */
  protected deferredIncomeCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeferredIncomeDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => DeferredIncomeDtoFromJSON(jsonValue));
  };

  /**
   */
  protected deferredIncomeCreateRaw = async (
    requestParameters: DeferredIncomeCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeferredIncomeDto>> => {
    const context = this.deferredIncomeCreateRequestOpts(requestParameters);
    return this.deferredIncomeCreateFetch(context, initOverrides);
  };

  /**
   */
  deferredIncomeCreate = async (
    companyIdOrSlug: string,
    deferredIncomeCreateRequestDto: DeferredIncomeCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<DeferredIncomeDto> => {
    const response = await this.deferredIncomeCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, deferredIncomeCreateRequestDto: deferredIncomeCreateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected deferredIncomeExistsRequestOpts = (requestParameters: DeferredIncomeExistsRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling deferredIncomeExists."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deferred-income/exists`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected deferredIncomeExistsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeferredIncomeExistsDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => DeferredIncomeExistsDtoFromJSON(jsonValue));
  };

  /**
   */
  protected deferredIncomeExistsRaw = async (
    requestParameters: DeferredIncomeExistsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeferredIncomeExistsDto>> => {
    const context = this.deferredIncomeExistsRequestOpts(requestParameters);
    return this.deferredIncomeExistsFetch(context, initOverrides);
  };

  /**
   */
  deferredIncomeExists = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<DeferredIncomeExistsDto> => {
    const response = await this.deferredIncomeExistsRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useDeferredIncomeExists = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<DeferredIncomeExistsDto>
  ): SWRResponse<DeferredIncomeExistsDto> => {
    return useSWR<DeferredIncomeExistsDto>(
      () => this.deferredIncomeExistsRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.deferredIncomeExistsFetch(context)),
      config
    );
  };

  /**
   */
  protected deferredIncomeHistoryRequestOpts = (
    requestParameters: DeferredIncomeHistoryRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling deferredIncomeHistory."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deferredIncomeHistory."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deferred-income/{id}/history`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected deferredIncomeHistoryFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DeferredIncomeHistoryDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeferredIncomeHistoryDtoFromJSON));
  };

  /**
   */
  protected deferredIncomeHistoryRaw = async (
    requestParameters: DeferredIncomeHistoryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DeferredIncomeHistoryDto>>> => {
    const context = this.deferredIncomeHistoryRequestOpts(requestParameters);
    return this.deferredIncomeHistoryFetch(context, initOverrides);
  };

  /**
   */
  deferredIncomeHistory = async (
    companyIdOrSlug: string,
    id: string,
    initOverrides?: RequestInit
  ): Promise<Array<DeferredIncomeHistoryDto>> => {
    const response = await this.deferredIncomeHistoryRaw({ companyIdOrSlug: companyIdOrSlug, id: id }, initOverrides);
    return await response.value();
  };

  /**
   */
  useDeferredIncomeHistory = (
    companyIdOrSlug: string,
    id: string,
    config?: SWRConfiguration<Array<DeferredIncomeHistoryDto>>
  ): SWRResponse<Array<DeferredIncomeHistoryDto>> => {
    return useSWR<Array<DeferredIncomeHistoryDto>>(
      () => this.deferredIncomeHistoryRequestOpts({ companyIdOrSlug: companyIdOrSlug, id: id }),
      (context) => this.swrFetch(this.deferredIncomeHistoryFetch(context)),
      config
    );
  };

  /**
   */
  protected deferredIncomeListRequestOpts = (requestParameters: DeferredIncomeListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling deferredIncomeList."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.statuses) {
      queryParameters["statuses"] = requestParameters.statuses;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deferred-income`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected deferredIncomeListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DeferredIncomeDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeferredIncomeDtoFromJSON));
  };

  /**
   */
  protected deferredIncomeListRaw = async (
    requestParameters: DeferredIncomeListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DeferredIncomeDto>>> => {
    const context = this.deferredIncomeListRequestOpts(requestParameters);
    return this.deferredIncomeListFetch(context, initOverrides);
  };

  /**
   */
  deferredIncomeList = async (
    companyIdOrSlug: string,
    from?: Date,
    to?: Date,
    statuses?: Array<DeferredIncomeListStatusesEnum>,
    initOverrides?: RequestInit
  ): Promise<Array<DeferredIncomeDto>> => {
    const response = await this.deferredIncomeListRaw(
      { companyIdOrSlug: companyIdOrSlug, from: from, to: to, statuses: statuses },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useDeferredIncomeList = (
    requestParameters: DeferredIncomeListRequest,
    config?: SWRConfiguration<Array<DeferredIncomeDto>>
  ): SWRResponse<Array<DeferredIncomeDto>> => {
    return useSWR<Array<DeferredIncomeDto>>(
      () => this.deferredIncomeListRequestOpts(requestParameters),
      (context) => this.swrFetch(this.deferredIncomeListFetch(context)),
      config
    );
  };

  /**
   */
  protected deferredIncomeOneRequestOpts = (requestParameters: DeferredIncomeOneRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deferredIncomeOne."
      );
    }

    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling deferredIncomeOne."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deferred-income/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected deferredIncomeOneFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeferredIncomeDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => DeferredIncomeDtoFromJSON(jsonValue));
  };

  /**
   */
  protected deferredIncomeOneRaw = async (
    requestParameters: DeferredIncomeOneRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeferredIncomeDto>> => {
    const context = this.deferredIncomeOneRequestOpts(requestParameters);
    return this.deferredIncomeOneFetch(context, initOverrides);
  };

  /**
   */
  deferredIncomeOne = async (
    id: string,
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<DeferredIncomeDto> => {
    const response = await this.deferredIncomeOneRaw({ id: id, companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useDeferredIncomeOne = (
    id: string,
    companyIdOrSlug: string,
    config?: SWRConfiguration<DeferredIncomeDto>
  ): SWRResponse<DeferredIncomeDto> => {
    return useSWR<DeferredIncomeDto>(
      () => this.deferredIncomeOneRequestOpts({ id: id, companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.deferredIncomeOneFetch(context)),
      config
    );
  };

  /**
   */
  protected deferredIncomeRenameRequestOpts = (requestParameters: DeferredIncomeRenameRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deferredIncomeRename."
      );
    }

    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling deferredIncomeRename."
      );
    }

    if (
      requestParameters.deferredIncomeRenameRequestDto === null ||
      requestParameters.deferredIncomeRenameRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "deferredIncomeRenameRequestDto",
        "Required parameter requestParameters.deferredIncomeRenameRequestDto was null or undefined when calling deferredIncomeRename."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deferred-income/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DeferredIncomeRenameRequestDtoToJSON(requestParameters.deferredIncomeRenameRequestDto),
    };
  };

  /**
   */
  protected deferredIncomeRenameFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected deferredIncomeRenameRaw = async (
    requestParameters: DeferredIncomeRenameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.deferredIncomeRenameRequestOpts(requestParameters);
    return this.deferredIncomeRenameFetch(context, initOverrides);
  };

  /**
   */
  deferredIncomeRename = async (
    id: string,
    companyIdOrSlug: string,
    deferredIncomeRenameRequestDto: DeferredIncomeRenameRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.deferredIncomeRenameRaw(
      { id: id, companyIdOrSlug: companyIdOrSlug, deferredIncomeRenameRequestDto: deferredIncomeRenameRequestDto },
      initOverrides
    );
  };
}

/**
 * @export
 * @enum {string}
 */
export type DeferredIncomeListStatusesEnum = "NEW" | "PLAN" | "PROCESS" | "CLOSED";
