/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ExpendituresOnFundsAndExpenseItemsReportExpenseItemDto,
  ExpendituresOnFundsAndExpenseItemsReportExpenseItemDtoFromJSON,
  ExpendituresOnFundsAndExpenseItemsReportExpenseItemDtoFromJSONTyped,
  ExpendituresOnFundsAndExpenseItemsReportExpenseItemDtoToJSON,
} from "./ExpendituresOnFundsAndExpenseItemsReportExpenseItemDto";
import {
  ExpendituresOnFundsAndExpenseItemsReportWithdrawDto,
  ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoFromJSON,
  ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoFromJSONTyped,
  ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoToJSON,
} from "./ExpendituresOnFundsAndExpenseItemsReportWithdrawDto";

/**
 *
 * @export
 * @interface ExpendituresOnFundsAndExpenseItemsReportFundDto
 */
export interface ExpendituresOnFundsAndExpenseItemsReportFundDto {
  /**
   *
   * @type {string}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportFundDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportFundDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportFundDto
   */
  expenseItemsTotal: number;
  /**
   *
   * @type {number}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportFundDto
   */
  withdrawsTotal: number;
  /**
   *
   * @type {Array<ExpendituresOnFundsAndExpenseItemsReportExpenseItemDto>}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportFundDto
   */
  expenseItems: Array<ExpendituresOnFundsAndExpenseItemsReportExpenseItemDto>;
  /**
   *
   * @type {Array<ExpendituresOnFundsAndExpenseItemsReportWithdrawDto>}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportFundDto
   */
  withdraws: Array<ExpendituresOnFundsAndExpenseItemsReportWithdrawDto>;
}

export function ExpendituresOnFundsAndExpenseItemsReportFundDtoFromJSON(
  json: any
): ExpendituresOnFundsAndExpenseItemsReportFundDto {
  return ExpendituresOnFundsAndExpenseItemsReportFundDtoFromJSONTyped(json, false);
}

export function ExpendituresOnFundsAndExpenseItemsReportFundDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExpendituresOnFundsAndExpenseItemsReportFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    expenseItemsTotal: json["expenseItemsTotal"],
    withdrawsTotal: json["withdrawsTotal"],
    expenseItems: (json["expenseItems"] as Array<any>).map(
      ExpendituresOnFundsAndExpenseItemsReportExpenseItemDtoFromJSON
    ),
    withdraws: (json["withdraws"] as Array<any>).map(ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoFromJSON),
  };
}

export function ExpendituresOnFundsAndExpenseItemsReportFundDtoToJSON(
  value?: ExpendituresOnFundsAndExpenseItemsReportFundDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    expenseItemsTotal: value.expenseItemsTotal,
    withdrawsTotal: value.withdrawsTotal,
    expenseItems: (value.expenseItems as Array<any>).map(ExpendituresOnFundsAndExpenseItemsReportExpenseItemDtoToJSON),
    withdraws: (value.withdraws as Array<any>).map(ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoToJSON),
  };
}
