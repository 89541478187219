/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AdminCompanyCourseInviteCourseDto,
  AdminCompanyCourseInviteCourseDtoFromJSON,
  AdminCompanyCourseInviteCourseDtoFromJSONTyped,
  AdminCompanyCourseInviteCourseDtoToJSON,
} from "./AdminCompanyCourseInviteCourseDto";

/**
 *
 * @export
 * @interface AdminCompanyCourseInviteDto
 */
export interface AdminCompanyCourseInviteDto {
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCourseInviteDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCourseInviteDto
   */
  invite: string;
  /**
   *
   * @type {AdminCompanyCourseInviteCourseDto}
   * @memberof AdminCompanyCourseInviteDto
   */
  course: AdminCompanyCourseInviteCourseDto;
  /**
   *
   * @type {Date}
   * @memberof AdminCompanyCourseInviteDto
   */
  accessUntil: Date;
  /**
   *
   * @type {Date}
   * @memberof AdminCompanyCourseInviteDto
   */
  expiresAt: Date;
  /**
   *
   * @type {boolean}
   * @memberof AdminCompanyCourseInviteDto
   */
  expired: boolean;
  /**
   *
   * @type {number}
   * @memberof AdminCompanyCourseInviteDto
   */
  activations: number;
}

export function AdminCompanyCourseInviteDtoFromJSON(json: any): AdminCompanyCourseInviteDto {
  return AdminCompanyCourseInviteDtoFromJSONTyped(json, false);
}

export function AdminCompanyCourseInviteDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminCompanyCourseInviteDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    invite: json["invite"],
    course: AdminCompanyCourseInviteCourseDtoFromJSON(json["course"]),
    accessUntil: new Date(json["accessUntil"]),
    expiresAt: new Date(json["expiresAt"]),
    expired: json["expired"],
    activations: json["activations"],
  };
}

export function AdminCompanyCourseInviteDtoToJSON(value?: AdminCompanyCourseInviteDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    invite: value.invite,
    course: AdminCompanyCourseInviteCourseDtoToJSON(value.course),
    accessUntil: value.accessUntil.toISOString(),
    expiresAt: value.expiresAt.toISOString(),
    expired: value.expired,
    activations: value.activations,
  };
}
