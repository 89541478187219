/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CompanyEmployeeRoleType {
  OWNER = "OWNER",
  DEFAULT = "DEFAULT",
  COMMON = "COMMON",
}

export function CompanyEmployeeRoleTypeFromJSON(json: any): CompanyEmployeeRoleType {
  return CompanyEmployeeRoleTypeFromJSONTyped(json, false);
}

export function CompanyEmployeeRoleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyEmployeeRoleType {
  return json as CompanyEmployeeRoleType;
}

export function CompanyEmployeeRoleTypeToJSON(value?: CompanyEmployeeRoleType | null): any {
  return value as any;
}
