/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinancialPlanAddTransferDto
 */
export interface FinancialPlanAddTransferDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanAddTransferDto
   */
  fromFundId: string;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanAddTransferDto
   */
  toFundId: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanAddTransferDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanAddTransferDto
   */
  comment: string;
}

export function FinancialPlanAddTransferDtoFromJSON(json: any): FinancialPlanAddTransferDto {
  return FinancialPlanAddTransferDtoFromJSONTyped(json, false);
}

export function FinancialPlanAddTransferDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanAddTransferDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fromFundId: json["fromFundId"],
    toFundId: json["toFundId"],
    amount: json["amount"],
    comment: json["comment"],
  };
}

export function FinancialPlanAddTransferDtoToJSON(value?: FinancialPlanAddTransferDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fromFundId: value.fromFundId,
    toFundId: value.toFundId,
    amount: value.amount,
    comment: value.comment,
  };
}
