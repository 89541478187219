import * as Sentry from "@sentry/nextjs";
import { useAuthed } from "modules/auth";
import { AppProps } from "next/app";
import React, { useEffect } from "react";
import { api } from "shared/api";

export const withSentryIdentify = (app: (props: AppProps) => React.ReactNode) => {
  const WithSentryIdentify = (props: AppProps) => {
    const authed = useAuthed();
    const profile = api.userProfile.useUserProfileMe();

    useEffect(() => {
      if (!authed) {
        Sentry.setUser(null);
      } else if (profile.data) {
        Sentry.setUser(profile.data);
      }
    }, [authed, profile.data]);

    return app(props);
  };

  return WithSentryIdentify;
};
