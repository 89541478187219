/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FundHistoryFundDto,
  FundHistoryFundDtoFromJSON,
  FundHistoryFundDtoFromJSONTyped,
  FundHistoryFundDtoToJSON,
} from "./FundHistoryFundDto";
import {
  FundHistoryLoanDtoFromDeferredIncome,
  FundHistoryLoanDtoFromDeferredIncomeFromJSON,
  FundHistoryLoanDtoFromDeferredIncomeFromJSONTyped,
  FundHistoryLoanDtoFromDeferredIncomeToJSON,
} from "./FundHistoryLoanDtoFromDeferredIncome";
import {
  FundHistoryLoanDtoFromFund,
  FundHistoryLoanDtoFromFundFromJSON,
  FundHistoryLoanDtoFromFundFromJSONTyped,
  FundHistoryLoanDtoFromFundToJSON,
} from "./FundHistoryLoanDtoFromFund";

/**
 *
 * @export
 * @interface FundHistoryDtoLoan
 */
export interface FundHistoryDtoLoan {
  /**
   *
   * @type {string}
   * @memberof FundHistoryDtoLoan
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDtoLoan
   */
  number: number;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryDtoLoan
   */
  createdAt: Date;
  /**
   *
   * @type {FundHistoryLoanDtoFromDeferredIncome}
   * @memberof FundHistoryDtoLoan
   */
  fromDeferredIncome: FundHistoryLoanDtoFromDeferredIncome | null;
  /**
   *
   * @type {FundHistoryLoanDtoFromFund}
   * @memberof FundHistoryDtoLoan
   */
  fromFund: FundHistoryLoanDtoFromFund | null;
  /**
   *
   * @type {FundHistoryFundDto}
   * @memberof FundHistoryDtoLoan
   */
  toFund: FundHistoryFundDto;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryDtoLoan
   */
  returnAt: Date;
  /**
   *
   * @type {string}
   * @memberof FundHistoryDtoLoan
   */
  comment: string;
}

export function FundHistoryDtoLoanFromJSON(json: any): FundHistoryDtoLoan {
  return FundHistoryDtoLoanFromJSONTyped(json, false);
}

export function FundHistoryDtoLoanFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundHistoryDtoLoan {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    number: json["number"],
    createdAt: new Date(json["createdAt"]),
    fromDeferredIncome: FundHistoryLoanDtoFromDeferredIncomeFromJSON(json["fromDeferredIncome"]),
    fromFund: FundHistoryLoanDtoFromFundFromJSON(json["fromFund"]),
    toFund: FundHistoryFundDtoFromJSON(json["toFund"]),
    returnAt: new Date(json["returnAt"]),
    comment: json["comment"],
  };
}

export function FundHistoryDtoLoanToJSON(value?: FundHistoryDtoLoan | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    number: value.number,
    createdAt: value.createdAt.toISOString(),
    fromDeferredIncome: FundHistoryLoanDtoFromDeferredIncomeToJSON(value.fromDeferredIncome),
    fromFund: FundHistoryLoanDtoFromFundToJSON(value.fromFund),
    toFund: FundHistoryFundDtoToJSON(value.toFund),
    returnAt: value.returnAt.toISOString(),
    comment: value.comment,
  };
}
