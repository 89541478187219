/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FundDto
 */
export interface FundDto {
  /**
   *
   * @type {string}
   * @memberof FundDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FundDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FundDto
   */
  fundGroupId: string;
  /**
   *
   * @type {number}
   * @memberof FundDto
   */
  balance: number;
  /**
   *
   * @type {number}
   * @memberof FundDto
   */
  reserved: number;
  /**
   *
   * @type {boolean}
   * @memberof FundDto
   */
  archived: boolean;
}

export function FundDtoFromJSON(json: any): FundDto {
  return FundDtoFromJSONTyped(json, false);
}

export function FundDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    fundGroupId: json["fundGroupId"],
    balance: json["balance"],
    reserved: json["reserved"],
    archived: json["archived"],
  };
}

export function FundDtoToJSON(value?: FundDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    fundGroupId: value.fundGroupId,
    balance: value.balance,
    reserved: value.reserved,
    archived: value.archived,
  };
}
