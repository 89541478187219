/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AdminCompanyCourseInviteCreateRequestDto
 */
export interface AdminCompanyCourseInviteCreateRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminCompanyCourseInviteCreateRequestDto
   */
  courseId: string;
  /**
   *
   * @type {Date}
   * @memberof AdminCompanyCourseInviteCreateRequestDto
   */
  accessUntil: Date;
}

export function AdminCompanyCourseInviteCreateRequestDtoFromJSON(json: any): AdminCompanyCourseInviteCreateRequestDto {
  return AdminCompanyCourseInviteCreateRequestDtoFromJSONTyped(json, false);
}

export function AdminCompanyCourseInviteCreateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminCompanyCourseInviteCreateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    courseId: json["courseId"],
    accessUntil: new Date(json["accessUntil"]),
  };
}

export function AdminCompanyCourseInviteCreateRequestDtoToJSON(
  value?: AdminCompanyCourseInviteCreateRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    courseId: value.courseId,
    accessUntil: value.accessUntil.toISOString(),
  };
}
