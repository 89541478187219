/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FundHistoryFundDto,
  FundHistoryFundDtoFromJSON,
  FundHistoryFundDtoFromJSONTyped,
  FundHistoryFundDtoToJSON,
} from "./FundHistoryFundDto";

/**
 *
 * @export
 * @interface FundHistoryDtoTransfer
 */
export interface FundHistoryDtoTransfer {
  /**
   *
   * @type {string}
   * @memberof FundHistoryDtoTransfer
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDtoTransfer
   */
  number: number;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryDtoTransfer
   */
  createdAt: Date;
  /**
   *
   * @type {FundHistoryFundDto}
   * @memberof FundHistoryDtoTransfer
   */
  fromFund: FundHistoryFundDto;
  /**
   *
   * @type {FundHistoryFundDto}
   * @memberof FundHistoryDtoTransfer
   */
  toFund: FundHistoryFundDto;
}

export function FundHistoryDtoTransferFromJSON(json: any): FundHistoryDtoTransfer {
  return FundHistoryDtoTransferFromJSONTyped(json, false);
}

export function FundHistoryDtoTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundHistoryDtoTransfer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    number: json["number"],
    createdAt: new Date(json["createdAt"]),
    fromFund: FundHistoryFundDtoFromJSON(json["fromFund"]),
    toFund: FundHistoryFundDtoFromJSON(json["toFund"]),
  };
}

export function FundHistoryDtoTransferToJSON(value?: FundHistoryDtoTransfer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    number: value.number,
    createdAt: value.createdAt.toISOString(),
    fromFund: FundHistoryFundDtoToJSON(value.fromFund),
    toFund: FundHistoryFundDtoToJSON(value.toFund),
  };
}
