import { AppProps } from "next/app";
import React from "react";
import { Toaster } from "react-hot-toast";
import styles from "./with-toast.module.scss";

const toastOptions = {
  className: styles.toast,

  style: {
    padding: "var(--geist-gap)",
    fontWeight: 400,
    width: "100%",
    fontSize: ".875rem",

    boxShadow: "var(--shadow-small)",
    border: "1px solid var(--accents-2)",
    borderRadius: "var(--geist-radius)",
    background: "var(--geist-background)",
    color: "var(--geist-foreground)",

    maxWidth: "min(420px,calc(100vw - 2 * var(--geist-gap)))",
  },
  duration: 5000,
  icon: null,
  success: {
    style: {
      background: "var(--geist-success)",
      justifyContent: "start",
      color: "#FFF",
    },
  },
  error: {
    style: {
      background: "var(--geist-error)",
      justifyContent: "start",
      color: "#FFF",
    },
  },
};

export const withToast = (app: (props: AppProps) => React.ReactNode) => {
  const WithToast = (props: AppProps) => (
    <>
      <Toaster position="bottom-right" reverseOrder={false} gutter={8} toastOptions={toastOptions} />
      {app(props)}
    </>
  );
  return WithToast;
};
