/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  AdminCompanyNotificationCreateRequestDto,
  AdminCompanyNotificationCreateRequestDtoFromJSON,
  AdminCompanyNotificationCreateRequestDtoToJSON,
  AdminCompanyNotificationDto,
  AdminCompanyNotificationDtoFromJSON,
  AdminCompanyNotificationDtoToJSON,
} from "../models";

export interface AdminCompanyNotificationCreateRequest {
  adminCompanyNotificationCreateRequestDto: AdminCompanyNotificationCreateRequestDto;
}

export interface AdminCompanyNotificationRemoveRequest {
  id: string;
}

/**
 *
 */
export class AdminCompanyNotificationApi extends runtime.BaseAPI {
  /**
   */
  protected adminCompanyNotificationCreateRequestOpts = (
    requestParameters: AdminCompanyNotificationCreateRequest
  ): runtime.RequestOpts => {
    if (
      requestParameters.adminCompanyNotificationCreateRequestDto === null ||
      requestParameters.adminCompanyNotificationCreateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyNotificationCreateRequestDto",
        "Required parameter requestParameters.adminCompanyNotificationCreateRequestDto was null or undefined when calling adminCompanyNotificationCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company-notification`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyNotificationCreateRequestDtoToJSON(requestParameters.adminCompanyNotificationCreateRequestDto),
    };
  };

  /**
   */
  protected adminCompanyNotificationCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyNotificationCreateRaw = async (
    requestParameters: AdminCompanyNotificationCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyNotificationCreateRequestOpts(requestParameters);
    return this.adminCompanyNotificationCreateFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyNotificationCreate = async (
    adminCompanyNotificationCreateRequestDto: AdminCompanyNotificationCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyNotificationCreateRaw(
      { adminCompanyNotificationCreateRequestDto: adminCompanyNotificationCreateRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyNotificationListRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company-notification`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyNotificationListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyNotificationDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminCompanyNotificationDtoFromJSON));
  };

  /**
   */
  protected adminCompanyNotificationListRaw = async (
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyNotificationDto>>> => {
    const context = this.adminCompanyNotificationListRequestOpts();
    return this.adminCompanyNotificationListFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyNotificationList = async (initOverrides?: RequestInit): Promise<Array<AdminCompanyNotificationDto>> => {
    const response = await this.adminCompanyNotificationListRaw(initOverrides);
    return await response.value();
  };

  /**
   */
  useAdminCompanyNotificationList = (
    config?: SWRConfiguration<Array<AdminCompanyNotificationDto>>
  ): SWRResponse<Array<AdminCompanyNotificationDto>> => {
    return useSWR<Array<AdminCompanyNotificationDto>>(
      () => this.adminCompanyNotificationListRequestOpts(),
      (context) => this.swrFetch(this.adminCompanyNotificationListFetch(context)),
      config
    );
  };

  /**
   */
  protected adminCompanyNotificationRemoveRequestOpts = (
    requestParameters: AdminCompanyNotificationRemoveRequest
  ): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling adminCompanyNotificationRemove."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company-notification/{id}/remove`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyNotificationRemoveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyNotificationRemoveRaw = async (
    requestParameters: AdminCompanyNotificationRemoveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyNotificationRemoveRequestOpts(requestParameters);
    return this.adminCompanyNotificationRemoveFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyNotificationRemove = async (id: string, initOverrides?: RequestInit): Promise<void> => {
    await this.adminCompanyNotificationRemoveRaw({ id: id }, initOverrides);
  };

  /**
   */
  protected adminCompanyNotificationSendRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company-notification/send`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyNotificationSendFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyNotificationSendRaw = async (
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyNotificationSendRequestOpts();
    return this.adminCompanyNotificationSendFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyNotificationSend = async (initOverrides?: RequestInit): Promise<void> => {
    await this.adminCompanyNotificationSendRaw(initOverrides);
  };
}
