/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FinancialPlanStatus {
  DRAFT = "DRAFT",
  ON_APPROVAL = "ON_APPROVAL",
  APPROVED = "APPROVED",
}

export function FinancialPlanStatusFromJSON(json: any): FinancialPlanStatus {
  return FinancialPlanStatusFromJSONTyped(json, false);
}

export function FinancialPlanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanStatus {
  return json as FinancialPlanStatus;
}

export function FinancialPlanStatusToJSON(value?: FinancialPlanStatus | null): any {
  return value as any;
}
