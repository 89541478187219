/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserAuthStateDto
 */
export interface UserAuthStateDto {
  /**
   *
   * @type {string}
   * @memberof UserAuthStateDto
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof UserAuthStateDto
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof UserAuthStateDto
   */
  activated: boolean;
}

export function UserAuthStateDtoFromJSON(json: any): UserAuthStateDto {
  return UserAuthStateDtoFromJSONTyped(json, false);
}

export function UserAuthStateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthStateDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    key: json["key"],
    email: json["email"],
    activated: json["activated"],
  };
}

export function UserAuthStateDtoToJSON(value?: UserAuthStateDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    key: value.key,
    email: value.email,
    activated: value.activated,
  };
}
