/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FundHistoryOperationType,
  FundHistoryOperationTypeFromJSON,
  FundHistoryOperationTypeFromJSONTyped,
  FundHistoryOperationTypeToJSON,
} from "./FundHistoryOperationType";
import {
  MovementOnFundsReportFundOperationDtoDeposit,
  MovementOnFundsReportFundOperationDtoDepositFromJSON,
  MovementOnFundsReportFundOperationDtoDepositFromJSONTyped,
  MovementOnFundsReportFundOperationDtoDepositToJSON,
} from "./MovementOnFundsReportFundOperationDtoDeposit";
import {
  MovementOnFundsReportFundOperationDtoEmployee,
  MovementOnFundsReportFundOperationDtoEmployeeFromJSON,
  MovementOnFundsReportFundOperationDtoEmployeeFromJSONTyped,
  MovementOnFundsReportFundOperationDtoEmployeeToJSON,
} from "./MovementOnFundsReportFundOperationDtoEmployee";
import {
  MovementOnFundsReportFundOperationDtoFinancialPlan,
  MovementOnFundsReportFundOperationDtoFinancialPlanFromJSON,
  MovementOnFundsReportFundOperationDtoFinancialPlanFromJSONTyped,
  MovementOnFundsReportFundOperationDtoFinancialPlanToJSON,
} from "./MovementOnFundsReportFundOperationDtoFinancialPlan";
import {
  MovementOnFundsReportFundOperationDtoFundingRequest,
  MovementOnFundsReportFundOperationDtoFundingRequestFromJSON,
  MovementOnFundsReportFundOperationDtoFundingRequestFromJSONTyped,
  MovementOnFundsReportFundOperationDtoFundingRequestToJSON,
} from "./MovementOnFundsReportFundOperationDtoFundingRequest";
import {
  MovementOnFundsReportFundOperationDtoLoan,
  MovementOnFundsReportFundOperationDtoLoanFromJSON,
  MovementOnFundsReportFundOperationDtoLoanFromJSONTyped,
  MovementOnFundsReportFundOperationDtoLoanToJSON,
} from "./MovementOnFundsReportFundOperationDtoLoan";
import {
  MovementOnFundsReportFundOperationDtoTransfer,
  MovementOnFundsReportFundOperationDtoTransferFromJSON,
  MovementOnFundsReportFundOperationDtoTransferFromJSONTyped,
  MovementOnFundsReportFundOperationDtoTransferToJSON,
} from "./MovementOnFundsReportFundOperationDtoTransfer";
import {
  MovementOnFundsReportFundOperationDtoWithdraw,
  MovementOnFundsReportFundOperationDtoWithdrawFromJSON,
  MovementOnFundsReportFundOperationDtoWithdrawFromJSONTyped,
  MovementOnFundsReportFundOperationDtoWithdrawToJSON,
} from "./MovementOnFundsReportFundOperationDtoWithdraw";

/**
 *
 * @export
 * @interface MovementOnFundsReportFundOperationDto
 */
export interface MovementOnFundsReportFundOperationDto {
  /**
   *
   * @type {FundHistoryOperationType}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  type: FundHistoryOperationType;
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  createdAt: Date;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  balanceBefore: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  balanceAfter: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  reservedBefore: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  reservedAfter: number;
  /**
   *
   * @type {MovementOnFundsReportFundOperationDtoFinancialPlan}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  financialPlan: MovementOnFundsReportFundOperationDtoFinancialPlan | null;
  /**
   *
   * @type {MovementOnFundsReportFundOperationDtoFundingRequest}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  fundingRequest: MovementOnFundsReportFundOperationDtoFundingRequest | null;
  /**
   *
   * @type {MovementOnFundsReportFundOperationDtoDeposit}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  deposit: MovementOnFundsReportFundOperationDtoDeposit | null;
  /**
   *
   * @type {MovementOnFundsReportFundOperationDtoTransfer}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  transfer: MovementOnFundsReportFundOperationDtoTransfer | null;
  /**
   *
   * @type {MovementOnFundsReportFundOperationDtoLoan}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  loan: MovementOnFundsReportFundOperationDtoLoan | null;
  /**
   *
   * @type {MovementOnFundsReportFundOperationDtoWithdraw}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  withdraw: MovementOnFundsReportFundOperationDtoWithdraw | null;
  /**
   *
   * @type {MovementOnFundsReportFundOperationDtoEmployee}
   * @memberof MovementOnFundsReportFundOperationDto
   */
  employee: MovementOnFundsReportFundOperationDtoEmployee | null;
}

export function MovementOnFundsReportFundOperationDtoFromJSON(json: any): MovementOnFundsReportFundOperationDto {
  return MovementOnFundsReportFundOperationDtoFromJSONTyped(json, false);
}

export function MovementOnFundsReportFundOperationDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MovementOnFundsReportFundOperationDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: FundHistoryOperationTypeFromJSON(json["type"]),
    id: json["id"],
    createdAt: new Date(json["createdAt"]),
    amount: json["amount"],
    balanceBefore: json["balanceBefore"],
    balanceAfter: json["balanceAfter"],
    reservedBefore: json["reservedBefore"],
    reservedAfter: json["reservedAfter"],
    financialPlan: MovementOnFundsReportFundOperationDtoFinancialPlanFromJSON(json["financialPlan"]),
    fundingRequest: MovementOnFundsReportFundOperationDtoFundingRequestFromJSON(json["fundingRequest"]),
    deposit: MovementOnFundsReportFundOperationDtoDepositFromJSON(json["deposit"]),
    transfer: MovementOnFundsReportFundOperationDtoTransferFromJSON(json["transfer"]),
    loan: MovementOnFundsReportFundOperationDtoLoanFromJSON(json["loan"]),
    withdraw: MovementOnFundsReportFundOperationDtoWithdrawFromJSON(json["withdraw"]),
    employee: MovementOnFundsReportFundOperationDtoEmployeeFromJSON(json["employee"]),
  };
}

export function MovementOnFundsReportFundOperationDtoToJSON(value?: MovementOnFundsReportFundOperationDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: FundHistoryOperationTypeToJSON(value.type),
    id: value.id,
    createdAt: value.createdAt.toISOString(),
    amount: value.amount,
    balanceBefore: value.balanceBefore,
    balanceAfter: value.balanceAfter,
    reservedBefore: value.reservedBefore,
    reservedAfter: value.reservedAfter,
    financialPlan: MovementOnFundsReportFundOperationDtoFinancialPlanToJSON(value.financialPlan),
    fundingRequest: MovementOnFundsReportFundOperationDtoFundingRequestToJSON(value.fundingRequest),
    deposit: MovementOnFundsReportFundOperationDtoDepositToJSON(value.deposit),
    transfer: MovementOnFundsReportFundOperationDtoTransferToJSON(value.transfer),
    loan: MovementOnFundsReportFundOperationDtoLoanToJSON(value.loan),
    withdraw: MovementOnFundsReportFundOperationDtoWithdrawToJSON(value.withdraw),
    employee: MovementOnFundsReportFundOperationDtoEmployeeToJSON(value.employee),
  };
}
