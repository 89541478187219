/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CompanyEmployeeRoleDto,
  CompanyEmployeeRoleDtoFromJSON,
  CompanyEmployeeRoleDtoFromJSONTyped,
  CompanyEmployeeRoleDtoToJSON,
} from "./CompanyEmployeeRoleDto";
import {
  UserProfileDto,
  UserProfileDtoFromJSON,
  UserProfileDtoFromJSONTyped,
  UserProfileDtoToJSON,
} from "./UserProfileDto";

/**
 *
 * @export
 * @interface FundingRequestPayoutDtoCreator
 */
export interface FundingRequestPayoutDtoCreator {
  /**
   *
   * @type {string}
   * @memberof FundingRequestPayoutDtoCreator
   */
  id: string;
  /**
   *
   * @type {UserProfileDto}
   * @memberof FundingRequestPayoutDtoCreator
   */
  user: UserProfileDto;
  /**
   *
   * @type {CompanyEmployeeRoleDto}
   * @memberof FundingRequestPayoutDtoCreator
   */
  companyEmployeeRole: CompanyEmployeeRoleDto;
}

export function FundingRequestPayoutDtoCreatorFromJSON(json: any): FundingRequestPayoutDtoCreator {
  return FundingRequestPayoutDtoCreatorFromJSONTyped(json, false);
}

export function FundingRequestPayoutDtoCreatorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundingRequestPayoutDtoCreator {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    user: UserProfileDtoFromJSON(json["user"]),
    companyEmployeeRole: CompanyEmployeeRoleDtoFromJSON(json["companyEmployeeRole"]),
  };
}

export function FundingRequestPayoutDtoCreatorToJSON(value?: FundingRequestPayoutDtoCreator | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user: UserProfileDtoToJSON(value.user),
    companyEmployeeRole: CompanyEmployeeRoleDtoToJSON(value.companyEmployeeRole),
  };
}
