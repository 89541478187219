/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportBalancesOnFundGroupsAndFundsFundGroupDto,
  ReportBalancesOnFundGroupsAndFundsFundGroupDtoFromJSON,
  ReportBalancesOnFundGroupsAndFundsFundGroupDtoFromJSONTyped,
  ReportBalancesOnFundGroupsAndFundsFundGroupDtoToJSON,
} from "./ReportBalancesOnFundGroupsAndFundsFundGroupDto";

/**
 *
 * @export
 * @interface ReportBalancesOnFundGroupsAndFundsFundDto
 */
export interface ReportBalancesOnFundGroupsAndFundsFundDto {
  /**
   *
   * @type {string}
   * @memberof ReportBalancesOnFundGroupsAndFundsFundDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ReportBalancesOnFundGroupsAndFundsFundDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ReportBalancesOnFundGroupsAndFundsFundDto
   */
  balance: number;
  /**
   *
   * @type {number}
   * @memberof ReportBalancesOnFundGroupsAndFundsFundDto
   */
  reserved: number;
  /**
   *
   * @type {number}
   * @memberof ReportBalancesOnFundGroupsAndFundsFundDto
   */
  total: number;
  /**
   *
   * @type {ReportBalancesOnFundGroupsAndFundsFundGroupDto}
   * @memberof ReportBalancesOnFundGroupsAndFundsFundDto
   */
  fundGroup: ReportBalancesOnFundGroupsAndFundsFundGroupDto;
}

export function ReportBalancesOnFundGroupsAndFundsFundDtoFromJSON(
  json: any
): ReportBalancesOnFundGroupsAndFundsFundDto {
  return ReportBalancesOnFundGroupsAndFundsFundDtoFromJSONTyped(json, false);
}

export function ReportBalancesOnFundGroupsAndFundsFundDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReportBalancesOnFundGroupsAndFundsFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    balance: json["balance"],
    reserved: json["reserved"],
    total: json["total"],
    fundGroup: ReportBalancesOnFundGroupsAndFundsFundGroupDtoFromJSON(json["fundGroup"]),
  };
}

export function ReportBalancesOnFundGroupsAndFundsFundDtoToJSON(
  value?: ReportBalancesOnFundGroupsAndFundsFundDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    balance: value.balance,
    reserved: value.reserved,
    total: value.total,
    fundGroup: ReportBalancesOnFundGroupsAndFundsFundGroupDtoToJSON(value.fundGroup),
  };
}
