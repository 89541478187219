import { useEffect, useState } from "react";
import { LocalStorage } from "shared/lib";

export const useTheme = (): ["light" | "dark" | undefined, (theme: "light" | "dark" | undefined) => void] => {
  const [theme, setTheme] = useState<"light" | "dark" | undefined>();

  useEffect(() => {
    setTheme(LocalStorage.getItem("theme"));

    return LocalStorage.subscribe("theme", (value) => {
      if (value === "dark") setTheme("dark");
      else if (value === "light") setTheme("light");
      else setTheme(undefined);
    });
  });

  const onChange = (theme: "light" | "dark" | undefined) => {
    if (theme === "light" || theme === "dark") {
      setTheme(theme);
      LocalStorage.setItem("theme", theme);
    } else {
      LocalStorage.removeItem("theme");
      setTheme(undefined);
    }
  };

  return [theme, onChange];
};

const getSystemTheme = () => {
  const mediaQuery = "(prefers-color-scheme: dark)";
  const match = window.matchMedia(mediaQuery);
  return match.media !== mediaQuery || match.matches ? "dark" : "light";
};

export const setDocumentTheme = (theme: "light" | "dark" | undefined) => {
  try {
    const documentElement = document.documentElement;
    const classList = documentElement.classList;
    classList.remove("light-theme", "dark-theme");

    if (!theme) theme = getSystemTheme();

    const classNames = {
      light: "light-theme",
      dark: "dark-theme",
    };
    classList.add(classNames[theme] || "");
    documentElement.style.colorScheme = theme;
  } catch (e) {}
};
