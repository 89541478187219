/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanLastDraftOrOnApprovalDtoFinancialPlan,
  FinancialPlanLastDraftOrOnApprovalDtoFinancialPlanFromJSON,
  FinancialPlanLastDraftOrOnApprovalDtoFinancialPlanFromJSONTyped,
  FinancialPlanLastDraftOrOnApprovalDtoFinancialPlanToJSON,
} from "./FinancialPlanLastDraftOrOnApprovalDtoFinancialPlan";

/**
 *
 * @export
 * @interface FinancialPlanLastDraftOrOnApprovalDto
 */
export interface FinancialPlanLastDraftOrOnApprovalDto {
  /**
   *
   * @type {FinancialPlanLastDraftOrOnApprovalDtoFinancialPlan}
   * @memberof FinancialPlanLastDraftOrOnApprovalDto
   */
  financialPlan: FinancialPlanLastDraftOrOnApprovalDtoFinancialPlan | null;
}

export function FinancialPlanLastDraftOrOnApprovalDtoFromJSON(json: any): FinancialPlanLastDraftOrOnApprovalDto {
  return FinancialPlanLastDraftOrOnApprovalDtoFromJSONTyped(json, false);
}

export function FinancialPlanLastDraftOrOnApprovalDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanLastDraftOrOnApprovalDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    financialPlan: FinancialPlanLastDraftOrOnApprovalDtoFinancialPlanFromJSON(json["financialPlan"]),
  };
}

export function FinancialPlanLastDraftOrOnApprovalDtoToJSON(value?: FinancialPlanLastDraftOrOnApprovalDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    financialPlan: FinancialPlanLastDraftOrOnApprovalDtoFinancialPlanToJSON(value.financialPlan),
  };
}
