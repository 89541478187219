/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinancialPlanFactIncomeDto
 */
export interface FinancialPlanFactIncomeDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanFactIncomeDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanFactIncomeDto
   */
  incomeTypeId: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFactIncomeDto
   */
  amount: number;
}

export function FinancialPlanFactIncomeDtoFromJSON(json: any): FinancialPlanFactIncomeDto {
  return FinancialPlanFactIncomeDtoFromJSONTyped(json, false);
}

export function FinancialPlanFactIncomeDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanFactIncomeDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    incomeTypeId: json["incomeTypeId"],
    amount: json["amount"],
  };
}

export function FinancialPlanFactIncomeDtoToJSON(value?: FinancialPlanFactIncomeDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    incomeTypeId: value.incomeTypeId,
    amount: value.amount,
  };
}
