/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  MonthPlanAddDeferredIncomeRequestDto,
  MonthPlanAddDeferredIncomeRequestDtoFromJSON,
  MonthPlanAddDeferredIncomeRequestDtoToJSON,
  MonthPlanAddFixedCostRequestDto,
  MonthPlanAddFixedCostRequestDtoFromJSON,
  MonthPlanAddFixedCostRequestDtoToJSON,
  MonthPlanAddIncomeRequestDto,
  MonthPlanAddIncomeRequestDtoFromJSON,
  MonthPlanAddIncomeRequestDtoToJSON,
  MonthPlanAddVariableCostFromMarginRequestDto,
  MonthPlanAddVariableCostFromMarginRequestDtoFromJSON,
  MonthPlanAddVariableCostFromMarginRequestDtoToJSON,
  MonthPlanAddVariableCostRequestDto,
  MonthPlanAddVariableCostRequestDtoFromJSON,
  MonthPlanAddVariableCostRequestDtoToJSON,
  MonthPlanChangeDeferredIncomeWeekPlanRequestDto,
  MonthPlanChangeDeferredIncomeWeekPlanRequestDtoFromJSON,
  MonthPlanChangeDeferredIncomeWeekPlanRequestDtoToJSON,
  MonthPlanChangeFixedCostNumberRequestDto,
  MonthPlanChangeFixedCostNumberRequestDtoFromJSON,
  MonthPlanChangeFixedCostNumberRequestDtoToJSON,
  MonthPlanChangeFixedCostPlanRequestDto,
  MonthPlanChangeFixedCostPlanRequestDtoFromJSON,
  MonthPlanChangeFixedCostPlanRequestDtoToJSON,
  MonthPlanChangeIncomeWeekPlanRequestDto,
  MonthPlanChangeIncomeWeekPlanRequestDtoFromJSON,
  MonthPlanChangeIncomeWeekPlanRequestDtoToJSON,
  MonthPlanChangeOverflowFund,
  MonthPlanChangeOverflowFundFromJSON,
  MonthPlanChangeOverflowFundToJSON,
  MonthPlanChangeVariableCostFromMarginPercentRequestDto,
  MonthPlanChangeVariableCostFromMarginPercentRequestDtoFromJSON,
  MonthPlanChangeVariableCostFromMarginPercentRequestDtoToJSON,
  MonthPlanChangeVariableCostIncomePercentRequestDto,
  MonthPlanChangeVariableCostIncomePercentRequestDtoFromJSON,
  MonthPlanChangeVariableCostIncomePercentRequestDtoToJSON,
  MonthPlanCommonNextMonthPlanDto,
  MonthPlanCommonNextMonthPlanDtoFromJSON,
  MonthPlanCommonNextMonthPlanDtoToJSON,
  MonthPlanCopiedEducationDto,
  MonthPlanCopiedEducationDtoFromJSON,
  MonthPlanCopiedEducationDtoToJSON,
  MonthPlanCopyEducationRequestDto,
  MonthPlanCopyEducationRequestDtoFromJSON,
  MonthPlanCopyEducationRequestDtoToJSON,
  MonthPlanCreateRequestDto,
  MonthPlanCreateRequestDtoFromJSON,
  MonthPlanCreateRequestDtoToJSON,
  MonthPlanFullDto,
  MonthPlanFullDtoFromJSON,
  MonthPlanFullDtoToJSON,
  MonthPlanRemoveDeferredIncomeRequestDto,
  MonthPlanRemoveDeferredIncomeRequestDtoFromJSON,
  MonthPlanRemoveDeferredIncomeRequestDtoToJSON,
  MonthPlanRemoveFixedCostRequestDto,
  MonthPlanRemoveFixedCostRequestDtoFromJSON,
  MonthPlanRemoveFixedCostRequestDtoToJSON,
  MonthPlanRemoveIncomeRequestDto,
  MonthPlanRemoveIncomeRequestDtoFromJSON,
  MonthPlanRemoveIncomeRequestDtoToJSON,
  MonthPlanRemoveVariableCostFromMarginRequestDto,
  MonthPlanRemoveVariableCostFromMarginRequestDtoFromJSON,
  MonthPlanRemoveVariableCostFromMarginRequestDtoToJSON,
  MonthPlanRemoveVariableCostRequestDto,
  MonthPlanRemoveVariableCostRequestDtoFromJSON,
  MonthPlanRemoveVariableCostRequestDtoToJSON,
  MonthPlanSimpleDto,
  MonthPlanSimpleDtoFromJSON,
  MonthPlanSimpleDtoToJSON,
} from "../models";

export interface MonthPlanAddDeferredIncomeRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanAddDeferredIncomeRequestDto: MonthPlanAddDeferredIncomeRequestDto;
}

export interface MonthPlanAddFixedCostRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanAddFixedCostRequestDto: MonthPlanAddFixedCostRequestDto;
}

export interface MonthPlanAddIncomeRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanAddIncomeRequestDto: MonthPlanAddIncomeRequestDto;
}

export interface MonthPlanAddVariableCostRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanAddVariableCostRequestDto: MonthPlanAddVariableCostRequestDto;
}

export interface MonthPlanAddVariableCostFromMarginRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanAddVariableCostFromMarginRequestDto: MonthPlanAddVariableCostFromMarginRequestDto;
}

export interface MonthPlanAddWeekRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

export interface MonthPlanApproveRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

export interface MonthPlanChangeDeferredIncomeRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanChangeDeferredIncomeWeekPlanRequestDto: MonthPlanChangeDeferredIncomeWeekPlanRequestDto;
}

export interface MonthPlanChangeFixedCostNumberRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanChangeFixedCostNumberRequestDto: MonthPlanChangeFixedCostNumberRequestDto;
}

export interface MonthPlanChangeFixedCostPlanRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanChangeFixedCostPlanRequestDto: MonthPlanChangeFixedCostPlanRequestDto;
}

export interface MonthPlanChangeIncomeWeekPlanRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanChangeIncomeWeekPlanRequestDto: MonthPlanChangeIncomeWeekPlanRequestDto;
}

export interface MonthPlanChangeOverflowFundRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanChangeOverflowFund: MonthPlanChangeOverflowFund;
}

export interface MonthPlanChangeVariableCostFromMarginPercentRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanChangeVariableCostFromMarginPercentRequestDto: MonthPlanChangeVariableCostFromMarginPercentRequestDto;
}

export interface MonthPlanChangeVariableCostIncomePercentRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanChangeVariableCostIncomePercentRequestDto: MonthPlanChangeVariableCostIncomePercentRequestDto;
}

export interface MonthPlanCommonNextMonthPlanRequest {
  companyIdOrSlug: string;
  year: number;
  month: number;
}

export interface MonthPlanCopyEducationRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanCopyEducationRequestDto: MonthPlanCopyEducationRequestDto;
}

export interface MonthPlanCreateRequest {
  companyIdOrSlug: string;
  monthPlanCreateRequestDto: MonthPlanCreateRequestDto;
}

export interface MonthPlanDeleteRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

export interface MonthPlanGetRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

export interface MonthPlanListRequest {
  companyIdOrSlug: string;
}

export interface MonthPlanRemoveDeferredIncomeRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanRemoveDeferredIncomeRequestDto: MonthPlanRemoveDeferredIncomeRequestDto;
}

export interface MonthPlanRemoveFixedCostRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanRemoveFixedCostRequestDto: MonthPlanRemoveFixedCostRequestDto;
}

export interface MonthPlanRemoveIncomeRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanRemoveIncomeRequestDto: MonthPlanRemoveIncomeRequestDto;
}

export interface MonthPlanRemoveVariableCostRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanRemoveVariableCostRequestDto: MonthPlanRemoveVariableCostRequestDto;
}

export interface MonthPlanRemoveVariableCostFromMarginRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
  monthPlanRemoveVariableCostFromMarginRequestDto: MonthPlanRemoveVariableCostFromMarginRequestDto;
}

export interface MonthPlanRemoveWeekRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

export interface MonthPlanRollbackApproveRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

export interface MonthPlanRollbackSubmitForApprovalRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

export interface MonthPlanSubmitForApprovalRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug: string;
}

/**
 *
 */
export class MonthPlanApi extends runtime.BaseAPI {
  /**
   */
  protected monthPlanAddDeferredIncomeRequestOpts = (
    requestParameters: MonthPlanAddDeferredIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanAddDeferredIncome."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanAddDeferredIncome."
      );
    }

    if (
      requestParameters.monthPlanAddDeferredIncomeRequestDto === null ||
      requestParameters.monthPlanAddDeferredIncomeRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanAddDeferredIncomeRequestDto",
        "Required parameter requestParameters.monthPlanAddDeferredIncomeRequestDto was null or undefined when calling monthPlanAddDeferredIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/add-deferred-income`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanAddDeferredIncomeRequestDtoToJSON(requestParameters.monthPlanAddDeferredIncomeRequestDto),
    };
  };

  /**
   */
  protected monthPlanAddDeferredIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanAddDeferredIncomeRaw = async (
    requestParameters: MonthPlanAddDeferredIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanAddDeferredIncomeRequestOpts(requestParameters);
    return this.monthPlanAddDeferredIncomeFetch(context, initOverrides);
  };

  /**
   */
  monthPlanAddDeferredIncome = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanAddDeferredIncomeRequestDto: MonthPlanAddDeferredIncomeRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanAddDeferredIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanAddDeferredIncomeRequestDto: monthPlanAddDeferredIncomeRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanAddFixedCostRequestOpts = (
    requestParameters: MonthPlanAddFixedCostRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanAddFixedCost."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanAddFixedCost."
      );
    }

    if (
      requestParameters.monthPlanAddFixedCostRequestDto === null ||
      requestParameters.monthPlanAddFixedCostRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanAddFixedCostRequestDto",
        "Required parameter requestParameters.monthPlanAddFixedCostRequestDto was null or undefined when calling monthPlanAddFixedCost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/add-fixed-cost`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanAddFixedCostRequestDtoToJSON(requestParameters.monthPlanAddFixedCostRequestDto),
    };
  };

  /**
   */
  protected monthPlanAddFixedCostFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanAddFixedCostRaw = async (
    requestParameters: MonthPlanAddFixedCostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanAddFixedCostRequestOpts(requestParameters);
    return this.monthPlanAddFixedCostFetch(context, initOverrides);
  };

  /**
   */
  monthPlanAddFixedCost = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanAddFixedCostRequestDto: MonthPlanAddFixedCostRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanAddFixedCostRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanAddFixedCostRequestDto: monthPlanAddFixedCostRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanAddIncomeRequestOpts = (requestParameters: MonthPlanAddIncomeRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanAddIncome."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanAddIncome."
      );
    }

    if (
      requestParameters.monthPlanAddIncomeRequestDto === null ||
      requestParameters.monthPlanAddIncomeRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanAddIncomeRequestDto",
        "Required parameter requestParameters.monthPlanAddIncomeRequestDto was null or undefined when calling monthPlanAddIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/add-income`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanAddIncomeRequestDtoToJSON(requestParameters.monthPlanAddIncomeRequestDto),
    };
  };

  /**
   */
  protected monthPlanAddIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanAddIncomeRaw = async (
    requestParameters: MonthPlanAddIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanAddIncomeRequestOpts(requestParameters);
    return this.monthPlanAddIncomeFetch(context, initOverrides);
  };

  /**
   */
  monthPlanAddIncome = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanAddIncomeRequestDto: MonthPlanAddIncomeRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanAddIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanAddIncomeRequestDto: monthPlanAddIncomeRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanAddVariableCostRequestOpts = (
    requestParameters: MonthPlanAddVariableCostRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanAddVariableCost."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanAddVariableCost."
      );
    }

    if (
      requestParameters.monthPlanAddVariableCostRequestDto === null ||
      requestParameters.monthPlanAddVariableCostRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanAddVariableCostRequestDto",
        "Required parameter requestParameters.monthPlanAddVariableCostRequestDto was null or undefined when calling monthPlanAddVariableCost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/add-variable-cost`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanAddVariableCostRequestDtoToJSON(requestParameters.monthPlanAddVariableCostRequestDto),
    };
  };

  /**
   */
  protected monthPlanAddVariableCostFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanAddVariableCostRaw = async (
    requestParameters: MonthPlanAddVariableCostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanAddVariableCostRequestOpts(requestParameters);
    return this.monthPlanAddVariableCostFetch(context, initOverrides);
  };

  /**
   */
  monthPlanAddVariableCost = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanAddVariableCostRequestDto: MonthPlanAddVariableCostRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanAddVariableCostRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanAddVariableCostRequestDto: monthPlanAddVariableCostRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanAddVariableCostFromMarginRequestOpts = (
    requestParameters: MonthPlanAddVariableCostFromMarginRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanAddVariableCostFromMargin."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanAddVariableCostFromMargin."
      );
    }

    if (
      requestParameters.monthPlanAddVariableCostFromMarginRequestDto === null ||
      requestParameters.monthPlanAddVariableCostFromMarginRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanAddVariableCostFromMarginRequestDto",
        "Required parameter requestParameters.monthPlanAddVariableCostFromMarginRequestDto was null or undefined when calling monthPlanAddVariableCostFromMargin."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/add-variable-cost-from-margin`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanAddVariableCostFromMarginRequestDtoToJSON(
        requestParameters.monthPlanAddVariableCostFromMarginRequestDto
      ),
    };
  };

  /**
   */
  protected monthPlanAddVariableCostFromMarginFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanAddVariableCostFromMarginRaw = async (
    requestParameters: MonthPlanAddVariableCostFromMarginRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanAddVariableCostFromMarginRequestOpts(requestParameters);
    return this.monthPlanAddVariableCostFromMarginFetch(context, initOverrides);
  };

  /**
   */
  monthPlanAddVariableCostFromMargin = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanAddVariableCostFromMarginRequestDto: MonthPlanAddVariableCostFromMarginRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanAddVariableCostFromMarginRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanAddVariableCostFromMarginRequestDto: monthPlanAddVariableCostFromMarginRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanAddWeekRequestOpts = (requestParameters: MonthPlanAddWeekRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanAddWeek."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanAddWeek."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/add-week`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanAddWeekFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanAddWeekRaw = async (
    requestParameters: MonthPlanAddWeekRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanAddWeekRequestOpts(requestParameters);
    return this.monthPlanAddWeekFetch(context, initOverrides);
  };

  /**
   */
  monthPlanAddWeek = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanAddWeekRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanApproveRequestOpts = (requestParameters: MonthPlanApproveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanApprove."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanApprove."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/approve`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanApproveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanApproveRaw = async (
    requestParameters: MonthPlanApproveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanApproveRequestOpts(requestParameters);
    return this.monthPlanApproveFetch(context, initOverrides);
  };

  /**
   */
  monthPlanApprove = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanApproveRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanChangeDeferredIncomeRequestOpts = (
    requestParameters: MonthPlanChangeDeferredIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanChangeDeferredIncome."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanChangeDeferredIncome."
      );
    }

    if (
      requestParameters.monthPlanChangeDeferredIncomeWeekPlanRequestDto === null ||
      requestParameters.monthPlanChangeDeferredIncomeWeekPlanRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanChangeDeferredIncomeWeekPlanRequestDto",
        "Required parameter requestParameters.monthPlanChangeDeferredIncomeWeekPlanRequestDto was null or undefined when calling monthPlanChangeDeferredIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/change-deferred-income`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanChangeDeferredIncomeWeekPlanRequestDtoToJSON(
        requestParameters.monthPlanChangeDeferredIncomeWeekPlanRequestDto
      ),
    };
  };

  /**
   */
  protected monthPlanChangeDeferredIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanChangeDeferredIncomeRaw = async (
    requestParameters: MonthPlanChangeDeferredIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanChangeDeferredIncomeRequestOpts(requestParameters);
    return this.monthPlanChangeDeferredIncomeFetch(context, initOverrides);
  };

  /**
   */
  monthPlanChangeDeferredIncome = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanChangeDeferredIncomeWeekPlanRequestDto: MonthPlanChangeDeferredIncomeWeekPlanRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanChangeDeferredIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanChangeDeferredIncomeWeekPlanRequestDto: monthPlanChangeDeferredIncomeWeekPlanRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanChangeFixedCostNumberRequestOpts = (
    requestParameters: MonthPlanChangeFixedCostNumberRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanChangeFixedCostNumber."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanChangeFixedCostNumber."
      );
    }

    if (
      requestParameters.monthPlanChangeFixedCostNumberRequestDto === null ||
      requestParameters.monthPlanChangeFixedCostNumberRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanChangeFixedCostNumberRequestDto",
        "Required parameter requestParameters.monthPlanChangeFixedCostNumberRequestDto was null or undefined when calling monthPlanChangeFixedCostNumber."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/change-fixed-cost-number`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanChangeFixedCostNumberRequestDtoToJSON(requestParameters.monthPlanChangeFixedCostNumberRequestDto),
    };
  };

  /**
   */
  protected monthPlanChangeFixedCostNumberFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanChangeFixedCostNumberRaw = async (
    requestParameters: MonthPlanChangeFixedCostNumberRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanChangeFixedCostNumberRequestOpts(requestParameters);
    return this.monthPlanChangeFixedCostNumberFetch(context, initOverrides);
  };

  /**
   */
  monthPlanChangeFixedCostNumber = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanChangeFixedCostNumberRequestDto: MonthPlanChangeFixedCostNumberRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanChangeFixedCostNumberRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanChangeFixedCostNumberRequestDto: monthPlanChangeFixedCostNumberRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanChangeFixedCostPlanRequestOpts = (
    requestParameters: MonthPlanChangeFixedCostPlanRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanChangeFixedCostPlan."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanChangeFixedCostPlan."
      );
    }

    if (
      requestParameters.monthPlanChangeFixedCostPlanRequestDto === null ||
      requestParameters.monthPlanChangeFixedCostPlanRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanChangeFixedCostPlanRequestDto",
        "Required parameter requestParameters.monthPlanChangeFixedCostPlanRequestDto was null or undefined when calling monthPlanChangeFixedCostPlan."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/change-fixed-cost-plan`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanChangeFixedCostPlanRequestDtoToJSON(requestParameters.monthPlanChangeFixedCostPlanRequestDto),
    };
  };

  /**
   */
  protected monthPlanChangeFixedCostPlanFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanChangeFixedCostPlanRaw = async (
    requestParameters: MonthPlanChangeFixedCostPlanRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanChangeFixedCostPlanRequestOpts(requestParameters);
    return this.monthPlanChangeFixedCostPlanFetch(context, initOverrides);
  };

  /**
   */
  monthPlanChangeFixedCostPlan = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanChangeFixedCostPlanRequestDto: MonthPlanChangeFixedCostPlanRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanChangeFixedCostPlanRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanChangeFixedCostPlanRequestDto: monthPlanChangeFixedCostPlanRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanChangeIncomeWeekPlanRequestOpts = (
    requestParameters: MonthPlanChangeIncomeWeekPlanRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanChangeIncomeWeekPlan."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanChangeIncomeWeekPlan."
      );
    }

    if (
      requestParameters.monthPlanChangeIncomeWeekPlanRequestDto === null ||
      requestParameters.monthPlanChangeIncomeWeekPlanRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanChangeIncomeWeekPlanRequestDto",
        "Required parameter requestParameters.monthPlanChangeIncomeWeekPlanRequestDto was null or undefined when calling monthPlanChangeIncomeWeekPlan."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/change-income-week-plan`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanChangeIncomeWeekPlanRequestDtoToJSON(requestParameters.monthPlanChangeIncomeWeekPlanRequestDto),
    };
  };

  /**
   */
  protected monthPlanChangeIncomeWeekPlanFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanChangeIncomeWeekPlanRaw = async (
    requestParameters: MonthPlanChangeIncomeWeekPlanRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanChangeIncomeWeekPlanRequestOpts(requestParameters);
    return this.monthPlanChangeIncomeWeekPlanFetch(context, initOverrides);
  };

  /**
   */
  monthPlanChangeIncomeWeekPlan = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanChangeIncomeWeekPlanRequestDto: MonthPlanChangeIncomeWeekPlanRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanChangeIncomeWeekPlanRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanChangeIncomeWeekPlanRequestDto: monthPlanChangeIncomeWeekPlanRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanChangeOverflowFundRequestOpts = (
    requestParameters: MonthPlanChangeOverflowFundRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanChangeOverflowFund."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanChangeOverflowFund."
      );
    }

    if (
      requestParameters.monthPlanChangeOverflowFund === null ||
      requestParameters.monthPlanChangeOverflowFund === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanChangeOverflowFund",
        "Required parameter requestParameters.monthPlanChangeOverflowFund was null or undefined when calling monthPlanChangeOverflowFund."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/change-overflow-fund`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanChangeOverflowFundToJSON(requestParameters.monthPlanChangeOverflowFund),
    };
  };

  /**
   */
  protected monthPlanChangeOverflowFundFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanChangeOverflowFundRaw = async (
    requestParameters: MonthPlanChangeOverflowFundRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanChangeOverflowFundRequestOpts(requestParameters);
    return this.monthPlanChangeOverflowFundFetch(context, initOverrides);
  };

  /**
   */
  monthPlanChangeOverflowFund = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanChangeOverflowFund: MonthPlanChangeOverflowFund,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanChangeOverflowFundRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanChangeOverflowFund: monthPlanChangeOverflowFund,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanChangeVariableCostFromMarginPercentRequestOpts = (
    requestParameters: MonthPlanChangeVariableCostFromMarginPercentRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanChangeVariableCostFromMarginPercent."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanChangeVariableCostFromMarginPercent."
      );
    }

    if (
      requestParameters.monthPlanChangeVariableCostFromMarginPercentRequestDto === null ||
      requestParameters.monthPlanChangeVariableCostFromMarginPercentRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanChangeVariableCostFromMarginPercentRequestDto",
        "Required parameter requestParameters.monthPlanChangeVariableCostFromMarginPercentRequestDto was null or undefined when calling monthPlanChangeVariableCostFromMarginPercent."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/change-variable-cost-from-margin-percent`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanChangeVariableCostFromMarginPercentRequestDtoToJSON(
        requestParameters.monthPlanChangeVariableCostFromMarginPercentRequestDto
      ),
    };
  };

  /**
   */
  protected monthPlanChangeVariableCostFromMarginPercentFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanChangeVariableCostFromMarginPercentRaw = async (
    requestParameters: MonthPlanChangeVariableCostFromMarginPercentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanChangeVariableCostFromMarginPercentRequestOpts(requestParameters);
    return this.monthPlanChangeVariableCostFromMarginPercentFetch(context, initOverrides);
  };

  /**
   */
  monthPlanChangeVariableCostFromMarginPercent = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanChangeVariableCostFromMarginPercentRequestDto: MonthPlanChangeVariableCostFromMarginPercentRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanChangeVariableCostFromMarginPercentRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanChangeVariableCostFromMarginPercentRequestDto: monthPlanChangeVariableCostFromMarginPercentRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanChangeVariableCostIncomePercentRequestOpts = (
    requestParameters: MonthPlanChangeVariableCostIncomePercentRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanChangeVariableCostIncomePercent."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanChangeVariableCostIncomePercent."
      );
    }

    if (
      requestParameters.monthPlanChangeVariableCostIncomePercentRequestDto === null ||
      requestParameters.monthPlanChangeVariableCostIncomePercentRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanChangeVariableCostIncomePercentRequestDto",
        "Required parameter requestParameters.monthPlanChangeVariableCostIncomePercentRequestDto was null or undefined when calling monthPlanChangeVariableCostIncomePercent."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/change-variable-cost-income-percent`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanChangeVariableCostIncomePercentRequestDtoToJSON(
        requestParameters.monthPlanChangeVariableCostIncomePercentRequestDto
      ),
    };
  };

  /**
   */
  protected monthPlanChangeVariableCostIncomePercentFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanChangeVariableCostIncomePercentRaw = async (
    requestParameters: MonthPlanChangeVariableCostIncomePercentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanChangeVariableCostIncomePercentRequestOpts(requestParameters);
    return this.monthPlanChangeVariableCostIncomePercentFetch(context, initOverrides);
  };

  /**
   */
  monthPlanChangeVariableCostIncomePercent = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanChangeVariableCostIncomePercentRequestDto: MonthPlanChangeVariableCostIncomePercentRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanChangeVariableCostIncomePercentRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanChangeVariableCostIncomePercentRequestDto: monthPlanChangeVariableCostIncomePercentRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanCommonNextMonthPlanRequestOpts = (
    requestParameters: MonthPlanCommonNextMonthPlanRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanCommonNextMonthPlan."
      );
    }

    if (requestParameters.year === null || requestParameters.year === undefined) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling monthPlanCommonNextMonthPlan."
      );
    }

    if (requestParameters.month === null || requestParameters.month === undefined) {
      throw new runtime.RequiredError(
        "month",
        "Required parameter requestParameters.month was null or undefined when calling monthPlanCommonNextMonthPlan."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.year !== undefined) {
      queryParameters["year"] = requestParameters.year;
    }

    if (requestParameters.month !== undefined) {
      queryParameters["month"] = requestParameters.month;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan-common/next`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanCommonNextMonthPlanFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanCommonNextMonthPlanDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => MonthPlanCommonNextMonthPlanDtoFromJSON(jsonValue));
  };

  /**
   */
  protected monthPlanCommonNextMonthPlanRaw = async (
    requestParameters: MonthPlanCommonNextMonthPlanRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanCommonNextMonthPlanDto>> => {
    const context = this.monthPlanCommonNextMonthPlanRequestOpts(requestParameters);
    return this.monthPlanCommonNextMonthPlanFetch(context, initOverrides);
  };

  /**
   */
  monthPlanCommonNextMonthPlan = async (
    companyIdOrSlug: string,
    year: number,
    month: number,
    initOverrides?: RequestInit
  ): Promise<MonthPlanCommonNextMonthPlanDto> => {
    const response = await this.monthPlanCommonNextMonthPlanRaw(
      { companyIdOrSlug: companyIdOrSlug, year: year, month: month },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useMonthPlanCommonNextMonthPlan = (
    companyIdOrSlug: string,
    year: number,
    month: number,
    config?: SWRConfiguration<MonthPlanCommonNextMonthPlanDto>
  ): SWRResponse<MonthPlanCommonNextMonthPlanDto> => {
    return useSWR<MonthPlanCommonNextMonthPlanDto>(
      () =>
        this.monthPlanCommonNextMonthPlanRequestOpts({ companyIdOrSlug: companyIdOrSlug, year: year, month: month }),
      (context) => this.swrFetch(this.monthPlanCommonNextMonthPlanFetch(context)),
      config
    );
  };

  /**
   */
  protected monthPlanCopyEducationRequestOpts = (
    requestParameters: MonthPlanCopyEducationRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanCopyEducation."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanCopyEducation."
      );
    }

    if (
      requestParameters.monthPlanCopyEducationRequestDto === null ||
      requestParameters.monthPlanCopyEducationRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanCopyEducationRequestDto",
        "Required parameter requestParameters.monthPlanCopyEducationRequestDto was null or undefined when calling monthPlanCopyEducation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/copy-education`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanCopyEducationRequestDtoToJSON(requestParameters.monthPlanCopyEducationRequestDto),
    };
  };

  /**
   */
  protected monthPlanCopyEducationFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanCopiedEducationDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => MonthPlanCopiedEducationDtoFromJSON(jsonValue));
  };

  /**
   */
  protected monthPlanCopyEducationRaw = async (
    requestParameters: MonthPlanCopyEducationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanCopiedEducationDto>> => {
    const context = this.monthPlanCopyEducationRequestOpts(requestParameters);
    return this.monthPlanCopyEducationFetch(context, initOverrides);
  };

  /**
   */
  monthPlanCopyEducation = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanCopyEducationRequestDto: MonthPlanCopyEducationRequestDto,
    initOverrides?: RequestInit
  ): Promise<MonthPlanCopiedEducationDto> => {
    const response = await this.monthPlanCopyEducationRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanCopyEducationRequestDto: monthPlanCopyEducationRequestDto,
      },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected monthPlanCreateRequestOpts = (requestParameters: MonthPlanCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanCreate."
      );
    }

    if (
      requestParameters.monthPlanCreateRequestDto === null ||
      requestParameters.monthPlanCreateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanCreateRequestDto",
        "Required parameter requestParameters.monthPlanCreateRequestDto was null or undefined when calling monthPlanCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanCreateRequestDtoToJSON(requestParameters.monthPlanCreateRequestDto),
    };
  };

  /**
   */
  protected monthPlanCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanFullDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => MonthPlanFullDtoFromJSON(jsonValue));
  };

  /**
   */
  protected monthPlanCreateRaw = async (
    requestParameters: MonthPlanCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanFullDto>> => {
    const context = this.monthPlanCreateRequestOpts(requestParameters);
    return this.monthPlanCreateFetch(context, initOverrides);
  };

  /**
   */
  monthPlanCreate = async (
    companyIdOrSlug: string,
    monthPlanCreateRequestDto: MonthPlanCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<MonthPlanFullDto> => {
    const response = await this.monthPlanCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanCreateRequestDto: monthPlanCreateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected monthPlanDeleteRequestOpts = (requestParameters: MonthPlanDeleteRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanDelete."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanDeleteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanDeleteRaw = async (
    requestParameters: MonthPlanDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanDeleteRequestOpts(requestParameters);
    return this.monthPlanDeleteFetch(context, initOverrides);
  };

  /**
   */
  monthPlanDelete = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanDeleteRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanGetRequestOpts = (requestParameters: MonthPlanGetRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanGet."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanGetFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanFullDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => MonthPlanFullDtoFromJSON(jsonValue));
  };

  /**
   */
  protected monthPlanGetRaw = async (
    requestParameters: MonthPlanGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MonthPlanFullDto>> => {
    const context = this.monthPlanGetRequestOpts(requestParameters);
    return this.monthPlanGetFetch(context, initOverrides);
  };

  /**
   */
  monthPlanGet = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<MonthPlanFullDto> => {
    const response = await this.monthPlanGetRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useMonthPlanGet = (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    config?: SWRConfiguration<MonthPlanFullDto>
  ): SWRResponse<MonthPlanFullDto> => {
    return useSWR<MonthPlanFullDto>(
      () => this.monthPlanGetRequestOpts({ companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug }),
      (context) => this.swrFetch(this.monthPlanGetFetch(context)),
      config
    );
  };

  /**
   */
  protected monthPlanListRequestOpts = (requestParameters: MonthPlanListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<MonthPlanSimpleDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonthPlanSimpleDtoFromJSON));
  };

  /**
   */
  protected monthPlanListRaw = async (
    requestParameters: MonthPlanListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<MonthPlanSimpleDto>>> => {
    const context = this.monthPlanListRequestOpts(requestParameters);
    return this.monthPlanListFetch(context, initOverrides);
  };

  /**
   */
  monthPlanList = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<Array<MonthPlanSimpleDto>> => {
    const response = await this.monthPlanListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useMonthPlanList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<MonthPlanSimpleDto>>
  ): SWRResponse<Array<MonthPlanSimpleDto>> => {
    return useSWR<Array<MonthPlanSimpleDto>>(
      () => this.monthPlanListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.monthPlanListFetch(context)),
      config
    );
  };

  /**
   */
  protected monthPlanRemoveDeferredIncomeRequestOpts = (
    requestParameters: MonthPlanRemoveDeferredIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRemoveDeferredIncome."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRemoveDeferredIncome."
      );
    }

    if (
      requestParameters.monthPlanRemoveDeferredIncomeRequestDto === null ||
      requestParameters.monthPlanRemoveDeferredIncomeRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanRemoveDeferredIncomeRequestDto",
        "Required parameter requestParameters.monthPlanRemoveDeferredIncomeRequestDto was null or undefined when calling monthPlanRemoveDeferredIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/remove-deferred-income`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanRemoveDeferredIncomeRequestDtoToJSON(requestParameters.monthPlanRemoveDeferredIncomeRequestDto),
    };
  };

  /**
   */
  protected monthPlanRemoveDeferredIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRemoveDeferredIncomeRaw = async (
    requestParameters: MonthPlanRemoveDeferredIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRemoveDeferredIncomeRequestOpts(requestParameters);
    return this.monthPlanRemoveDeferredIncomeFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRemoveDeferredIncome = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanRemoveDeferredIncomeRequestDto: MonthPlanRemoveDeferredIncomeRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRemoveDeferredIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanRemoveDeferredIncomeRequestDto: monthPlanRemoveDeferredIncomeRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanRemoveFixedCostRequestOpts = (
    requestParameters: MonthPlanRemoveFixedCostRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRemoveFixedCost."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRemoveFixedCost."
      );
    }

    if (
      requestParameters.monthPlanRemoveFixedCostRequestDto === null ||
      requestParameters.monthPlanRemoveFixedCostRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanRemoveFixedCostRequestDto",
        "Required parameter requestParameters.monthPlanRemoveFixedCostRequestDto was null or undefined when calling monthPlanRemoveFixedCost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/remove-fixed-cost`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanRemoveFixedCostRequestDtoToJSON(requestParameters.monthPlanRemoveFixedCostRequestDto),
    };
  };

  /**
   */
  protected monthPlanRemoveFixedCostFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRemoveFixedCostRaw = async (
    requestParameters: MonthPlanRemoveFixedCostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRemoveFixedCostRequestOpts(requestParameters);
    return this.monthPlanRemoveFixedCostFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRemoveFixedCost = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanRemoveFixedCostRequestDto: MonthPlanRemoveFixedCostRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRemoveFixedCostRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanRemoveFixedCostRequestDto: monthPlanRemoveFixedCostRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanRemoveIncomeRequestOpts = (
    requestParameters: MonthPlanRemoveIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRemoveIncome."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRemoveIncome."
      );
    }

    if (
      requestParameters.monthPlanRemoveIncomeRequestDto === null ||
      requestParameters.monthPlanRemoveIncomeRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanRemoveIncomeRequestDto",
        "Required parameter requestParameters.monthPlanRemoveIncomeRequestDto was null or undefined when calling monthPlanRemoveIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/remove-income`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanRemoveIncomeRequestDtoToJSON(requestParameters.monthPlanRemoveIncomeRequestDto),
    };
  };

  /**
   */
  protected monthPlanRemoveIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRemoveIncomeRaw = async (
    requestParameters: MonthPlanRemoveIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRemoveIncomeRequestOpts(requestParameters);
    return this.monthPlanRemoveIncomeFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRemoveIncome = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanRemoveIncomeRequestDto: MonthPlanRemoveIncomeRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRemoveIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanRemoveIncomeRequestDto: monthPlanRemoveIncomeRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanRemoveVariableCostRequestOpts = (
    requestParameters: MonthPlanRemoveVariableCostRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRemoveVariableCost."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRemoveVariableCost."
      );
    }

    if (
      requestParameters.monthPlanRemoveVariableCostRequestDto === null ||
      requestParameters.monthPlanRemoveVariableCostRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanRemoveVariableCostRequestDto",
        "Required parameter requestParameters.monthPlanRemoveVariableCostRequestDto was null or undefined when calling monthPlanRemoveVariableCost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/remove-variable-cost`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanRemoveVariableCostRequestDtoToJSON(requestParameters.monthPlanRemoveVariableCostRequestDto),
    };
  };

  /**
   */
  protected monthPlanRemoveVariableCostFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRemoveVariableCostRaw = async (
    requestParameters: MonthPlanRemoveVariableCostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRemoveVariableCostRequestOpts(requestParameters);
    return this.monthPlanRemoveVariableCostFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRemoveVariableCost = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanRemoveVariableCostRequestDto: MonthPlanRemoveVariableCostRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRemoveVariableCostRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanRemoveVariableCostRequestDto: monthPlanRemoveVariableCostRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanRemoveVariableCostFromMarginRequestOpts = (
    requestParameters: MonthPlanRemoveVariableCostFromMarginRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRemoveVariableCostFromMargin."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRemoveVariableCostFromMargin."
      );
    }

    if (
      requestParameters.monthPlanRemoveVariableCostFromMarginRequestDto === null ||
      requestParameters.monthPlanRemoveVariableCostFromMarginRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "monthPlanRemoveVariableCostFromMarginRequestDto",
        "Required parameter requestParameters.monthPlanRemoveVariableCostFromMarginRequestDto was null or undefined when calling monthPlanRemoveVariableCostFromMargin."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/remove-variable-cost-from-margin`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MonthPlanRemoveVariableCostFromMarginRequestDtoToJSON(
        requestParameters.monthPlanRemoveVariableCostFromMarginRequestDto
      ),
    };
  };

  /**
   */
  protected monthPlanRemoveVariableCostFromMarginFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRemoveVariableCostFromMarginRaw = async (
    requestParameters: MonthPlanRemoveVariableCostFromMarginRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRemoveVariableCostFromMarginRequestOpts(requestParameters);
    return this.monthPlanRemoveVariableCostFromMarginFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRemoveVariableCostFromMargin = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    monthPlanRemoveVariableCostFromMarginRequestDto: MonthPlanRemoveVariableCostFromMarginRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRemoveVariableCostFromMarginRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        monthPlanIdOrSlug: monthPlanIdOrSlug,
        monthPlanRemoveVariableCostFromMarginRequestDto: monthPlanRemoveVariableCostFromMarginRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanRemoveWeekRequestOpts = (requestParameters: MonthPlanRemoveWeekRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRemoveWeek."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRemoveWeek."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/remove-week`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanRemoveWeekFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRemoveWeekRaw = async (
    requestParameters: MonthPlanRemoveWeekRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRemoveWeekRequestOpts(requestParameters);
    return this.monthPlanRemoveWeekFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRemoveWeek = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRemoveWeekRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanRollbackApproveRequestOpts = (
    requestParameters: MonthPlanRollbackApproveRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRollbackApprove."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRollbackApprove."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/rollback-approve`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanRollbackApproveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRollbackApproveRaw = async (
    requestParameters: MonthPlanRollbackApproveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRollbackApproveRequestOpts(requestParameters);
    return this.monthPlanRollbackApproveFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRollbackApprove = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRollbackApproveRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanRollbackSubmitForApprovalRequestOpts = (
    requestParameters: MonthPlanRollbackSubmitForApprovalRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanRollbackSubmitForApproval."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanRollbackSubmitForApproval."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/rollback-submit-for-approval`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanRollbackSubmitForApprovalFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanRollbackSubmitForApprovalRaw = async (
    requestParameters: MonthPlanRollbackSubmitForApprovalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanRollbackSubmitForApprovalRequestOpts(requestParameters);
    return this.monthPlanRollbackSubmitForApprovalFetch(context, initOverrides);
  };

  /**
   */
  monthPlanRollbackSubmitForApproval = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanRollbackSubmitForApprovalRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
  };

  /**
   */
  protected monthPlanSubmitForApprovalRequestOpts = (
    requestParameters: MonthPlanSubmitForApprovalRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling monthPlanSubmitForApproval."
      );
    }

    if (requestParameters.monthPlanIdOrSlug === null || requestParameters.monthPlanIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "monthPlanIdOrSlug",
        "Required parameter requestParameters.monthPlanIdOrSlug was null or undefined when calling monthPlanSubmitForApproval."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/month-plan/{monthPlanIdOrSlug}/submit-for-approval`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"monthPlanIdOrSlug"}}`, encodeURIComponent(String(requestParameters.monthPlanIdOrSlug))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected monthPlanSubmitForApprovalFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected monthPlanSubmitForApprovalRaw = async (
    requestParameters: MonthPlanSubmitForApprovalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.monthPlanSubmitForApprovalRequestOpts(requestParameters);
    return this.monthPlanSubmitForApprovalFetch(context, initOverrides);
  };

  /**
   */
  monthPlanSubmitForApproval = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.monthPlanSubmitForApprovalRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
  };
}
