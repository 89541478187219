/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { AdminRole, AdminRoleFromJSON, AdminRoleFromJSONTyped, AdminRoleToJSON } from "./AdminRole";

/**
 *
 * @export
 * @interface AdminChangeRoleRequestDto
 */
export interface AdminChangeRoleRequestDto {
  /**
   *
   * @type {AdminRole}
   * @memberof AdminChangeRoleRequestDto
   */
  role: AdminRole;
}

export function AdminChangeRoleRequestDtoFromJSON(json: any): AdminChangeRoleRequestDto {
  return AdminChangeRoleRequestDtoFromJSONTyped(json, false);
}

export function AdminChangeRoleRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminChangeRoleRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: AdminRoleFromJSON(json["role"]),
  };
}

export function AdminChangeRoleRequestDtoToJSON(value?: AdminChangeRoleRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: AdminRoleToJSON(value.role),
  };
}
