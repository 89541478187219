/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ExpendituresOnFundsAndExpenseItemsReportWithdrawDto
 */
export interface ExpendituresOnFundsAndExpenseItemsReportWithdrawDto {
  /**
   *
   * @type {string}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportWithdrawDto
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportWithdrawDto
   */
  createdAt: Date;
  /**
   *
   * @type {number}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportWithdrawDto
   */
  amount: number;
}

export function ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoFromJSON(
  json: any
): ExpendituresOnFundsAndExpenseItemsReportWithdrawDto {
  return ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoFromJSONTyped(json, false);
}

export function ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExpendituresOnFundsAndExpenseItemsReportWithdrawDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["createdAt"]),
    amount: json["amount"],
  };
}

export function ExpendituresOnFundsAndExpenseItemsReportWithdrawDtoToJSON(
  value?: ExpendituresOnFundsAndExpenseItemsReportWithdrawDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt.toISOString(),
    amount: value.amount,
  };
}
