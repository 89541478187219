/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { FundLoanDto, FundLoanDtoFromJSON, FundLoanDtoFromJSONTyped, FundLoanDtoToJSON } from "./FundLoanDto";

/**
 *
 * @export
 * @interface FinancialPlanLoanRepaymentDto
 */
export interface FinancialPlanLoanRepaymentDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanLoanRepaymentDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanLoanRepaymentDto
   */
  amount: number;
  /**
   *
   * @type {FundLoanDto}
   * @memberof FinancialPlanLoanRepaymentDto
   */
  loan: FundLoanDto;
}

export function FinancialPlanLoanRepaymentDtoFromJSON(json: any): FinancialPlanLoanRepaymentDto {
  return FinancialPlanLoanRepaymentDtoFromJSONTyped(json, false);
}

export function FinancialPlanLoanRepaymentDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanLoanRepaymentDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    amount: json["amount"],
    loan: FundLoanDtoFromJSON(json["loan"]),
  };
}

export function FinancialPlanLoanRepaymentDtoToJSON(value?: FinancialPlanLoanRepaymentDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    amount: value.amount,
    loan: FundLoanDtoToJSON(value.loan),
  };
}
