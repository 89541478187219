/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanStateFundDto,
  MonthPlanStateFundDtoFromJSON,
  MonthPlanStateFundDtoFromJSONTyped,
  MonthPlanStateFundDtoToJSON,
} from "./MonthPlanStateFundDto";
import {
  MonthPlanStateWeekItemDto,
  MonthPlanStateWeekItemDtoFromJSON,
  MonthPlanStateWeekItemDtoFromJSONTyped,
  MonthPlanStateWeekItemDtoToJSON,
} from "./MonthPlanStateWeekItemDto";

/**
 *
 * @export
 * @interface MonthPlanStateFixedCostDto
 */
export interface MonthPlanStateFixedCostDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanStateFixedCostDto
   */
  id: string;
  /**
   *
   * @type {MonthPlanStateFundDto}
   * @memberof MonthPlanStateFixedCostDto
   */
  fund: MonthPlanStateFundDto;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateFixedCostDto
   */
  number: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateFixedCostDto
   */
  plan: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateFixedCostDto
   */
  fact: number;
  /**
   *
   * @type {Array<MonthPlanStateWeekItemDto>}
   * @memberof MonthPlanStateFixedCostDto
   */
  weeks: Array<MonthPlanStateWeekItemDto>;
}

export function MonthPlanStateFixedCostDtoFromJSON(json: any): MonthPlanStateFixedCostDto {
  return MonthPlanStateFixedCostDtoFromJSONTyped(json, false);
}

export function MonthPlanStateFixedCostDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanStateFixedCostDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fund: MonthPlanStateFundDtoFromJSON(json["fund"]),
    number: json["number"],
    plan: json["plan"],
    fact: json["fact"],
    weeks: (json["weeks"] as Array<any>).map(MonthPlanStateWeekItemDtoFromJSON),
  };
}

export function MonthPlanStateFixedCostDtoToJSON(value?: MonthPlanStateFixedCostDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fund: MonthPlanStateFundDtoToJSON(value.fund),
    number: value.number,
    plan: value.plan,
    fact: value.fact,
    weeks: (value.weeks as Array<any>).map(MonthPlanStateWeekItemDtoToJSON),
  };
}
