/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  FinanceSettingsChangeRequest,
  FinanceSettingsChangeRequestFromJSON,
  FinanceSettingsChangeRequestToJSON,
  FinanceSettingsDto,
  FinanceSettingsDtoFromJSON,
  FinanceSettingsDtoToJSON,
  FinanceSettingsUpdateEducationRequest,
  FinanceSettingsUpdateEducationRequestFromJSON,
  FinanceSettingsUpdateEducationRequestToJSON,
  FinanceSettingsUpdateEducationStepRequest,
  FinanceSettingsUpdateEducationStepRequestFromJSON,
  FinanceSettingsUpdateEducationStepRequestToJSON,
} from "../models";

export interface FinanceSettingsGetRequest {
  companyIdOrSlug: string;
}

export interface FinanceSettingsUpdateRequest {
  companyIdOrSlug: string;
  financeSettingsChangeRequest: FinanceSettingsChangeRequest;
}

export interface FinanceSettingsUpdateEducationOperationRequest {
  companyIdOrSlug: string;
  financeSettingsUpdateEducationRequest: FinanceSettingsUpdateEducationRequest;
}

export interface FinanceSettingsUpdateEducationStepOperationRequest {
  companyIdOrSlug: string;
  financeSettingsUpdateEducationStepRequest: FinanceSettingsUpdateEducationStepRequest;
}

/**
 *
 */
export class FinanceSettingsApi extends runtime.BaseAPI {
  /**
   */
  protected financeSettingsGetRequestOpts = (requestParameters: FinanceSettingsGetRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financeSettingsGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/settings`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financeSettingsGetFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinanceSettingsDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FinanceSettingsDtoFromJSON(jsonValue));
  };

  /**
   */
  protected financeSettingsGetRaw = async (
    requestParameters: FinanceSettingsGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinanceSettingsDto>> => {
    const context = this.financeSettingsGetRequestOpts(requestParameters);
    return this.financeSettingsGetFetch(context, initOverrides);
  };

  /**
   */
  financeSettingsGet = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<FinanceSettingsDto> => {
    const response = await this.financeSettingsGetRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useFinanceSettingsGet = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<FinanceSettingsDto>
  ): SWRResponse<FinanceSettingsDto> => {
    return useSWR<FinanceSettingsDto>(
      () => this.financeSettingsGetRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.financeSettingsGetFetch(context)),
      config
    );
  };

  /**
   */
  protected financeSettingsUpdateRequestOpts = (
    requestParameters: FinanceSettingsUpdateRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financeSettingsUpdate."
      );
    }

    if (
      requestParameters.financeSettingsChangeRequest === null ||
      requestParameters.financeSettingsChangeRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "financeSettingsChangeRequest",
        "Required parameter requestParameters.financeSettingsChangeRequest was null or undefined when calling financeSettingsUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/settings`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FinanceSettingsChangeRequestToJSON(requestParameters.financeSettingsChangeRequest),
    };
  };

  /**
   */
  protected financeSettingsUpdateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financeSettingsUpdateRaw = async (
    requestParameters: FinanceSettingsUpdateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financeSettingsUpdateRequestOpts(requestParameters);
    return this.financeSettingsUpdateFetch(context, initOverrides);
  };

  /**
   */
  financeSettingsUpdate = async (
    companyIdOrSlug: string,
    financeSettingsChangeRequest: FinanceSettingsChangeRequest,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financeSettingsUpdateRaw(
      { companyIdOrSlug: companyIdOrSlug, financeSettingsChangeRequest: financeSettingsChangeRequest },
      initOverrides
    );
  };

  /**
   */
  protected financeSettingsUpdateEducationRequestOpts = (
    requestParameters: FinanceSettingsUpdateEducationOperationRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financeSettingsUpdateEducation."
      );
    }

    if (
      requestParameters.financeSettingsUpdateEducationRequest === null ||
      requestParameters.financeSettingsUpdateEducationRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "financeSettingsUpdateEducationRequest",
        "Required parameter requestParameters.financeSettingsUpdateEducationRequest was null or undefined when calling financeSettingsUpdateEducation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/settings/education`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FinanceSettingsUpdateEducationRequestToJSON(requestParameters.financeSettingsUpdateEducationRequest),
    };
  };

  /**
   */
  protected financeSettingsUpdateEducationFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financeSettingsUpdateEducationRaw = async (
    requestParameters: FinanceSettingsUpdateEducationOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financeSettingsUpdateEducationRequestOpts(requestParameters);
    return this.financeSettingsUpdateEducationFetch(context, initOverrides);
  };

  /**
   */
  financeSettingsUpdateEducation = async (
    companyIdOrSlug: string,
    financeSettingsUpdateEducationRequest: FinanceSettingsUpdateEducationRequest,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financeSettingsUpdateEducationRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financeSettingsUpdateEducationRequest: financeSettingsUpdateEducationRequest,
      },
      initOverrides
    );
  };

  /**
   */
  protected financeSettingsUpdateEducationStepRequestOpts = (
    requestParameters: FinanceSettingsUpdateEducationStepOperationRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financeSettingsUpdateEducationStep."
      );
    }

    if (
      requestParameters.financeSettingsUpdateEducationStepRequest === null ||
      requestParameters.financeSettingsUpdateEducationStepRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "financeSettingsUpdateEducationStepRequest",
        "Required parameter requestParameters.financeSettingsUpdateEducationStepRequest was null or undefined when calling financeSettingsUpdateEducationStep."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/settings/education-step`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FinanceSettingsUpdateEducationStepRequestToJSON(
        requestParameters.financeSettingsUpdateEducationStepRequest
      ),
    };
  };

  /**
   */
  protected financeSettingsUpdateEducationStepFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financeSettingsUpdateEducationStepRaw = async (
    requestParameters: FinanceSettingsUpdateEducationStepOperationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financeSettingsUpdateEducationStepRequestOpts(requestParameters);
    return this.financeSettingsUpdateEducationStepFetch(context, initOverrides);
  };

  /**
   */
  financeSettingsUpdateEducationStep = async (
    companyIdOrSlug: string,
    financeSettingsUpdateEducationStepRequest: FinanceSettingsUpdateEducationStepRequest,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financeSettingsUpdateEducationStepRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financeSettingsUpdateEducationStepRequest: financeSettingsUpdateEducationStepRequest,
      },
      initOverrides
    );
  };
}
