/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AdminCompanyChangeInternalNameAndDescriptionRequestDto
 */
export interface AdminCompanyChangeInternalNameAndDescriptionRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminCompanyChangeInternalNameAndDescriptionRequestDto
   */
  internalName: string | null;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyChangeInternalNameAndDescriptionRequestDto
   */
  description: string | null;
}

export function AdminCompanyChangeInternalNameAndDescriptionRequestDtoFromJSON(
  json: any
): AdminCompanyChangeInternalNameAndDescriptionRequestDto {
  return AdminCompanyChangeInternalNameAndDescriptionRequestDtoFromJSONTyped(json, false);
}

export function AdminCompanyChangeInternalNameAndDescriptionRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminCompanyChangeInternalNameAndDescriptionRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    internalName: json["internalName"],
    description: json["description"],
  };
}

export function AdminCompanyChangeInternalNameAndDescriptionRequestDtoToJSON(
  value?: AdminCompanyChangeInternalNameAndDescriptionRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    internalName: value.internalName,
    description: value.description,
  };
}
