/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileDtoAvatarImage,
  UserProfileDtoAvatarImageFromJSON,
  UserProfileDtoAvatarImageFromJSONTyped,
  UserProfileDtoAvatarImageToJSON,
} from "./UserProfileDtoAvatarImage";

/**
 *
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
  /**
   *
   * @type {string}
   * @memberof CompanyDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyDto
   */
  ownerUserId: string;
  /**
   *
   * @type {Date}
   * @memberof CompanyDto
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof CompanyDto
   */
  hasAccessUntil: Date;
  /**
   *
   * @type {boolean}
   * @memberof CompanyDto
   */
  hasAccess: boolean;
  /**
   *
   * @type {string}
   * @memberof CompanyDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CompanyDto
   */
  slug: string;
  /**
   *
   * @type {UserProfileDtoAvatarImage}
   * @memberof CompanyDto
   */
  avatarImage: UserProfileDtoAvatarImage | null;
}

export function CompanyDtoFromJSON(json: any): CompanyDto {
  return CompanyDtoFromJSONTyped(json, false);
}

export function CompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    ownerUserId: json["ownerUserId"],
    createdAt: new Date(json["createdAt"]),
    hasAccessUntil: new Date(json["hasAccessUntil"]),
    hasAccess: json["hasAccess"],
    name: json["name"],
    slug: json["slug"],
    avatarImage: UserProfileDtoAvatarImageFromJSON(json["avatarImage"]),
  };
}

export function CompanyDtoToJSON(value?: CompanyDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    ownerUserId: value.ownerUserId,
    createdAt: value.createdAt.toISOString(),
    hasAccessUntil: value.hasAccessUntil.toISOString(),
    hasAccess: value.hasAccess,
    name: value.name,
    slug: value.slug,
    avatarImage: UserProfileDtoAvatarImageToJSON(value.avatarImage),
  };
}
