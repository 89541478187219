/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  DepositDto,
  DepositDtoFromJSON,
  DepositDtoToJSON,
  FinancialPlanAddDeferredIncomeRequestDto,
  FinancialPlanAddDeferredIncomeRequestDtoFromJSON,
  FinancialPlanAddDeferredIncomeRequestDtoToJSON,
  FinancialPlanAddLoansRequestDto,
  FinancialPlanAddLoansRequestDtoFromJSON,
  FinancialPlanAddLoansRequestDtoToJSON,
  FinancialPlanAddTransfersRequestDto,
  FinancialPlanAddTransfersRequestDtoFromJSON,
  FinancialPlanAddTransfersRequestDtoToJSON,
  FinancialPlanDto,
  FinancialPlanDtoFromJSON,
  FinancialPlanDtoToJSON,
  FinancialPlanFundingRequestDecisionDto,
  FinancialPlanFundingRequestDecisionDtoFromJSON,
  FinancialPlanFundingRequestDecisionDtoToJSON,
  FinancialPlanFundsDto,
  FinancialPlanFundsDtoFromJSON,
  FinancialPlanFundsDtoToJSON,
  FinancialPlanLoanDto,
  FinancialPlanLoanDtoFromJSON,
  FinancialPlanLoanDtoToJSON,
  FinancialPlanLoanRepaymentDto,
  FinancialPlanLoanRepaymentDtoFromJSON,
  FinancialPlanLoanRepaymentDtoToJSON,
  FinancialPlanSmallDto,
  FinancialPlanSmallDtoFromJSON,
  FinancialPlanSmallDtoToJSON,
  FinancialPlanTransferDto,
  FinancialPlanTransferDtoFromJSON,
  FinancialPlanTransferDtoToJSON,
  FinancialPlanUpdateDeferredIncomeRequestDto,
  FinancialPlanUpdateDeferredIncomeRequestDtoFromJSON,
  FinancialPlanUpdateDeferredIncomeRequestDtoToJSON,
  FinancialPlanUpdateFundingRequestDecisionRequestDto,
  FinancialPlanUpdateFundingRequestDecisionRequestDtoFromJSON,
  FinancialPlanUpdateFundingRequestDecisionRequestDtoToJSON,
  FinancialPlanUpdateIncomeRequestDto,
  FinancialPlanUpdateIncomeRequestDtoFromJSON,
  FinancialPlanUpdateIncomeRequestDtoToJSON,
  FinancialPlanUpdateLoanRepaymentRequestDto,
  FinancialPlanUpdateLoanRepaymentRequestDtoFromJSON,
  FinancialPlanUpdateLoanRepaymentRequestDtoToJSON,
} from "../models";

export interface FinancialPlanAddDeferredIncomeRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  financialPlanAddDeferredIncomeRequestDto: FinancialPlanAddDeferredIncomeRequestDto;
}

export interface FinancialPlanAddLoanRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  financialPlanAddLoansRequestDto: FinancialPlanAddLoansRequestDto;
}

export interface FinancialPlanAddTransferRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  financialPlanAddTransfersRequestDto: FinancialPlanAddTransfersRequestDto;
}

export interface FinancialPlanApproveRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanCreateRequest {
  companyIdOrSlug: string;
}

export interface FinancialPlanDeleteRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanDepositsRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanFundingRequestDecisionsRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanFundsRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanGetRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanListRequest {
  companyIdOrSlug: string;
  monthPlanIdOrSlug?: string;
}

export interface FinancialPlanLoanRepaymentsRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanLoansRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanRefreshFundingRequestDecisionsRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanRemoveDeferredIncomeRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  deferredIncomeId: string;
}

export interface FinancialPlanRemoveLoanRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  financialPlanLoanId: string;
}

export interface FinancialPlanRemoveTransferRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  financialPlanTransferId: string;
}

export interface FinancialPlanRollbackSubmitForApprovalRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanSubmitForApprovalRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanTransfersRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
}

export interface FinancialPlanUpdateDeferredIncomeRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  deferredIncomeId: string;
  financialPlanUpdateDeferredIncomeRequestDto: FinancialPlanUpdateDeferredIncomeRequestDto;
}

export interface FinancialPlanUpdateFundingRequestDecisionRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  fundingRequestId: string;
  financialPlanUpdateFundingRequestDecisionRequestDto: FinancialPlanUpdateFundingRequestDecisionRequestDto;
}

export interface FinancialPlanUpdateIncomeRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  incomeTypeId: string;
  financialPlanUpdateIncomeRequestDto: FinancialPlanUpdateIncomeRequestDto;
}

export interface FinancialPlanUpdateLoanRepaymentRequest {
  companyIdOrSlug: string;
  financialPlanId: string;
  financialPlanLoanRepaymentId: string;
  financialPlanUpdateLoanRepaymentRequestDto: FinancialPlanUpdateLoanRepaymentRequestDto;
}

/**
 *
 */
export class FinancialPlanApi extends runtime.BaseAPI {
  /**
   */
  protected financialPlanAddDeferredIncomeRequestOpts = (
    requestParameters: FinancialPlanAddDeferredIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanAddDeferredIncome."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanAddDeferredIncome."
      );
    }

    if (
      requestParameters.financialPlanAddDeferredIncomeRequestDto === null ||
      requestParameters.financialPlanAddDeferredIncomeRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanAddDeferredIncomeRequestDto",
        "Required parameter requestParameters.financialPlanAddDeferredIncomeRequestDto was null or undefined when calling financialPlanAddDeferredIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/deferred-income`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FinancialPlanAddDeferredIncomeRequestDtoToJSON(requestParameters.financialPlanAddDeferredIncomeRequestDto),
    };
  };

  /**
   */
  protected financialPlanAddDeferredIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanAddDeferredIncomeRaw = async (
    requestParameters: FinancialPlanAddDeferredIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanAddDeferredIncomeRequestOpts(requestParameters);
    return this.financialPlanAddDeferredIncomeFetch(context, initOverrides);
  };

  /**
   */
  financialPlanAddDeferredIncome = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    financialPlanAddDeferredIncomeRequestDto: FinancialPlanAddDeferredIncomeRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanAddDeferredIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        financialPlanAddDeferredIncomeRequestDto: financialPlanAddDeferredIncomeRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanAddLoanRequestOpts = (requestParameters: FinancialPlanAddLoanRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanAddLoan."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanAddLoan."
      );
    }

    if (
      requestParameters.financialPlanAddLoansRequestDto === null ||
      requestParameters.financialPlanAddLoansRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanAddLoansRequestDto",
        "Required parameter requestParameters.financialPlanAddLoansRequestDto was null or undefined when calling financialPlanAddLoan."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/loan`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FinancialPlanAddLoansRequestDtoToJSON(requestParameters.financialPlanAddLoansRequestDto),
    };
  };

  /**
   */
  protected financialPlanAddLoanFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanAddLoanRaw = async (
    requestParameters: FinancialPlanAddLoanRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanAddLoanRequestOpts(requestParameters);
    return this.financialPlanAddLoanFetch(context, initOverrides);
  };

  /**
   */
  financialPlanAddLoan = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    financialPlanAddLoansRequestDto: FinancialPlanAddLoansRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanAddLoanRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        financialPlanAddLoansRequestDto: financialPlanAddLoansRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanAddTransferRequestOpts = (
    requestParameters: FinancialPlanAddTransferRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanAddTransfer."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanAddTransfer."
      );
    }

    if (
      requestParameters.financialPlanAddTransfersRequestDto === null ||
      requestParameters.financialPlanAddTransfersRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanAddTransfersRequestDto",
        "Required parameter requestParameters.financialPlanAddTransfersRequestDto was null or undefined when calling financialPlanAddTransfer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/transfer`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FinancialPlanAddTransfersRequestDtoToJSON(requestParameters.financialPlanAddTransfersRequestDto),
    };
  };

  /**
   */
  protected financialPlanAddTransferFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanAddTransferRaw = async (
    requestParameters: FinancialPlanAddTransferRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanAddTransferRequestOpts(requestParameters);
    return this.financialPlanAddTransferFetch(context, initOverrides);
  };

  /**
   */
  financialPlanAddTransfer = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    financialPlanAddTransfersRequestDto: FinancialPlanAddTransfersRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanAddTransferRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        financialPlanAddTransfersRequestDto: financialPlanAddTransfersRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanApproveRequestOpts = (requestParameters: FinancialPlanApproveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanApprove."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanApprove."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/approve`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanApproveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanApproveRaw = async (
    requestParameters: FinancialPlanApproveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanApproveRequestOpts(requestParameters);
    return this.financialPlanApproveFetch(context, initOverrides);
  };

  /**
   */
  financialPlanApprove = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanApproveRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanCreateRequestOpts = (requestParameters: FinancialPlanCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FinancialPlanDtoFromJSON(jsonValue));
  };

  /**
   */
  protected financialPlanCreateRaw = async (
    requestParameters: FinancialPlanCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanDto>> => {
    const context = this.financialPlanCreateRequestOpts(requestParameters);
    return this.financialPlanCreateFetch(context, initOverrides);
  };

  /**
   */
  financialPlanCreate = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<FinancialPlanDto> => {
    const response = await this.financialPlanCreateRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  protected financialPlanDeleteRequestOpts = (requestParameters: FinancialPlanDeleteRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanDelete."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanDeleteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanDeleteRaw = async (
    requestParameters: FinancialPlanDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanDeleteRequestOpts(requestParameters);
    return this.financialPlanDeleteFetch(context, initOverrides);
  };

  /**
   */
  financialPlanDelete = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanDeleteRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanDepositsRequestOpts = (
    requestParameters: FinancialPlanDepositsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanDeposits."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanDeposits."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/deposits`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanDepositsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DepositDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DepositDtoFromJSON));
  };

  /**
   */
  protected financialPlanDepositsRaw = async (
    requestParameters: FinancialPlanDepositsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DepositDto>>> => {
    const context = this.financialPlanDepositsRequestOpts(requestParameters);
    return this.financialPlanDepositsFetch(context, initOverrides);
  };

  /**
   */
  financialPlanDeposits = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<Array<DepositDto>> => {
    const response = await this.financialPlanDepositsRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanDeposits = (
    companyIdOrSlug: string,
    financialPlanId: string,
    config?: SWRConfiguration<Array<DepositDto>>
  ): SWRResponse<Array<DepositDto>> => {
    return useSWR<Array<DepositDto>>(
      () =>
        this.financialPlanDepositsRequestOpts({ companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId }),
      (context) => this.swrFetch(this.financialPlanDepositsFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanFundingRequestDecisionsRequestOpts = (
    requestParameters: FinancialPlanFundingRequestDecisionsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanFundingRequestDecisions."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanFundingRequestDecisions."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/funding-request-decision`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanFundingRequestDecisionsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanFundingRequestDecisionDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(FinancialPlanFundingRequestDecisionDtoFromJSON)
    );
  };

  /**
   */
  protected financialPlanFundingRequestDecisionsRaw = async (
    requestParameters: FinancialPlanFundingRequestDecisionsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanFundingRequestDecisionDto>>> => {
    const context = this.financialPlanFundingRequestDecisionsRequestOpts(requestParameters);
    return this.financialPlanFundingRequestDecisionsFetch(context, initOverrides);
  };

  /**
   */
  financialPlanFundingRequestDecisions = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<Array<FinancialPlanFundingRequestDecisionDto>> => {
    const response = await this.financialPlanFundingRequestDecisionsRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanFundingRequestDecisions = (
    companyIdOrSlug: string,
    financialPlanId: string,
    config?: SWRConfiguration<Array<FinancialPlanFundingRequestDecisionDto>>
  ): SWRResponse<Array<FinancialPlanFundingRequestDecisionDto>> => {
    return useSWR<Array<FinancialPlanFundingRequestDecisionDto>>(
      () =>
        this.financialPlanFundingRequestDecisionsRequestOpts({
          companyIdOrSlug: companyIdOrSlug,
          financialPlanId: financialPlanId,
        }),
      (context) => this.swrFetch(this.financialPlanFundingRequestDecisionsFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanFundsRequestOpts = (requestParameters: FinancialPlanFundsRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanFunds."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanFunds."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/funds`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanFundsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanFundsDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FinancialPlanFundsDtoFromJSON(jsonValue));
  };

  /**
   */
  protected financialPlanFundsRaw = async (
    requestParameters: FinancialPlanFundsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanFundsDto>> => {
    const context = this.financialPlanFundsRequestOpts(requestParameters);
    return this.financialPlanFundsFetch(context, initOverrides);
  };

  /**
   */
  financialPlanFunds = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<FinancialPlanFundsDto> => {
    const response = await this.financialPlanFundsRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanFunds = (
    companyIdOrSlug: string,
    financialPlanId: string,
    config?: SWRConfiguration<FinancialPlanFundsDto>
  ): SWRResponse<FinancialPlanFundsDto> => {
    return useSWR<FinancialPlanFundsDto>(
      () => this.financialPlanFundsRequestOpts({ companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId }),
      (context) => this.swrFetch(this.financialPlanFundsFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanGetRequestOpts = (requestParameters: FinancialPlanGetRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanGet."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanGetFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FinancialPlanDtoFromJSON(jsonValue));
  };

  /**
   */
  protected financialPlanGetRaw = async (
    requestParameters: FinancialPlanGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanDto>> => {
    const context = this.financialPlanGetRequestOpts(requestParameters);
    return this.financialPlanGetFetch(context, initOverrides);
  };

  /**
   */
  financialPlanGet = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<FinancialPlanDto> => {
    const response = await this.financialPlanGetRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanGet = (
    companyIdOrSlug: string,
    financialPlanId: string,
    config?: SWRConfiguration<FinancialPlanDto>
  ): SWRResponse<FinancialPlanDto> => {
    return useSWR<FinancialPlanDto>(
      () => this.financialPlanGetRequestOpts({ companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId }),
      (context) => this.swrFetch(this.financialPlanGetFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanListRequestOpts = (requestParameters: FinancialPlanListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanList."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.monthPlanIdOrSlug !== undefined) {
      queryParameters["monthPlanIdOrSlug"] = requestParameters.monthPlanIdOrSlug;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanSmallDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FinancialPlanSmallDtoFromJSON));
  };

  /**
   */
  protected financialPlanListRaw = async (
    requestParameters: FinancialPlanListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanSmallDto>>> => {
    const context = this.financialPlanListRequestOpts(requestParameters);
    return this.financialPlanListFetch(context, initOverrides);
  };

  /**
   */
  financialPlanList = async (
    companyIdOrSlug: string,
    monthPlanIdOrSlug?: string,
    initOverrides?: RequestInit
  ): Promise<Array<FinancialPlanSmallDto>> => {
    const response = await this.financialPlanListRaw(
      { companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanList = (
    companyIdOrSlug: string,
    monthPlanIdOrSlug?: string,
    config?: SWRConfiguration<Array<FinancialPlanSmallDto>>
  ): SWRResponse<Array<FinancialPlanSmallDto>> => {
    return useSWR<Array<FinancialPlanSmallDto>>(
      () =>
        this.financialPlanListRequestOpts({ companyIdOrSlug: companyIdOrSlug, monthPlanIdOrSlug: monthPlanIdOrSlug }),
      (context) => this.swrFetch(this.financialPlanListFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanLoanRepaymentsRequestOpts = (
    requestParameters: FinancialPlanLoanRepaymentsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanLoanRepayments."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanLoanRepayments."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/loan-repayment`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanLoanRepaymentsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanLoanRepaymentDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FinancialPlanLoanRepaymentDtoFromJSON));
  };

  /**
   */
  protected financialPlanLoanRepaymentsRaw = async (
    requestParameters: FinancialPlanLoanRepaymentsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanLoanRepaymentDto>>> => {
    const context = this.financialPlanLoanRepaymentsRequestOpts(requestParameters);
    return this.financialPlanLoanRepaymentsFetch(context, initOverrides);
  };

  /**
   */
  financialPlanLoanRepayments = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<Array<FinancialPlanLoanRepaymentDto>> => {
    const response = await this.financialPlanLoanRepaymentsRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanLoanRepayments = (
    companyIdOrSlug: string,
    financialPlanId: string,
    config?: SWRConfiguration<Array<FinancialPlanLoanRepaymentDto>>
  ): SWRResponse<Array<FinancialPlanLoanRepaymentDto>> => {
    return useSWR<Array<FinancialPlanLoanRepaymentDto>>(
      () =>
        this.financialPlanLoanRepaymentsRequestOpts({
          companyIdOrSlug: companyIdOrSlug,
          financialPlanId: financialPlanId,
        }),
      (context) => this.swrFetch(this.financialPlanLoanRepaymentsFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanLoansRequestOpts = (requestParameters: FinancialPlanLoansRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanLoans."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanLoans."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/loan`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanLoansFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanLoanDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FinancialPlanLoanDtoFromJSON));
  };

  /**
   */
  protected financialPlanLoansRaw = async (
    requestParameters: FinancialPlanLoansRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanLoanDto>>> => {
    const context = this.financialPlanLoansRequestOpts(requestParameters);
    return this.financialPlanLoansFetch(context, initOverrides);
  };

  /**
   */
  financialPlanLoans = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<Array<FinancialPlanLoanDto>> => {
    const response = await this.financialPlanLoansRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanLoans = (
    companyIdOrSlug: string,
    financialPlanId: string,
    config?: SWRConfiguration<Array<FinancialPlanLoanDto>>
  ): SWRResponse<Array<FinancialPlanLoanDto>> => {
    return useSWR<Array<FinancialPlanLoanDto>>(
      () => this.financialPlanLoansRequestOpts({ companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId }),
      (context) => this.swrFetch(this.financialPlanLoansFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanRefreshFundingRequestDecisionsRequestOpts = (
    requestParameters: FinancialPlanRefreshFundingRequestDecisionsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanRefreshFundingRequestDecisions."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanRefreshFundingRequestDecisions."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/refresh-funding-request-decisions`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanRefreshFundingRequestDecisionsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanRefreshFundingRequestDecisionsRaw = async (
    requestParameters: FinancialPlanRefreshFundingRequestDecisionsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanRefreshFundingRequestDecisionsRequestOpts(requestParameters);
    return this.financialPlanRefreshFundingRequestDecisionsFetch(context, initOverrides);
  };

  /**
   */
  financialPlanRefreshFundingRequestDecisions = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanRefreshFundingRequestDecisionsRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanRemoveDeferredIncomeRequestOpts = (
    requestParameters: FinancialPlanRemoveDeferredIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanRemoveDeferredIncome."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanRemoveDeferredIncome."
      );
    }

    if (requestParameters.deferredIncomeId === null || requestParameters.deferredIncomeId === undefined) {
      throw new runtime.RequiredError(
        "deferredIncomeId",
        "Required parameter requestParameters.deferredIncomeId was null or undefined when calling financialPlanRemoveDeferredIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/deferred-income/{deferredIncomeId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId)))
        .replace(`{${"deferredIncomeId"}}`, encodeURIComponent(String(requestParameters.deferredIncomeId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanRemoveDeferredIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanRemoveDeferredIncomeRaw = async (
    requestParameters: FinancialPlanRemoveDeferredIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanRemoveDeferredIncomeRequestOpts(requestParameters);
    return this.financialPlanRemoveDeferredIncomeFetch(context, initOverrides);
  };

  /**
   */
  financialPlanRemoveDeferredIncome = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    deferredIncomeId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanRemoveDeferredIncomeRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId, deferredIncomeId: deferredIncomeId },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanRemoveLoanRequestOpts = (
    requestParameters: FinancialPlanRemoveLoanRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanRemoveLoan."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanRemoveLoan."
      );
    }

    if (requestParameters.financialPlanLoanId === null || requestParameters.financialPlanLoanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanLoanId",
        "Required parameter requestParameters.financialPlanLoanId was null or undefined when calling financialPlanRemoveLoan."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/loan/{financialPlanLoanId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId)))
        .replace(`{${"financialPlanLoanId"}}`, encodeURIComponent(String(requestParameters.financialPlanLoanId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanRemoveLoanFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanRemoveLoanRaw = async (
    requestParameters: FinancialPlanRemoveLoanRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanRemoveLoanRequestOpts(requestParameters);
    return this.financialPlanRemoveLoanFetch(context, initOverrides);
  };

  /**
   */
  financialPlanRemoveLoan = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    financialPlanLoanId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanRemoveLoanRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId, financialPlanLoanId: financialPlanLoanId },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanRemoveTransferRequestOpts = (
    requestParameters: FinancialPlanRemoveTransferRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanRemoveTransfer."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanRemoveTransfer."
      );
    }

    if (requestParameters.financialPlanTransferId === null || requestParameters.financialPlanTransferId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanTransferId",
        "Required parameter requestParameters.financialPlanTransferId was null or undefined when calling financialPlanRemoveTransfer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/transfer/{financialPlanTransferId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId)))
        .replace(
          `{${"financialPlanTransferId"}}`,
          encodeURIComponent(String(requestParameters.financialPlanTransferId))
        ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanRemoveTransferFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanRemoveTransferRaw = async (
    requestParameters: FinancialPlanRemoveTransferRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanRemoveTransferRequestOpts(requestParameters);
    return this.financialPlanRemoveTransferFetch(context, initOverrides);
  };

  /**
   */
  financialPlanRemoveTransfer = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    financialPlanTransferId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanRemoveTransferRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        financialPlanTransferId: financialPlanTransferId,
      },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanRollbackSubmitForApprovalRequestOpts = (
    requestParameters: FinancialPlanRollbackSubmitForApprovalRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanRollbackSubmitForApproval."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanRollbackSubmitForApproval."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/rollback-submit-for-approval`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanRollbackSubmitForApprovalFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanRollbackSubmitForApprovalRaw = async (
    requestParameters: FinancialPlanRollbackSubmitForApprovalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanRollbackSubmitForApprovalRequestOpts(requestParameters);
    return this.financialPlanRollbackSubmitForApprovalFetch(context, initOverrides);
  };

  /**
   */
  financialPlanRollbackSubmitForApproval = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanRollbackSubmitForApprovalRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanSubmitForApprovalRequestOpts = (
    requestParameters: FinancialPlanSubmitForApprovalRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanSubmitForApproval."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanSubmitForApproval."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/submit-for-approval`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanSubmitForApprovalFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanSubmitForApprovalRaw = async (
    requestParameters: FinancialPlanSubmitForApprovalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanSubmitForApprovalRequestOpts(requestParameters);
    return this.financialPlanSubmitForApprovalFetch(context, initOverrides);
  };

  /**
   */
  financialPlanSubmitForApproval = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanSubmitForApprovalRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanTransfersRequestOpts = (
    requestParameters: FinancialPlanTransfersRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanTransfers."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanTransfers."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/transfer`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanTransfersFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanTransferDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FinancialPlanTransferDtoFromJSON));
  };

  /**
   */
  protected financialPlanTransfersRaw = async (
    requestParameters: FinancialPlanTransfersRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FinancialPlanTransferDto>>> => {
    const context = this.financialPlanTransfersRequestOpts(requestParameters);
    return this.financialPlanTransfersFetch(context, initOverrides);
  };

  /**
   */
  financialPlanTransfers = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    initOverrides?: RequestInit
  ): Promise<Array<FinancialPlanTransferDto>> => {
    const response = await this.financialPlanTransfersRaw(
      { companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanTransfers = (
    companyIdOrSlug: string,
    financialPlanId: string,
    config?: SWRConfiguration<Array<FinancialPlanTransferDto>>
  ): SWRResponse<Array<FinancialPlanTransferDto>> => {
    return useSWR<Array<FinancialPlanTransferDto>>(
      () =>
        this.financialPlanTransfersRequestOpts({ companyIdOrSlug: companyIdOrSlug, financialPlanId: financialPlanId }),
      (context) => this.swrFetch(this.financialPlanTransfersFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanUpdateDeferredIncomeRequestOpts = (
    requestParameters: FinancialPlanUpdateDeferredIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanUpdateDeferredIncome."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanUpdateDeferredIncome."
      );
    }

    if (requestParameters.deferredIncomeId === null || requestParameters.deferredIncomeId === undefined) {
      throw new runtime.RequiredError(
        "deferredIncomeId",
        "Required parameter requestParameters.deferredIncomeId was null or undefined when calling financialPlanUpdateDeferredIncome."
      );
    }

    if (
      requestParameters.financialPlanUpdateDeferredIncomeRequestDto === null ||
      requestParameters.financialPlanUpdateDeferredIncomeRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanUpdateDeferredIncomeRequestDto",
        "Required parameter requestParameters.financialPlanUpdateDeferredIncomeRequestDto was null or undefined when calling financialPlanUpdateDeferredIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/deferred-income/{deferredIncomeId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId)))
        .replace(`{${"deferredIncomeId"}}`, encodeURIComponent(String(requestParameters.deferredIncomeId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FinancialPlanUpdateDeferredIncomeRequestDtoToJSON(
        requestParameters.financialPlanUpdateDeferredIncomeRequestDto
      ),
    };
  };

  /**
   */
  protected financialPlanUpdateDeferredIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanUpdateDeferredIncomeRaw = async (
    requestParameters: FinancialPlanUpdateDeferredIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanUpdateDeferredIncomeRequestOpts(requestParameters);
    return this.financialPlanUpdateDeferredIncomeFetch(context, initOverrides);
  };

  /**
   */
  financialPlanUpdateDeferredIncome = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    deferredIncomeId: string,
    financialPlanUpdateDeferredIncomeRequestDto: FinancialPlanUpdateDeferredIncomeRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanUpdateDeferredIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        deferredIncomeId: deferredIncomeId,
        financialPlanUpdateDeferredIncomeRequestDto: financialPlanUpdateDeferredIncomeRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanUpdateFundingRequestDecisionRequestOpts = (
    requestParameters: FinancialPlanUpdateFundingRequestDecisionRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanUpdateFundingRequestDecision."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanUpdateFundingRequestDecision."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling financialPlanUpdateFundingRequestDecision."
      );
    }

    if (
      requestParameters.financialPlanUpdateFundingRequestDecisionRequestDto === null ||
      requestParameters.financialPlanUpdateFundingRequestDecisionRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanUpdateFundingRequestDecisionRequestDto",
        "Required parameter requestParameters.financialPlanUpdateFundingRequestDecisionRequestDto was null or undefined when calling financialPlanUpdateFundingRequestDecision."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/funding-request-decision/{fundingRequestId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FinancialPlanUpdateFundingRequestDecisionRequestDtoToJSON(
        requestParameters.financialPlanUpdateFundingRequestDecisionRequestDto
      ),
    };
  };

  /**
   */
  protected financialPlanUpdateFundingRequestDecisionFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanUpdateFundingRequestDecisionRaw = async (
    requestParameters: FinancialPlanUpdateFundingRequestDecisionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanUpdateFundingRequestDecisionRequestOpts(requestParameters);
    return this.financialPlanUpdateFundingRequestDecisionFetch(context, initOverrides);
  };

  /**
   */
  financialPlanUpdateFundingRequestDecision = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    fundingRequestId: string,
    financialPlanUpdateFundingRequestDecisionRequestDto: FinancialPlanUpdateFundingRequestDecisionRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanUpdateFundingRequestDecisionRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        fundingRequestId: fundingRequestId,
        financialPlanUpdateFundingRequestDecisionRequestDto: financialPlanUpdateFundingRequestDecisionRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanUpdateIncomeRequestOpts = (
    requestParameters: FinancialPlanUpdateIncomeRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanUpdateIncome."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanUpdateIncome."
      );
    }

    if (requestParameters.incomeTypeId === null || requestParameters.incomeTypeId === undefined) {
      throw new runtime.RequiredError(
        "incomeTypeId",
        "Required parameter requestParameters.incomeTypeId was null or undefined when calling financialPlanUpdateIncome."
      );
    }

    if (
      requestParameters.financialPlanUpdateIncomeRequestDto === null ||
      requestParameters.financialPlanUpdateIncomeRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanUpdateIncomeRequestDto",
        "Required parameter requestParameters.financialPlanUpdateIncomeRequestDto was null or undefined when calling financialPlanUpdateIncome."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/income/{incomeTypeId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId)))
        .replace(`{${"incomeTypeId"}}`, encodeURIComponent(String(requestParameters.incomeTypeId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FinancialPlanUpdateIncomeRequestDtoToJSON(requestParameters.financialPlanUpdateIncomeRequestDto),
    };
  };

  /**
   */
  protected financialPlanUpdateIncomeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanUpdateIncomeRaw = async (
    requestParameters: FinancialPlanUpdateIncomeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanUpdateIncomeRequestOpts(requestParameters);
    return this.financialPlanUpdateIncomeFetch(context, initOverrides);
  };

  /**
   */
  financialPlanUpdateIncome = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    incomeTypeId: string,
    financialPlanUpdateIncomeRequestDto: FinancialPlanUpdateIncomeRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanUpdateIncomeRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        incomeTypeId: incomeTypeId,
        financialPlanUpdateIncomeRequestDto: financialPlanUpdateIncomeRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected financialPlanUpdateLoanRepaymentRequestOpts = (
    requestParameters: FinancialPlanUpdateLoanRepaymentRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanUpdateLoanRepayment."
      );
    }

    if (requestParameters.financialPlanId === null || requestParameters.financialPlanId === undefined) {
      throw new runtime.RequiredError(
        "financialPlanId",
        "Required parameter requestParameters.financialPlanId was null or undefined when calling financialPlanUpdateLoanRepayment."
      );
    }

    if (
      requestParameters.financialPlanLoanRepaymentId === null ||
      requestParameters.financialPlanLoanRepaymentId === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanLoanRepaymentId",
        "Required parameter requestParameters.financialPlanLoanRepaymentId was null or undefined when calling financialPlanUpdateLoanRepayment."
      );
    }

    if (
      requestParameters.financialPlanUpdateLoanRepaymentRequestDto === null ||
      requestParameters.financialPlanUpdateLoanRepaymentRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "financialPlanUpdateLoanRepaymentRequestDto",
        "Required parameter requestParameters.financialPlanUpdateLoanRepaymentRequestDto was null or undefined when calling financialPlanUpdateLoanRepayment."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan/{financialPlanId}/transfer/{financialPlanLoanRepaymentId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"financialPlanId"}}`, encodeURIComponent(String(requestParameters.financialPlanId)))
        .replace(
          `{${"financialPlanLoanRepaymentId"}}`,
          encodeURIComponent(String(requestParameters.financialPlanLoanRepaymentId))
        ),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FinancialPlanUpdateLoanRepaymentRequestDtoToJSON(
        requestParameters.financialPlanUpdateLoanRepaymentRequestDto
      ),
    };
  };

  /**
   */
  protected financialPlanUpdateLoanRepaymentFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected financialPlanUpdateLoanRepaymentRaw = async (
    requestParameters: FinancialPlanUpdateLoanRepaymentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.financialPlanUpdateLoanRepaymentRequestOpts(requestParameters);
    return this.financialPlanUpdateLoanRepaymentFetch(context, initOverrides);
  };

  /**
   */
  financialPlanUpdateLoanRepayment = async (
    companyIdOrSlug: string,
    financialPlanId: string,
    financialPlanLoanRepaymentId: string,
    financialPlanUpdateLoanRepaymentRequestDto: FinancialPlanUpdateLoanRepaymentRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.financialPlanUpdateLoanRepaymentRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        financialPlanId: financialPlanId,
        financialPlanLoanRepaymentId: financialPlanLoanRepaymentId,
        financialPlanUpdateLoanRepaymentRequestDto: financialPlanUpdateLoanRepaymentRequestDto,
      },
      initOverrides
    );
  };
}
