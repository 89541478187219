/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  ApiKeyDto,
  ApiKeyDtoFromJSON,
  ApiKeyDtoToJSON,
  CompanyDto,
  CompanyDtoFromJSON,
  CompanyDtoToJSON,
  EmployeeInviterCompanyInviteDto,
  EmployeeInviterCompanyInviteDtoFromJSON,
  EmployeeInviterCompanyInviteDtoToJSON,
} from "../models";

export interface EmployeeInviterCompanyInviteRequest {
  invite: string;
}

export interface EmployeeInviterCompanyInviteInformationRequest {
  invite: string;
}

export interface EmployeeInviterEmployeeInviteRequest {
  invite: string;
}

export interface EmployeeInviterEmployeeInviteCompanyRequest {
  invite: string;
}

/**
 *
 */
export class EmployeeInviterApi extends runtime.BaseAPI {
  /**
   */
  protected employeeInviterCompanyInviteRequestOpts = (
    requestParameters: EmployeeInviterCompanyInviteRequest
  ): runtime.RequestOpts => {
    if (requestParameters.invite === null || requestParameters.invite === undefined) {
      throw new runtime.RequiredError(
        "invite",
        "Required parameter requestParameters.invite was null or undefined when calling employeeInviterCompanyInvite."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/employee-inviter/company-invite/{invite}`.replace(
        `{${"invite"}}`,
        encodeURIComponent(String(requestParameters.invite))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected employeeInviterCompanyInviteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected employeeInviterCompanyInviteRaw = async (
    requestParameters: EmployeeInviterCompanyInviteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.employeeInviterCompanyInviteRequestOpts(requestParameters);
    return this.employeeInviterCompanyInviteFetch(context, initOverrides);
  };

  /**
   */
  employeeInviterCompanyInvite = async (invite: string, initOverrides?: RequestInit): Promise<void> => {
    await this.employeeInviterCompanyInviteRaw({ invite: invite }, initOverrides);
  };

  /**
   */
  protected employeeInviterCompanyInviteInformationRequestOpts = (
    requestParameters: EmployeeInviterCompanyInviteInformationRequest
  ): runtime.RequestOpts => {
    if (requestParameters.invite === null || requestParameters.invite === undefined) {
      throw new runtime.RequiredError(
        "invite",
        "Required parameter requestParameters.invite was null or undefined when calling employeeInviterCompanyInviteInformation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/employee-inviter/company-invite/{invite}`.replace(
        `{${"invite"}}`,
        encodeURIComponent(String(requestParameters.invite))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected employeeInviterCompanyInviteInformationFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
  };

  /**
   */
  protected employeeInviterCompanyInviteInformationRaw = async (
    requestParameters: EmployeeInviterCompanyInviteInformationRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyDto>> => {
    const context = this.employeeInviterCompanyInviteInformationRequestOpts(requestParameters);
    return this.employeeInviterCompanyInviteInformationFetch(context, initOverrides);
  };

  /**
   */
  employeeInviterCompanyInviteInformation = async (
    invite: string,
    initOverrides?: RequestInit
  ): Promise<CompanyDto> => {
    const response = await this.employeeInviterCompanyInviteInformationRaw({ invite: invite }, initOverrides);
    return await response.value();
  };

  /**
   */
  useEmployeeInviterCompanyInviteInformation = (
    invite: string,
    config?: SWRConfiguration<CompanyDto>
  ): SWRResponse<CompanyDto> => {
    return useSWR<CompanyDto>(
      () => this.employeeInviterCompanyInviteInformationRequestOpts({ invite: invite }),
      (context) => this.swrFetch(this.employeeInviterCompanyInviteInformationFetch(context)),
      config
    );
  };

  /**
   */
  protected employeeInviterEmployeeInviteRequestOpts = (
    requestParameters: EmployeeInviterEmployeeInviteRequest
  ): runtime.RequestOpts => {
    if (requestParameters.invite === null || requestParameters.invite === undefined) {
      throw new runtime.RequiredError(
        "invite",
        "Required parameter requestParameters.invite was null or undefined when calling employeeInviterEmployeeInvite."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    return {
      path: `/api/employee-inviter/employee-invite/{invite}`.replace(
        `{${"invite"}}`,
        encodeURIComponent(String(requestParameters.invite))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected employeeInviterEmployeeInviteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ApiKeyDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => ApiKeyDtoFromJSON(jsonValue));
  };

  /**
   */
  protected employeeInviterEmployeeInviteRaw = async (
    requestParameters: EmployeeInviterEmployeeInviteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ApiKeyDto>> => {
    const context = this.employeeInviterEmployeeInviteRequestOpts(requestParameters);
    return this.employeeInviterEmployeeInviteFetch(context, initOverrides);
  };

  /**
   */
  employeeInviterEmployeeInvite = async (invite: string, initOverrides?: RequestInit): Promise<ApiKeyDto> => {
    const response = await this.employeeInviterEmployeeInviteRaw({ invite: invite }, initOverrides);
    return await response.value();
  };

  /**
   */
  protected employeeInviterEmployeeInviteCompanyRequestOpts = (
    requestParameters: EmployeeInviterEmployeeInviteCompanyRequest
  ): runtime.RequestOpts => {
    if (requestParameters.invite === null || requestParameters.invite === undefined) {
      throw new runtime.RequiredError(
        "invite",
        "Required parameter requestParameters.invite was null or undefined when calling employeeInviterEmployeeInviteCompany."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    return {
      path: `/api/employee-inviter/employee-invite/{invite}/company`.replace(
        `{${"invite"}}`,
        encodeURIComponent(String(requestParameters.invite))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected employeeInviterEmployeeInviteCompanyFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmployeeInviterCompanyInviteDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => EmployeeInviterCompanyInviteDtoFromJSON(jsonValue));
  };

  /**
   */
  protected employeeInviterEmployeeInviteCompanyRaw = async (
    requestParameters: EmployeeInviterEmployeeInviteCompanyRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<EmployeeInviterCompanyInviteDto>> => {
    const context = this.employeeInviterEmployeeInviteCompanyRequestOpts(requestParameters);
    return this.employeeInviterEmployeeInviteCompanyFetch(context, initOverrides);
  };

  /**
   */
  employeeInviterEmployeeInviteCompany = async (
    invite: string,
    initOverrides?: RequestInit
  ): Promise<EmployeeInviterCompanyInviteDto> => {
    const response = await this.employeeInviterEmployeeInviteCompanyRaw({ invite: invite }, initOverrides);
    return await response.value();
  };

  /**
   */
  useEmployeeInviterEmployeeInviteCompany = (
    invite: string,
    config?: SWRConfiguration<EmployeeInviterCompanyInviteDto>
  ): SWRResponse<EmployeeInviterCompanyInviteDto> => {
    return useSWR<EmployeeInviterCompanyInviteDto>(
      () => this.employeeInviterEmployeeInviteCompanyRequestOpts({ invite: invite }),
      (context) => this.swrFetch(this.employeeInviterEmployeeInviteCompanyFetch(context)),
      config
    );
  };
}
