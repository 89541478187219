/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanChangeDeferredIncomeWeekPlanRequestDto
 */
export interface MonthPlanChangeDeferredIncomeWeekPlanRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanChangeDeferredIncomeWeekPlanRequestDto
   */
  deferredIncomeId: string;
  /**
   *
   * @type {number}
   * @memberof MonthPlanChangeDeferredIncomeWeekPlanRequestDto
   */
  weekNumber: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanChangeDeferredIncomeWeekPlanRequestDto
   */
  plan: number;
}

export function MonthPlanChangeDeferredIncomeWeekPlanRequestDtoFromJSON(
  json: any
): MonthPlanChangeDeferredIncomeWeekPlanRequestDto {
  return MonthPlanChangeDeferredIncomeWeekPlanRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanChangeDeferredIncomeWeekPlanRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanChangeDeferredIncomeWeekPlanRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deferredIncomeId: json["deferredIncomeId"],
    weekNumber: json["weekNumber"],
    plan: json["plan"],
  };
}

export function MonthPlanChangeDeferredIncomeWeekPlanRequestDtoToJSON(
  value?: MonthPlanChangeDeferredIncomeWeekPlanRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    deferredIncomeId: value.deferredIncomeId,
    weekNumber: value.weekNumber,
    plan: value.plan,
  };
}
