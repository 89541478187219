/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CompanyRolePermission,
  CompanyRolePermissionFromJSON,
  CompanyRolePermissionFromJSONTyped,
  CompanyRolePermissionToJSON,
} from "./CompanyRolePermission";

/**
 *
 * @export
 * @interface CompanyEmployeeRoleCreateRequestDto
 */
export interface CompanyEmployeeRoleCreateRequestDto {
  /**
   *
   * @type {Array<CompanyRolePermission>}
   * @memberof CompanyEmployeeRoleCreateRequestDto
   */
  permissions: Array<CompanyRolePermission>;
  /**
   *
   * @type {string}
   * @memberof CompanyEmployeeRoleCreateRequestDto
   */
  name: string;
}

export function CompanyEmployeeRoleCreateRequestDtoFromJSON(json: any): CompanyEmployeeRoleCreateRequestDto {
  return CompanyEmployeeRoleCreateRequestDtoFromJSONTyped(json, false);
}

export function CompanyEmployeeRoleCreateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyEmployeeRoleCreateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    permissions: (json["permissions"] as Array<any>).map(CompanyRolePermissionFromJSON),
    name: json["name"],
  };
}

export function CompanyEmployeeRoleCreateRequestDtoToJSON(value?: CompanyEmployeeRoleCreateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    permissions: (value.permissions as Array<any>).map(CompanyRolePermissionToJSON),
    name: value.name,
  };
}
