/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FundHistoryDtoEmployee
 */
export interface FundHistoryDtoEmployee {
  /**
   *
   * @type {string}
   * @memberof FundHistoryDtoEmployee
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FundHistoryDtoEmployee
   */
  name: string;
}

export function FundHistoryDtoEmployeeFromJSON(json: any): FundHistoryDtoEmployee {
  return FundHistoryDtoEmployeeFromJSONTyped(json, false);
}

export function FundHistoryDtoEmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundHistoryDtoEmployee {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
  };
}

export function FundHistoryDtoEmployeeToJSON(value?: FundHistoryDtoEmployee | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
  };
}
