/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  FundLoanDto,
  FundLoanDtoFromJSON,
  FundLoanDtoToJSON,
  FundLoanRepaymentDto,
  FundLoanRepaymentDtoFromJSON,
  FundLoanRepaymentDtoToJSON,
} from "../models";

export interface FundLoanListRequest {
  companyIdOrSlug: string;
  fromDeferredIncomeId?: string;
  fromFundId?: string;
  toFundId?: string;
  repaid?: boolean;
}

export interface FundLoanRepaymentsRequest {
  companyIdOrSlug: string;
  fundLoanId: string;
}

/**
 *
 */
export class FundLoanApi extends runtime.BaseAPI {
  /**
   */
  protected fundLoanListRequestOpts = (requestParameters: FundLoanListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundLoanList."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fromDeferredIncomeId !== undefined) {
      queryParameters["fromDeferredIncomeId"] = requestParameters.fromDeferredIncomeId;
    }

    if (requestParameters.fromFundId !== undefined) {
      queryParameters["fromFundId"] = requestParameters.fromFundId;
    }

    if (requestParameters.toFundId !== undefined) {
      queryParameters["toFundId"] = requestParameters.toFundId;
    }

    if (requestParameters.repaid !== undefined) {
      queryParameters["repaid"] = requestParameters.repaid;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund-loan`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundLoanListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundLoanDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundLoanDtoFromJSON));
  };

  /**
   */
  protected fundLoanListRaw = async (
    requestParameters: FundLoanListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundLoanDto>>> => {
    const context = this.fundLoanListRequestOpts(requestParameters);
    return this.fundLoanListFetch(context, initOverrides);
  };

  /**
   */
  fundLoanList = async (
    companyIdOrSlug: string,
    fromDeferredIncomeId?: string,
    fromFundId?: string,
    toFundId?: string,
    repaid?: boolean,
    initOverrides?: RequestInit
  ): Promise<Array<FundLoanDto>> => {
    const response = await this.fundLoanListRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        fromDeferredIncomeId: fromDeferredIncomeId,
        fromFundId: fromFundId,
        toFundId: toFundId,
        repaid: repaid,
      },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFundLoanList = (
    requestParameters: FundLoanListRequest,
    config?: SWRConfiguration<Array<FundLoanDto>>
  ): SWRResponse<Array<FundLoanDto>> => {
    return useSWR<Array<FundLoanDto>>(
      () => this.fundLoanListRequestOpts(requestParameters),
      (context) => this.swrFetch(this.fundLoanListFetch(context)),
      config
    );
  };

  /**
   */
  protected fundLoanRepaymentsRequestOpts = (requestParameters: FundLoanRepaymentsRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundLoanRepayments."
      );
    }

    if (requestParameters.fundLoanId === null || requestParameters.fundLoanId === undefined) {
      throw new runtime.RequiredError(
        "fundLoanId",
        "Required parameter requestParameters.fundLoanId was null or undefined when calling fundLoanRepayments."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund-loan/{fundLoanId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundLoanId"}}`, encodeURIComponent(String(requestParameters.fundLoanId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundLoanRepaymentsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundLoanRepaymentDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundLoanRepaymentDtoFromJSON));
  };

  /**
   */
  protected fundLoanRepaymentsRaw = async (
    requestParameters: FundLoanRepaymentsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundLoanRepaymentDto>>> => {
    const context = this.fundLoanRepaymentsRequestOpts(requestParameters);
    return this.fundLoanRepaymentsFetch(context, initOverrides);
  };

  /**
   */
  fundLoanRepayments = async (
    companyIdOrSlug: string,
    fundLoanId: string,
    initOverrides?: RequestInit
  ): Promise<Array<FundLoanRepaymentDto>> => {
    const response = await this.fundLoanRepaymentsRaw(
      { companyIdOrSlug: companyIdOrSlug, fundLoanId: fundLoanId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFundLoanRepayments = (
    companyIdOrSlug: string,
    fundLoanId: string,
    config?: SWRConfiguration<Array<FundLoanRepaymentDto>>
  ): SWRResponse<Array<FundLoanRepaymentDto>> => {
    return useSWR<Array<FundLoanRepaymentDto>>(
      () => this.fundLoanRepaymentsRequestOpts({ companyIdOrSlug: companyIdOrSlug, fundLoanId: fundLoanId }),
      (context) => this.swrFetch(this.fundLoanRepaymentsFetch(context)),
      config
    );
  };
}
