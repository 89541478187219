/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileDto,
  UserProfileDtoFromJSON,
  UserProfileDtoFromJSONTyped,
  UserProfileDtoToJSON,
} from "./UserProfileDto";

/**
 *
 * @export
 * @interface AdminDto
 */
export interface AdminDto {
  /**
   *
   * @type {string}
   * @memberof AdminDto
   */
  id: string;
  /**
   *
   * @type {UserProfileDto}
   * @memberof AdminDto
   */
  user: UserProfileDto;
  /**
   *
   * @type {string}
   * @memberof AdminDto
   */
  role: AdminDtoRoleEnum;
}

/**
 * @export
 * @enum {string}
 */
export type AdminDtoRoleEnum = "SUPER_ADMIN" | "ADMIN";

export function AdminDtoFromJSON(json: any): AdminDto {
  return AdminDtoFromJSONTyped(json, false);
}

export function AdminDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    user: UserProfileDtoFromJSON(json["user"]),
    role: json["role"],
  };
}

export function AdminDtoToJSON(value?: AdminDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user: UserProfileDtoToJSON(value.user),
    role: value.role,
  };
}
