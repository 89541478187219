/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  ApiKeyDto,
  ApiKeyDtoFromJSON,
  ApiKeyDtoToJSON,
  UserAuthConfirmRequestDto,
  UserAuthConfirmRequestDtoFromJSON,
  UserAuthConfirmRequestDtoToJSON,
  UserAuthRequestDto,
  UserAuthRequestDtoFromJSON,
  UserAuthRequestDtoToJSON,
  UserAuthStateDto,
  UserAuthStateDtoFromJSON,
  UserAuthStateDtoToJSON,
} from "../models";

export interface UserAuthActivateRequest {
  userAuthConfirmRequestDto: UserAuthConfirmRequestDto;
}

export interface UserAuthApiKeyRequest {
  key: string;
}

export interface UserAuthAuthRequest {
  userAuthRequestDto: UserAuthRequestDto;
}

export interface UserAuthAuthStateRequest {
  key: string;
}

/**
 *
 */
export class UserAuthApi extends runtime.BaseAPI {
  /**
   */
  protected userAuthActivateRequestOpts = (requestParameters: UserAuthActivateRequest): runtime.RequestOpts => {
    if (
      requestParameters.userAuthConfirmRequestDto === null ||
      requestParameters.userAuthConfirmRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "userAuthConfirmRequestDto",
        "Required parameter requestParameters.userAuthConfirmRequestDto was null or undefined when calling userAuthActivate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    return {
      path: `/api/user/auth/activate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserAuthConfirmRequestDtoToJSON(requestParameters.userAuthConfirmRequestDto),
    };
  };

  /**
   */
  protected userAuthActivateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected userAuthActivateRaw = async (
    requestParameters: UserAuthActivateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.userAuthActivateRequestOpts(requestParameters);
    return this.userAuthActivateFetch(context, initOverrides);
  };

  /**
   */
  userAuthActivate = async (
    userAuthConfirmRequestDto: UserAuthConfirmRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.userAuthActivateRaw({ userAuthConfirmRequestDto: userAuthConfirmRequestDto }, initOverrides);
  };

  /**
   */
  protected userAuthApiKeyRequestOpts = (requestParameters: UserAuthApiKeyRequest): runtime.RequestOpts => {
    if (requestParameters.key === null || requestParameters.key === undefined) {
      throw new runtime.RequiredError(
        "key",
        "Required parameter requestParameters.key was null or undefined when calling userAuthApiKey."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    return {
      path: `/api/user/auth/auth-key/{key}/api-key`.replace(
        `{${"key"}}`,
        encodeURIComponent(String(requestParameters.key))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected userAuthApiKeyFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ApiKeyDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => ApiKeyDtoFromJSON(jsonValue));
  };

  /**
   */
  protected userAuthApiKeyRaw = async (
    requestParameters: UserAuthApiKeyRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ApiKeyDto>> => {
    const context = this.userAuthApiKeyRequestOpts(requestParameters);
    return this.userAuthApiKeyFetch(context, initOverrides);
  };

  /**
   */
  userAuthApiKey = async (key: string, initOverrides?: RequestInit): Promise<ApiKeyDto> => {
    const response = await this.userAuthApiKeyRaw({ key: key }, initOverrides);
    return await response.value();
  };

  /**
   */
  protected userAuthAuthRequestOpts = (requestParameters: UserAuthAuthRequest): runtime.RequestOpts => {
    if (requestParameters.userAuthRequestDto === null || requestParameters.userAuthRequestDto === undefined) {
      throw new runtime.RequiredError(
        "userAuthRequestDto",
        "Required parameter requestParameters.userAuthRequestDto was null or undefined when calling userAuthAuth."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    return {
      path: `/api/user/auth`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserAuthRequestDtoToJSON(requestParameters.userAuthRequestDto),
    };
  };

  /**
   */
  protected userAuthAuthFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserAuthStateDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthStateDtoFromJSON(jsonValue));
  };

  /**
   */
  protected userAuthAuthRaw = async (
    requestParameters: UserAuthAuthRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserAuthStateDto>> => {
    const context = this.userAuthAuthRequestOpts(requestParameters);
    return this.userAuthAuthFetch(context, initOverrides);
  };

  /**
   */
  userAuthAuth = async (
    userAuthRequestDto: UserAuthRequestDto,
    initOverrides?: RequestInit
  ): Promise<UserAuthStateDto> => {
    const response = await this.userAuthAuthRaw({ userAuthRequestDto: userAuthRequestDto }, initOverrides);
    return await response.value();
  };

  /**
   */
  protected userAuthAuthStateRequestOpts = (requestParameters: UserAuthAuthStateRequest): runtime.RequestOpts => {
    if (requestParameters.key === null || requestParameters.key === undefined) {
      throw new runtime.RequiredError(
        "key",
        "Required parameter requestParameters.key was null or undefined when calling userAuthAuthState."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    return {
      path: `/api/user/auth/auth-key/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected userAuthAuthStateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserAuthStateDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthStateDtoFromJSON(jsonValue));
  };

  /**
   */
  protected userAuthAuthStateRaw = async (
    requestParameters: UserAuthAuthStateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserAuthStateDto>> => {
    const context = this.userAuthAuthStateRequestOpts(requestParameters);
    return this.userAuthAuthStateFetch(context, initOverrides);
  };

  /**
   */
  userAuthAuthState = async (key: string, initOverrides?: RequestInit): Promise<UserAuthStateDto> => {
    const response = await this.userAuthAuthStateRaw({ key: key }, initOverrides);
    return await response.value();
  };

  /**
   */
  useUserAuthAuthState = (key: string, config?: SWRConfiguration<UserAuthStateDto>): SWRResponse<UserAuthStateDto> => {
    return useSWR<UserAuthStateDto>(
      () => this.userAuthAuthStateRequestOpts({ key: key }),
      (context) => this.swrFetch(this.userAuthAuthStateFetch(context)),
      config
    );
  };
}
