/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanSimpleFinancialPlanDto,
  MonthPlanSimpleFinancialPlanDtoFromJSON,
  MonthPlanSimpleFinancialPlanDtoFromJSONTyped,
  MonthPlanSimpleFinancialPlanDtoToJSON,
} from "./MonthPlanSimpleFinancialPlanDto";
import {
  MonthPlanSimpleTotalDto,
  MonthPlanSimpleTotalDtoFromJSON,
  MonthPlanSimpleTotalDtoFromJSONTyped,
  MonthPlanSimpleTotalDtoToJSON,
} from "./MonthPlanSimpleTotalDto";

/**
 *
 * @export
 * @interface MonthPlanSimpleWeekDto
 */
export interface MonthPlanSimpleWeekDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanSimpleWeekDto
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanSimpleWeekDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanSimpleWeekDto
   */
  to: Date;
  /**
   *
   * @type {MonthPlanSimpleTotalDto}
   * @memberof MonthPlanSimpleWeekDto
   */
  plan: MonthPlanSimpleTotalDto;
  /**
   *
   * @type {MonthPlanSimpleTotalDto}
   * @memberof MonthPlanSimpleWeekDto
   */
  fact: MonthPlanSimpleTotalDto;
  /**
   *
   * @type {boolean}
   * @memberof MonthPlanSimpleWeekDto
   */
  closed: boolean;
  /**
   *
   * @type {Array<MonthPlanSimpleFinancialPlanDto>}
   * @memberof MonthPlanSimpleWeekDto
   */
  financialPlans: Array<MonthPlanSimpleFinancialPlanDto>;
}

export function MonthPlanSimpleWeekDtoFromJSON(json: any): MonthPlanSimpleWeekDto {
  return MonthPlanSimpleWeekDtoFromJSONTyped(json, false);
}

export function MonthPlanSimpleWeekDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanSimpleWeekDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    plan: MonthPlanSimpleTotalDtoFromJSON(json["plan"]),
    fact: MonthPlanSimpleTotalDtoFromJSON(json["fact"]),
    closed: json["closed"],
    financialPlans: (json["financialPlans"] as Array<any>).map(MonthPlanSimpleFinancialPlanDtoFromJSON),
  };
}

export function MonthPlanSimpleWeekDtoToJSON(value?: MonthPlanSimpleWeekDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    plan: MonthPlanSimpleTotalDtoToJSON(value.plan),
    fact: MonthPlanSimpleTotalDtoToJSON(value.fact),
    closed: value.closed,
    financialPlans: (value.financialPlans as Array<any>).map(MonthPlanSimpleFinancialPlanDtoToJSON),
  };
}
