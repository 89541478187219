/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FundHistoryLoanDtoFromDeferredIncome,
  FundHistoryLoanDtoFromDeferredIncomeFromJSON,
  FundHistoryLoanDtoFromDeferredIncomeFromJSONTyped,
  FundHistoryLoanDtoFromDeferredIncomeToJSON,
} from "./FundHistoryLoanDtoFromDeferredIncome";
import {
  FundLoanDtoFromFund,
  FundLoanDtoFromFundFromJSON,
  FundLoanDtoFromFundFromJSONTyped,
  FundLoanDtoFromFundToJSON,
} from "./FundLoanDtoFromFund";
import {
  FundingRequestDtoExpenseItem,
  FundingRequestDtoExpenseItemFromJSON,
  FundingRequestDtoExpenseItemFromJSONTyped,
  FundingRequestDtoExpenseItemToJSON,
} from "./FundingRequestDtoExpenseItem";
import {
  FundingRequestPaymentMethod,
  FundingRequestPaymentMethodFromJSON,
  FundingRequestPaymentMethodFromJSONTyped,
  FundingRequestPaymentMethodToJSON,
} from "./FundingRequestPaymentMethod";
import {
  FundingRequestStatus,
  FundingRequestStatusFromJSON,
  FundingRequestStatusFromJSONTyped,
  FundingRequestStatusToJSON,
} from "./FundingRequestStatus";

/**
 *
 * @export
 * @interface FundingRequestDto
 */
export interface FundingRequestDto {
  /**
   *
   * @type {FundingRequestStatus}
   * @memberof FundingRequestDto
   */
  status: FundingRequestStatus;
  /**
   *
   * @type {FundingRequestPaymentMethod}
   * @memberof FundingRequestDto
   */
  paymentMethod: FundingRequestPaymentMethod;
  /**
   *
   * @type {string}
   * @memberof FundingRequestDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FundingRequestDto
   */
  number: number;
  /**
   *
   * @type {Date}
   * @memberof FundingRequestDto
   */
  createdAt: Date;
  /**
   *
   * @type {string}
   * @memberof FundingRequestDto
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof FundingRequestDto
   */
  createdByEmployeeId: string;
  /**
   *
   * @type {number}
   * @memberof FundingRequestDto
   */
  approvedAmount: number;
  /**
   *
   * @type {number}
   * @memberof FundingRequestDto
   */
  paidAmount: number;
  /**
   *
   * @type {number}
   * @memberof FundingRequestDto
   */
  remainsAmountToApprove: number;
  /**
   *
   * @type {number}
   * @memberof FundingRequestDto
   */
  reservedAmount: number;
  /**
   *
   * @type {string}
   * @memberof FundingRequestDto
   */
  name: string | null;
  /**
   *
   * @type {FundLoanDtoFromFund}
   * @memberof FundingRequestDto
   */
  fund: FundLoanDtoFromFund | null;
  /**
   *
   * @type {FundHistoryLoanDtoFromDeferredIncome}
   * @memberof FundingRequestDto
   */
  deferredIncome: FundHistoryLoanDtoFromDeferredIncome | null;
  /**
   *
   * @type {boolean}
   * @memberof FundingRequestDto
   */
  draft: boolean;
  /**
   *
   * @type {FundingRequestDtoExpenseItem}
   * @memberof FundingRequestDto
   */
  expenseItem: FundingRequestDtoExpenseItem | null;
  /**
   *
   * @type {number}
   * @memberof FundingRequestDto
   */
  amount: number | null;
  /**
   *
   * @type {string}
   * @memberof FundingRequestDto
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof FundingRequestDto
   */
  paymentReceiver: string | null;
  /**
   *
   * @type {Date}
   * @memberof FundingRequestDto
   */
  payAt: Date | null;
  /**
   *
   * @type {Array<string>}
   * @memberof FundingRequestDto
   */
  paymentFileIds: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FundingRequestDto
   */
  paymentComment: string | null;
}

export function FundingRequestDtoFromJSON(json: any): FundingRequestDto {
  return FundingRequestDtoFromJSONTyped(json, false);
}

export function FundingRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundingRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: FundingRequestStatusFromJSON(json["status"]),
    paymentMethod: FundingRequestPaymentMethodFromJSON(json["paymentMethod"]),
    id: json["id"],
    number: json["number"],
    createdAt: new Date(json["createdAt"]),
    companyId: json["companyId"],
    createdByEmployeeId: json["createdByEmployeeId"],
    approvedAmount: json["approvedAmount"],
    paidAmount: json["paidAmount"],
    remainsAmountToApprove: json["remainsAmountToApprove"],
    reservedAmount: json["reservedAmount"],
    name: json["name"],
    fund: FundLoanDtoFromFundFromJSON(json["fund"]),
    deferredIncome: FundHistoryLoanDtoFromDeferredIncomeFromJSON(json["deferredIncome"]),
    draft: json["draft"],
    expenseItem: FundingRequestDtoExpenseItemFromJSON(json["expenseItem"]),
    amount: json["amount"],
    description: json["description"],
    paymentReceiver: json["paymentReceiver"],
    payAt: json["payAt"] === null ? null : new Date(json["payAt"]),
    paymentFileIds: json["paymentFileIds"],
    paymentComment: json["paymentComment"],
  };
}

export function FundingRequestDtoToJSON(value?: FundingRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: FundingRequestStatusToJSON(value.status),
    paymentMethod: FundingRequestPaymentMethodToJSON(value.paymentMethod),
    id: value.id,
    number: value.number,
    createdAt: value.createdAt.toISOString(),
    companyId: value.companyId,
    createdByEmployeeId: value.createdByEmployeeId,
    approvedAmount: value.approvedAmount,
    paidAmount: value.paidAmount,
    remainsAmountToApprove: value.remainsAmountToApprove,
    reservedAmount: value.reservedAmount,
    name: value.name,
    fund: FundLoanDtoFromFundToJSON(value.fund),
    deferredIncome: FundHistoryLoanDtoFromDeferredIncomeToJSON(value.deferredIncome),
    draft: value.draft,
    expenseItem: FundingRequestDtoExpenseItemToJSON(value.expenseItem),
    amount: value.amount,
    description: value.description,
    paymentReceiver: value.paymentReceiver,
    payAt: value.payAt === null ? null : value.payAt.toISOString(),
    paymentFileIds: value.paymentFileIds,
    paymentComment: value.paymentComment,
  };
}
