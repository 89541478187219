/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinanceSettingsUpdateEducationStepRequest
 */
export interface FinanceSettingsUpdateEducationStepRequest {
  /**
   *
   * @type {number}
   * @memberof FinanceSettingsUpdateEducationStepRequest
   */
  educationStep: number;
}

export function FinanceSettingsUpdateEducationStepRequestFromJSON(
  json: any
): FinanceSettingsUpdateEducationStepRequest {
  return FinanceSettingsUpdateEducationStepRequestFromJSONTyped(json, false);
}

export function FinanceSettingsUpdateEducationStepRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinanceSettingsUpdateEducationStepRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    educationStep: json["educationStep"],
  };
}

export function FinanceSettingsUpdateEducationStepRequestToJSON(
  value?: FinanceSettingsUpdateEducationStepRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    educationStep: value.educationStep,
  };
}
