/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MovementOnFundsReportOperationLoanDtoFromDeferred
 */
export interface MovementOnFundsReportOperationLoanDtoFromDeferred {
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportOperationLoanDtoFromDeferred
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportOperationLoanDtoFromDeferred
   */
  name: string;
}

export function MovementOnFundsReportOperationLoanDtoFromDeferredFromJSON(
  json: any
): MovementOnFundsReportOperationLoanDtoFromDeferred {
  return MovementOnFundsReportOperationLoanDtoFromDeferredFromJSONTyped(json, false);
}

export function MovementOnFundsReportOperationLoanDtoFromDeferredFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MovementOnFundsReportOperationLoanDtoFromDeferred {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
  };
}

export function MovementOnFundsReportOperationLoanDtoFromDeferredToJSON(
  value?: MovementOnFundsReportOperationLoanDtoFromDeferred | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
  };
}
