/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FileDto
 */
export interface FileDto {
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof FileDto
   */
  extension: string;
}

export function FileDtoFromJSON(json: any): FileDto {
  return FileDtoFromJSONTyped(json, false);
}

export function FileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    url: json["url"],
    name: json["name"],
    extension: json["extension"],
  };
}

export function FileDtoToJSON(value?: FileDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    url: value.url,
    name: value.name,
    extension: value.extension,
  };
}
