/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanStateFundDto,
  MonthPlanStateFundDtoFromJSON,
  MonthPlanStateFundDtoFromJSONTyped,
  MonthPlanStateFundDtoToJSON,
} from "./MonthPlanStateFundDto";
import {
  MonthPlanStateVariableCostIncomeDto,
  MonthPlanStateVariableCostIncomeDtoFromJSON,
  MonthPlanStateVariableCostIncomeDtoFromJSONTyped,
  MonthPlanStateVariableCostIncomeDtoToJSON,
} from "./MonthPlanStateVariableCostIncomeDto";
import {
  MonthPlanStateWeekItemDto,
  MonthPlanStateWeekItemDtoFromJSON,
  MonthPlanStateWeekItemDtoFromJSONTyped,
  MonthPlanStateWeekItemDtoToJSON,
} from "./MonthPlanStateWeekItemDto";

/**
 *
 * @export
 * @interface MonthPlanStateVariableCostDto
 */
export interface MonthPlanStateVariableCostDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanStateVariableCostDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateVariableCostDto
   */
  plan: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateVariableCostDto
   */
  fact: number;
  /**
   *
   * @type {MonthPlanStateFundDto}
   * @memberof MonthPlanStateVariableCostDto
   */
  fund: MonthPlanStateFundDto;
  /**
   *
   * @type {Array<MonthPlanStateWeekItemDto>}
   * @memberof MonthPlanStateVariableCostDto
   */
  weeks: Array<MonthPlanStateWeekItemDto>;
  /**
   *
   * @type {Array<MonthPlanStateVariableCostIncomeDto>}
   * @memberof MonthPlanStateVariableCostDto
   */
  incomes: Array<MonthPlanStateVariableCostIncomeDto>;
}

export function MonthPlanStateVariableCostDtoFromJSON(json: any): MonthPlanStateVariableCostDto {
  return MonthPlanStateVariableCostDtoFromJSONTyped(json, false);
}

export function MonthPlanStateVariableCostDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanStateVariableCostDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    plan: json["plan"],
    fact: json["fact"],
    fund: MonthPlanStateFundDtoFromJSON(json["fund"]),
    weeks: (json["weeks"] as Array<any>).map(MonthPlanStateWeekItemDtoFromJSON),
    incomes: (json["incomes"] as Array<any>).map(MonthPlanStateVariableCostIncomeDtoFromJSON),
  };
}

export function MonthPlanStateVariableCostDtoToJSON(value?: MonthPlanStateVariableCostDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    plan: value.plan,
    fact: value.fact,
    fund: MonthPlanStateFundDtoToJSON(value.fund),
    weeks: (value.weeks as Array<any>).map(MonthPlanStateWeekItemDtoToJSON),
    incomes: (value.incomes as Array<any>).map(MonthPlanStateVariableCostIncomeDtoToJSON),
  };
}
