import React from "react";
import { GeistProvider } from "@geist-ui/core";
import { AppProps } from "next/app";
import { ModalsRoot } from "shared/lib";

export const withModals = (app: (props: AppProps) => React.ReactNode) => {
  const WithModals = (props: AppProps) => (
    <GeistProvider>
      <ModalsRoot />
      {app(props)}
    </GeistProvider>
  );

  return WithModals;
};
