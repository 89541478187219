/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FundHistoryDtoDeposit,
  FundHistoryDtoDepositFromJSON,
  FundHistoryDtoDepositFromJSONTyped,
  FundHistoryDtoDepositToJSON,
} from "./FundHistoryDtoDeposit";
import {
  FundHistoryDtoEmployee,
  FundHistoryDtoEmployeeFromJSON,
  FundHistoryDtoEmployeeFromJSONTyped,
  FundHistoryDtoEmployeeToJSON,
} from "./FundHistoryDtoEmployee";
import {
  FundHistoryDtoFinancialPlan,
  FundHistoryDtoFinancialPlanFromJSON,
  FundHistoryDtoFinancialPlanFromJSONTyped,
  FundHistoryDtoFinancialPlanToJSON,
} from "./FundHistoryDtoFinancialPlan";
import {
  FundHistoryDtoFundingRequest,
  FundHistoryDtoFundingRequestFromJSON,
  FundHistoryDtoFundingRequestFromJSONTyped,
  FundHistoryDtoFundingRequestToJSON,
} from "./FundHistoryDtoFundingRequest";
import {
  FundHistoryDtoLoan,
  FundHistoryDtoLoanFromJSON,
  FundHistoryDtoLoanFromJSONTyped,
  FundHistoryDtoLoanToJSON,
} from "./FundHistoryDtoLoan";
import {
  FundHistoryDtoTransfer,
  FundHistoryDtoTransferFromJSON,
  FundHistoryDtoTransferFromJSONTyped,
  FundHistoryDtoTransferToJSON,
} from "./FundHistoryDtoTransfer";
import {
  FundHistoryDtoWithdraw,
  FundHistoryDtoWithdrawFromJSON,
  FundHistoryDtoWithdrawFromJSONTyped,
  FundHistoryDtoWithdrawToJSON,
} from "./FundHistoryDtoWithdraw";
import {
  FundHistoryOperationType,
  FundHistoryOperationTypeFromJSON,
  FundHistoryOperationTypeFromJSONTyped,
  FundHistoryOperationTypeToJSON,
} from "./FundHistoryOperationType";

/**
 *
 * @export
 * @interface FundHistoryDto
 */
export interface FundHistoryDto {
  /**
   *
   * @type {FundHistoryOperationType}
   * @memberof FundHistoryDto
   */
  type: FundHistoryOperationType;
  /**
   *
   * @type {string}
   * @memberof FundHistoryDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDto
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDto
   */
  balanceBefore: number;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDto
   */
  balanceAfter: number;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDto
   */
  reservedBefore: number;
  /**
   *
   * @type {number}
   * @memberof FundHistoryDto
   */
  reservedAfter: number;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryDto
   */
  createdAt: Date;
  /**
   *
   * @type {FundHistoryDtoFinancialPlan}
   * @memberof FundHistoryDto
   */
  financialPlan: FundHistoryDtoFinancialPlan | null;
  /**
   *
   * @type {FundHistoryDtoFundingRequest}
   * @memberof FundHistoryDto
   */
  fundingRequest: FundHistoryDtoFundingRequest | null;
  /**
   *
   * @type {FundHistoryDtoDeposit}
   * @memberof FundHistoryDto
   */
  deposit: FundHistoryDtoDeposit | null;
  /**
   *
   * @type {FundHistoryDtoTransfer}
   * @memberof FundHistoryDto
   */
  transfer: FundHistoryDtoTransfer | null;
  /**
   *
   * @type {FundHistoryDtoLoan}
   * @memberof FundHistoryDto
   */
  loan: FundHistoryDtoLoan | null;
  /**
   *
   * @type {FundHistoryDtoWithdraw}
   * @memberof FundHistoryDto
   */
  withdraw: FundHistoryDtoWithdraw | null;
  /**
   *
   * @type {FundHistoryDtoEmployee}
   * @memberof FundHistoryDto
   */
  employee: FundHistoryDtoEmployee | null;
}

export function FundHistoryDtoFromJSON(json: any): FundHistoryDto {
  return FundHistoryDtoFromJSONTyped(json, false);
}

export function FundHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundHistoryDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: FundHistoryOperationTypeFromJSON(json["type"]),
    id: json["id"],
    amount: json["amount"],
    balanceBefore: json["balanceBefore"],
    balanceAfter: json["balanceAfter"],
    reservedBefore: json["reservedBefore"],
    reservedAfter: json["reservedAfter"],
    createdAt: new Date(json["createdAt"]),
    financialPlan: FundHistoryDtoFinancialPlanFromJSON(json["financialPlan"]),
    fundingRequest: FundHistoryDtoFundingRequestFromJSON(json["fundingRequest"]),
    deposit: FundHistoryDtoDepositFromJSON(json["deposit"]),
    transfer: FundHistoryDtoTransferFromJSON(json["transfer"]),
    loan: FundHistoryDtoLoanFromJSON(json["loan"]),
    withdraw: FundHistoryDtoWithdrawFromJSON(json["withdraw"]),
    employee: FundHistoryDtoEmployeeFromJSON(json["employee"]),
  };
}

export function FundHistoryDtoToJSON(value?: FundHistoryDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: FundHistoryOperationTypeToJSON(value.type),
    id: value.id,
    amount: value.amount,
    balanceBefore: value.balanceBefore,
    balanceAfter: value.balanceAfter,
    reservedBefore: value.reservedBefore,
    reservedAfter: value.reservedAfter,
    createdAt: value.createdAt.toISOString(),
    financialPlan: FundHistoryDtoFinancialPlanToJSON(value.financialPlan),
    fundingRequest: FundHistoryDtoFundingRequestToJSON(value.fundingRequest),
    deposit: FundHistoryDtoDepositToJSON(value.deposit),
    transfer: FundHistoryDtoTransferToJSON(value.transfer),
    loan: FundHistoryDtoLoanToJSON(value.loan),
    withdraw: FundHistoryDtoWithdrawToJSON(value.withdraw),
    employee: FundHistoryDtoEmployeeToJSON(value.employee),
  };
}
