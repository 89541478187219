/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanRemoveDeferredIncomeRequestDto
 */
export interface MonthPlanRemoveDeferredIncomeRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanRemoveDeferredIncomeRequestDto
   */
  deferredIncomeId: string;
}

export function MonthPlanRemoveDeferredIncomeRequestDtoFromJSON(json: any): MonthPlanRemoveDeferredIncomeRequestDto {
  return MonthPlanRemoveDeferredIncomeRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanRemoveDeferredIncomeRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanRemoveDeferredIncomeRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deferredIncomeId: json["deferredIncomeId"],
  };
}

export function MonthPlanRemoveDeferredIncomeRequestDtoToJSON(
  value?: MonthPlanRemoveDeferredIncomeRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    deferredIncomeId: value.deferredIncomeId,
  };
}
