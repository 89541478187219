/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FinancialPlanFundingRequestDecisionType {
  NONE = "NONE",
  APPROVE = "APPROVE",
  DECLINE = "DECLINE",
  POSTPONE = "POSTPONE",
}

export function FinancialPlanFundingRequestDecisionTypeFromJSON(json: any): FinancialPlanFundingRequestDecisionType {
  return FinancialPlanFundingRequestDecisionTypeFromJSONTyped(json, false);
}

export function FinancialPlanFundingRequestDecisionTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanFundingRequestDecisionType {
  return json as FinancialPlanFundingRequestDecisionType;
}

export function FinancialPlanFundingRequestDecisionTypeToJSON(
  value?: FinancialPlanFundingRequestDecisionType | null
): any {
  return value as any;
}
