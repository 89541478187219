/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DepositTypeCreateUpdateRequestDto
 */
export interface DepositTypeCreateUpdateRequestDto {
  /**
   *
   * @type {string}
   * @memberof DepositTypeCreateUpdateRequestDto
   */
  name: string;
}

export function DepositTypeCreateUpdateRequestDtoFromJSON(json: any): DepositTypeCreateUpdateRequestDto {
  return DepositTypeCreateUpdateRequestDtoFromJSONTyped(json, false);
}

export function DepositTypeCreateUpdateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DepositTypeCreateUpdateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
  };
}

export function DepositTypeCreateUpdateRequestDtoToJSON(value?: DepositTypeCreateUpdateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
