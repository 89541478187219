/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { IncomeTypeDto, IncomeTypeDtoFromJSON, IncomeTypeDtoFromJSONTyped, IncomeTypeDtoToJSON } from "./IncomeTypeDto";

/**
 *
 * @export
 * @interface FundHistoryLoanDtoFromDeferredIncome
 */
export interface FundHistoryLoanDtoFromDeferredIncome {
  /**
   *
   * @type {string}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  name: string;
  /**
   *
   * @type {IncomeTypeDto}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  incomeType: IncomeTypeDto;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  openAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  closedAt: Date | null;
  /**
   *
   * @type {number}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  frozenAmount: number;
  /**
   *
   * @type {string}
   * @memberof FundHistoryLoanDtoFromDeferredIncome
   */
  status: FundHistoryLoanDtoFromDeferredIncomeStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export type FundHistoryLoanDtoFromDeferredIncomeStatusEnum = "NEW" | "PLAN" | "PROCESS" | "CLOSED";

export function FundHistoryLoanDtoFromDeferredIncomeFromJSON(json: any): FundHistoryLoanDtoFromDeferredIncome {
  return FundHistoryLoanDtoFromDeferredIncomeFromJSONTyped(json, false);
}

export function FundHistoryLoanDtoFromDeferredIncomeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundHistoryLoanDtoFromDeferredIncome {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    number: json["number"],
    name: json["name"],
    incomeType: IncomeTypeDtoFromJSON(json["incomeType"]),
    createdAt: new Date(json["createdAt"]),
    openAt: json["openAt"] === null ? null : new Date(json["openAt"]),
    closedAt: json["closedAt"] === null ? null : new Date(json["closedAt"]),
    amount: json["amount"],
    frozenAmount: json["frozenAmount"],
    status: json["status"],
  };
}

export function FundHistoryLoanDtoFromDeferredIncomeToJSON(value?: FundHistoryLoanDtoFromDeferredIncome | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    number: value.number,
    name: value.name,
    incomeType: IncomeTypeDtoToJSON(value.incomeType),
    createdAt: value.createdAt.toISOString(),
    openAt: value.openAt === null ? null : value.openAt.toISOString(),
    closedAt: value.closedAt === null ? null : value.closedAt.toISOString(),
    amount: value.amount,
    frozenAmount: value.frozenAmount,
    status: value.status,
  };
}
