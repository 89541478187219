/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanAddTransferDto,
  FinancialPlanAddTransferDtoFromJSON,
  FinancialPlanAddTransferDtoFromJSONTyped,
  FinancialPlanAddTransferDtoToJSON,
} from "./FinancialPlanAddTransferDto";

/**
 *
 * @export
 * @interface FinancialPlanAddTransfersRequestDto
 */
export interface FinancialPlanAddTransfersRequestDto {
  /**
   *
   * @type {Array<FinancialPlanAddTransferDto>}
   * @memberof FinancialPlanAddTransfersRequestDto
   */
  transfers: Array<FinancialPlanAddTransferDto>;
}

export function FinancialPlanAddTransfersRequestDtoFromJSON(json: any): FinancialPlanAddTransfersRequestDto {
  return FinancialPlanAddTransfersRequestDtoFromJSONTyped(json, false);
}

export function FinancialPlanAddTransfersRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanAddTransfersRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    transfers: (json["transfers"] as Array<any>).map(FinancialPlanAddTransferDtoFromJSON),
  };
}

export function FinancialPlanAddTransfersRequestDtoToJSON(value?: FinancialPlanAddTransfersRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    transfers: (value.transfers as Array<any>).map(FinancialPlanAddTransferDtoToJSON),
  };
}
