/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanStateWeekItemDto,
  MonthPlanStateWeekItemDtoFromJSON,
  MonthPlanStateWeekItemDtoFromJSONTyped,
  MonthPlanStateWeekItemDtoToJSON,
} from "./MonthPlanStateWeekItemDto";

/**
 *
 * @export
 * @interface MonthPlanStateTotalItemDto
 */
export interface MonthPlanStateTotalItemDto {
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateTotalItemDto
   */
  plan: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateTotalItemDto
   */
  fact: number;
  /**
   *
   * @type {Array<MonthPlanStateWeekItemDto>}
   * @memberof MonthPlanStateTotalItemDto
   */
  weeks: Array<MonthPlanStateWeekItemDto>;
}

export function MonthPlanStateTotalItemDtoFromJSON(json: any): MonthPlanStateTotalItemDto {
  return MonthPlanStateTotalItemDtoFromJSONTyped(json, false);
}

export function MonthPlanStateTotalItemDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanStateTotalItemDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    plan: json["plan"],
    fact: json["fact"],
    weeks: (json["weeks"] as Array<any>).map(MonthPlanStateWeekItemDtoFromJSON),
  };
}

export function MonthPlanStateTotalItemDtoToJSON(value?: MonthPlanStateTotalItemDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    plan: value.plan,
    fact: value.fact,
    weeks: (value.weeks as Array<any>).map(MonthPlanStateWeekItemDtoToJSON),
  };
}
