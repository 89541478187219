/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  UserProfileDto,
  UserProfileDtoFromJSON,
  UserProfileDtoToJSON,
  UserProfileUpdateRequestDto,
  UserProfileUpdateRequestDtoFromJSON,
  UserProfileUpdateRequestDtoToJSON,
} from "../models";

export interface UserProfileUpdateRequest {
  userProfileUpdateRequestDto: UserProfileUpdateRequestDto;
}

/**
 *
 */
export class UserProfileApi extends runtime.BaseAPI {
  /**
   */
  protected userProfileMeRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/user/profile`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected userProfileMeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<UserProfileDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDtoFromJSON(jsonValue));
  };

  /**
   */
  protected userProfileMeRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserProfileDto>> => {
    const context = this.userProfileMeRequestOpts();
    return this.userProfileMeFetch(context, initOverrides);
  };

  /**
   */
  userProfileMe = async (initOverrides?: RequestInit): Promise<UserProfileDto> => {
    const response = await this.userProfileMeRaw(initOverrides);
    return await response.value();
  };

  /**
   */
  useUserProfileMe = (config?: SWRConfiguration<UserProfileDto>): SWRResponse<UserProfileDto> => {
    return useSWR<UserProfileDto>(
      () => this.userProfileMeRequestOpts(),
      (context) => this.swrFetch(this.userProfileMeFetch(context)),
      config
    );
  };

  /**
   */
  protected userProfileUpdateRequestOpts = (requestParameters: UserProfileUpdateRequest): runtime.RequestOpts => {
    if (
      requestParameters.userProfileUpdateRequestDto === null ||
      requestParameters.userProfileUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "userProfileUpdateRequestDto",
        "Required parameter requestParameters.userProfileUpdateRequestDto was null or undefined when calling userProfileUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/user/profile`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserProfileUpdateRequestDtoToJSON(requestParameters.userProfileUpdateRequestDto),
    };
  };

  /**
   */
  protected userProfileUpdateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected userProfileUpdateRaw = async (
    requestParameters: UserProfileUpdateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.userProfileUpdateRequestOpts(requestParameters);
    return this.userProfileUpdateFetch(context, initOverrides);
  };

  /**
   */
  userProfileUpdate = async (
    userProfileUpdateRequestDto: UserProfileUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.userProfileUpdateRaw({ userProfileUpdateRequestDto: userProfileUpdateRequestDto }, initOverrides);
  };
}
