/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinancialPlanNextDto
 */
export interface FinancialPlanNextDto {
  /**
   *
   * @type {boolean}
   * @memberof FinancialPlanNextDto
   */
  closesWeek: boolean;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanNextDto
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanNextDto
   */
  from: Date;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanNextDto
   */
  monthPlanId: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanNextDto
   */
  monthPlanWeekNumber: number;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanNextDto
   */
  previousMonthPlanStateId: string;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanNextDto
   */
  to: Date;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanNextDto
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanNextDto
   */
  year: number;
  /**
   *
   * @type {boolean}
   * @memberof FinancialPlanNextDto
   */
  isValid: boolean;
}

export function FinancialPlanNextDtoFromJSON(json: any): FinancialPlanNextDto {
  return FinancialPlanNextDtoFromJSONTyped(json, false);
}

export function FinancialPlanNextDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanNextDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    closesWeek: json["closesWeek"],
    createdAt: new Date(json["createdAt"]),
    from: new Date(json["from"]),
    monthPlanId: json["monthPlanId"],
    monthPlanWeekNumber: json["monthPlanWeekNumber"],
    previousMonthPlanStateId: json["previousMonthPlanStateId"],
    to: new Date(json["to"]),
    month: json["month"],
    year: json["year"],
    isValid: json["isValid"],
  };
}

export function FinancialPlanNextDtoToJSON(value?: FinancialPlanNextDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    closesWeek: value.closesWeek,
    createdAt: value.createdAt.toISOString(),
    from: value.from.toISOString(),
    monthPlanId: value.monthPlanId,
    monthPlanWeekNumber: value.monthPlanWeekNumber,
    previousMonthPlanStateId: value.previousMonthPlanStateId,
    to: value.to.toISOString(),
    month: value.month,
    year: value.year,
    isValid: value.isValid,
  };
}
