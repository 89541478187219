/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanStateOverflowFundDtoFund,
  MonthPlanStateOverflowFundDtoFundFromJSON,
  MonthPlanStateOverflowFundDtoFundFromJSONTyped,
  MonthPlanStateOverflowFundDtoFundToJSON,
} from "./MonthPlanStateOverflowFundDtoFund";

/**
 *
 * @export
 * @interface MonthPlanStateOverflowFundDto
 */
export interface MonthPlanStateOverflowFundDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanStateOverflowFundDto
   */
  id: string | null;
  /**
   *
   * @type {number}
   * @memberof MonthPlanStateOverflowFundDto
   */
  fact: number;
  /**
   *
   * @type {MonthPlanStateOverflowFundDtoFund}
   * @memberof MonthPlanStateOverflowFundDto
   */
  fund: MonthPlanStateOverflowFundDtoFund | null;
}

export function MonthPlanStateOverflowFundDtoFromJSON(json: any): MonthPlanStateOverflowFundDto {
  return MonthPlanStateOverflowFundDtoFromJSONTyped(json, false);
}

export function MonthPlanStateOverflowFundDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanStateOverflowFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fact: json["fact"],
    fund: MonthPlanStateOverflowFundDtoFundFromJSON(json["fund"]),
  };
}

export function MonthPlanStateOverflowFundDtoToJSON(value?: MonthPlanStateOverflowFundDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fact: value.fact,
    fund: MonthPlanStateOverflowFundDtoFundToJSON(value.fund),
  };
}
