/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FundingRequestPaymentMethod {
  CASH = "CASH",
  CASHLESS = "CASHLESS",
}

export function FundingRequestPaymentMethodFromJSON(json: any): FundingRequestPaymentMethod {
  return FundingRequestPaymentMethodFromJSONTyped(json, false);
}

export function FundingRequestPaymentMethodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundingRequestPaymentMethod {
  return json as FundingRequestPaymentMethod;
}

export function FundingRequestPaymentMethodToJSON(value?: FundingRequestPaymentMethod | null): any {
  return value as any;
}
