/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DeferredIncomeDto,
  DeferredIncomeDtoFromJSON,
  DeferredIncomeDtoFromJSONTyped,
  DeferredIncomeDtoToJSON,
} from "./DeferredIncomeDto";

/**
 *
 * @export
 * @interface FinancialPlanFactDeferredIncomeDto
 */
export interface FinancialPlanFactDeferredIncomeDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanFactDeferredIncomeDto
   */
  id: string;
  /**
   *
   * @type {DeferredIncomeDto}
   * @memberof FinancialPlanFactDeferredIncomeDto
   */
  deferredIncome: DeferredIncomeDto;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanFactDeferredIncomeDto
   */
  amount: number;
}

export function FinancialPlanFactDeferredIncomeDtoFromJSON(json: any): FinancialPlanFactDeferredIncomeDto {
  return FinancialPlanFactDeferredIncomeDtoFromJSONTyped(json, false);
}

export function FinancialPlanFactDeferredIncomeDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanFactDeferredIncomeDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    deferredIncome: DeferredIncomeDtoFromJSON(json["deferredIncome"]),
    amount: json["amount"],
  };
}

export function FinancialPlanFactDeferredIncomeDtoToJSON(value?: FinancialPlanFactDeferredIncomeDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    deferredIncome: DeferredIncomeDtoToJSON(value.deferredIncome),
    amount: value.amount,
  };
}
