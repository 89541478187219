/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  AdminChangeRoleRequestDto,
  AdminChangeRoleRequestDtoFromJSON,
  AdminChangeRoleRequestDtoToJSON,
  AdminCreateRequestDto,
  AdminCreateRequestDtoFromJSON,
  AdminCreateRequestDtoToJSON,
  AdminDto,
  AdminDtoFromJSON,
  AdminDtoToJSON,
} from "../models";

export interface AdminChangeRoleRequest {
  id: string;
  adminChangeRoleRequestDto: AdminChangeRoleRequestDto;
}

export interface AdminCreateRequest {
  adminCreateRequestDto: AdminCreateRequestDto;
}

export interface AdminRemoveRequest {
  id: string;
}

/**
 *
 */
export class AdminApi extends runtime.BaseAPI {
  /**
   */
  protected adminChangeRoleRequestOpts = (requestParameters: AdminChangeRoleRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling adminChangeRole."
      );
    }

    if (
      requestParameters.adminChangeRoleRequestDto === null ||
      requestParameters.adminChangeRoleRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminChangeRoleRequestDto",
        "Required parameter requestParameters.adminChangeRoleRequestDto was null or undefined when calling adminChangeRole."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/admin/{id}/change-role`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminChangeRoleRequestDtoToJSON(requestParameters.adminChangeRoleRequestDto),
    };
  };

  /**
   */
  protected adminChangeRoleFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminChangeRoleRaw = async (
    requestParameters: AdminChangeRoleRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminChangeRoleRequestOpts(requestParameters);
    return this.adminChangeRoleFetch(context, initOverrides);
  };

  /**
   */
  adminChangeRole = async (
    id: string,
    adminChangeRoleRequestDto: AdminChangeRoleRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminChangeRoleRaw({ id: id, adminChangeRoleRequestDto: adminChangeRoleRequestDto }, initOverrides);
  };

  /**
   */
  protected adminCreateRequestOpts = (requestParameters: AdminCreateRequest): runtime.RequestOpts => {
    if (requestParameters.adminCreateRequestDto === null || requestParameters.adminCreateRequestDto === undefined) {
      throw new runtime.RequiredError(
        "adminCreateRequestDto",
        "Required parameter requestParameters.adminCreateRequestDto was null or undefined when calling adminCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/admin`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCreateRequestDtoToJSON(requestParameters.adminCreateRequestDto),
    };
  };

  /**
   */
  protected adminCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCreateRaw = async (
    requestParameters: AdminCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCreateRequestOpts(requestParameters);
    return this.adminCreateFetch(context, initOverrides);
  };

  /**
   */
  adminCreate = async (adminCreateRequestDto: AdminCreateRequestDto, initOverrides?: RequestInit): Promise<void> => {
    await this.adminCreateRaw({ adminCreateRequestDto: adminCreateRequestDto }, initOverrides);
  };

  /**
   */
  protected adminListRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/admin`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminDtoFromJSON));
  };

  /**
   */
  protected adminListRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AdminDto>>> => {
    const context = this.adminListRequestOpts();
    return this.adminListFetch(context, initOverrides);
  };

  /**
   */
  adminList = async (initOverrides?: RequestInit): Promise<Array<AdminDto>> => {
    const response = await this.adminListRaw(initOverrides);
    return await response.value();
  };

  /**
   */
  useAdminList = (config?: SWRConfiguration<Array<AdminDto>>): SWRResponse<Array<AdminDto>> => {
    return useSWR<Array<AdminDto>>(
      () => this.adminListRequestOpts(),
      (context) => this.swrFetch(this.adminListFetch(context)),
      config
    );
  };

  /**
   */
  protected adminRemoveRequestOpts = (requestParameters: AdminRemoveRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling adminRemove."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/admin/{id}/remove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminRemoveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminRemoveRaw = async (
    requestParameters: AdminRemoveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminRemoveRequestOpts(requestParameters);
    return this.adminRemoveFetch(context, initOverrides);
  };

  /**
   */
  adminRemove = async (id: string, initOverrides?: RequestInit): Promise<void> => {
    await this.adminRemoveRaw({ id: id }, initOverrides);
  };
}
