/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanStateFundDto
 */
export interface MonthPlanStateFundDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanStateFundDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MonthPlanStateFundDto
   */
  name: string;
}

export function MonthPlanStateFundDtoFromJSON(json: any): MonthPlanStateFundDto {
  return MonthPlanStateFundDtoFromJSONTyped(json, false);
}

export function MonthPlanStateFundDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanStateFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
  };
}

export function MonthPlanStateFundDtoToJSON(value?: MonthPlanStateFundDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
  };
}
