/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanDeferredIncomeDto,
  FinancialPlanDeferredIncomeDtoFromJSON,
  FinancialPlanDeferredIncomeDtoFromJSONTyped,
  FinancialPlanDeferredIncomeDtoToJSON,
} from "./FinancialPlanDeferredIncomeDto";
import {
  FinancialPlanFundDto,
  FinancialPlanFundDtoFromJSON,
  FinancialPlanFundDtoFromJSONTyped,
  FinancialPlanFundDtoToJSON,
} from "./FinancialPlanFundDto";

/**
 *
 * @export
 * @interface FinancialPlanFundsDto
 */
export interface FinancialPlanFundsDto {
  /**
   *
   * @type {Array<FinancialPlanFundDto>}
   * @memberof FinancialPlanFundsDto
   */
  funds: Array<FinancialPlanFundDto>;
  /**
   *
   * @type {Array<FinancialPlanDeferredIncomeDto>}
   * @memberof FinancialPlanFundsDto
   */
  deferredIncomes: Array<FinancialPlanDeferredIncomeDto>;
}

export function FinancialPlanFundsDtoFromJSON(json: any): FinancialPlanFundsDto {
  return FinancialPlanFundsDtoFromJSONTyped(json, false);
}

export function FinancialPlanFundsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanFundsDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    funds: (json["funds"] as Array<any>).map(FinancialPlanFundDtoFromJSON),
    deferredIncomes: (json["deferredIncomes"] as Array<any>).map(FinancialPlanDeferredIncomeDtoFromJSON),
  };
}

export function FinancialPlanFundsDtoToJSON(value?: FinancialPlanFundsDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    funds: (value.funds as Array<any>).map(FinancialPlanFundDtoToJSON),
    deferredIncomes: (value.deferredIncomes as Array<any>).map(FinancialPlanDeferredIncomeDtoToJSON),
  };
}
