/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  FundRequestCreateUpdateRequestDto,
  FundRequestCreateUpdateRequestDtoFromJSON,
  FundRequestCreateUpdateRequestDtoToJSON,
  FundingRequestCloseRequestDto,
  FundingRequestCloseRequestDtoFromJSON,
  FundingRequestCloseRequestDtoToJSON,
  FundingRequestDto,
  FundingRequestDtoFromJSON,
  FundingRequestDtoToJSON,
  FundingRequestHistoryDto,
  FundingRequestHistoryDtoFromJSON,
  FundingRequestHistoryDtoToJSON,
  FundingRequestPayoutEditCommentRequestDto,
  FundingRequestPayoutEditCommentRequestDtoFromJSON,
  FundingRequestPayoutEditCommentRequestDtoToJSON,
  FundingRequestPayoutRequestDto,
  FundingRequestPayoutRequestDtoFromJSON,
  FundingRequestPayoutRequestDtoToJSON,
  FundingRequestSearchQueryStatus,
  FundingRequestSearchQueryStatusFromJSON,
  FundingRequestSearchQueryStatusToJSON,
} from "../models";

export interface FundingRequestAdmitRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
}

export interface FundingRequestCancelRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
}

export interface FundingRequestCloseRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
  fundingRequestCloseRequestDto: FundingRequestCloseRequestDto;
}

export interface FundingRequestCreateDraftRequest {
  companyIdOrSlug: string;
  fundRequestCreateUpdateRequestDto: FundRequestCreateUpdateRequestDto;
}

export interface FundingRequestCreateWaitAdmitRequest {
  companyIdOrSlug: string;
  fundRequestCreateUpdateRequestDto: FundRequestCreateUpdateRequestDto;
}

export interface FundingRequestDeleteRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
}

export interface FundingRequestGetRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
}

export interface FundingRequestHistoryRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
}

export interface FundingRequestListRequest {
  companyIdOrSlug: string;
  status?: FundingRequestSearchQueryStatus;
  my?: boolean;
  fundId?: string;
  search?: string;
}

export interface FundingRequestPayoutRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
  fundingRequestPayoutRequestDto: FundingRequestPayoutRequestDto;
}

export interface FundingRequestPayoutEditCommentRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
  payoutId: string;
  fundingRequestPayoutEditCommentRequestDto: FundingRequestPayoutEditCommentRequestDto;
}

export interface FundingRequestRollbackAdmitRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
}

export interface FundingRequestUpdateRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
  fundRequestCreateUpdateRequestDto: FundRequestCreateUpdateRequestDto;
}

export interface FundingRequestWaitAdmitRequest {
  companyIdOrSlug: string;
  fundingRequestId: string;
}

/**
 *
 */
export class FundingRequestApi extends runtime.BaseAPI {
  /**
   */
  protected fundingRequestAdmitRequestOpts = (requestParameters: FundingRequestAdmitRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestAdmit."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestAdmit."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/admit`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestAdmitFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestAdmitRaw = async (
    requestParameters: FundingRequestAdmitRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestAdmitRequestOpts(requestParameters);
    return this.fundingRequestAdmitFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestAdmit = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestAdmitRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestCancelRequestOpts = (requestParameters: FundingRequestCancelRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestCancel."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestCancel."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/cancel`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestCancelFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestCancelRaw = async (
    requestParameters: FundingRequestCancelRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestCancelRequestOpts(requestParameters);
    return this.fundingRequestCancelFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestCancel = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestCancelRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestCloseRequestOpts = (requestParameters: FundingRequestCloseRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestClose."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestClose."
      );
    }

    if (
      requestParameters.fundingRequestCloseRequestDto === null ||
      requestParameters.fundingRequestCloseRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundingRequestCloseRequestDto",
        "Required parameter requestParameters.fundingRequestCloseRequestDto was null or undefined when calling fundingRequestClose."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/close`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundingRequestCloseRequestDtoToJSON(requestParameters.fundingRequestCloseRequestDto),
    };
  };

  /**
   */
  protected fundingRequestCloseFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestCloseRaw = async (
    requestParameters: FundingRequestCloseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestCloseRequestOpts(requestParameters);
    return this.fundingRequestCloseFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestClose = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    fundingRequestCloseRequestDto: FundingRequestCloseRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestCloseRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        fundingRequestId: fundingRequestId,
        fundingRequestCloseRequestDto: fundingRequestCloseRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestCreateDraftRequestOpts = (
    requestParameters: FundingRequestCreateDraftRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestCreateDraft."
      );
    }

    if (
      requestParameters.fundRequestCreateUpdateRequestDto === null ||
      requestParameters.fundRequestCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundRequestCreateUpdateRequestDto",
        "Required parameter requestParameters.fundRequestCreateUpdateRequestDto was null or undefined when calling fundingRequestCreateDraft."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/draft`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundRequestCreateUpdateRequestDtoToJSON(requestParameters.fundRequestCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected fundingRequestCreateDraftFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundingRequestDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FundingRequestDtoFromJSON(jsonValue));
  };

  /**
   */
  protected fundingRequestCreateDraftRaw = async (
    requestParameters: FundingRequestCreateDraftRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundingRequestDto>> => {
    const context = this.fundingRequestCreateDraftRequestOpts(requestParameters);
    return this.fundingRequestCreateDraftFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestCreateDraft = async (
    companyIdOrSlug: string,
    fundRequestCreateUpdateRequestDto: FundRequestCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<FundingRequestDto> => {
    const response = await this.fundingRequestCreateDraftRaw(
      { companyIdOrSlug: companyIdOrSlug, fundRequestCreateUpdateRequestDto: fundRequestCreateUpdateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected fundingRequestCreateWaitAdmitRequestOpts = (
    requestParameters: FundingRequestCreateWaitAdmitRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestCreateWaitAdmit."
      );
    }

    if (
      requestParameters.fundRequestCreateUpdateRequestDto === null ||
      requestParameters.fundRequestCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundRequestCreateUpdateRequestDto",
        "Required parameter requestParameters.fundRequestCreateUpdateRequestDto was null or undefined when calling fundingRequestCreateWaitAdmit."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/wait-admit`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundRequestCreateUpdateRequestDtoToJSON(requestParameters.fundRequestCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected fundingRequestCreateWaitAdmitFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundingRequestDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FundingRequestDtoFromJSON(jsonValue));
  };

  /**
   */
  protected fundingRequestCreateWaitAdmitRaw = async (
    requestParameters: FundingRequestCreateWaitAdmitRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundingRequestDto>> => {
    const context = this.fundingRequestCreateWaitAdmitRequestOpts(requestParameters);
    return this.fundingRequestCreateWaitAdmitFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestCreateWaitAdmit = async (
    companyIdOrSlug: string,
    fundRequestCreateUpdateRequestDto: FundRequestCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<FundingRequestDto> => {
    const response = await this.fundingRequestCreateWaitAdmitRaw(
      { companyIdOrSlug: companyIdOrSlug, fundRequestCreateUpdateRequestDto: fundRequestCreateUpdateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected fundingRequestDeleteRequestOpts = (requestParameters: FundingRequestDeleteRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestDelete."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/delete`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestDeleteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestDeleteRaw = async (
    requestParameters: FundingRequestDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestDeleteRequestOpts(requestParameters);
    return this.fundingRequestDeleteFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestDelete = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestDeleteRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestGetRequestOpts = (requestParameters: FundingRequestGetRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestGet."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestGetFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundingRequestDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FundingRequestDtoFromJSON(jsonValue));
  };

  /**
   */
  protected fundingRequestGetRaw = async (
    requestParameters: FundingRequestGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundingRequestDto>> => {
    const context = this.fundingRequestGetRequestOpts(requestParameters);
    return this.fundingRequestGetFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestGet = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    initOverrides?: RequestInit
  ): Promise<FundingRequestDto> => {
    const response = await this.fundingRequestGetRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFundingRequestGet = (
    companyIdOrSlug: string,
    fundingRequestId: string,
    config?: SWRConfiguration<FundingRequestDto>
  ): SWRResponse<FundingRequestDto> => {
    return useSWR<FundingRequestDto>(
      () => this.fundingRequestGetRequestOpts({ companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId }),
      (context) => this.swrFetch(this.fundingRequestGetFetch(context)),
      config
    );
  };

  /**
   */
  protected fundingRequestHistoryRequestOpts = (
    requestParameters: FundingRequestHistoryRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestHistory."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestHistory."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/history`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestHistoryFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundingRequestHistoryDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundingRequestHistoryDtoFromJSON));
  };

  /**
   */
  protected fundingRequestHistoryRaw = async (
    requestParameters: FundingRequestHistoryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundingRequestHistoryDto>>> => {
    const context = this.fundingRequestHistoryRequestOpts(requestParameters);
    return this.fundingRequestHistoryFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestHistory = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    initOverrides?: RequestInit
  ): Promise<Array<FundingRequestHistoryDto>> => {
    const response = await this.fundingRequestHistoryRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFundingRequestHistory = (
    companyIdOrSlug: string,
    fundingRequestId: string,
    config?: SWRConfiguration<Array<FundingRequestHistoryDto>>
  ): SWRResponse<Array<FundingRequestHistoryDto>> => {
    return useSWR<Array<FundingRequestHistoryDto>>(
      () =>
        this.fundingRequestHistoryRequestOpts({ companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId }),
      (context) => this.swrFetch(this.fundingRequestHistoryFetch(context)),
      config
    );
  };

  /**
   */
  protected fundingRequestListRequestOpts = (requestParameters: FundingRequestListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestList."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.my !== undefined) {
      queryParameters["my"] = requestParameters.my;
    }

    if (requestParameters.fundId !== undefined) {
      queryParameters["fundId"] = requestParameters.fundId;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundingRequestDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundingRequestDtoFromJSON));
  };

  /**
   */
  protected fundingRequestListRaw = async (
    requestParameters: FundingRequestListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundingRequestDto>>> => {
    const context = this.fundingRequestListRequestOpts(requestParameters);
    return this.fundingRequestListFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestList = async (
    companyIdOrSlug: string,
    status?: FundingRequestSearchQueryStatus,
    my?: boolean,
    fundId?: string,
    search?: string,
    initOverrides?: RequestInit
  ): Promise<Array<FundingRequestDto>> => {
    const response = await this.fundingRequestListRaw(
      { companyIdOrSlug: companyIdOrSlug, status: status, my: my, fundId: fundId, search: search },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFundingRequestList = (
    requestParameters: FundingRequestListRequest,
    config?: SWRConfiguration<Array<FundingRequestDto>>
  ): SWRResponse<Array<FundingRequestDto>> => {
    return useSWR<Array<FundingRequestDto>>(
      () => this.fundingRequestListRequestOpts(requestParameters),
      (context) => this.swrFetch(this.fundingRequestListFetch(context)),
      config
    );
  };

  /**
   */
  protected fundingRequestPayoutRequestOpts = (requestParameters: FundingRequestPayoutRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestPayout."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestPayout."
      );
    }

    if (
      requestParameters.fundingRequestPayoutRequestDto === null ||
      requestParameters.fundingRequestPayoutRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundingRequestPayoutRequestDto",
        "Required parameter requestParameters.fundingRequestPayoutRequestDto was null or undefined when calling fundingRequestPayout."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/payout`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundingRequestPayoutRequestDtoToJSON(requestParameters.fundingRequestPayoutRequestDto),
    };
  };

  /**
   */
  protected fundingRequestPayoutFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestPayoutRaw = async (
    requestParameters: FundingRequestPayoutRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestPayoutRequestOpts(requestParameters);
    return this.fundingRequestPayoutFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestPayout = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    fundingRequestPayoutRequestDto: FundingRequestPayoutRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestPayoutRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        fundingRequestId: fundingRequestId,
        fundingRequestPayoutRequestDto: fundingRequestPayoutRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestPayoutEditCommentRequestOpts = (
    requestParameters: FundingRequestPayoutEditCommentRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestPayoutEditComment."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestPayoutEditComment."
      );
    }

    if (requestParameters.payoutId === null || requestParameters.payoutId === undefined) {
      throw new runtime.RequiredError(
        "payoutId",
        "Required parameter requestParameters.payoutId was null or undefined when calling fundingRequestPayoutEditComment."
      );
    }

    if (
      requestParameters.fundingRequestPayoutEditCommentRequestDto === null ||
      requestParameters.fundingRequestPayoutEditCommentRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundingRequestPayoutEditCommentRequestDto",
        "Required parameter requestParameters.fundingRequestPayoutEditCommentRequestDto was null or undefined when calling fundingRequestPayoutEditComment."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/payout/{payoutId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId)))
        .replace(`{${"payoutId"}}`, encodeURIComponent(String(requestParameters.payoutId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundingRequestPayoutEditCommentRequestDtoToJSON(
        requestParameters.fundingRequestPayoutEditCommentRequestDto
      ),
    };
  };

  /**
   */
  protected fundingRequestPayoutEditCommentFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestPayoutEditCommentRaw = async (
    requestParameters: FundingRequestPayoutEditCommentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestPayoutEditCommentRequestOpts(requestParameters);
    return this.fundingRequestPayoutEditCommentFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestPayoutEditComment = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    payoutId: string,
    fundingRequestPayoutEditCommentRequestDto: FundingRequestPayoutEditCommentRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestPayoutEditCommentRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        fundingRequestId: fundingRequestId,
        payoutId: payoutId,
        fundingRequestPayoutEditCommentRequestDto: fundingRequestPayoutEditCommentRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestRollbackAdmitRequestOpts = (
    requestParameters: FundingRequestRollbackAdmitRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestRollbackAdmit."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestRollbackAdmit."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/rollback-admit`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestRollbackAdmitFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestRollbackAdmitRaw = async (
    requestParameters: FundingRequestRollbackAdmitRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestRollbackAdmitRequestOpts(requestParameters);
    return this.fundingRequestRollbackAdmitFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestRollbackAdmit = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestRollbackAdmitRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestUpdateRequestOpts = (requestParameters: FundingRequestUpdateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestUpdate."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestUpdate."
      );
    }

    if (
      requestParameters.fundRequestCreateUpdateRequestDto === null ||
      requestParameters.fundRequestCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundRequestCreateUpdateRequestDto",
        "Required parameter requestParameters.fundRequestCreateUpdateRequestDto was null or undefined when calling fundingRequestUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "PATCH",
      headers: headerParameters,
      query: queryParameters,
      body: FundRequestCreateUpdateRequestDtoToJSON(requestParameters.fundRequestCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected fundingRequestUpdateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestUpdateRaw = async (
    requestParameters: FundingRequestUpdateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestUpdateRequestOpts(requestParameters);
    return this.fundingRequestUpdateFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestUpdate = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    fundRequestCreateUpdateRequestDto: FundRequestCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestUpdateRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        fundingRequestId: fundingRequestId,
        fundRequestCreateUpdateRequestDto: fundRequestCreateUpdateRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestWaitAdmitRequestOpts = (
    requestParameters: FundingRequestWaitAdmitRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestWaitAdmit."
      );
    }

    if (requestParameters.fundingRequestId === null || requestParameters.fundingRequestId === undefined) {
      throw new runtime.RequiredError(
        "fundingRequestId",
        "Required parameter requestParameters.fundingRequestId was null or undefined when calling fundingRequestWaitAdmit."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request/{fundingRequestId}/wait-admit`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundingRequestId"}}`, encodeURIComponent(String(requestParameters.fundingRequestId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestWaitAdmitFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestWaitAdmitRaw = async (
    requestParameters: FundingRequestWaitAdmitRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestWaitAdmitRequestOpts(requestParameters);
    return this.fundingRequestWaitAdmitFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestWaitAdmit = async (
    companyIdOrSlug: string,
    fundingRequestId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestWaitAdmitRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestId: fundingRequestId },
      initOverrides
    );
  };
}
