/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  CompanyEmployeeChangeRoleRequestDto,
  CompanyEmployeeChangeRoleRequestDtoFromJSON,
  CompanyEmployeeChangeRoleRequestDtoToJSON,
  CompanyEmployeeDto,
  CompanyEmployeeDtoFromJSON,
  CompanyEmployeeDtoToJSON,
} from "../models";

export interface CompanyEmployeeArchiveRequest {
  companyIdOrSlug: string;
  companyEmployeeId: string;
}

export interface CompanyEmployeeChangeRoleRequest {
  companyIdOrSlug: string;
  companyEmployeeId: string;
  companyEmployeeChangeRoleRequestDto: CompanyEmployeeChangeRoleRequestDto;
}

export interface CompanyEmployeeListRequest {
  companyIdOrSlug: string;
}

export interface CompanyEmployeeMeRequest {
  companyIdOrSlug: string;
}

/**
 *
 */
export class CompanyEmployeeApi extends runtime.BaseAPI {
  /**
   */
  protected companyEmployeeArchiveRequestOpts = (
    requestParameters: CompanyEmployeeArchiveRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeArchive."
      );
    }

    if (requestParameters.companyEmployeeId === null || requestParameters.companyEmployeeId === undefined) {
      throw new runtime.RequiredError(
        "companyEmployeeId",
        "Required parameter requestParameters.companyEmployeeId was null or undefined when calling companyEmployeeArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee/{companyEmployeeId}/archive`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"companyEmployeeId"}}`, encodeURIComponent(String(requestParameters.companyEmployeeId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeArchiveRaw = async (
    requestParameters: CompanyEmployeeArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeArchiveRequestOpts(requestParameters);
    return this.companyEmployeeArchiveFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeArchive = async (
    companyIdOrSlug: string,
    companyEmployeeId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeArchiveRaw(
      { companyIdOrSlug: companyIdOrSlug, companyEmployeeId: companyEmployeeId },
      initOverrides
    );
  };

  /**
   */
  protected companyEmployeeChangeRoleRequestOpts = (
    requestParameters: CompanyEmployeeChangeRoleRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeChangeRole."
      );
    }

    if (requestParameters.companyEmployeeId === null || requestParameters.companyEmployeeId === undefined) {
      throw new runtime.RequiredError(
        "companyEmployeeId",
        "Required parameter requestParameters.companyEmployeeId was null or undefined when calling companyEmployeeChangeRole."
      );
    }

    if (
      requestParameters.companyEmployeeChangeRoleRequestDto === null ||
      requestParameters.companyEmployeeChangeRoleRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyEmployeeChangeRoleRequestDto",
        "Required parameter requestParameters.companyEmployeeChangeRoleRequestDto was null or undefined when calling companyEmployeeChangeRole."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee/{companyEmployeeId}/role`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"companyEmployeeId"}}`, encodeURIComponent(String(requestParameters.companyEmployeeId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyEmployeeChangeRoleRequestDtoToJSON(requestParameters.companyEmployeeChangeRoleRequestDto),
    };
  };

  /**
   */
  protected companyEmployeeChangeRoleFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeChangeRoleRaw = async (
    requestParameters: CompanyEmployeeChangeRoleRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeChangeRoleRequestOpts(requestParameters);
    return this.companyEmployeeChangeRoleFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeChangeRole = async (
    companyIdOrSlug: string,
    companyEmployeeId: string,
    companyEmployeeChangeRoleRequestDto: CompanyEmployeeChangeRoleRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeChangeRoleRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        companyEmployeeId: companyEmployeeId,
        companyEmployeeChangeRoleRequestDto: companyEmployeeChangeRoleRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected companyEmployeeListRequestOpts = (requestParameters: CompanyEmployeeListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<CompanyEmployeeDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyEmployeeDtoFromJSON));
  };

  /**
   */
  protected companyEmployeeListRaw = async (
    requestParameters: CompanyEmployeeListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<CompanyEmployeeDto>>> => {
    const context = this.companyEmployeeListRequestOpts(requestParameters);
    return this.companyEmployeeListFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeList = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<Array<CompanyEmployeeDto>> => {
    const response = await this.companyEmployeeListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyEmployeeList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<CompanyEmployeeDto>>
  ): SWRResponse<Array<CompanyEmployeeDto>> => {
    return useSWR<Array<CompanyEmployeeDto>>(
      () => this.companyEmployeeListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.companyEmployeeListFetch(context)),
      config
    );
  };

  /**
   */
  protected companyEmployeeMeRequestOpts = (requestParameters: CompanyEmployeeMeRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeMe."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/employee/me`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeMeFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyEmployeeDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyEmployeeDtoFromJSON(jsonValue));
  };

  /**
   */
  protected companyEmployeeMeRaw = async (
    requestParameters: CompanyEmployeeMeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyEmployeeDto>> => {
    const context = this.companyEmployeeMeRequestOpts(requestParameters);
    return this.companyEmployeeMeFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeMe = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<CompanyEmployeeDto> => {
    const response = await this.companyEmployeeMeRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyEmployeeMe = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<CompanyEmployeeDto>
  ): SWRResponse<CompanyEmployeeDto> => {
    return useSWR<CompanyEmployeeDto>(
      () => this.companyEmployeeMeRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.companyEmployeeMeFetch(context)),
      config
    );
  };
}
