/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanSmallTotalDto,
  FinancialPlanSmallTotalDtoFromJSON,
  FinancialPlanSmallTotalDtoFromJSONTyped,
  FinancialPlanSmallTotalDtoToJSON,
} from "./FinancialPlanSmallTotalDto";
import {
  FinancialPlanStatus,
  FinancialPlanStatusFromJSON,
  FinancialPlanStatusFromJSONTyped,
  FinancialPlanStatusToJSON,
} from "./FinancialPlanStatus";

/**
 *
 * @export
 * @interface FinancialPlanSmallDto
 */
export interface FinancialPlanSmallDto {
  /**
   *
   * @type {FinancialPlanStatus}
   * @memberof FinancialPlanSmallDto
   */
  status: FinancialPlanStatus;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanSmallDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanSmallDto
   */
  monthPlanId: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanSmallDto
   */
  number: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanSmallDto
   */
  monthWeekPosition: number;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanSmallDto
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanSmallDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanSmallDto
   */
  to: Date;
  /**
   *
   * @type {boolean}
   * @memberof FinancialPlanSmallDto
   */
  closesWeek: boolean;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanSmallDto
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanSmallDto
   */
  year: number;
  /**
   *
   * @type {FinancialPlanSmallTotalDto}
   * @memberof FinancialPlanSmallDto
   */
  plan: FinancialPlanSmallTotalDto;
  /**
   *
   * @type {FinancialPlanSmallTotalDto}
   * @memberof FinancialPlanSmallDto
   */
  fact: FinancialPlanSmallTotalDto;
}

export function FinancialPlanSmallDtoFromJSON(json: any): FinancialPlanSmallDto {
  return FinancialPlanSmallDtoFromJSONTyped(json, false);
}

export function FinancialPlanSmallDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanSmallDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: FinancialPlanStatusFromJSON(json["status"]),
    id: json["id"],
    monthPlanId: json["monthPlanId"],
    number: json["number"],
    monthWeekPosition: json["monthWeekPosition"],
    createdAt: new Date(json["createdAt"]),
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    closesWeek: json["closesWeek"],
    month: json["month"],
    year: json["year"],
    plan: FinancialPlanSmallTotalDtoFromJSON(json["plan"]),
    fact: FinancialPlanSmallTotalDtoFromJSON(json["fact"]),
  };
}

export function FinancialPlanSmallDtoToJSON(value?: FinancialPlanSmallDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: FinancialPlanStatusToJSON(value.status),
    id: value.id,
    monthPlanId: value.monthPlanId,
    number: value.number,
    monthWeekPosition: value.monthWeekPosition,
    createdAt: value.createdAt.toISOString(),
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    closesWeek: value.closesWeek,
    month: value.month,
    year: value.year,
    plan: FinancialPlanSmallTotalDtoToJSON(value.plan),
    fact: FinancialPlanSmallTotalDtoToJSON(value.fact),
  };
}
