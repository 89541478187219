/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MovementOnFundsReportFundGroupDto,
  MovementOnFundsReportFundGroupDtoFromJSON,
  MovementOnFundsReportFundGroupDtoFromJSONTyped,
  MovementOnFundsReportFundGroupDtoToJSON,
} from "./MovementOnFundsReportFundGroupDto";

/**
 *
 * @export
 * @interface MovementOnFundsReportDto
 */
export interface MovementOnFundsReportDto {
  /**
   *
   * @type {Date}
   * @memberof MovementOnFundsReportDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof MovementOnFundsReportDto
   */
  to: Date;
  /**
   *
   * @type {Array<MovementOnFundsReportFundGroupDto>}
   * @memberof MovementOnFundsReportDto
   */
  fundGroups: Array<MovementOnFundsReportFundGroupDto>;
}

export function MovementOnFundsReportDtoFromJSON(json: any): MovementOnFundsReportDto {
  return MovementOnFundsReportDtoFromJSONTyped(json, false);
}

export function MovementOnFundsReportDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MovementOnFundsReportDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    fundGroups: (json["fundGroups"] as Array<any>).map(MovementOnFundsReportFundGroupDtoFromJSON),
  };
}

export function MovementOnFundsReportDtoToJSON(value?: MovementOnFundsReportDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    fundGroups: (value.fundGroups as Array<any>).map(MovementOnFundsReportFundGroupDtoToJSON),
  };
}
