/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanStateWeekDto
 */
export interface MonthPlanStateWeekDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanStateWeekDto
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanStateWeekDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanStateWeekDto
   */
  to: Date;
  /**
   *
   * @type {boolean}
   * @memberof MonthPlanStateWeekDto
   */
  closed: boolean;
}

export function MonthPlanStateWeekDtoFromJSON(json: any): MonthPlanStateWeekDto {
  return MonthPlanStateWeekDtoFromJSONTyped(json, false);
}

export function MonthPlanStateWeekDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanStateWeekDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    closed: json["closed"],
  };
}

export function MonthPlanStateWeekDtoToJSON(value?: MonthPlanStateWeekDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    closed: value.closed,
  };
}
