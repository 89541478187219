/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { FundDto, FundDtoFromJSON, FundDtoFromJSONTyped, FundDtoToJSON } from "./FundDto";

/**
 *
 * @export
 * @interface FinancialPlanTransferDto
 */
export interface FinancialPlanTransferDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanTransferDto
   */
  id: string;
  /**
   *
   * @type {FundDto}
   * @memberof FinancialPlanTransferDto
   */
  fromFund: FundDto;
  /**
   *
   * @type {FundDto}
   * @memberof FinancialPlanTransferDto
   */
  toFund: FundDto;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanTransferDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanTransferDto
   */
  comment: string;
}

export function FinancialPlanTransferDtoFromJSON(json: any): FinancialPlanTransferDto {
  return FinancialPlanTransferDtoFromJSONTyped(json, false);
}

export function FinancialPlanTransferDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanTransferDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fromFund: FundDtoFromJSON(json["fromFund"]),
    toFund: FundDtoFromJSON(json["toFund"]),
    amount: json["amount"],
    comment: json["comment"],
  };
}

export function FinancialPlanTransferDtoToJSON(value?: FinancialPlanTransferDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fromFund: FundDtoToJSON(value.fromFund),
    toFund: FundDtoToJSON(value.toFund),
    amount: value.amount,
    comment: value.comment,
  };
}
