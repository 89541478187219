/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanFundAfterDto,
  FinancialPlanFundAfterDtoFromJSON,
  FinancialPlanFundAfterDtoFromJSONTyped,
  FinancialPlanFundAfterDtoToJSON,
} from "./FinancialPlanFundAfterDto";
import {
  FinancialPlanFundBeforeDto,
  FinancialPlanFundBeforeDtoFromJSON,
  FinancialPlanFundBeforeDtoFromJSONTyped,
  FinancialPlanFundBeforeDtoToJSON,
} from "./FinancialPlanFundBeforeDto";
import { FundDto, FundDtoFromJSON, FundDtoFromJSONTyped, FundDtoToJSON } from "./FundDto";

/**
 *
 * @export
 * @interface FinancialPlanFundDto
 */
export interface FinancialPlanFundDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanFundDto
   */
  id: string;
  /**
   *
   * @type {FundDto}
   * @memberof FinancialPlanFundDto
   */
  fund: FundDto;
  /**
   *
   * @type {FinancialPlanFundBeforeDto}
   * @memberof FinancialPlanFundDto
   */
  before: FinancialPlanFundBeforeDto;
  /**
   *
   * @type {FinancialPlanFundAfterDto}
   * @memberof FinancialPlanFundDto
   */
  after: FinancialPlanFundAfterDto;
}

export function FinancialPlanFundDtoFromJSON(json: any): FinancialPlanFundDto {
  return FinancialPlanFundDtoFromJSONTyped(json, false);
}

export function FinancialPlanFundDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fund: FundDtoFromJSON(json["fund"]),
    before: FinancialPlanFundBeforeDtoFromJSON(json["before"]),
    after: FinancialPlanFundAfterDtoFromJSON(json["after"]),
  };
}

export function FinancialPlanFundDtoToJSON(value?: FinancialPlanFundDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fund: FundDtoToJSON(value.fund),
    before: FinancialPlanFundBeforeDtoToJSON(value.before),
    after: FinancialPlanFundAfterDtoToJSON(value.after),
  };
}
