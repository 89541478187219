/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinancialPlanSmallTotalDto
 */
export interface FinancialPlanSmallTotalDto {
  /**
   *
   * @type {number}
   * @memberof FinancialPlanSmallTotalDto
   */
  incomeTotal: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanSmallTotalDto
   */
  marginProfit: number;
}

export function FinancialPlanSmallTotalDtoFromJSON(json: any): FinancialPlanSmallTotalDto {
  return FinancialPlanSmallTotalDtoFromJSONTyped(json, false);
}

export function FinancialPlanSmallTotalDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanSmallTotalDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    incomeTotal: json["incomeTotal"],
    marginProfit: json["marginProfit"],
  };
}

export function FinancialPlanSmallTotalDtoToJSON(value?: FinancialPlanSmallTotalDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    incomeTotal: value.incomeTotal,
    marginProfit: value.marginProfit,
  };
}
