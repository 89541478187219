/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanSimpleTotalDto
 */
export interface MonthPlanSimpleTotalDto {
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleTotalDto
   */
  incomeTotal: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleTotalDto
   */
  marginProfit: number;
}

export function MonthPlanSimpleTotalDtoFromJSON(json: any): MonthPlanSimpleTotalDto {
  return MonthPlanSimpleTotalDtoFromJSONTyped(json, false);
}

export function MonthPlanSimpleTotalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanSimpleTotalDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    incomeTotal: json["incomeTotal"],
    marginProfit: json["marginProfit"],
  };
}

export function MonthPlanSimpleTotalDtoToJSON(value?: MonthPlanSimpleTotalDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    incomeTotal: value.incomeTotal,
    marginProfit: value.marginProfit,
  };
}
