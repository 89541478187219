/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FundLoanRepaymentDto
 */
export interface FundLoanRepaymentDto {
  /**
   *
   * @type {string}
   * @memberof FundLoanRepaymentDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FundLoanRepaymentDto
   */
  loanId: string;
  /**
   *
   * @type {number}
   * @memberof FundLoanRepaymentDto
   */
  amount: number;
}

export function FundLoanRepaymentDtoFromJSON(json: any): FundLoanRepaymentDto {
  return FundLoanRepaymentDtoFromJSONTyped(json, false);
}

export function FundLoanRepaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundLoanRepaymentDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    loanId: json["loanId"],
    amount: json["amount"],
  };
}

export function FundLoanRepaymentDtoToJSON(value?: FundLoanRepaymentDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    loanId: value.loanId,
    amount: value.amount,
  };
}
