/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FundingRequestStatus {
  DRAFT = "DRAFT",
  NEW = "NEW",
  ADMITTED = "ADMITTED",
  APPROVED = "APPROVED",
  CLOSED = "CLOSED",
  DECLINED = "DECLINED",
  CANCELED = "CANCELED",
}

export function FundingRequestStatusFromJSON(json: any): FundingRequestStatus {
  return FundingRequestStatusFromJSONTyped(json, false);
}

export function FundingRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FundingRequestStatus {
  return json as FundingRequestStatus;
}

export function FundingRequestStatusToJSON(value?: FundingRequestStatus | null): any {
  return value as any;
}
