/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  CompanyEmployeeRoleChangeNameRequestDto,
  CompanyEmployeeRoleChangeNameRequestDtoFromJSON,
  CompanyEmployeeRoleChangeNameRequestDtoToJSON,
  CompanyEmployeeRoleChangePermissionsRequestDto,
  CompanyEmployeeRoleChangePermissionsRequestDtoFromJSON,
  CompanyEmployeeRoleChangePermissionsRequestDtoToJSON,
  CompanyEmployeeRoleCreateRequestDto,
  CompanyEmployeeRoleCreateRequestDtoFromJSON,
  CompanyEmployeeRoleCreateRequestDtoToJSON,
  CompanyEmployeeRoleDto,
  CompanyEmployeeRoleDtoFromJSON,
  CompanyEmployeeRoleDtoToJSON,
  CompanyEmployeeRolePermissionsDto,
  CompanyEmployeeRolePermissionsDtoFromJSON,
  CompanyEmployeeRolePermissionsDtoToJSON,
} from "../models";

export interface CompanyEmployeeRoleChangeNameRequest {
  companyIdOrSlug: string;
  companyEmployeeRoleId: string;
  companyEmployeeRoleChangeNameRequestDto: CompanyEmployeeRoleChangeNameRequestDto;
}

export interface CompanyEmployeeRoleChangePermissionsRequest {
  companyIdOrSlug: string;
  companyEmployeeRoleId: string;
  companyEmployeeRoleChangePermissionsRequestDto: CompanyEmployeeRoleChangePermissionsRequestDto;
}

export interface CompanyEmployeeRoleCreateRequest {
  companyIdOrSlug: string;
  companyEmployeeRoleCreateRequestDto: CompanyEmployeeRoleCreateRequestDto;
}

export interface CompanyEmployeeRoleListRequest {
  companyIdOrSlug: string;
}

export interface CompanyEmployeeRolePermissionsRequest {
  companyIdOrSlug: string;
}

export interface CompanyEmployeeRoleRemoveRequest {
  companyIdOrSlug: string;
  companyEmployeeRoleId: string;
}

/**
 *
 */
export class CompanyRbacApi extends runtime.BaseAPI {
  /**
   */
  protected companyEmployeeRoleChangeNameRequestOpts = (
    requestParameters: CompanyEmployeeRoleChangeNameRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeRoleChangeName."
      );
    }

    if (requestParameters.companyEmployeeRoleId === null || requestParameters.companyEmployeeRoleId === undefined) {
      throw new runtime.RequiredError(
        "companyEmployeeRoleId",
        "Required parameter requestParameters.companyEmployeeRoleId was null or undefined when calling companyEmployeeRoleChangeName."
      );
    }

    if (
      requestParameters.companyEmployeeRoleChangeNameRequestDto === null ||
      requestParameters.companyEmployeeRoleChangeNameRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyEmployeeRoleChangeNameRequestDto",
        "Required parameter requestParameters.companyEmployeeRoleChangeNameRequestDto was null or undefined when calling companyEmployeeRoleChangeName."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/rbac/role/{companyEmployeeRoleId}/name`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"companyEmployeeRoleId"}}`, encodeURIComponent(String(requestParameters.companyEmployeeRoleId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyEmployeeRoleChangeNameRequestDtoToJSON(requestParameters.companyEmployeeRoleChangeNameRequestDto),
    };
  };

  /**
   */
  protected companyEmployeeRoleChangeNameFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeRoleChangeNameRaw = async (
    requestParameters: CompanyEmployeeRoleChangeNameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeRoleChangeNameRequestOpts(requestParameters);
    return this.companyEmployeeRoleChangeNameFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeRoleChangeName = async (
    companyIdOrSlug: string,
    companyEmployeeRoleId: string,
    companyEmployeeRoleChangeNameRequestDto: CompanyEmployeeRoleChangeNameRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeRoleChangeNameRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        companyEmployeeRoleId: companyEmployeeRoleId,
        companyEmployeeRoleChangeNameRequestDto: companyEmployeeRoleChangeNameRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected companyEmployeeRoleChangePermissionsRequestOpts = (
    requestParameters: CompanyEmployeeRoleChangePermissionsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeRoleChangePermissions."
      );
    }

    if (requestParameters.companyEmployeeRoleId === null || requestParameters.companyEmployeeRoleId === undefined) {
      throw new runtime.RequiredError(
        "companyEmployeeRoleId",
        "Required parameter requestParameters.companyEmployeeRoleId was null or undefined when calling companyEmployeeRoleChangePermissions."
      );
    }

    if (
      requestParameters.companyEmployeeRoleChangePermissionsRequestDto === null ||
      requestParameters.companyEmployeeRoleChangePermissionsRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyEmployeeRoleChangePermissionsRequestDto",
        "Required parameter requestParameters.companyEmployeeRoleChangePermissionsRequestDto was null or undefined when calling companyEmployeeRoleChangePermissions."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/rbac/role/{companyEmployeeRoleId}/permissions`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"companyEmployeeRoleId"}}`, encodeURIComponent(String(requestParameters.companyEmployeeRoleId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyEmployeeRoleChangePermissionsRequestDtoToJSON(
        requestParameters.companyEmployeeRoleChangePermissionsRequestDto
      ),
    };
  };

  /**
   */
  protected companyEmployeeRoleChangePermissionsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeRoleChangePermissionsRaw = async (
    requestParameters: CompanyEmployeeRoleChangePermissionsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeRoleChangePermissionsRequestOpts(requestParameters);
    return this.companyEmployeeRoleChangePermissionsFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeRoleChangePermissions = async (
    companyIdOrSlug: string,
    companyEmployeeRoleId: string,
    companyEmployeeRoleChangePermissionsRequestDto: CompanyEmployeeRoleChangePermissionsRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeRoleChangePermissionsRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        companyEmployeeRoleId: companyEmployeeRoleId,
        companyEmployeeRoleChangePermissionsRequestDto: companyEmployeeRoleChangePermissionsRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected companyEmployeeRoleCreateRequestOpts = (
    requestParameters: CompanyEmployeeRoleCreateRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeRoleCreate."
      );
    }

    if (
      requestParameters.companyEmployeeRoleCreateRequestDto === null ||
      requestParameters.companyEmployeeRoleCreateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyEmployeeRoleCreateRequestDto",
        "Required parameter requestParameters.companyEmployeeRoleCreateRequestDto was null or undefined when calling companyEmployeeRoleCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/rbac/role`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyEmployeeRoleCreateRequestDtoToJSON(requestParameters.companyEmployeeRoleCreateRequestDto),
    };
  };

  /**
   */
  protected companyEmployeeRoleCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeRoleCreateRaw = async (
    requestParameters: CompanyEmployeeRoleCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeRoleCreateRequestOpts(requestParameters);
    return this.companyEmployeeRoleCreateFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeRoleCreate = async (
    companyIdOrSlug: string,
    companyEmployeeRoleCreateRequestDto: CompanyEmployeeRoleCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeRoleCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, companyEmployeeRoleCreateRequestDto: companyEmployeeRoleCreateRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected companyEmployeeRoleListRequestOpts = (
    requestParameters: CompanyEmployeeRoleListRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeRoleList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/rbac/role`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeRoleListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<CompanyEmployeeRoleDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyEmployeeRoleDtoFromJSON));
  };

  /**
   */
  protected companyEmployeeRoleListRaw = async (
    requestParameters: CompanyEmployeeRoleListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<CompanyEmployeeRoleDto>>> => {
    const context = this.companyEmployeeRoleListRequestOpts(requestParameters);
    return this.companyEmployeeRoleListFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeRoleList = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<Array<CompanyEmployeeRoleDto>> => {
    const response = await this.companyEmployeeRoleListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyEmployeeRoleList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<CompanyEmployeeRoleDto>>
  ): SWRResponse<Array<CompanyEmployeeRoleDto>> => {
    return useSWR<Array<CompanyEmployeeRoleDto>>(
      () => this.companyEmployeeRoleListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.companyEmployeeRoleListFetch(context)),
      config
    );
  };

  /**
   */
  protected companyEmployeeRolePermissionsRequestOpts = (
    requestParameters: CompanyEmployeeRolePermissionsRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeRolePermissions."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/rbac/permissions`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeRolePermissionsFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyEmployeeRolePermissionsDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyEmployeeRolePermissionsDtoFromJSON(jsonValue));
  };

  /**
   */
  protected companyEmployeeRolePermissionsRaw = async (
    requestParameters: CompanyEmployeeRolePermissionsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyEmployeeRolePermissionsDto>> => {
    const context = this.companyEmployeeRolePermissionsRequestOpts(requestParameters);
    return this.companyEmployeeRolePermissionsFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeRolePermissions = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<CompanyEmployeeRolePermissionsDto> => {
    const response = await this.companyEmployeeRolePermissionsRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyEmployeeRolePermissions = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<CompanyEmployeeRolePermissionsDto>
  ): SWRResponse<CompanyEmployeeRolePermissionsDto> => {
    return useSWR<CompanyEmployeeRolePermissionsDto>(
      () => this.companyEmployeeRolePermissionsRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.companyEmployeeRolePermissionsFetch(context)),
      config
    );
  };

  /**
   */
  protected companyEmployeeRoleRemoveRequestOpts = (
    requestParameters: CompanyEmployeeRoleRemoveRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyEmployeeRoleRemove."
      );
    }

    if (requestParameters.companyEmployeeRoleId === null || requestParameters.companyEmployeeRoleId === undefined) {
      throw new runtime.RequiredError(
        "companyEmployeeRoleId",
        "Required parameter requestParameters.companyEmployeeRoleId was null or undefined when calling companyEmployeeRoleRemove."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/rbac/role/{companyEmployeeRoleId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"companyEmployeeRoleId"}}`, encodeURIComponent(String(requestParameters.companyEmployeeRoleId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyEmployeeRoleRemoveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyEmployeeRoleRemoveRaw = async (
    requestParameters: CompanyEmployeeRoleRemoveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyEmployeeRoleRemoveRequestOpts(requestParameters);
    return this.companyEmployeeRoleRemoveFetch(context, initOverrides);
  };

  /**
   */
  companyEmployeeRoleRemove = async (
    companyIdOrSlug: string,
    companyEmployeeRoleId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyEmployeeRoleRemoveRaw(
      { companyIdOrSlug: companyIdOrSlug, companyEmployeeRoleId: companyEmployeeRoleId },
      initOverrides
    );
  };
}
