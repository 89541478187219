/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanStateDto,
  MonthPlanStateDtoFromJSON,
  MonthPlanStateDtoFromJSONTyped,
  MonthPlanStateDtoToJSON,
} from "./MonthPlanStateDto";
import {
  MonthPlanStatus,
  MonthPlanStatusFromJSON,
  MonthPlanStatusFromJSONTyped,
  MonthPlanStatusToJSON,
} from "./MonthPlanStatus";

/**
 *
 * @export
 * @interface MonthPlanFullDto
 */
export interface MonthPlanFullDto {
  /**
   *
   * @type {MonthPlanStatus}
   * @memberof MonthPlanFullDto
   */
  status: MonthPlanStatus;
  /**
   *
   * @type {string}
   * @memberof MonthPlanFullDto
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanFullDto
   */
  createdAt: Date;
  /**
   *
   * @type {string}
   * @memberof MonthPlanFullDto
   */
  companyId: string;
  /**
   *
   * @type {number}
   * @memberof MonthPlanFullDto
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanFullDto
   */
  year: number;
  /**
   *
   * @type {MonthPlanStateDto}
   * @memberof MonthPlanFullDto
   */
  state: MonthPlanStateDto;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanFullDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanFullDto
   */
  to: Date;
}

export function MonthPlanFullDtoFromJSON(json: any): MonthPlanFullDto {
  return MonthPlanFullDtoFromJSONTyped(json, false);
}

export function MonthPlanFullDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanFullDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: MonthPlanStatusFromJSON(json["status"]),
    id: json["id"],
    createdAt: new Date(json["createdAt"]),
    companyId: json["companyId"],
    month: json["month"],
    year: json["year"],
    state: MonthPlanStateDtoFromJSON(json["state"]),
    from: new Date(json["from"]),
    to: new Date(json["to"]),
  };
}

export function MonthPlanFullDtoToJSON(value?: MonthPlanFullDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: MonthPlanStatusToJSON(value.status),
    id: value.id,
    createdAt: value.createdAt.toISOString(),
    companyId: value.companyId,
    month: value.month,
    year: value.year,
    state: MonthPlanStateDtoToJSON(value.state),
    from: value.from.toISOString(),
    to: value.to.toISOString(),
  };
}
