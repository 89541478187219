/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OpenedFundingRequestReportFundDto,
  OpenedFundingRequestReportFundDtoFromJSON,
  OpenedFundingRequestReportFundDtoFromJSONTyped,
  OpenedFundingRequestReportFundDtoToJSON,
} from "./OpenedFundingRequestReportFundDto";

/**
 *
 * @export
 * @interface OpenedFundingRequestReportFundGroupDto
 */
export interface OpenedFundingRequestReportFundGroupDto {
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundGroupDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundGroupDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundGroupDto
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundGroupDto
   */
  approvedAmount: number;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundGroupDto
   */
  paidAmount: number;
  /**
   *
   * @type {Array<OpenedFundingRequestReportFundDto>}
   * @memberof OpenedFundingRequestReportFundGroupDto
   */
  funds: Array<OpenedFundingRequestReportFundDto>;
}

export function OpenedFundingRequestReportFundGroupDtoFromJSON(json: any): OpenedFundingRequestReportFundGroupDto {
  return OpenedFundingRequestReportFundGroupDtoFromJSONTyped(json, false);
}

export function OpenedFundingRequestReportFundGroupDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpenedFundingRequestReportFundGroupDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    amount: json["amount"],
    approvedAmount: json["approvedAmount"],
    paidAmount: json["paidAmount"],
    funds: (json["funds"] as Array<any>).map(OpenedFundingRequestReportFundDtoFromJSON),
  };
}

export function OpenedFundingRequestReportFundGroupDtoToJSON(
  value?: OpenedFundingRequestReportFundGroupDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    amount: value.amount,
    approvedAmount: value.approvedAmount,
    paidAmount: value.paidAmount,
    funds: (value.funds as Array<any>).map(OpenedFundingRequestReportFundDtoToJSON),
  };
}
