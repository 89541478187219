import { useEffect, useState } from "react";
import { getApiKey, onApiKeyChange } from "shared/api";

export const useAuthed = () => {
  const [authed, setAuthed] = useState(() => !!getApiKey());

  useEffect(() => {
    const unsubscribe = onApiKeyChange((apiKey) => setAuthed(!!apiKey));
    return () => unsubscribe();
  });

  return authed;
};
