/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinancialPlanAddLoanDto
 */
export interface FinancialPlanAddLoanDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanAddLoanDto
   */
  fromDeferredIncomeId: string | null;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanAddLoanDto
   */
  fromFundId: string | null;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanAddLoanDto
   */
  toFundId: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanAddLoanDto
   */
  amount: number;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanAddLoanDto
   */
  returnAt: Date;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanAddLoanDto
   */
  comment: string;
}

export function FinancialPlanAddLoanDtoFromJSON(json: any): FinancialPlanAddLoanDto {
  return FinancialPlanAddLoanDtoFromJSONTyped(json, false);
}

export function FinancialPlanAddLoanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanAddLoanDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fromDeferredIncomeId: json["fromDeferredIncomeId"],
    fromFundId: json["fromFundId"],
    toFundId: json["toFundId"],
    amount: json["amount"],
    returnAt: new Date(json["returnAt"]),
    comment: json["comment"],
  };
}

export function FinancialPlanAddLoanDtoToJSON(value?: FinancialPlanAddLoanDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fromDeferredIncomeId: value.fromDeferredIncomeId,
    fromFundId: value.fromFundId,
    toFundId: value.toFundId,
    amount: value.amount,
    returnAt: value.returnAt.toISOString(),
    comment: value.comment,
  };
}
