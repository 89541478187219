/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CompanyEmployeeChangeRoleRequestDto
 */
export interface CompanyEmployeeChangeRoleRequestDto {
  /**
   *
   * @type {string}
   * @memberof CompanyEmployeeChangeRoleRequestDto
   */
  roleId: string;
}

export function CompanyEmployeeChangeRoleRequestDtoFromJSON(json: any): CompanyEmployeeChangeRoleRequestDto {
  return CompanyEmployeeChangeRoleRequestDtoFromJSONTyped(json, false);
}

export function CompanyEmployeeChangeRoleRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyEmployeeChangeRoleRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    roleId: json["roleId"],
  };
}

export function CompanyEmployeeChangeRoleRequestDtoToJSON(value?: CompanyEmployeeChangeRoleRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    roleId: value.roleId,
  };
}
