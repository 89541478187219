/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MovementOnFundsReportOperationFundDto,
  MovementOnFundsReportOperationFundDtoFromJSON,
  MovementOnFundsReportOperationFundDtoFromJSONTyped,
  MovementOnFundsReportOperationFundDtoToJSON,
} from "./MovementOnFundsReportOperationFundDto";

/**
 *
 * @export
 * @interface MovementOnFundsReportFundOperationDtoTransfer
 */
export interface MovementOnFundsReportFundOperationDtoTransfer {
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportFundOperationDtoTransfer
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundOperationDtoTransfer
   */
  number: number;
  /**
   *
   * @type {Date}
   * @memberof MovementOnFundsReportFundOperationDtoTransfer
   */
  createdAt: Date;
  /**
   *
   * @type {MovementOnFundsReportOperationFundDto}
   * @memberof MovementOnFundsReportFundOperationDtoTransfer
   */
  fromFund: MovementOnFundsReportOperationFundDto;
  /**
   *
   * @type {MovementOnFundsReportOperationFundDto}
   * @memberof MovementOnFundsReportFundOperationDtoTransfer
   */
  toFund: MovementOnFundsReportOperationFundDto;
}

export function MovementOnFundsReportFundOperationDtoTransferFromJSON(
  json: any
): MovementOnFundsReportFundOperationDtoTransfer {
  return MovementOnFundsReportFundOperationDtoTransferFromJSONTyped(json, false);
}

export function MovementOnFundsReportFundOperationDtoTransferFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MovementOnFundsReportFundOperationDtoTransfer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    number: json["number"],
    createdAt: new Date(json["createdAt"]),
    fromFund: MovementOnFundsReportOperationFundDtoFromJSON(json["fromFund"]),
    toFund: MovementOnFundsReportOperationFundDtoFromJSON(json["toFund"]),
  };
}

export function MovementOnFundsReportFundOperationDtoTransferToJSON(
  value?: MovementOnFundsReportFundOperationDtoTransfer | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    number: value.number,
    createdAt: value.createdAt.toISOString(),
    fromFund: MovementOnFundsReportOperationFundDtoToJSON(value.fromFund),
    toFund: MovementOnFundsReportOperationFundDtoToJSON(value.toFund),
  };
}
