/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CompanyEmployeeRoleType,
  CompanyEmployeeRoleTypeFromJSON,
  CompanyEmployeeRoleTypeFromJSONTyped,
  CompanyEmployeeRoleTypeToJSON,
} from "./CompanyEmployeeRoleType";
import {
  CompanyRolePermission,
  CompanyRolePermissionFromJSON,
  CompanyRolePermissionFromJSONTyped,
  CompanyRolePermissionToJSON,
} from "./CompanyRolePermission";

/**
 *
 * @export
 * @interface CompanyEmployeeRoleDto
 */
export interface CompanyEmployeeRoleDto {
  /**
   *
   * @type {Array<CompanyRolePermission>}
   * @memberof CompanyEmployeeRoleDto
   */
  permissions: Array<CompanyRolePermission>;
  /**
   *
   * @type {CompanyEmployeeRoleType}
   * @memberof CompanyEmployeeRoleDto
   */
  type: CompanyEmployeeRoleType;
  /**
   *
   * @type {string}
   * @memberof CompanyEmployeeRoleDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CompanyEmployeeRoleDto
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof CompanyEmployeeRoleDto
   */
  createdAt: Date;
}

export function CompanyEmployeeRoleDtoFromJSON(json: any): CompanyEmployeeRoleDto {
  return CompanyEmployeeRoleDtoFromJSONTyped(json, false);
}

export function CompanyEmployeeRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyEmployeeRoleDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    permissions: (json["permissions"] as Array<any>).map(CompanyRolePermissionFromJSON),
    type: CompanyEmployeeRoleTypeFromJSON(json["type"]),
    id: json["id"],
    name: json["name"],
    createdAt: new Date(json["createdAt"]),
  };
}

export function CompanyEmployeeRoleDtoToJSON(value?: CompanyEmployeeRoleDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    permissions: (value.permissions as Array<any>).map(CompanyRolePermissionToJSON),
    type: CompanyEmployeeRoleTypeToJSON(value.type),
    id: value.id,
    name: value.name,
    createdAt: value.createdAt.toISOString(),
  };
}
