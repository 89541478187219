/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface DeferredIncomeExistsDto
 */
export interface DeferredIncomeExistsDto {
  /**
   *
   * @type {boolean}
   * @memberof DeferredIncomeExistsDto
   */
  _exists: boolean;
}

export function DeferredIncomeExistsDtoFromJSON(json: any): DeferredIncomeExistsDto {
  return DeferredIncomeExistsDtoFromJSONTyped(json, false);
}

export function DeferredIncomeExistsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeferredIncomeExistsDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    _exists: json["exists"],
  };
}

export function DeferredIncomeExistsDtoToJSON(value?: DeferredIncomeExistsDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    exists: value._exists,
  };
}
