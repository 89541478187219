/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserProfileUpdateRequestDto
 */
export interface UserProfileUpdateRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserProfileUpdateRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileUpdateRequestDto
   */
  avatarImageId: string | null;
}

export function UserProfileUpdateRequestDtoFromJSON(json: any): UserProfileUpdateRequestDto {
  return UserProfileUpdateRequestDtoFromJSONTyped(json, false);
}

export function UserProfileUpdateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserProfileUpdateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    avatarImageId: json["avatarImageId"],
  };
}

export function UserProfileUpdateRequestDtoToJSON(value?: UserProfileUpdateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    avatarImageId: value.avatarImageId,
  };
}
