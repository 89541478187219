/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  FundingRequestPayoutDto,
  FundingRequestPayoutDtoFromJSON,
  FundingRequestPayoutDtoToJSON,
  FundingRequestPayoutGroupRequestDto,
  FundingRequestPayoutGroupRequestDtoFromJSON,
  FundingRequestPayoutGroupRequestDtoToJSON,
} from "../models";

export interface FundingRequestPayoutCreateRequest {
  companyIdOrSlug: string;
  fundingRequestPayoutGroupRequestDto: FundingRequestPayoutGroupRequestDto;
}

export interface FundingRequestPayoutListRequest {
  companyIdOrSlug: string;
  fundId?: string;
  search?: string;
  from?: Date;
  to?: Date;
}

/**
 *
 */
export class FundingRequestPayoutApi extends runtime.BaseAPI {
  /**
   */
  protected fundingRequestPayoutCreateRequestOpts = (
    requestParameters: FundingRequestPayoutCreateRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestPayoutCreate."
      );
    }

    if (
      requestParameters.fundingRequestPayoutGroupRequestDto === null ||
      requestParameters.fundingRequestPayoutGroupRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "fundingRequestPayoutGroupRequestDto",
        "Required parameter requestParameters.fundingRequestPayoutGroupRequestDto was null or undefined when calling fundingRequestPayoutCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request-payout`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundingRequestPayoutGroupRequestDtoToJSON(requestParameters.fundingRequestPayoutGroupRequestDto),
    };
  };

  /**
   */
  protected fundingRequestPayoutCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundingRequestPayoutCreateRaw = async (
    requestParameters: FundingRequestPayoutCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundingRequestPayoutCreateRequestOpts(requestParameters);
    return this.fundingRequestPayoutCreateFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestPayoutCreate = async (
    companyIdOrSlug: string,
    fundingRequestPayoutGroupRequestDto: FundingRequestPayoutGroupRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundingRequestPayoutCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, fundingRequestPayoutGroupRequestDto: fundingRequestPayoutGroupRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected fundingRequestPayoutListRequestOpts = (
    requestParameters: FundingRequestPayoutListRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundingRequestPayoutList."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fundId !== undefined) {
      queryParameters["fundId"] = requestParameters.fundId;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/funding-request-payout`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundingRequestPayoutListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundingRequestPayoutDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundingRequestPayoutDtoFromJSON));
  };

  /**
   */
  protected fundingRequestPayoutListRaw = async (
    requestParameters: FundingRequestPayoutListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundingRequestPayoutDto>>> => {
    const context = this.fundingRequestPayoutListRequestOpts(requestParameters);
    return this.fundingRequestPayoutListFetch(context, initOverrides);
  };

  /**
   */
  fundingRequestPayoutList = async (
    companyIdOrSlug: string,
    fundId?: string,
    search?: string,
    from?: Date,
    to?: Date,
    initOverrides?: RequestInit
  ): Promise<Array<FundingRequestPayoutDto>> => {
    const response = await this.fundingRequestPayoutListRaw(
      { companyIdOrSlug: companyIdOrSlug, fundId: fundId, search: search, from: from, to: to },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFundingRequestPayoutList = (
    requestParameters: FundingRequestPayoutListRequest,
    config?: SWRConfiguration<Array<FundingRequestPayoutDto>>
  ): SWRResponse<Array<FundingRequestPayoutDto>> => {
    return useSWR<Array<FundingRequestPayoutDto>>(
      () => this.fundingRequestPayoutListRequestOpts(requestParameters),
      (context) => this.swrFetch(this.fundingRequestPayoutListFetch(context)),
      config
    );
  };
}
