/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MovementOnFundsReportFundOperationDtoWithdraw
 */
export interface MovementOnFundsReportFundOperationDtoWithdraw {
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportFundOperationDtoWithdraw
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundOperationDtoWithdraw
   */
  number: number;
  /**
   *
   * @type {Date}
   * @memberof MovementOnFundsReportFundOperationDtoWithdraw
   */
  createdAt: Date;
}

export function MovementOnFundsReportFundOperationDtoWithdrawFromJSON(
  json: any
): MovementOnFundsReportFundOperationDtoWithdraw {
  return MovementOnFundsReportFundOperationDtoWithdrawFromJSONTyped(json, false);
}

export function MovementOnFundsReportFundOperationDtoWithdrawFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MovementOnFundsReportFundOperationDtoWithdraw {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    number: json["number"],
    createdAt: new Date(json["createdAt"]),
  };
}

export function MovementOnFundsReportFundOperationDtoWithdrawToJSON(
  value?: MovementOnFundsReportFundOperationDtoWithdraw | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    number: value.number,
    createdAt: value.createdAt.toISOString(),
  };
}
