/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DeferredIncomeHistoryDtoEmployee,
  DeferredIncomeHistoryDtoEmployeeFromJSON,
  DeferredIncomeHistoryDtoEmployeeFromJSONTyped,
  DeferredIncomeHistoryDtoEmployeeToJSON,
} from "./DeferredIncomeHistoryDtoEmployee";
import {
  DeferredIncomeHistoryDtoFinancialPlan,
  DeferredIncomeHistoryDtoFinancialPlanFromJSON,
  DeferredIncomeHistoryDtoFinancialPlanFromJSONTyped,
  DeferredIncomeHistoryDtoFinancialPlanToJSON,
} from "./DeferredIncomeHistoryDtoFinancialPlan";
import {
  DeferredIncomeHistoryDtoFundingRequest,
  DeferredIncomeHistoryDtoFundingRequestFromJSON,
  DeferredIncomeHistoryDtoFundingRequestFromJSONTyped,
  DeferredIncomeHistoryDtoFundingRequestToJSON,
} from "./DeferredIncomeHistoryDtoFundingRequest";
import {
  DeferredIncomeHistoryDtoLoan,
  DeferredIncomeHistoryDtoLoanFromJSON,
  DeferredIncomeHistoryDtoLoanFromJSONTyped,
  DeferredIncomeHistoryDtoLoanToJSON,
} from "./DeferredIncomeHistoryDtoLoan";
import {
  DeferredIncomeHistoryOperationType,
  DeferredIncomeHistoryOperationTypeFromJSON,
  DeferredIncomeHistoryOperationTypeFromJSONTyped,
  DeferredIncomeHistoryOperationTypeToJSON,
} from "./DeferredIncomeHistoryOperationType";

/**
 *
 * @export
 * @interface DeferredIncomeHistoryDto
 */
export interface DeferredIncomeHistoryDto {
  /**
   *
   * @type {DeferredIncomeHistoryOperationType}
   * @memberof DeferredIncomeHistoryDto
   */
  type: DeferredIncomeHistoryOperationType;
  /**
   *
   * @type {string}
   * @memberof DeferredIncomeHistoryDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof DeferredIncomeHistoryDto
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof DeferredIncomeHistoryDto
   */
  balanceBefore: number;
  /**
   *
   * @type {number}
   * @memberof DeferredIncomeHistoryDto
   */
  balanceAfter: number;
  /**
   *
   * @type {number}
   * @memberof DeferredIncomeHistoryDto
   */
  reservedBefore: number;
  /**
   *
   * @type {number}
   * @memberof DeferredIncomeHistoryDto
   */
  reservedAfter: number;
  /**
   *
   * @type {Date}
   * @memberof DeferredIncomeHistoryDto
   */
  createdAt: Date;
  /**
   *
   * @type {DeferredIncomeHistoryDtoFinancialPlan}
   * @memberof DeferredIncomeHistoryDto
   */
  financialPlan: DeferredIncomeHistoryDtoFinancialPlan | null;
  /**
   *
   * @type {DeferredIncomeHistoryDtoFundingRequest}
   * @memberof DeferredIncomeHistoryDto
   */
  fundingRequest: DeferredIncomeHistoryDtoFundingRequest | null;
  /**
   *
   * @type {DeferredIncomeHistoryDtoLoan}
   * @memberof DeferredIncomeHistoryDto
   */
  loan: DeferredIncomeHistoryDtoLoan | null;
  /**
   *
   * @type {DeferredIncomeHistoryDtoEmployee}
   * @memberof DeferredIncomeHistoryDto
   */
  employee: DeferredIncomeHistoryDtoEmployee | null;
}

export function DeferredIncomeHistoryDtoFromJSON(json: any): DeferredIncomeHistoryDto {
  return DeferredIncomeHistoryDtoFromJSONTyped(json, false);
}

export function DeferredIncomeHistoryDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeferredIncomeHistoryDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: DeferredIncomeHistoryOperationTypeFromJSON(json["type"]),
    id: json["id"],
    amount: json["amount"],
    balanceBefore: json["balanceBefore"],
    balanceAfter: json["balanceAfter"],
    reservedBefore: json["reservedBefore"],
    reservedAfter: json["reservedAfter"],
    createdAt: new Date(json["createdAt"]),
    financialPlan: DeferredIncomeHistoryDtoFinancialPlanFromJSON(json["financialPlan"]),
    fundingRequest: DeferredIncomeHistoryDtoFundingRequestFromJSON(json["fundingRequest"]),
    loan: DeferredIncomeHistoryDtoLoanFromJSON(json["loan"]),
    employee: DeferredIncomeHistoryDtoEmployeeFromJSON(json["employee"]),
  };
}

export function DeferredIncomeHistoryDtoToJSON(value?: DeferredIncomeHistoryDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: DeferredIncomeHistoryOperationTypeToJSON(value.type),
    id: value.id,
    amount: value.amount,
    balanceBefore: value.balanceBefore,
    balanceAfter: value.balanceAfter,
    reservedBefore: value.reservedBefore,
    reservedAfter: value.reservedAfter,
    createdAt: value.createdAt.toISOString(),
    financialPlan: DeferredIncomeHistoryDtoFinancialPlanToJSON(value.financialPlan),
    fundingRequest: DeferredIncomeHistoryDtoFundingRequestToJSON(value.fundingRequest),
    loan: DeferredIncomeHistoryDtoLoanToJSON(value.loan),
    employee: DeferredIncomeHistoryDtoEmployeeToJSON(value.employee),
  };
}
