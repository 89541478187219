import { DateTime, DurationLike } from "luxon";

declare global {
  interface Date {
    minus(duration: DurationLike): Date;

    plus(duration: DurationLike): Date;
  }
}

Date.prototype.minus = function (duration) {
  return DateTime.fromJSDate(this).minus(duration).toJSDate();
};

Date.prototype.plus = function (duration) {
  return DateTime.fromJSDate(this).plus(duration).toJSDate();
};
