/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { CompanyInviteDto, CompanyInviteDtoFromJSON, CompanyInviteDtoToJSON } from "../models";

export interface CompanyInviteInviteRequest {
  companyIdOrSlug: string;
}

export interface CompanyInviteResetRequest {
  companyIdOrSlug: string;
}

/**
 *
 */
export class CompanyInviteApi extends runtime.BaseAPI {
  /**
   */
  protected companyInviteInviteRequestOpts = (requestParameters: CompanyInviteInviteRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyInviteInvite."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/invite`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyInviteInviteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyInviteDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyInviteDtoFromJSON(jsonValue));
  };

  /**
   */
  protected companyInviteInviteRaw = async (
    requestParameters: CompanyInviteInviteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyInviteDto>> => {
    const context = this.companyInviteInviteRequestOpts(requestParameters);
    return this.companyInviteInviteFetch(context, initOverrides);
  };

  /**
   */
  companyInviteInvite = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<CompanyInviteDto> => {
    const response = await this.companyInviteInviteRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyInviteInvite = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<CompanyInviteDto>
  ): SWRResponse<CompanyInviteDto> => {
    return useSWR<CompanyInviteDto>(
      () => this.companyInviteInviteRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.companyInviteInviteFetch(context)),
      config
    );
  };

  /**
   */
  protected companyInviteResetRequestOpts = (requestParameters: CompanyInviteResetRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyInviteReset."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/invite/reset`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyInviteResetFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyInviteResetRaw = async (
    requestParameters: CompanyInviteResetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyInviteResetRequestOpts(requestParameters);
    return this.companyInviteResetFetch(context, initOverrides);
  };

  /**
   */
  companyInviteReset = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<void> => {
    await this.companyInviteResetRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
  };
}
