/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanFactDeferredIncomeDto,
  FinancialPlanFactDeferredIncomeDtoFromJSON,
  FinancialPlanFactDeferredIncomeDtoFromJSONTyped,
  FinancialPlanFactDeferredIncomeDtoToJSON,
} from "./FinancialPlanFactDeferredIncomeDto";
import {
  FinancialPlanFactIncomeDto,
  FinancialPlanFactIncomeDtoFromJSON,
  FinancialPlanFactIncomeDtoFromJSONTyped,
  FinancialPlanFactIncomeDtoToJSON,
} from "./FinancialPlanFactIncomeDto";
import {
  FinancialPlanStatus,
  FinancialPlanStatusFromJSON,
  FinancialPlanStatusFromJSONTyped,
  FinancialPlanStatusToJSON,
} from "./FinancialPlanStatus";
import {
  MonthPlanStateDto,
  MonthPlanStateDtoFromJSON,
  MonthPlanStateDtoFromJSONTyped,
  MonthPlanStateDtoToJSON,
} from "./MonthPlanStateDto";

/**
 *
 * @export
 * @interface FinancialPlanDto
 */
export interface FinancialPlanDto {
  /**
   *
   * @type {FinancialPlanStatus}
   * @memberof FinancialPlanDto
   */
  status: FinancialPlanStatus;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanDto
   */
  monthPlanId: string;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanDto
   */
  monthPlanWeekNumber: number;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanDto
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanDto
   */
  to: Date;
  /**
   *
   * @type {boolean}
   * @memberof FinancialPlanDto
   */
  closesWeek: boolean;
  /**
   *
   * @type {Array<FinancialPlanFactIncomeDto>}
   * @memberof FinancialPlanDto
   */
  factIncomes: Array<FinancialPlanFactIncomeDto>;
  /**
   *
   * @type {Array<FinancialPlanFactDeferredIncomeDto>}
   * @memberof FinancialPlanDto
   */
  factDeferredIncomes: Array<FinancialPlanFactDeferredIncomeDto>;
  /**
   *
   * @type {MonthPlanStateDto}
   * @memberof FinancialPlanDto
   */
  state: MonthPlanStateDto;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanDto
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanDto
   */
  year: number;
}

export function FinancialPlanDtoFromJSON(json: any): FinancialPlanDto {
  return FinancialPlanDtoFromJSONTyped(json, false);
}

export function FinancialPlanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: FinancialPlanStatusFromJSON(json["status"]),
    id: json["id"],
    monthPlanId: json["monthPlanId"],
    monthPlanWeekNumber: json["monthPlanWeekNumber"],
    createdAt: new Date(json["createdAt"]),
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    closesWeek: json["closesWeek"],
    factIncomes: (json["factIncomes"] as Array<any>).map(FinancialPlanFactIncomeDtoFromJSON),
    factDeferredIncomes: (json["factDeferredIncomes"] as Array<any>).map(FinancialPlanFactDeferredIncomeDtoFromJSON),
    state: MonthPlanStateDtoFromJSON(json["state"]),
    month: json["month"],
    year: json["year"],
  };
}

export function FinancialPlanDtoToJSON(value?: FinancialPlanDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: FinancialPlanStatusToJSON(value.status),
    id: value.id,
    monthPlanId: value.monthPlanId,
    monthPlanWeekNumber: value.monthPlanWeekNumber,
    createdAt: value.createdAt.toISOString(),
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    closesWeek: value.closesWeek,
    factIncomes: (value.factIncomes as Array<any>).map(FinancialPlanFactIncomeDtoToJSON),
    factDeferredIncomes: (value.factDeferredIncomes as Array<any>).map(FinancialPlanFactDeferredIncomeDtoToJSON),
    state: MonthPlanStateDtoToJSON(value.state),
    month: value.month,
    year: value.year,
  };
}
