/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CompanyType {
  PAID = "PAID",
  FREE = "FREE",
}

export function CompanyTypeFromJSON(json: any): CompanyType {
  return CompanyTypeFromJSONTyped(json, false);
}

export function CompanyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyType {
  return json as CompanyType;
}

export function CompanyTypeToJSON(value?: CompanyType | null): any {
  return value as any;
}
