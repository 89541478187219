/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  AdminCompanyAddRemoveCouchRequestDto,
  AdminCompanyAddRemoveCouchRequestDtoFromJSON,
  AdminCompanyAddRemoveCouchRequestDtoToJSON,
  AdminCompanyAddRemoveCourseRequestDto,
  AdminCompanyAddRemoveCourseRequestDtoFromJSON,
  AdminCompanyAddRemoveCourseRequestDtoToJSON,
  AdminCompanyChangeInternalNameAndDescriptionRequestDto,
  AdminCompanyChangeInternalNameAndDescriptionRequestDtoFromJSON,
  AdminCompanyChangeInternalNameAndDescriptionRequestDtoToJSON,
  AdminCompanyDto,
  AdminCompanyDtoFromJSON,
  AdminCompanyDtoToJSON,
  AdminCompanyProlongRequestDto,
  AdminCompanyProlongRequestDtoFromJSON,
  AdminCompanyProlongRequestDtoToJSON,
  CompanyType,
  CompanyTypeFromJSON,
  CompanyTypeToJSON,
} from "../models";

export interface AdminCompanyAddCouchRequest {
  companyIdOrSlug: string;
  adminCompanyAddRemoveCouchRequestDto: AdminCompanyAddRemoveCouchRequestDto;
}

export interface AdminCompanyAddCourseRequest {
  companyIdOrSlug: string;
  adminCompanyAddRemoveCourseRequestDto: AdminCompanyAddRemoveCourseRequestDto;
}

export interface AdminCompanyArchiveRequest {
  companyIdOrSlug: string;
}

export interface AdminCompanyChangeInternalNameAndDescriptionRequest {
  companyIdOrSlug: string;
  adminCompanyChangeInternalNameAndDescriptionRequestDto: AdminCompanyChangeInternalNameAndDescriptionRequestDto;
}

export interface AdminCompanyGetRequest {
  companyIdOrSlug: string;
}

export interface AdminCompanyListRequest {
  type?: CompanyType;
  hasAccess?: boolean;
  active?: boolean;
  adminId?: string;
  search?: string;
}

export interface AdminCompanyProlongRequest {
  companyIdOrSlug: string;
  adminCompanyProlongRequestDto: AdminCompanyProlongRequestDto;
}

export interface AdminCompanyRemoveCouchRequest {
  companyIdOrSlug: string;
  adminCompanyAddRemoveCouchRequestDto: AdminCompanyAddRemoveCouchRequestDto;
}

export interface AdminCompanyRemoveCourseRequest {
  companyIdOrSlug: string;
  adminCompanyAddRemoveCourseRequestDto: AdminCompanyAddRemoveCourseRequestDto;
}

export interface AdminCompanyUnArchiveRequest {
  companyIdOrSlug: string;
}

/**
 *
 */
export class AdminCompanyApi extends runtime.BaseAPI {
  /**
   */
  protected adminCompanyAddCouchRequestOpts = (requestParameters: AdminCompanyAddCouchRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyAddCouch."
      );
    }

    if (
      requestParameters.adminCompanyAddRemoveCouchRequestDto === null ||
      requestParameters.adminCompanyAddRemoveCouchRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyAddRemoveCouchRequestDto",
        "Required parameter requestParameters.adminCompanyAddRemoveCouchRequestDto was null or undefined when calling adminCompanyAddCouch."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/add-couch`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyAddRemoveCouchRequestDtoToJSON(requestParameters.adminCompanyAddRemoveCouchRequestDto),
    };
  };

  /**
   */
  protected adminCompanyAddCouchFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyAddCouchRaw = async (
    requestParameters: AdminCompanyAddCouchRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyAddCouchRequestOpts(requestParameters);
    return this.adminCompanyAddCouchFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyAddCouch = async (
    companyIdOrSlug: string,
    adminCompanyAddRemoveCouchRequestDto: AdminCompanyAddRemoveCouchRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyAddCouchRaw(
      { companyIdOrSlug: companyIdOrSlug, adminCompanyAddRemoveCouchRequestDto: adminCompanyAddRemoveCouchRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyAddCourseRequestOpts = (
    requestParameters: AdminCompanyAddCourseRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyAddCourse."
      );
    }

    if (
      requestParameters.adminCompanyAddRemoveCourseRequestDto === null ||
      requestParameters.adminCompanyAddRemoveCourseRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyAddRemoveCourseRequestDto",
        "Required parameter requestParameters.adminCompanyAddRemoveCourseRequestDto was null or undefined when calling adminCompanyAddCourse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/add-course`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyAddRemoveCourseRequestDtoToJSON(requestParameters.adminCompanyAddRemoveCourseRequestDto),
    };
  };

  /**
   */
  protected adminCompanyAddCourseFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyAddCourseRaw = async (
    requestParameters: AdminCompanyAddCourseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyAddCourseRequestOpts(requestParameters);
    return this.adminCompanyAddCourseFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyAddCourse = async (
    companyIdOrSlug: string,
    adminCompanyAddRemoveCourseRequestDto: AdminCompanyAddRemoveCourseRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyAddCourseRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        adminCompanyAddRemoveCourseRequestDto: adminCompanyAddRemoveCourseRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyArchiveRequestOpts = (requestParameters: AdminCompanyArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/archive`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyArchiveRaw = async (
    requestParameters: AdminCompanyArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyArchiveRequestOpts(requestParameters);
    return this.adminCompanyArchiveFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyArchive = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<void> => {
    await this.adminCompanyArchiveRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
  };

  /**
   */
  protected adminCompanyChangeInternalNameAndDescriptionRequestOpts = (
    requestParameters: AdminCompanyChangeInternalNameAndDescriptionRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyChangeInternalNameAndDescription."
      );
    }

    if (
      requestParameters.adminCompanyChangeInternalNameAndDescriptionRequestDto === null ||
      requestParameters.adminCompanyChangeInternalNameAndDescriptionRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyChangeInternalNameAndDescriptionRequestDto",
        "Required parameter requestParameters.adminCompanyChangeInternalNameAndDescriptionRequestDto was null or undefined when calling adminCompanyChangeInternalNameAndDescription."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/change-internal-name-and-description`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyChangeInternalNameAndDescriptionRequestDtoToJSON(
        requestParameters.adminCompanyChangeInternalNameAndDescriptionRequestDto
      ),
    };
  };

  /**
   */
  protected adminCompanyChangeInternalNameAndDescriptionFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyChangeInternalNameAndDescriptionRaw = async (
    requestParameters: AdminCompanyChangeInternalNameAndDescriptionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyChangeInternalNameAndDescriptionRequestOpts(requestParameters);
    return this.adminCompanyChangeInternalNameAndDescriptionFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyChangeInternalNameAndDescription = async (
    companyIdOrSlug: string,
    adminCompanyChangeInternalNameAndDescriptionRequestDto: AdminCompanyChangeInternalNameAndDescriptionRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyChangeInternalNameAndDescriptionRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        adminCompanyChangeInternalNameAndDescriptionRequestDto: adminCompanyChangeInternalNameAndDescriptionRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyGetRequestOpts = (requestParameters: AdminCompanyGetRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyGetFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AdminCompanyDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => AdminCompanyDtoFromJSON(jsonValue));
  };

  /**
   */
  protected adminCompanyGetRaw = async (
    requestParameters: AdminCompanyGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AdminCompanyDto>> => {
    const context = this.adminCompanyGetRequestOpts(requestParameters);
    return this.adminCompanyGetFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyGet = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<AdminCompanyDto> => {
    const response = await this.adminCompanyGetRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useAdminCompanyGet = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<AdminCompanyDto>
  ): SWRResponse<AdminCompanyDto> => {
    return useSWR<AdminCompanyDto>(
      () => this.adminCompanyGetRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.adminCompanyGetFetch(context)),
      config
    );
  };

  /**
   */
  protected adminCompanyListRequestOpts = (requestParameters: AdminCompanyListRequest): runtime.RequestOpts => {
    const queryParameters: any = {};

    if (requestParameters.type !== undefined) {
      queryParameters["type"] = requestParameters.type;
    }

    if (requestParameters.hasAccess !== undefined) {
      queryParameters["hasAccess"] = requestParameters.hasAccess;
    }

    if (requestParameters.active !== undefined) {
      queryParameters["active"] = requestParameters.active;
    }

    if (requestParameters.adminId !== undefined) {
      queryParameters["adminId"] = requestParameters.adminId;
    }

    if (requestParameters.search !== undefined) {
      queryParameters["search"] = requestParameters.search;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminCompanyDtoFromJSON));
  };

  /**
   */
  protected adminCompanyListRaw = async (
    requestParameters: AdminCompanyListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCompanyDto>>> => {
    const context = this.adminCompanyListRequestOpts(requestParameters);
    return this.adminCompanyListFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyList = async (
    type?: CompanyType,
    hasAccess?: boolean,
    active?: boolean,
    adminId?: string,
    search?: string,
    initOverrides?: RequestInit
  ): Promise<Array<AdminCompanyDto>> => {
    const response = await this.adminCompanyListRaw(
      { type: type, hasAccess: hasAccess, active: active, adminId: adminId, search: search },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useAdminCompanyList = (
    requestParameters: AdminCompanyListRequest = {},
    config?: SWRConfiguration<Array<AdminCompanyDto>>
  ): SWRResponse<Array<AdminCompanyDto>> => {
    return useSWR<Array<AdminCompanyDto>>(
      () => this.adminCompanyListRequestOpts(requestParameters),
      (context) => this.swrFetch(this.adminCompanyListFetch(context)),
      config
    );
  };

  /**
   */
  protected adminCompanyProlongRequestOpts = (requestParameters: AdminCompanyProlongRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyProlong."
      );
    }

    if (
      requestParameters.adminCompanyProlongRequestDto === null ||
      requestParameters.adminCompanyProlongRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyProlongRequestDto",
        "Required parameter requestParameters.adminCompanyProlongRequestDto was null or undefined when calling adminCompanyProlong."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/prolong`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyProlongRequestDtoToJSON(requestParameters.adminCompanyProlongRequestDto),
    };
  };

  /**
   */
  protected adminCompanyProlongFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyProlongRaw = async (
    requestParameters: AdminCompanyProlongRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyProlongRequestOpts(requestParameters);
    return this.adminCompanyProlongFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyProlong = async (
    companyIdOrSlug: string,
    adminCompanyProlongRequestDto: AdminCompanyProlongRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyProlongRaw(
      { companyIdOrSlug: companyIdOrSlug, adminCompanyProlongRequestDto: adminCompanyProlongRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyRemoveCouchRequestOpts = (
    requestParameters: AdminCompanyRemoveCouchRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyRemoveCouch."
      );
    }

    if (
      requestParameters.adminCompanyAddRemoveCouchRequestDto === null ||
      requestParameters.adminCompanyAddRemoveCouchRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyAddRemoveCouchRequestDto",
        "Required parameter requestParameters.adminCompanyAddRemoveCouchRequestDto was null or undefined when calling adminCompanyRemoveCouch."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/remove-couch`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyAddRemoveCouchRequestDtoToJSON(requestParameters.adminCompanyAddRemoveCouchRequestDto),
    };
  };

  /**
   */
  protected adminCompanyRemoveCouchFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyRemoveCouchRaw = async (
    requestParameters: AdminCompanyRemoveCouchRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyRemoveCouchRequestOpts(requestParameters);
    return this.adminCompanyRemoveCouchFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyRemoveCouch = async (
    companyIdOrSlug: string,
    adminCompanyAddRemoveCouchRequestDto: AdminCompanyAddRemoveCouchRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyRemoveCouchRaw(
      { companyIdOrSlug: companyIdOrSlug, adminCompanyAddRemoveCouchRequestDto: adminCompanyAddRemoveCouchRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyRemoveCourseRequestOpts = (
    requestParameters: AdminCompanyRemoveCourseRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyRemoveCourse."
      );
    }

    if (
      requestParameters.adminCompanyAddRemoveCourseRequestDto === null ||
      requestParameters.adminCompanyAddRemoveCourseRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCompanyAddRemoveCourseRequestDto",
        "Required parameter requestParameters.adminCompanyAddRemoveCourseRequestDto was null or undefined when calling adminCompanyRemoveCourse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/remove-course`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCompanyAddRemoveCourseRequestDtoToJSON(requestParameters.adminCompanyAddRemoveCourseRequestDto),
    };
  };

  /**
   */
  protected adminCompanyRemoveCourseFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyRemoveCourseRaw = async (
    requestParameters: AdminCompanyRemoveCourseRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyRemoveCourseRequestOpts(requestParameters);
    return this.adminCompanyRemoveCourseFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyRemoveCourse = async (
    companyIdOrSlug: string,
    adminCompanyAddRemoveCourseRequestDto: AdminCompanyAddRemoveCourseRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.adminCompanyRemoveCourseRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        adminCompanyAddRemoveCourseRequestDto: adminCompanyAddRemoveCourseRequestDto,
      },
      initOverrides
    );
  };

  /**
   */
  protected adminCompanyUnArchiveRequestOpts = (
    requestParameters: AdminCompanyUnArchiveRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling adminCompanyUnArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/company/{companyIdOrSlug}/un-archive`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCompanyUnArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCompanyUnArchiveRaw = async (
    requestParameters: AdminCompanyUnArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCompanyUnArchiveRequestOpts(requestParameters);
    return this.adminCompanyUnArchiveFetch(context, initOverrides);
  };

  /**
   */
  adminCompanyUnArchive = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<void> => {
    await this.adminCompanyUnArchiveRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
  };
}
