/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { CompanyType, CompanyTypeFromJSON, CompanyTypeFromJSONTyped, CompanyTypeToJSON } from "./CompanyType";

/**
 *
 * @export
 * @interface AdminCompanyProlongRequestDto
 */
export interface AdminCompanyProlongRequestDto {
  /**
   *
   * @type {CompanyType}
   * @memberof AdminCompanyProlongRequestDto
   */
  type: CompanyType;
  /**
   *
   * @type {Date}
   * @memberof AdminCompanyProlongRequestDto
   */
  hasAccessUntil: Date;
}

export function AdminCompanyProlongRequestDtoFromJSON(json: any): AdminCompanyProlongRequestDto {
  return AdminCompanyProlongRequestDtoFromJSONTyped(json, false);
}

export function AdminCompanyProlongRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AdminCompanyProlongRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: CompanyTypeFromJSON(json["type"]),
    hasAccessUntil: new Date(json["hasAccessUntil"]),
  };
}

export function AdminCompanyProlongRequestDtoToJSON(value?: AdminCompanyProlongRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: CompanyTypeToJSON(value.type),
    hasAccessUntil: value.hasAccessUntil.toISOString(),
  };
}
