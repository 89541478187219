import { MonthPlanApi, MonthPlanCommonNextMonthPlanDto } from "shared/api/back/_generated";
import { mutate } from "swr";

export class MonthPlanPatchedApi extends MonthPlanApi {
  useMonthPlanCommonNextMonthPlanMutate = (companyIdOrSlug: string, year: number, month: number) => {
    return mutate<MonthPlanCommonNextMonthPlanDto>(() =>
      this.monthPlanCommonNextMonthPlanRequestOpts({ companyIdOrSlug: companyIdOrSlug, year: year, month: month })
    );
  };
}
