/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FundingRequestPaymentMethod,
  FundingRequestPaymentMethodFromJSON,
  FundingRequestPaymentMethodFromJSONTyped,
  FundingRequestPaymentMethodToJSON,
} from "./FundingRequestPaymentMethod";

/**
 *
 * @export
 * @interface FundRequestCreateUpdateRequestDto
 */
export interface FundRequestCreateUpdateRequestDto {
  /**
   *
   * @type {FundingRequestPaymentMethod}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  paymentMethod?: FundingRequestPaymentMethod;
  /**
   *
   * @type {string}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  deferredIncomeId?: string;
  /**
   *
   * @type {string}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  fundId?: string;
  /**
   *
   * @type {string}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  expenseItemId?: string | null;
  /**
   *
   * @type {number}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  amount?: number | null;
  /**
   *
   * @type {string}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  paymentReceiver?: string;
  /**
   *
   * @type {Date}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  payAt?: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  paymentFileIds?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FundRequestCreateUpdateRequestDto
   */
  paymentComment?: string | null;
}

export function FundRequestCreateUpdateRequestDtoFromJSON(json: any): FundRequestCreateUpdateRequestDto {
  return FundRequestCreateUpdateRequestDtoFromJSONTyped(json, false);
}

export function FundRequestCreateUpdateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FundRequestCreateUpdateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    paymentMethod: !exists(json, "paymentMethod")
      ? undefined
      : FundingRequestPaymentMethodFromJSON(json["paymentMethod"]),
    name: !exists(json, "name") ? undefined : json["name"],
    deferredIncomeId: !exists(json, "deferredIncomeId") ? undefined : json["deferredIncomeId"],
    fundId: !exists(json, "fundId") ? undefined : json["fundId"],
    expenseItemId: !exists(json, "expenseItemId") ? undefined : json["expenseItemId"],
    amount: !exists(json, "amount") ? undefined : json["amount"],
    description: !exists(json, "description") ? undefined : json["description"],
    paymentReceiver: !exists(json, "paymentReceiver") ? undefined : json["paymentReceiver"],
    payAt: !exists(json, "payAt") ? undefined : new Date(json["payAt"]),
    paymentFileIds: !exists(json, "paymentFileIds") ? undefined : json["paymentFileIds"],
    paymentComment: !exists(json, "paymentComment") ? undefined : json["paymentComment"],
  };
}

export function FundRequestCreateUpdateRequestDtoToJSON(value?: FundRequestCreateUpdateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    paymentMethod: FundingRequestPaymentMethodToJSON(value.paymentMethod),
    name: value.name,
    deferredIncomeId: value.deferredIncomeId,
    fundId: value.fundId,
    expenseItemId: value.expenseItemId,
    amount: value.amount,
    description: value.description,
    paymentReceiver: value.paymentReceiver,
    payAt: value.payAt === undefined ? undefined : value.payAt.toISOString(),
    paymentFileIds: value.paymentFileIds,
    paymentComment: value.paymentComment,
  };
}
