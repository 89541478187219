/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  CompanyCourseInviteActivateRequestDto,
  CompanyCourseInviteActivateRequestDtoFromJSON,
  CompanyCourseInviteActivateRequestDtoToJSON,
  CompanyCourseInviteDto,
  CompanyCourseInviteDtoFromJSON,
  CompanyCourseInviteDtoToJSON,
} from "../models";

export interface CompanyCourseInviteActivateRequest {
  invite: string;
  companyCourseInviteActivateRequestDto: CompanyCourseInviteActivateRequestDto;
}

export interface CompanyCourseInviteInviteRequest {
  invite: string;
}

/**
 *
 */
export class CompanyCourseInviteApi extends runtime.BaseAPI {
  /**
   */
  protected companyCourseInviteActivateRequestOpts = (
    requestParameters: CompanyCourseInviteActivateRequest
  ): runtime.RequestOpts => {
    if (requestParameters.invite === null || requestParameters.invite === undefined) {
      throw new runtime.RequiredError(
        "invite",
        "Required parameter requestParameters.invite was null or undefined when calling companyCourseInviteActivate."
      );
    }

    if (
      requestParameters.companyCourseInviteActivateRequestDto === null ||
      requestParameters.companyCourseInviteActivateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyCourseInviteActivateRequestDto",
        "Required parameter requestParameters.companyCourseInviteActivateRequestDto was null or undefined when calling companyCourseInviteActivate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/course-invite/{invite}`.replace(
        `{${"invite"}}`,
        encodeURIComponent(String(requestParameters.invite))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyCourseInviteActivateRequestDtoToJSON(requestParameters.companyCourseInviteActivateRequestDto),
    };
  };

  /**
   */
  protected companyCourseInviteActivateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyCourseInviteActivateRaw = async (
    requestParameters: CompanyCourseInviteActivateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyCourseInviteActivateRequestOpts(requestParameters);
    return this.companyCourseInviteActivateFetch(context, initOverrides);
  };

  /**
   */
  companyCourseInviteActivate = async (
    invite: string,
    companyCourseInviteActivateRequestDto: CompanyCourseInviteActivateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyCourseInviteActivateRaw(
      { invite: invite, companyCourseInviteActivateRequestDto: companyCourseInviteActivateRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected companyCourseInviteInviteRequestOpts = (
    requestParameters: CompanyCourseInviteInviteRequest
  ): runtime.RequestOpts => {
    if (requestParameters.invite === null || requestParameters.invite === undefined) {
      throw new runtime.RequiredError(
        "invite",
        "Required parameter requestParameters.invite was null or undefined when calling companyCourseInviteInvite."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/course-invite/{invite}`.replace(
        `{${"invite"}}`,
        encodeURIComponent(String(requestParameters.invite))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyCourseInviteInviteFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyCourseInviteDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyCourseInviteDtoFromJSON(jsonValue));
  };

  /**
   */
  protected companyCourseInviteInviteRaw = async (
    requestParameters: CompanyCourseInviteInviteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyCourseInviteDto>> => {
    const context = this.companyCourseInviteInviteRequestOpts(requestParameters);
    return this.companyCourseInviteInviteFetch(context, initOverrides);
  };

  /**
   */
  companyCourseInviteInvite = async (invite: string, initOverrides?: RequestInit): Promise<CompanyCourseInviteDto> => {
    const response = await this.companyCourseInviteInviteRaw({ invite: invite }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyCourseInviteInvite = (
    invite: string,
    config?: SWRConfiguration<CompanyCourseInviteDto>
  ): SWRResponse<CompanyCourseInviteDto> => {
    return useSWR<CompanyCourseInviteDto>(
      () => this.companyCourseInviteInviteRequestOpts({ invite: invite }),
      (context) => this.swrFetch(this.companyCourseInviteInviteFetch(context)),
      config
    );
  };
}
