/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  FundCreateRequestDto,
  FundCreateRequestDtoFromJSON,
  FundCreateRequestDtoToJSON,
  FundDepositRequestDto,
  FundDepositRequestDtoFromJSON,
  FundDepositRequestDtoToJSON,
  FundDto,
  FundDtoFromJSON,
  FundDtoToJSON,
  FundHistoryDto,
  FundHistoryDtoFromJSON,
  FundHistoryDtoToJSON,
  FundUpdateRequestDto,
  FundUpdateRequestDtoFromJSON,
  FundUpdateRequestDtoToJSON,
  FundWithdrawRequestDto,
  FundWithdrawRequestDtoFromJSON,
  FundWithdrawRequestDtoToJSON,
} from "../models";

export interface FundArchiveRequest {
  companyIdOrSlug: string;
  fundId: string;
}

export interface FundCreateRequest {
  companyIdOrSlug: string;
  fundCreateRequestDto: FundCreateRequestDto;
}

export interface FundDepositRequest {
  companyIdOrSlug: string;
  fundId: string;
  fundDepositRequestDto: FundDepositRequestDto;
}

export interface FundHistoryRequest {
  companyIdOrSlug: string;
  fundId: string;
  from: Date;
  to: Date;
}

export interface FundListRequest {
  companyIdOrSlug: string;
}

export interface FundUnArchiveRequest {
  companyIdOrSlug: string;
  fundId: string;
}

export interface FundUpdateRequest {
  companyIdOrSlug: string;
  fundId: string;
  fundUpdateRequestDto: FundUpdateRequestDto;
}

export interface FundWithdrawRequest {
  companyIdOrSlug: string;
  fundId: string;
  fundWithdrawRequestDto: FundWithdrawRequestDto;
}

/**
 *
 */
export class FundApi extends runtime.BaseAPI {
  /**
   */
  protected fundArchiveRequestOpts = (requestParameters: FundArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundArchive."
      );
    }

    if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
      throw new runtime.RequiredError(
        "fundId",
        "Required parameter requestParameters.fundId was null or undefined when calling fundArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund/{fundId}/archive`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundArchiveRaw = async (
    requestParameters: FundArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundArchiveRequestOpts(requestParameters);
    return this.fundArchiveFetch(context, initOverrides);
  };

  /**
   */
  fundArchive = async (companyIdOrSlug: string, fundId: string, initOverrides?: RequestInit): Promise<void> => {
    await this.fundArchiveRaw({ companyIdOrSlug: companyIdOrSlug, fundId: fundId }, initOverrides);
  };

  /**
   */
  protected fundCreateRequestOpts = (requestParameters: FundCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundCreate."
      );
    }

    if (requestParameters.fundCreateRequestDto === null || requestParameters.fundCreateRequestDto === undefined) {
      throw new runtime.RequiredError(
        "fundCreateRequestDto",
        "Required parameter requestParameters.fundCreateRequestDto was null or undefined when calling fundCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundCreateRequestDtoToJSON(requestParameters.fundCreateRequestDto),
    };
  };

  /**
   */
  protected fundCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FundDtoFromJSON(jsonValue));
  };

  /**
   */
  protected fundCreateRaw = async (
    requestParameters: FundCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FundDto>> => {
    const context = this.fundCreateRequestOpts(requestParameters);
    return this.fundCreateFetch(context, initOverrides);
  };

  /**
   */
  fundCreate = async (
    companyIdOrSlug: string,
    fundCreateRequestDto: FundCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<FundDto> => {
    const response = await this.fundCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, fundCreateRequestDto: fundCreateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected fundDepositRequestOpts = (requestParameters: FundDepositRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundDeposit."
      );
    }

    if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
      throw new runtime.RequiredError(
        "fundId",
        "Required parameter requestParameters.fundId was null or undefined when calling fundDeposit."
      );
    }

    if (requestParameters.fundDepositRequestDto === null || requestParameters.fundDepositRequestDto === undefined) {
      throw new runtime.RequiredError(
        "fundDepositRequestDto",
        "Required parameter requestParameters.fundDepositRequestDto was null or undefined when calling fundDeposit."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund/{fundId}/deposit`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundDepositRequestDtoToJSON(requestParameters.fundDepositRequestDto),
    };
  };

  /**
   */
  protected fundDepositFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundDepositRaw = async (
    requestParameters: FundDepositRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundDepositRequestOpts(requestParameters);
    return this.fundDepositFetch(context, initOverrides);
  };

  /**
   */
  fundDeposit = async (
    companyIdOrSlug: string,
    fundId: string,
    fundDepositRequestDto: FundDepositRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundDepositRaw(
      { companyIdOrSlug: companyIdOrSlug, fundId: fundId, fundDepositRequestDto: fundDepositRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected fundHistoryRequestOpts = (requestParameters: FundHistoryRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundHistory."
      );
    }

    if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
      throw new runtime.RequiredError(
        "fundId",
        "Required parameter requestParameters.fundId was null or undefined when calling fundHistory."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling fundHistory."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling fundHistory."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund/{fundId}/history`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundHistoryFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundHistoryDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundHistoryDtoFromJSON));
  };

  /**
   */
  protected fundHistoryRaw = async (
    requestParameters: FundHistoryRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundHistoryDto>>> => {
    const context = this.fundHistoryRequestOpts(requestParameters);
    return this.fundHistoryFetch(context, initOverrides);
  };

  /**
   */
  fundHistory = async (
    companyIdOrSlug: string,
    fundId: string,
    from: Date,
    to: Date,
    initOverrides?: RequestInit
  ): Promise<Array<FundHistoryDto>> => {
    const response = await this.fundHistoryRaw(
      { companyIdOrSlug: companyIdOrSlug, fundId: fundId, from: from, to: to },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFundHistory = (
    requestParameters: FundHistoryRequest,
    config?: SWRConfiguration<Array<FundHistoryDto>>
  ): SWRResponse<Array<FundHistoryDto>> => {
    return useSWR<Array<FundHistoryDto>>(
      () => this.fundHistoryRequestOpts(requestParameters),
      (context) => this.swrFetch(this.fundHistoryFetch(context)),
      config
    );
  };

  /**
   */
  protected fundListRequestOpts = (requestParameters: FundListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FundDtoFromJSON));
  };

  /**
   */
  protected fundListRaw = async (
    requestParameters: FundListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<FundDto>>> => {
    const context = this.fundListRequestOpts(requestParameters);
    return this.fundListFetch(context, initOverrides);
  };

  /**
   */
  fundList = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<Array<FundDto>> => {
    const response = await this.fundListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useFundList = (companyIdOrSlug: string, config?: SWRConfiguration<Array<FundDto>>): SWRResponse<Array<FundDto>> => {
    return useSWR<Array<FundDto>>(
      () => this.fundListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.fundListFetch(context)),
      config
    );
  };

  /**
   */
  protected fundUnArchiveRequestOpts = (requestParameters: FundUnArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundUnArchive."
      );
    }

    if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
      throw new runtime.RequiredError(
        "fundId",
        "Required parameter requestParameters.fundId was null or undefined when calling fundUnArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund/{fundId}/un-archive`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fundUnArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundUnArchiveRaw = async (
    requestParameters: FundUnArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundUnArchiveRequestOpts(requestParameters);
    return this.fundUnArchiveFetch(context, initOverrides);
  };

  /**
   */
  fundUnArchive = async (companyIdOrSlug: string, fundId: string, initOverrides?: RequestInit): Promise<void> => {
    await this.fundUnArchiveRaw({ companyIdOrSlug: companyIdOrSlug, fundId: fundId }, initOverrides);
  };

  /**
   */
  protected fundUpdateRequestOpts = (requestParameters: FundUpdateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundUpdate."
      );
    }

    if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
      throw new runtime.RequiredError(
        "fundId",
        "Required parameter requestParameters.fundId was null or undefined when calling fundUpdate."
      );
    }

    if (requestParameters.fundUpdateRequestDto === null || requestParameters.fundUpdateRequestDto === undefined) {
      throw new runtime.RequiredError(
        "fundUpdateRequestDto",
        "Required parameter requestParameters.fundUpdateRequestDto was null or undefined when calling fundUpdate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund/{fundId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundUpdateRequestDtoToJSON(requestParameters.fundUpdateRequestDto),
    };
  };

  /**
   */
  protected fundUpdateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundUpdateRaw = async (
    requestParameters: FundUpdateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundUpdateRequestOpts(requestParameters);
    return this.fundUpdateFetch(context, initOverrides);
  };

  /**
   */
  fundUpdate = async (
    companyIdOrSlug: string,
    fundId: string,
    fundUpdateRequestDto: FundUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundUpdateRaw(
      { companyIdOrSlug: companyIdOrSlug, fundId: fundId, fundUpdateRequestDto: fundUpdateRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected fundWithdrawRequestOpts = (requestParameters: FundWithdrawRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling fundWithdraw."
      );
    }

    if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
      throw new runtime.RequiredError(
        "fundId",
        "Required parameter requestParameters.fundId was null or undefined when calling fundWithdraw."
      );
    }

    if (requestParameters.fundWithdrawRequestDto === null || requestParameters.fundWithdrawRequestDto === undefined) {
      throw new runtime.RequiredError(
        "fundWithdrawRequestDto",
        "Required parameter requestParameters.fundWithdrawRequestDto was null or undefined when calling fundWithdraw."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/fund/{fundId}/withdraw`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FundWithdrawRequestDtoToJSON(requestParameters.fundWithdrawRequestDto),
    };
  };

  /**
   */
  protected fundWithdrawFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected fundWithdrawRaw = async (
    requestParameters: FundWithdrawRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.fundWithdrawRequestOpts(requestParameters);
    return this.fundWithdrawFetch(context, initOverrides);
  };

  /**
   */
  fundWithdraw = async (
    companyIdOrSlug: string,
    fundId: string,
    fundWithdrawRequestDto: FundWithdrawRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.fundWithdrawRaw(
      { companyIdOrSlug: companyIdOrSlug, fundId: fundId, fundWithdrawRequestDto: fundWithdrawRequestDto },
      initOverrides
    );
  };
}
