/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  DepositTypeCreateUpdateRequestDto,
  DepositTypeCreateUpdateRequestDtoFromJSON,
  DepositTypeCreateUpdateRequestDtoToJSON,
  DepositTypeDto,
  DepositTypeDtoFromJSON,
  DepositTypeDtoToJSON,
} from "../models";

export interface DepositTypeArchiveRequest {
  companyIdOrSlug: string;
  depositTypeId: string;
}

export interface DepositTypeCreateRequest {
  companyIdOrSlug: string;
  depositTypeCreateUpdateRequestDto: DepositTypeCreateUpdateRequestDto;
}

export interface DepositTypeListRequest {
  companyIdOrSlug: string;
}

export interface DepositTypeRenameRequest {
  companyIdOrSlug: string;
  depositTypeId: string;
  depositTypeCreateUpdateRequestDto: DepositTypeCreateUpdateRequestDto;
}

/**
 *
 */
export class DepositTypeApi extends runtime.BaseAPI {
  /**
   */
  protected depositTypeArchiveRequestOpts = (requestParameters: DepositTypeArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling depositTypeArchive."
      );
    }

    if (requestParameters.depositTypeId === null || requestParameters.depositTypeId === undefined) {
      throw new runtime.RequiredError(
        "depositTypeId",
        "Required parameter requestParameters.depositTypeId was null or undefined when calling depositTypeArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deposit-type/{depositTypeId}/archive`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"depositTypeId"}}`, encodeURIComponent(String(requestParameters.depositTypeId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected depositTypeArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected depositTypeArchiveRaw = async (
    requestParameters: DepositTypeArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.depositTypeArchiveRequestOpts(requestParameters);
    return this.depositTypeArchiveFetch(context, initOverrides);
  };

  /**
   */
  depositTypeArchive = async (
    companyIdOrSlug: string,
    depositTypeId: string,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.depositTypeArchiveRaw({ companyIdOrSlug: companyIdOrSlug, depositTypeId: depositTypeId }, initOverrides);
  };

  /**
   */
  protected depositTypeCreateRequestOpts = (requestParameters: DepositTypeCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling depositTypeCreate."
      );
    }

    if (
      requestParameters.depositTypeCreateUpdateRequestDto === null ||
      requestParameters.depositTypeCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "depositTypeCreateUpdateRequestDto",
        "Required parameter requestParameters.depositTypeCreateUpdateRequestDto was null or undefined when calling depositTypeCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deposit-type`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DepositTypeCreateUpdateRequestDtoToJSON(requestParameters.depositTypeCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected depositTypeCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected depositTypeCreateRaw = async (
    requestParameters: DepositTypeCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.depositTypeCreateRequestOpts(requestParameters);
    return this.depositTypeCreateFetch(context, initOverrides);
  };

  /**
   */
  depositTypeCreate = async (
    companyIdOrSlug: string,
    depositTypeCreateUpdateRequestDto: DepositTypeCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.depositTypeCreateRaw(
      { companyIdOrSlug: companyIdOrSlug, depositTypeCreateUpdateRequestDto: depositTypeCreateUpdateRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected depositTypeListRequestOpts = (requestParameters: DepositTypeListRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling depositTypeList."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deposit-type`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected depositTypeListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DepositTypeDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DepositTypeDtoFromJSON));
  };

  /**
   */
  protected depositTypeListRaw = async (
    requestParameters: DepositTypeListRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<DepositTypeDto>>> => {
    const context = this.depositTypeListRequestOpts(requestParameters);
    return this.depositTypeListFetch(context, initOverrides);
  };

  /**
   */
  depositTypeList = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<Array<DepositTypeDto>> => {
    const response = await this.depositTypeListRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useDepositTypeList = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<Array<DepositTypeDto>>
  ): SWRResponse<Array<DepositTypeDto>> => {
    return useSWR<Array<DepositTypeDto>>(
      () => this.depositTypeListRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.depositTypeListFetch(context)),
      config
    );
  };

  /**
   */
  protected depositTypeRenameRequestOpts = (requestParameters: DepositTypeRenameRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling depositTypeRename."
      );
    }

    if (requestParameters.depositTypeId === null || requestParameters.depositTypeId === undefined) {
      throw new runtime.RequiredError(
        "depositTypeId",
        "Required parameter requestParameters.depositTypeId was null or undefined when calling depositTypeRename."
      );
    }

    if (
      requestParameters.depositTypeCreateUpdateRequestDto === null ||
      requestParameters.depositTypeCreateUpdateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "depositTypeCreateUpdateRequestDto",
        "Required parameter requestParameters.depositTypeCreateUpdateRequestDto was null or undefined when calling depositTypeRename."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/deposit-type/{depositTypeId}`
        .replace(`{${"companyIdOrSlug"}}`, encodeURIComponent(String(requestParameters.companyIdOrSlug)))
        .replace(`{${"depositTypeId"}}`, encodeURIComponent(String(requestParameters.depositTypeId))),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DepositTypeCreateUpdateRequestDtoToJSON(requestParameters.depositTypeCreateUpdateRequestDto),
    };
  };

  /**
   */
  protected depositTypeRenameFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected depositTypeRenameRaw = async (
    requestParameters: DepositTypeRenameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.depositTypeRenameRequestOpts(requestParameters);
    return this.depositTypeRenameFetch(context, initOverrides);
  };

  /**
   */
  depositTypeRename = async (
    companyIdOrSlug: string,
    depositTypeId: string,
    depositTypeCreateUpdateRequestDto: DepositTypeCreateUpdateRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.depositTypeRenameRaw(
      {
        companyIdOrSlug: companyIdOrSlug,
        depositTypeId: depositTypeId,
        depositTypeCreateUpdateRequestDto: depositTypeCreateUpdateRequestDto,
      },
      initOverrides
    );
  };
}
