/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ReportBalancesOnFundGroupsAndFundsFundDto,
  ReportBalancesOnFundGroupsAndFundsFundDtoFromJSON,
  ReportBalancesOnFundGroupsAndFundsFundDtoFromJSONTyped,
  ReportBalancesOnFundGroupsAndFundsFundDtoToJSON,
} from "./ReportBalancesOnFundGroupsAndFundsFundDto";

/**
 *
 * @export
 * @interface ReportBalancesOnFundGroupsAndFundsDto
 */
export interface ReportBalancesOnFundGroupsAndFundsDto {
  /**
   *
   * @type {Date}
   * @memberof ReportBalancesOnFundGroupsAndFundsDto
   */
  createdAt: Date;
  /**
   *
   * @type {Array<ReportBalancesOnFundGroupsAndFundsFundDto>}
   * @memberof ReportBalancesOnFundGroupsAndFundsDto
   */
  funds: Array<ReportBalancesOnFundGroupsAndFundsFundDto>;
}

export function ReportBalancesOnFundGroupsAndFundsDtoFromJSON(json: any): ReportBalancesOnFundGroupsAndFundsDto {
  return ReportBalancesOnFundGroupsAndFundsDtoFromJSONTyped(json, false);
}

export function ReportBalancesOnFundGroupsAndFundsDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReportBalancesOnFundGroupsAndFundsDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    createdAt: new Date(json["createdAt"]),
    funds: (json["funds"] as Array<any>).map(ReportBalancesOnFundGroupsAndFundsFundDtoFromJSON),
  };
}

export function ReportBalancesOnFundGroupsAndFundsDtoToJSON(value?: ReportBalancesOnFundGroupsAndFundsDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    createdAt: value.createdAt.toISOString(),
    funds: (value.funds as Array<any>).map(ReportBalancesOnFundGroupsAndFundsFundDtoToJSON),
  };
}
