/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanStateDeferredIncomeDto,
  MonthPlanStateDeferredIncomeDtoFromJSON,
  MonthPlanStateDeferredIncomeDtoFromJSONTyped,
  MonthPlanStateDeferredIncomeDtoToJSON,
} from "./MonthPlanStateDeferredIncomeDto";
import {
  MonthPlanStateFixedCostDto,
  MonthPlanStateFixedCostDtoFromJSON,
  MonthPlanStateFixedCostDtoFromJSONTyped,
  MonthPlanStateFixedCostDtoToJSON,
} from "./MonthPlanStateFixedCostDto";
import {
  MonthPlanStateIncomeDto,
  MonthPlanStateIncomeDtoFromJSON,
  MonthPlanStateIncomeDtoFromJSONTyped,
  MonthPlanStateIncomeDtoToJSON,
} from "./MonthPlanStateIncomeDto";
import {
  MonthPlanStateOverflowFundDto,
  MonthPlanStateOverflowFundDtoFromJSON,
  MonthPlanStateOverflowFundDtoFromJSONTyped,
  MonthPlanStateOverflowFundDtoToJSON,
} from "./MonthPlanStateOverflowFundDto";
import {
  MonthPlanStateTotalItemDto,
  MonthPlanStateTotalItemDtoFromJSON,
  MonthPlanStateTotalItemDtoFromJSONTyped,
  MonthPlanStateTotalItemDtoToJSON,
} from "./MonthPlanStateTotalItemDto";
import {
  MonthPlanStateVariableCostDto,
  MonthPlanStateVariableCostDtoFromJSON,
  MonthPlanStateVariableCostDtoFromJSONTyped,
  MonthPlanStateVariableCostDtoToJSON,
} from "./MonthPlanStateVariableCostDto";
import {
  MonthPlanStateVariableCostFromMarginDto,
  MonthPlanStateVariableCostFromMarginDtoFromJSON,
  MonthPlanStateVariableCostFromMarginDtoFromJSONTyped,
  MonthPlanStateVariableCostFromMarginDtoToJSON,
} from "./MonthPlanStateVariableCostFromMarginDto";
import {
  MonthPlanStateWeekDto,
  MonthPlanStateWeekDtoFromJSON,
  MonthPlanStateWeekDtoFromJSONTyped,
  MonthPlanStateWeekDtoToJSON,
} from "./MonthPlanStateWeekDto";

/**
 *
 * @export
 * @interface MonthPlanStateDto
 */
export interface MonthPlanStateDto {
  /**
   *
   * @type {Array<MonthPlanStateWeekDto>}
   * @memberof MonthPlanStateDto
   */
  weeks: Array<MonthPlanStateWeekDto>;
  /**
   *
   * @type {Array<MonthPlanStateIncomeDto>}
   * @memberof MonthPlanStateDto
   */
  incomes: Array<MonthPlanStateIncomeDto>;
  /**
   *
   * @type {Array<MonthPlanStateDeferredIncomeDto>}
   * @memberof MonthPlanStateDto
   */
  deferredIncomes: Array<MonthPlanStateDeferredIncomeDto>;
  /**
   *
   * @type {Array<MonthPlanStateVariableCostDto>}
   * @memberof MonthPlanStateDto
   */
  variableCosts: Array<MonthPlanStateVariableCostDto>;
  /**
   *
   * @type {Array<MonthPlanStateVariableCostFromMarginDto>}
   * @memberof MonthPlanStateDto
   */
  variableCostsFromMargin: Array<MonthPlanStateVariableCostFromMarginDto>;
  /**
   *
   * @type {Array<MonthPlanStateFixedCostDto>}
   * @memberof MonthPlanStateDto
   */
  fixedCosts: Array<MonthPlanStateFixedCostDto>;
  /**
   *
   * @type {MonthPlanStateOverflowFundDto}
   * @memberof MonthPlanStateDto
   */
  overflowFund: MonthPlanStateOverflowFundDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  incomeTotal: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  deferredIncomeTotal: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  toFunds: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  variableCostTotal: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  marginProfit: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  variableCostFromMarginTotal: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  forFixedCosts: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  fixedCostTotal: MonthPlanStateTotalItemDto;
  /**
   *
   * @type {MonthPlanStateTotalItemDto}
   * @memberof MonthPlanStateDto
   */
  balance: MonthPlanStateTotalItemDto;
}

export function MonthPlanStateDtoFromJSON(json: any): MonthPlanStateDto {
  return MonthPlanStateDtoFromJSONTyped(json, false);
}

export function MonthPlanStateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanStateDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    weeks: (json["weeks"] as Array<any>).map(MonthPlanStateWeekDtoFromJSON),
    incomes: (json["incomes"] as Array<any>).map(MonthPlanStateIncomeDtoFromJSON),
    deferredIncomes: (json["deferredIncomes"] as Array<any>).map(MonthPlanStateDeferredIncomeDtoFromJSON),
    variableCosts: (json["variableCosts"] as Array<any>).map(MonthPlanStateVariableCostDtoFromJSON),
    variableCostsFromMargin: (json["variableCostsFromMargin"] as Array<any>).map(
      MonthPlanStateVariableCostFromMarginDtoFromJSON
    ),
    fixedCosts: (json["fixedCosts"] as Array<any>).map(MonthPlanStateFixedCostDtoFromJSON),
    overflowFund: MonthPlanStateOverflowFundDtoFromJSON(json["overflowFund"]),
    incomeTotal: MonthPlanStateTotalItemDtoFromJSON(json["incomeTotal"]),
    deferredIncomeTotal: MonthPlanStateTotalItemDtoFromJSON(json["deferredIncomeTotal"]),
    toFunds: MonthPlanStateTotalItemDtoFromJSON(json["toFunds"]),
    variableCostTotal: MonthPlanStateTotalItemDtoFromJSON(json["variableCostTotal"]),
    marginProfit: MonthPlanStateTotalItemDtoFromJSON(json["marginProfit"]),
    variableCostFromMarginTotal: MonthPlanStateTotalItemDtoFromJSON(json["variableCostFromMarginTotal"]),
    forFixedCosts: MonthPlanStateTotalItemDtoFromJSON(json["forFixedCosts"]),
    fixedCostTotal: MonthPlanStateTotalItemDtoFromJSON(json["fixedCostTotal"]),
    balance: MonthPlanStateTotalItemDtoFromJSON(json["balance"]),
  };
}

export function MonthPlanStateDtoToJSON(value?: MonthPlanStateDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    weeks: (value.weeks as Array<any>).map(MonthPlanStateWeekDtoToJSON),
    incomes: (value.incomes as Array<any>).map(MonthPlanStateIncomeDtoToJSON),
    deferredIncomes: (value.deferredIncomes as Array<any>).map(MonthPlanStateDeferredIncomeDtoToJSON),
    variableCosts: (value.variableCosts as Array<any>).map(MonthPlanStateVariableCostDtoToJSON),
    variableCostsFromMargin: (value.variableCostsFromMargin as Array<any>).map(
      MonthPlanStateVariableCostFromMarginDtoToJSON
    ),
    fixedCosts: (value.fixedCosts as Array<any>).map(MonthPlanStateFixedCostDtoToJSON),
    overflowFund: MonthPlanStateOverflowFundDtoToJSON(value.overflowFund),
    incomeTotal: MonthPlanStateTotalItemDtoToJSON(value.incomeTotal),
    deferredIncomeTotal: MonthPlanStateTotalItemDtoToJSON(value.deferredIncomeTotal),
    toFunds: MonthPlanStateTotalItemDtoToJSON(value.toFunds),
    variableCostTotal: MonthPlanStateTotalItemDtoToJSON(value.variableCostTotal),
    marginProfit: MonthPlanStateTotalItemDtoToJSON(value.marginProfit),
    variableCostFromMarginTotal: MonthPlanStateTotalItemDtoToJSON(value.variableCostFromMarginTotal),
    forFixedCosts: MonthPlanStateTotalItemDtoToJSON(value.forFixedCosts),
    fixedCostTotal: MonthPlanStateTotalItemDtoToJSON(value.fixedCostTotal),
    balance: MonthPlanStateTotalItemDtoToJSON(value.balance),
  };
}
