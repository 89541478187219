/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MovementOnFundsReportFundOperationDto,
  MovementOnFundsReportFundOperationDtoFromJSON,
  MovementOnFundsReportFundOperationDtoFromJSONTyped,
  MovementOnFundsReportFundOperationDtoToJSON,
} from "./MovementOnFundsReportFundOperationDto";

/**
 *
 * @export
 * @interface MovementOnFundsReportFundDto
 */
export interface MovementOnFundsReportFundDto {
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportFundDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportFundDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundDto
   */
  beginBalance: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundDto
   */
  income: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundDto
   */
  expense: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundDto
   */
  endBalance: number;
  /**
   *
   * @type {Array<MovementOnFundsReportFundOperationDto>}
   * @memberof MovementOnFundsReportFundDto
   */
  operations: Array<MovementOnFundsReportFundOperationDto>;
}

export function MovementOnFundsReportFundDtoFromJSON(json: any): MovementOnFundsReportFundDto {
  return MovementOnFundsReportFundDtoFromJSONTyped(json, false);
}

export function MovementOnFundsReportFundDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MovementOnFundsReportFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    beginBalance: json["beginBalance"],
    income: json["income"],
    expense: json["expense"],
    endBalance: json["endBalance"],
    operations: (json["operations"] as Array<any>).map(MovementOnFundsReportFundOperationDtoFromJSON),
  };
}

export function MovementOnFundsReportFundDtoToJSON(value?: MovementOnFundsReportFundDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    beginBalance: value.beginBalance,
    income: value.income,
    expense: value.expense,
    endBalance: value.endBalance,
    operations: (value.operations as Array<any>).map(MovementOnFundsReportFundOperationDtoToJSON),
  };
}
