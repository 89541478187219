/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OpenedFundingRequestReportFundGroupDto,
  OpenedFundingRequestReportFundGroupDtoFromJSON,
  OpenedFundingRequestReportFundGroupDtoFromJSONTyped,
  OpenedFundingRequestReportFundGroupDtoToJSON,
} from "./OpenedFundingRequestReportFundGroupDto";

/**
 *
 * @export
 * @interface OpenedFundingRequestReportDto
 */
export interface OpenedFundingRequestReportDto {
  /**
   *
   * @type {Date}
   * @memberof OpenedFundingRequestReportDto
   */
  createdAt: Date;
  /**
   *
   * @type {Array<OpenedFundingRequestReportFundGroupDto>}
   * @memberof OpenedFundingRequestReportDto
   */
  fundGroups: Array<OpenedFundingRequestReportFundGroupDto>;
}

export function OpenedFundingRequestReportDtoFromJSON(json: any): OpenedFundingRequestReportDto {
  return OpenedFundingRequestReportDtoFromJSONTyped(json, false);
}

export function OpenedFundingRequestReportDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpenedFundingRequestReportDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    createdAt: new Date(json["createdAt"]),
    fundGroups: (json["fundGroups"] as Array<any>).map(OpenedFundingRequestReportFundGroupDtoFromJSON),
  };
}

export function OpenedFundingRequestReportDtoToJSON(value?: OpenedFundingRequestReportDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    createdAt: value.createdAt.toISOString(),
    fundGroups: (value.fundGroups as Array<any>).map(OpenedFundingRequestReportFundGroupDtoToJSON),
  };
}
