/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanCopyEducationRequestDto
 */
export interface MonthPlanCopyEducationRequestDto {
  /**
   *
   * @type {number}
   * @memberof MonthPlanCopyEducationRequestDto
   */
  financePlanningWeekday: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanCopyEducationRequestDto
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanCopyEducationRequestDto
   */
  year: number;
}

export function MonthPlanCopyEducationRequestDtoFromJSON(json: any): MonthPlanCopyEducationRequestDto {
  return MonthPlanCopyEducationRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanCopyEducationRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanCopyEducationRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    financePlanningWeekday: json["financePlanningWeekday"],
    month: json["month"],
    year: json["year"],
  };
}

export function MonthPlanCopyEducationRequestDtoToJSON(value?: MonthPlanCopyEducationRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    financePlanningWeekday: value.financePlanningWeekday,
    month: value.month,
    year: value.year,
  };
}
