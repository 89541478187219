/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ExpendituresOnFundsAndExpenseItemsReportFundDto,
  ExpendituresOnFundsAndExpenseItemsReportFundDtoFromJSON,
  ExpendituresOnFundsAndExpenseItemsReportFundDtoFromJSONTyped,
  ExpendituresOnFundsAndExpenseItemsReportFundDtoToJSON,
} from "./ExpendituresOnFundsAndExpenseItemsReportFundDto";

/**
 *
 * @export
 * @interface ExpendituresOnFundsAndExpenseItemsReportDto
 */
export interface ExpendituresOnFundsAndExpenseItemsReportDto {
  /**
   *
   * @type {Date}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportDto
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportDto
   */
  to: Date;
  /**
   *
   * @type {Array<ExpendituresOnFundsAndExpenseItemsReportFundDto>}
   * @memberof ExpendituresOnFundsAndExpenseItemsReportDto
   */
  funds: Array<ExpendituresOnFundsAndExpenseItemsReportFundDto>;
}

export function ExpendituresOnFundsAndExpenseItemsReportDtoFromJSON(
  json: any
): ExpendituresOnFundsAndExpenseItemsReportDto {
  return ExpendituresOnFundsAndExpenseItemsReportDtoFromJSONTyped(json, false);
}

export function ExpendituresOnFundsAndExpenseItemsReportDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExpendituresOnFundsAndExpenseItemsReportDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    createdAt: new Date(json["createdAt"]),
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    funds: (json["funds"] as Array<any>).map(ExpendituresOnFundsAndExpenseItemsReportFundDtoFromJSON),
  };
}

export function ExpendituresOnFundsAndExpenseItemsReportDtoToJSON(
  value?: ExpendituresOnFundsAndExpenseItemsReportDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    createdAt: value.createdAt.toISOString(),
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    funds: (value.funds as Array<any>).map(ExpendituresOnFundsAndExpenseItemsReportFundDtoToJSON),
  };
}
