/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanCreateRequestDto
 */
export interface MonthPlanCreateRequestDto {
  /**
   *
   * @type {number}
   * @memberof MonthPlanCreateRequestDto
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanCreateRequestDto
   */
  year: number;
}

export function MonthPlanCreateRequestDtoFromJSON(json: any): MonthPlanCreateRequestDto {
  return MonthPlanCreateRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanCreateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanCreateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    month: json["month"],
    year: json["year"],
  };
}

export function MonthPlanCreateRequestDtoToJSON(value?: MonthPlanCreateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    month: value.month,
    year: value.year,
  };
}
