/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AdminRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
}

export function AdminRoleFromJSON(json: any): AdminRole {
  return AdminRoleFromJSONTyped(json, false);
}

export function AdminRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminRole {
  return json as AdminRole;
}

export function AdminRoleToJSON(value?: AdminRole | null): any {
  return value as any;
}
