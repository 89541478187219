/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OpenedFundingRequestReportFundingRequestDto,
  OpenedFundingRequestReportFundingRequestDtoFromJSON,
  OpenedFundingRequestReportFundingRequestDtoFromJSONTyped,
  OpenedFundingRequestReportFundingRequestDtoToJSON,
} from "./OpenedFundingRequestReportFundingRequestDto";

/**
 *
 * @export
 * @interface OpenedFundingRequestReportFundDto
 */
export interface OpenedFundingRequestReportFundDto {
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundDto
   */
  fundGroupId: string;
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundDto
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundDto
   */
  approvedAmount: number;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundDto
   */
  paidAmount: number;
  /**
   *
   * @type {Array<OpenedFundingRequestReportFundingRequestDto>}
   * @memberof OpenedFundingRequestReportFundDto
   */
  fundingRequests: Array<OpenedFundingRequestReportFundingRequestDto>;
}

export function OpenedFundingRequestReportFundDtoFromJSON(json: any): OpenedFundingRequestReportFundDto {
  return OpenedFundingRequestReportFundDtoFromJSONTyped(json, false);
}

export function OpenedFundingRequestReportFundDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpenedFundingRequestReportFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fundGroupId: json["fundGroupId"],
    name: json["name"],
    amount: json["amount"],
    approvedAmount: json["approvedAmount"],
    paidAmount: json["paidAmount"],
    fundingRequests: (json["fundingRequests"] as Array<any>).map(OpenedFundingRequestReportFundingRequestDtoFromJSON),
  };
}

export function OpenedFundingRequestReportFundDtoToJSON(value?: OpenedFundingRequestReportFundDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fundGroupId: value.fundGroupId,
    name: value.name,
    amount: value.amount,
    approvedAmount: value.approvedAmount,
    paidAmount: value.paidAmount,
    fundingRequests: (value.fundingRequests as Array<any>).map(OpenedFundingRequestReportFundingRequestDtoToJSON),
  };
}
