import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { getApiKey, onApiKeyChange } from "shared/api/back/lib/current-api-key";
import { api } from "shared/api";

const LOGROCKET_APP_ID = process.env.NEXT_PUBLIC_LOGROCKET_APP_ID;

const init = () => {
  if (!LOGROCKET_APP_ID) return;

  onApiKeyChange(identfy);
  identfy();
};

const identfy = async () => {
  if (!LOGROCKET_APP_ID) return;

  const authed = !!getApiKey();
  if (!authed) return;

  const profile = await api.userProfile.userProfileMe();

  if (profile.role) {
    LogRocket.init(LOGROCKET_APP_ID);
    setupLogRocketReact(LogRocket);

    LogRocket.identify(profile.id, profile as any);
  }
};

const logrocket = {
  init,
};

export default logrocket;
