/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MovementOnFundsReportFundDto,
  MovementOnFundsReportFundDtoFromJSON,
  MovementOnFundsReportFundDtoFromJSONTyped,
  MovementOnFundsReportFundDtoToJSON,
} from "./MovementOnFundsReportFundDto";

/**
 *
 * @export
 * @interface MovementOnFundsReportFundGroupDto
 */
export interface MovementOnFundsReportFundGroupDto {
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportFundGroupDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof MovementOnFundsReportFundGroupDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundGroupDto
   */
  beginBalance: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundGroupDto
   */
  income: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundGroupDto
   */
  expense: number;
  /**
   *
   * @type {number}
   * @memberof MovementOnFundsReportFundGroupDto
   */
  endBalance: number;
  /**
   *
   * @type {Array<MovementOnFundsReportFundDto>}
   * @memberof MovementOnFundsReportFundGroupDto
   */
  funds: Array<MovementOnFundsReportFundDto>;
}

export function MovementOnFundsReportFundGroupDtoFromJSON(json: any): MovementOnFundsReportFundGroupDto {
  return MovementOnFundsReportFundGroupDtoFromJSONTyped(json, false);
}

export function MovementOnFundsReportFundGroupDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MovementOnFundsReportFundGroupDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    beginBalance: json["beginBalance"],
    income: json["income"],
    expense: json["expense"],
    endBalance: json["endBalance"],
    funds: (json["funds"] as Array<any>).map(MovementOnFundsReportFundDtoFromJSON),
  };
}

export function MovementOnFundsReportFundGroupDtoToJSON(value?: MovementOnFundsReportFundGroupDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    beginBalance: value.beginBalance,
    income: value.income,
    expense: value.expense,
    endBalance: value.endBalance,
    funds: (value.funds as Array<any>).map(MovementOnFundsReportFundDtoToJSON),
  };
}
