/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  CompanyChangeAvatarImageRequestDto,
  CompanyChangeAvatarImageRequestDtoFromJSON,
  CompanyChangeAvatarImageRequestDtoToJSON,
  CompanyChangeNameRequestDto,
  CompanyChangeNameRequestDtoFromJSON,
  CompanyChangeNameRequestDtoToJSON,
  CompanyChangeSlugRequestDto,
  CompanyChangeSlugRequestDtoFromJSON,
  CompanyChangeSlugRequestDtoToJSON,
  CompanyCreateRequestDto,
  CompanyCreateRequestDtoFromJSON,
  CompanyCreateRequestDtoToJSON,
  CompanyDto,
  CompanyDtoFromJSON,
  CompanyDtoToJSON,
} from "../models";

export interface CompanyArchiveRequest {
  companyIdOrSlug: string;
}

export interface CompanyChangeAvatarImageRequest {
  companyIdOrSlug: string;
  companyChangeAvatarImageRequestDto: CompanyChangeAvatarImageRequestDto;
}

export interface CompanyChangeNameRequest {
  companyIdOrSlug: string;
  companyChangeNameRequestDto: CompanyChangeNameRequestDto;
}

export interface CompanyChangeSlugRequest {
  companyIdOrSlug: string;
  companyChangeSlugRequestDto: CompanyChangeSlugRequestDto;
}

export interface CompanyCreateRequest {
  companyCreateRequestDto: CompanyCreateRequestDto;
}

export interface CompanyGetRequest {
  companyIdOrSlug: string;
}

/**
 *
 */
export class CompanyApi extends runtime.BaseAPI {
  /**
   */
  protected companyArchiveRequestOpts = (requestParameters: CompanyArchiveRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyArchive."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/archive`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyArchiveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyArchiveRaw = async (
    requestParameters: CompanyArchiveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyArchiveRequestOpts(requestParameters);
    return this.companyArchiveFetch(context, initOverrides);
  };

  /**
   */
  companyArchive = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<void> => {
    await this.companyArchiveRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
  };

  /**
   */
  protected companyChangeAvatarImageRequestOpts = (
    requestParameters: CompanyChangeAvatarImageRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyChangeAvatarImage."
      );
    }

    if (
      requestParameters.companyChangeAvatarImageRequestDto === null ||
      requestParameters.companyChangeAvatarImageRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyChangeAvatarImageRequestDto",
        "Required parameter requestParameters.companyChangeAvatarImageRequestDto was null or undefined when calling companyChangeAvatarImage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/avatar-image`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyChangeAvatarImageRequestDtoToJSON(requestParameters.companyChangeAvatarImageRequestDto),
    };
  };

  /**
   */
  protected companyChangeAvatarImageFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyChangeAvatarImageRaw = async (
    requestParameters: CompanyChangeAvatarImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyChangeAvatarImageRequestOpts(requestParameters);
    return this.companyChangeAvatarImageFetch(context, initOverrides);
  };

  /**
   */
  companyChangeAvatarImage = async (
    companyIdOrSlug: string,
    companyChangeAvatarImageRequestDto: CompanyChangeAvatarImageRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyChangeAvatarImageRaw(
      { companyIdOrSlug: companyIdOrSlug, companyChangeAvatarImageRequestDto: companyChangeAvatarImageRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected companyChangeNameRequestOpts = (requestParameters: CompanyChangeNameRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyChangeName."
      );
    }

    if (
      requestParameters.companyChangeNameRequestDto === null ||
      requestParameters.companyChangeNameRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyChangeNameRequestDto",
        "Required parameter requestParameters.companyChangeNameRequestDto was null or undefined when calling companyChangeName."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/name`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyChangeNameRequestDtoToJSON(requestParameters.companyChangeNameRequestDto),
    };
  };

  /**
   */
  protected companyChangeNameFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyChangeNameRaw = async (
    requestParameters: CompanyChangeNameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyChangeNameRequestOpts(requestParameters);
    return this.companyChangeNameFetch(context, initOverrides);
  };

  /**
   */
  companyChangeName = async (
    companyIdOrSlug: string,
    companyChangeNameRequestDto: CompanyChangeNameRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyChangeNameRaw(
      { companyIdOrSlug: companyIdOrSlug, companyChangeNameRequestDto: companyChangeNameRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected companyChangeSlugRequestOpts = (requestParameters: CompanyChangeSlugRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyChangeSlug."
      );
    }

    if (
      requestParameters.companyChangeSlugRequestDto === null ||
      requestParameters.companyChangeSlugRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "companyChangeSlugRequestDto",
        "Required parameter requestParameters.companyChangeSlugRequestDto was null or undefined when calling companyChangeSlug."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/slug`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyChangeSlugRequestDtoToJSON(requestParameters.companyChangeSlugRequestDto),
    };
  };

  /**
   */
  protected companyChangeSlugFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected companyChangeSlugRaw = async (
    requestParameters: CompanyChangeSlugRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.companyChangeSlugRequestOpts(requestParameters);
    return this.companyChangeSlugFetch(context, initOverrides);
  };

  /**
   */
  companyChangeSlug = async (
    companyIdOrSlug: string,
    companyChangeSlugRequestDto: CompanyChangeSlugRequestDto,
    initOverrides?: RequestInit
  ): Promise<void> => {
    await this.companyChangeSlugRaw(
      { companyIdOrSlug: companyIdOrSlug, companyChangeSlugRequestDto: companyChangeSlugRequestDto },
      initOverrides
    );
  };

  /**
   */
  protected companyCreateRequestOpts = (requestParameters: CompanyCreateRequest): runtime.RequestOpts => {
    if (requestParameters.companyCreateRequestDto === null || requestParameters.companyCreateRequestDto === undefined) {
      throw new runtime.RequiredError(
        "companyCreateRequestDto",
        "Required parameter requestParameters.companyCreateRequestDto was null or undefined when calling companyCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyCreateRequestDtoToJSON(requestParameters.companyCreateRequestDto),
    };
  };

  /**
   */
  protected companyCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
  };

  /**
   */
  protected companyCreateRaw = async (
    requestParameters: CompanyCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyDto>> => {
    const context = this.companyCreateRequestOpts(requestParameters);
    return this.companyCreateFetch(context, initOverrides);
  };

  /**
   */
  companyCreate = async (
    companyCreateRequestDto: CompanyCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<CompanyDto> => {
    const response = await this.companyCreateRaw({ companyCreateRequestDto: companyCreateRequestDto }, initOverrides);
    return await response.value();
  };

  /**
   */
  protected companyGetRequestOpts = (requestParameters: CompanyGetRequest): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling companyGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyGetFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
  };

  /**
   */
  protected companyGetRaw = async (
    requestParameters: CompanyGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CompanyDto>> => {
    const context = this.companyGetRequestOpts(requestParameters);
    return this.companyGetFetch(context, initOverrides);
  };

  /**
   */
  companyGet = async (companyIdOrSlug: string, initOverrides?: RequestInit): Promise<CompanyDto> => {
    const response = await this.companyGetRaw({ companyIdOrSlug: companyIdOrSlug }, initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyGet = (companyIdOrSlug: string, config?: SWRConfiguration<CompanyDto>): SWRResponse<CompanyDto> => {
    return useSWR<CompanyDto>(
      () => this.companyGetRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.companyGetFetch(context)),
      config
    );
  };

  /**
   */
  protected companyListRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected companyListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<CompanyDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyDtoFromJSON));
  };

  /**
   */
  protected companyListRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CompanyDto>>> => {
    const context = this.companyListRequestOpts();
    return this.companyListFetch(context, initOverrides);
  };

  /**
   */
  companyList = async (initOverrides?: RequestInit): Promise<Array<CompanyDto>> => {
    const response = await this.companyListRaw(initOverrides);
    return await response.value();
  };

  /**
   */
  useCompanyList = (config?: SWRConfiguration<Array<CompanyDto>>): SWRResponse<Array<CompanyDto>> => {
    return useSWR<Array<CompanyDto>>(
      () => this.companyListRequestOpts(),
      (context) => this.swrFetch(this.companyListFetch(context)),
      config
    );
  };
}
