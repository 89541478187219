/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FinanceSettingsDto
 */
export interface FinanceSettingsDto {
  /**
   *
   * @type {string}
   * @memberof FinanceSettingsDto
   */
  companyId: string;
  /**
   *
   * @type {number}
   * @memberof FinanceSettingsDto
   */
  financialPlanningWeekDay: number;
  /**
   *
   * @type {boolean}
   * @memberof FinanceSettingsDto
   */
  education: boolean;
  /**
   *
   * @type {number}
   * @memberof FinanceSettingsDto
   */
  educationStep: number;
}

export function FinanceSettingsDtoFromJSON(json: any): FinanceSettingsDto {
  return FinanceSettingsDtoFromJSONTyped(json, false);
}

export function FinanceSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinanceSettingsDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    companyId: json["companyId"],
    financialPlanningWeekDay: json["financialPlanningWeekDay"],
    education: json["education"],
    educationStep: json["educationStep"],
  };
}

export function FinanceSettingsDtoToJSON(value?: FinanceSettingsDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    companyId: value.companyId,
    financialPlanningWeekDay: value.financialPlanningWeekDay,
    education: value.education,
    educationStep: value.educationStep,
  };
}
