/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  AdminCourseCreateRequestDto,
  AdminCourseCreateRequestDtoFromJSON,
  AdminCourseCreateRequestDtoToJSON,
  AdminCourseDto,
  AdminCourseDtoFromJSON,
  AdminCourseDtoToJSON,
} from "../models";

export interface AdminCourseCreateRequest {
  adminCourseCreateRequestDto: AdminCourseCreateRequestDto;
}

export interface AdminCourseRemoveRequest {
  id: string;
}

/**
 *
 */
export class AdminCourseApi extends runtime.BaseAPI {
  /**
   */
  protected adminCourseCreateRequestOpts = (requestParameters: AdminCourseCreateRequest): runtime.RequestOpts => {
    if (
      requestParameters.adminCourseCreateRequestDto === null ||
      requestParameters.adminCourseCreateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        "adminCourseCreateRequestDto",
        "Required parameter requestParameters.adminCourseCreateRequestDto was null or undefined when calling adminCourseCreate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/course`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AdminCourseCreateRequestDtoToJSON(requestParameters.adminCourseCreateRequestDto),
    };
  };

  /**
   */
  protected adminCourseCreateFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AdminCourseDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => AdminCourseDtoFromJSON(jsonValue));
  };

  /**
   */
  protected adminCourseCreateRaw = async (
    requestParameters: AdminCourseCreateRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AdminCourseDto>> => {
    const context = this.adminCourseCreateRequestOpts(requestParameters);
    return this.adminCourseCreateFetch(context, initOverrides);
  };

  /**
   */
  adminCourseCreate = async (
    adminCourseCreateRequestDto: AdminCourseCreateRequestDto,
    initOverrides?: RequestInit
  ): Promise<AdminCourseDto> => {
    const response = await this.adminCourseCreateRaw(
      { adminCourseCreateRequestDto: adminCourseCreateRequestDto },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  protected adminCourseListRequestOpts = (): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/course`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCourseListFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCourseDto>>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminCourseDtoFromJSON));
  };

  /**
   */
  protected adminCourseListRaw = async (
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminCourseDto>>> => {
    const context = this.adminCourseListRequestOpts();
    return this.adminCourseListFetch(context, initOverrides);
  };

  /**
   */
  adminCourseList = async (initOverrides?: RequestInit): Promise<Array<AdminCourseDto>> => {
    const response = await this.adminCourseListRaw(initOverrides);
    return await response.value();
  };

  /**
   */
  useAdminCourseList = (config?: SWRConfiguration<Array<AdminCourseDto>>): SWRResponse<Array<AdminCourseDto>> => {
    return useSWR<Array<AdminCourseDto>>(
      () => this.adminCourseListRequestOpts(),
      (context) => this.swrFetch(this.adminCourseListFetch(context)),
      config
    );
  };

  /**
   */
  protected adminCourseRemoveRequestOpts = (requestParameters: AdminCourseRemoveRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling adminCourseRemove."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/admin/course/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected adminCourseRemoveFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.VoidApiResponse(response);
  };

  /**
   */
  protected adminCourseRemoveRaw = async (
    requestParameters: AdminCourseRemoveRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> => {
    const context = this.adminCourseRemoveRequestOpts(requestParameters);
    return this.adminCourseRemoveFetch(context, initOverrides);
  };

  /**
   */
  adminCourseRemove = async (id: string, initOverrides?: RequestInit): Promise<void> => {
    await this.adminCourseRemoveRaw({ id: id }, initOverrides);
  };
}
