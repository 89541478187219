/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { FileDto, FileDtoFromJSON, FileDtoToJSON } from "../models";

export interface FileFileRequest {
  id: string;
}

export interface FileUploadFileRequest {
  file?: Blob;
}

/**
 *
 */
export class FileApi extends runtime.BaseAPI {
  /**
   */
  protected fileFileRequestOpts = (requestParameters: FileFileRequest): runtime.RequestOpts => {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling fileFile."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    return {
      path: `/api/file/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected fileFileFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FileDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FileDtoFromJSON(jsonValue));
  };

  /**
   */
  protected fileFileRaw = async (
    requestParameters: FileFileRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FileDto>> => {
    const context = this.fileFileRequestOpts(requestParameters);
    return this.fileFileFetch(context, initOverrides);
  };

  /**
   */
  fileFile = async (id: string, initOverrides?: RequestInit): Promise<FileDto> => {
    const response = await this.fileFileRaw({ id: id }, initOverrides);
    return await response.value();
  };

  /**
   */
  useFileFile = (id: string, config?: SWRConfiguration<FileDto>): SWRResponse<FileDto> => {
    return useSWR<FileDto>(
      () => this.fileFileRequestOpts({ id: id }),
      (context) => this.swrFetch(this.fileFileFetch(context)),
      config
    );
  };

  /**
   */
  protected fileUploadFileRequestOpts = (requestParameters: FileUploadFileRequest): runtime.RequestOpts => {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    return {
      path: `/api/file`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formParams,
    };
  };

  /**
   */
  protected fileUploadFileFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FileDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FileDtoFromJSON(jsonValue));
  };

  /**
   */
  protected fileUploadFileRaw = async (
    requestParameters: FileUploadFileRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FileDto>> => {
    const context = this.fileUploadFileRequestOpts(requestParameters);
    return this.fileUploadFileFetch(context, initOverrides);
  };

  /**
   */
  fileUploadFile = async (file?: Blob, initOverrides?: RequestInit): Promise<FileDto> => {
    const response = await this.fileUploadFileRaw({ file: file }, initOverrides);
    return await response.value();
  };
}
