/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { FundDto, FundDtoFromJSON, FundDtoFromJSONTyped, FundDtoToJSON } from "./FundDto";
import {
  FundHistoryLoanDtoFromDeferredIncome,
  FundHistoryLoanDtoFromDeferredIncomeFromJSON,
  FundHistoryLoanDtoFromDeferredIncomeFromJSONTyped,
  FundHistoryLoanDtoFromDeferredIncomeToJSON,
} from "./FundHistoryLoanDtoFromDeferredIncome";
import {
  FundLoanDtoFromFund,
  FundLoanDtoFromFundFromJSON,
  FundLoanDtoFromFundFromJSONTyped,
  FundLoanDtoFromFundToJSON,
} from "./FundLoanDtoFromFund";

/**
 *
 * @export
 * @interface FinancialPlanLoanDto
 */
export interface FinancialPlanLoanDto {
  /**
   *
   * @type {string}
   * @memberof FinancialPlanLoanDto
   */
  id: string;
  /**
   *
   * @type {FundHistoryLoanDtoFromDeferredIncome}
   * @memberof FinancialPlanLoanDto
   */
  fromDeferredIncome: FundHistoryLoanDtoFromDeferredIncome | null;
  /**
   *
   * @type {FundLoanDtoFromFund}
   * @memberof FinancialPlanLoanDto
   */
  fromFund: FundLoanDtoFromFund | null;
  /**
   *
   * @type {FundDto}
   * @memberof FinancialPlanLoanDto
   */
  toFund: FundDto;
  /**
   *
   * @type {number}
   * @memberof FinancialPlanLoanDto
   */
  amount: number;
  /**
   *
   * @type {string}
   * @memberof FinancialPlanLoanDto
   */
  comment: string;
  /**
   *
   * @type {Date}
   * @memberof FinancialPlanLoanDto
   */
  returnAt: Date;
}

export function FinancialPlanLoanDtoFromJSON(json: any): FinancialPlanLoanDto {
  return FinancialPlanLoanDtoFromJSONTyped(json, false);
}

export function FinancialPlanLoanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialPlanLoanDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    fromDeferredIncome: FundHistoryLoanDtoFromDeferredIncomeFromJSON(json["fromDeferredIncome"]),
    fromFund: FundLoanDtoFromFundFromJSON(json["fromFund"]),
    toFund: FundDtoFromJSON(json["toFund"]),
    amount: json["amount"],
    comment: json["comment"],
    returnAt: new Date(json["returnAt"]),
  };
}

export function FinancialPlanLoanDtoToJSON(value?: FinancialPlanLoanDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fromDeferredIncome: FundHistoryLoanDtoFromDeferredIncomeToJSON(value.fromDeferredIncome),
    fromFund: FundLoanDtoFromFundToJSON(value.fromFund),
    toFund: FundDtoToJSON(value.toFund),
    amount: value.amount,
    comment: value.comment,
    returnAt: value.returnAt.toISOString(),
  };
}
