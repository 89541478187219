/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanChangeIncomeWeekPlanRequestDto
 */
export interface MonthPlanChangeIncomeWeekPlanRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanChangeIncomeWeekPlanRequestDto
   */
  incomeTypeId: string;
  /**
   *
   * @type {number}
   * @memberof MonthPlanChangeIncomeWeekPlanRequestDto
   */
  weekNumber: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanChangeIncomeWeekPlanRequestDto
   */
  plan: number;
}

export function MonthPlanChangeIncomeWeekPlanRequestDtoFromJSON(json: any): MonthPlanChangeIncomeWeekPlanRequestDto {
  return MonthPlanChangeIncomeWeekPlanRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanChangeIncomeWeekPlanRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanChangeIncomeWeekPlanRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    incomeTypeId: json["incomeTypeId"],
    weekNumber: json["weekNumber"],
    plan: json["plan"],
  };
}

export function MonthPlanChangeIncomeWeekPlanRequestDtoToJSON(
  value?: MonthPlanChangeIncomeWeekPlanRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    incomeTypeId: value.incomeTypeId,
    weekNumber: value.weekNumber,
    plan: value.plan,
  };
}
