/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FinancialPlanAddLoanDto,
  FinancialPlanAddLoanDtoFromJSON,
  FinancialPlanAddLoanDtoFromJSONTyped,
  FinancialPlanAddLoanDtoToJSON,
} from "./FinancialPlanAddLoanDto";

/**
 *
 * @export
 * @interface FinancialPlanAddLoansRequestDto
 */
export interface FinancialPlanAddLoansRequestDto {
  /**
   *
   * @type {Array<FinancialPlanAddLoanDto>}
   * @memberof FinancialPlanAddLoansRequestDto
   */
  loans: Array<FinancialPlanAddLoanDto>;
}

export function FinancialPlanAddLoansRequestDtoFromJSON(json: any): FinancialPlanAddLoansRequestDto {
  return FinancialPlanAddLoansRequestDtoFromJSONTyped(json, false);
}

export function FinancialPlanAddLoansRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FinancialPlanAddLoansRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    loans: (json["loans"] as Array<any>).map(FinancialPlanAddLoanDtoFromJSON),
  };
}

export function FinancialPlanAddLoansRequestDtoToJSON(value?: FinancialPlanAddLoansRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    loans: (value.loans as Array<any>).map(FinancialPlanAddLoanDtoToJSON),
  };
}
