import React, { useEffect } from "react";
import { AppProps } from "next/app";
import { setDocumentTheme, useTheme } from "modules/layout";
import Head from "next/head";

export const withThemeDetector = (app: (props: AppProps) => React.ReactNode) => {
  const WithThemeDetector = (props: AppProps) => {
    const [currentTheme] = useTheme();

    useEffect(() => {
      setDocumentTheme(currentTheme);
    }, [currentTheme]);

    return (
      <>
        <Head>
          <meta name="theme-color" content="black" />
        </Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `!function() {
              try {
                  var d = document.documentElement,
                      c = d.classList;
                  c.remove('light-theme', 'dark-theme');
                  var e = localStorage.getItem('theme');
                  if ('system' === e || (!e && true)) {
                      var t = '(prefers-color-scheme: dark)',
                          m = window.matchMedia(t);
                      if (m.media !== t || m.matches) {
                          d.style.colorScheme = 'dark';
                          c.add('dark-theme')
                      } else {
                          d.style.colorScheme = 'light';
                          c.add('light-theme')
                      }
                  } else if (e) {
                      var x = {
                          "light": "light-theme",
                          "dark": "dark-theme"
                      };
                      c.add(x[e] || '')
                  }
                  if (e === 'light' || e === 'dark')
                      d.style.colorScheme = e
              } catch (e) {}
          }()`,
          }}
        />
        {app(props)}
      </>
    );
  };

  return WithThemeDetector;
};
