/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  UserProfileDtoAvatarImage,
  UserProfileDtoAvatarImageFromJSON,
  UserProfileDtoAvatarImageFromJSONTyped,
  UserProfileDtoAvatarImageToJSON,
} from "./UserProfileDtoAvatarImage";
import { UserRole, UserRoleFromJSON, UserRoleFromJSONTyped, UserRoleToJSON } from "./UserRole";

/**
 *
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
  /**
   *
   * @type {UserRole}
   * @memberof UserProfileDto
   */
  role: UserRole;
  /**
   *
   * @type {string}
   * @memberof UserProfileDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileDto
   */
  name: string;
  /**
   *
   * @type {UserProfileDtoAvatarImage}
   * @memberof UserProfileDto
   */
  avatarImage: UserProfileDtoAvatarImage | null;
}

export function UserProfileDtoFromJSON(json: any): UserProfileDto {
  return UserProfileDtoFromJSONTyped(json, false);
}

export function UserProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: UserRoleFromJSON(json["role"]),
    id: json["id"],
    email: json["email"],
    name: json["name"],
    avatarImage: UserProfileDtoAvatarImageFromJSON(json["avatarImage"]),
  };
}

export function UserProfileDtoToJSON(value?: UserProfileDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: UserRoleToJSON(value.role),
    id: value.id,
    email: value.email,
    name: value.name,
    avatarImage: UserProfileDtoAvatarImageToJSON(value.avatarImage),
  };
}
