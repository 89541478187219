/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import {
  FinancialPlanLastDraftOrOnApprovalDto,
  FinancialPlanLastDraftOrOnApprovalDtoFromJSON,
  FinancialPlanLastDraftOrOnApprovalDtoToJSON,
  FinancialPlanNextDto,
  FinancialPlanNextDtoFromJSON,
  FinancialPlanNextDtoToJSON,
} from "../models";

export interface FinancialPlanCommonLastDraftOrOnApprovalRequest {
  companyIdOrSlug: string;
}

export interface FinancialPlanCommonNextFinancialPlanRequest {
  companyIdOrSlug: string;
}

/**
 *
 */
export class FinancialPlanCommonApi extends runtime.BaseAPI {
  /**
   */
  protected financialPlanCommonLastDraftOrOnApprovalRequestOpts = (
    requestParameters: FinancialPlanCommonLastDraftOrOnApprovalRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanCommonLastDraftOrOnApproval."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan-common/last-draft-or-on-approval`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanCommonLastDraftOrOnApprovalFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanLastDraftOrOnApprovalDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FinancialPlanLastDraftOrOnApprovalDtoFromJSON(jsonValue)
    );
  };

  /**
   */
  protected financialPlanCommonLastDraftOrOnApprovalRaw = async (
    requestParameters: FinancialPlanCommonLastDraftOrOnApprovalRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanLastDraftOrOnApprovalDto>> => {
    const context = this.financialPlanCommonLastDraftOrOnApprovalRequestOpts(requestParameters);
    return this.financialPlanCommonLastDraftOrOnApprovalFetch(context, initOverrides);
  };

  /**
   */
  financialPlanCommonLastDraftOrOnApproval = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<FinancialPlanLastDraftOrOnApprovalDto> => {
    const response = await this.financialPlanCommonLastDraftOrOnApprovalRaw(
      { companyIdOrSlug: companyIdOrSlug },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanCommonLastDraftOrOnApproval = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<FinancialPlanLastDraftOrOnApprovalDto>
  ): SWRResponse<FinancialPlanLastDraftOrOnApprovalDto> => {
    return useSWR<FinancialPlanLastDraftOrOnApprovalDto>(
      () => this.financialPlanCommonLastDraftOrOnApprovalRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.financialPlanCommonLastDraftOrOnApprovalFetch(context)),
      config
    );
  };

  /**
   */
  protected financialPlanCommonNextFinancialPlanRequestOpts = (
    requestParameters: FinancialPlanCommonNextFinancialPlanRequest
  ): runtime.RequestOpts => {
    if (requestParameters.companyIdOrSlug === null || requestParameters.companyIdOrSlug === undefined) {
      throw new runtime.RequiredError(
        "companyIdOrSlug",
        "Required parameter requestParameters.companyIdOrSlug was null or undefined when calling financialPlanCommonNextFinancialPlan."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // X-API-KEY authentication
    }

    return {
      path: `/api/company/{companyIdOrSlug}/finance/financial-plan-common/next`.replace(
        `{${"companyIdOrSlug"}}`,
        encodeURIComponent(String(requestParameters.companyIdOrSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    };
  };

  /**
   */
  protected financialPlanCommonNextFinancialPlanFetch = async (
    context: runtime.RequestOpts,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanNextDto>> => {
    const response = await this.request(context, initOverrides);

    return new runtime.JSONApiResponse(response, (jsonValue) => FinancialPlanNextDtoFromJSON(jsonValue));
  };

  /**
   */
  protected financialPlanCommonNextFinancialPlanRaw = async (
    requestParameters: FinancialPlanCommonNextFinancialPlanRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<FinancialPlanNextDto>> => {
    const context = this.financialPlanCommonNextFinancialPlanRequestOpts(requestParameters);
    return this.financialPlanCommonNextFinancialPlanFetch(context, initOverrides);
  };

  /**
   */
  financialPlanCommonNextFinancialPlan = async (
    companyIdOrSlug: string,
    initOverrides?: RequestInit
  ): Promise<FinancialPlanNextDto> => {
    const response = await this.financialPlanCommonNextFinancialPlanRaw(
      { companyIdOrSlug: companyIdOrSlug },
      initOverrides
    );
    return await response.value();
  };

  /**
   */
  useFinancialPlanCommonNextFinancialPlan = (
    companyIdOrSlug: string,
    config?: SWRConfiguration<FinancialPlanNextDto>
  ): SWRResponse<FinancialPlanNextDto> => {
    return useSWR<FinancialPlanNextDto>(
      () => this.financialPlanCommonNextFinancialPlanRequestOpts({ companyIdOrSlug: companyIdOrSlug }),
      (context) => this.swrFetch(this.financialPlanCommonNextFinancialPlanFetch(context)),
      config
    );
  };
}
