/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ApiKeyDto
 */
export interface ApiKeyDto {
  /**
   *
   * @type {string}
   * @memberof ApiKeyDto
   */
  value: string;
}

export function ApiKeyDtoFromJSON(json: any): ApiKeyDto {
  return ApiKeyDtoFromJSONTyped(json, false);
}

export function ApiKeyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    value: json["value"],
  };
}

export function ApiKeyDtoToJSON(value?: ApiKeyDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    value: value.value,
  };
}
