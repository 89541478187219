/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MonthPlanSimpleWeekDto,
  MonthPlanSimpleWeekDtoFromJSON,
  MonthPlanSimpleWeekDtoFromJSONTyped,
  MonthPlanSimpleWeekDtoToJSON,
} from "./MonthPlanSimpleWeekDto";
import {
  MonthPlanStatus,
  MonthPlanStatusFromJSON,
  MonthPlanStatusFromJSONTyped,
  MonthPlanStatusToJSON,
} from "./MonthPlanStatus";

/**
 *
 * @export
 * @interface MonthPlanSimpleDto
 */
export interface MonthPlanSimpleDto {
  /**
   *
   * @type {MonthPlanStatus}
   * @memberof MonthPlanSimpleDto
   */
  status: MonthPlanStatus;
  /**
   *
   * @type {string}
   * @memberof MonthPlanSimpleDto
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanSimpleDto
   */
  createdAt: Date;
  /**
   *
   * @type {string}
   * @memberof MonthPlanSimpleDto
   */
  companyId: string;
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleDto
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleDto
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleDto
   */
  planIncome: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleDto
   */
  planMarginProfit: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleDto
   */
  factIncome: number;
  /**
   *
   * @type {number}
   * @memberof MonthPlanSimpleDto
   */
  factMarginProfit: number;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanSimpleDto
   */
  from: Date;
  /**
   *
   * @type {Date}
   * @memberof MonthPlanSimpleDto
   */
  to: Date;
  /**
   *
   * @type {Array<MonthPlanSimpleWeekDto>}
   * @memberof MonthPlanSimpleDto
   */
  weeks: Array<MonthPlanSimpleWeekDto>;
}

export function MonthPlanSimpleDtoFromJSON(json: any): MonthPlanSimpleDto {
  return MonthPlanSimpleDtoFromJSONTyped(json, false);
}

export function MonthPlanSimpleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthPlanSimpleDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: MonthPlanStatusFromJSON(json["status"]),
    id: json["id"],
    createdAt: new Date(json["createdAt"]),
    companyId: json["companyId"],
    month: json["month"],
    year: json["year"],
    planIncome: json["planIncome"],
    planMarginProfit: json["planMarginProfit"],
    factIncome: json["factIncome"],
    factMarginProfit: json["factMarginProfit"],
    from: new Date(json["from"]),
    to: new Date(json["to"]),
    weeks: (json["weeks"] as Array<any>).map(MonthPlanSimpleWeekDtoFromJSON),
  };
}

export function MonthPlanSimpleDtoToJSON(value?: MonthPlanSimpleDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: MonthPlanStatusToJSON(value.status),
    id: value.id,
    createdAt: value.createdAt.toISOString(),
    companyId: value.companyId,
    month: value.month,
    year: value.year,
    planIncome: value.planIncome,
    planMarginProfit: value.planMarginProfit,
    factIncome: value.factIncome,
    factMarginProfit: value.factMarginProfit,
    from: value.from.toISOString(),
    to: value.to.toISOString(),
    weeks: (value.weeks as Array<any>).map(MonthPlanSimpleWeekDtoToJSON),
  };
}
