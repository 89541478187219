/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ExpenseItemCreateUpdateRequestDto
 */
export interface ExpenseItemCreateUpdateRequestDto {
  /**
   *
   * @type {string}
   * @memberof ExpenseItemCreateUpdateRequestDto
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof ExpenseItemCreateUpdateRequestDto
   */
  name: string;
}

export function ExpenseItemCreateUpdateRequestDtoFromJSON(json: any): ExpenseItemCreateUpdateRequestDto {
  return ExpenseItemCreateUpdateRequestDtoFromJSONTyped(json, false);
}

export function ExpenseItemCreateUpdateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExpenseItemCreateUpdateRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fundId: json["fundId"],
    name: json["name"],
  };
}

export function ExpenseItemCreateUpdateRequestDtoToJSON(value?: ExpenseItemCreateUpdateRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fundId: value.fundId,
    name: value.name,
  };
}
