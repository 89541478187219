/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface OpenedFundingRequestReportFundingRequestDto
 */
export interface OpenedFundingRequestReportFundingRequestDto {
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundingRequestDto
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundingRequestDto
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundingRequestDto
   */
  fundId: string;
  /**
   *
   * @type {string}
   * @memberof OpenedFundingRequestReportFundingRequestDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundingRequestDto
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundingRequestDto
   */
  approvedAmount: number;
  /**
   *
   * @type {number}
   * @memberof OpenedFundingRequestReportFundingRequestDto
   */
  paidAmount: number;
}

export function OpenedFundingRequestReportFundingRequestDtoFromJSON(
  json: any
): OpenedFundingRequestReportFundingRequestDto {
  return OpenedFundingRequestReportFundingRequestDtoFromJSONTyped(json, false);
}

export function OpenedFundingRequestReportFundingRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OpenedFundingRequestReportFundingRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    number: json["number"],
    fundId: json["fundId"],
    name: json["name"],
    amount: json["amount"],
    approvedAmount: json["approvedAmount"],
    paidAmount: json["paidAmount"],
  };
}

export function OpenedFundingRequestReportFundingRequestDtoToJSON(
  value?: OpenedFundingRequestReportFundingRequestDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    number: value.number,
    fundId: value.fundId,
    name: value.name,
    amount: value.amount,
    approvedAmount: value.approvedAmount,
    paidAmount: value.paidAmount,
  };
}
