/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanAddVariableCostRequestDto
 */
export interface MonthPlanAddVariableCostRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanAddVariableCostRequestDto
   */
  fundId: string;
  /**
   *
   * @type {number}
   * @memberof MonthPlanAddVariableCostRequestDto
   */
  percent: number;
}

export function MonthPlanAddVariableCostRequestDtoFromJSON(json: any): MonthPlanAddVariableCostRequestDto {
  return MonthPlanAddVariableCostRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanAddVariableCostRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanAddVariableCostRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    fundId: json["fundId"],
    percent: json["percent"],
  };
}

export function MonthPlanAddVariableCostRequestDtoToJSON(value?: MonthPlanAddVariableCostRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    fundId: value.fundId,
    percent: value.percent,
  };
}
