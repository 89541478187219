/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  AdminCourseDto,
  AdminCourseDtoFromJSON,
  AdminCourseDtoFromJSONTyped,
  AdminCourseDtoToJSON,
} from "./AdminCourseDto";
import { AdminDto, AdminDtoFromJSON, AdminDtoFromJSONTyped, AdminDtoToJSON } from "./AdminDto";
import { CompanyType, CompanyTypeFromJSON, CompanyTypeFromJSONTyped, CompanyTypeToJSON } from "./CompanyType";
import {
  UserProfileDto,
  UserProfileDtoFromJSON,
  UserProfileDtoFromJSONTyped,
  UserProfileDtoToJSON,
} from "./UserProfileDto";
import {
  UserProfileDtoAvatarImage,
  UserProfileDtoAvatarImageFromJSON,
  UserProfileDtoAvatarImageFromJSONTyped,
  UserProfileDtoAvatarImageToJSON,
} from "./UserProfileDtoAvatarImage";

/**
 *
 * @export
 * @interface AdminCompanyDto
 */
export interface AdminCompanyDto {
  /**
   *
   * @type {CompanyType}
   * @memberof AdminCompanyDto
   */
  type: CompanyType;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyDto
   */
  id: string;
  /**
   *
   * @type {UserProfileDto}
   * @memberof AdminCompanyDto
   */
  ownerUser: UserProfileDto;
  /**
   *
   * @type {Date}
   * @memberof AdminCompanyDto
   */
  createdAt: Date;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyDto
   */
  slug: string;
  /**
   *
   * @type {UserProfileDtoAvatarImage}
   * @memberof AdminCompanyDto
   */
  avatarImage: UserProfileDtoAvatarImage | null;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyDto
   */
  internalName: string | null;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyDto
   */
  description: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AdminCompanyDto
   */
  hasAccess: boolean;
  /**
   *
   * @type {Date}
   * @memberof AdminCompanyDto
   */
  hasAccessUntil: Date;
  /**
   *
   * @type {Date}
   * @memberof AdminCompanyDto
   */
  touchedAt: Date;
  /**
   *
   * @type {Array<AdminDto>}
   * @memberof AdminCompanyDto
   */
  couches: Array<AdminDto>;
  /**
   *
   * @type {Array<AdminCourseDto>}
   * @memberof AdminCompanyDto
   */
  courses: Array<AdminCourseDto>;
  /**
   *
   * @type {boolean}
   * @memberof AdminCompanyDto
   */
  archived: boolean;
}

export function AdminCompanyDtoFromJSON(json: any): AdminCompanyDto {
  return AdminCompanyDtoFromJSONTyped(json, false);
}

export function AdminCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCompanyDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: CompanyTypeFromJSON(json["type"]),
    id: json["id"],
    ownerUser: UserProfileDtoFromJSON(json["ownerUser"]),
    createdAt: new Date(json["createdAt"]),
    name: json["name"],
    slug: json["slug"],
    avatarImage: UserProfileDtoAvatarImageFromJSON(json["avatarImage"]),
    internalName: json["internalName"],
    description: json["description"],
    hasAccess: json["hasAccess"],
    hasAccessUntil: new Date(json["hasAccessUntil"]),
    touchedAt: new Date(json["touchedAt"]),
    couches: (json["couches"] as Array<any>).map(AdminDtoFromJSON),
    courses: (json["courses"] as Array<any>).map(AdminCourseDtoFromJSON),
    archived: json["archived"],
  };
}

export function AdminCompanyDtoToJSON(value?: AdminCompanyDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: CompanyTypeToJSON(value.type),
    id: value.id,
    ownerUser: UserProfileDtoToJSON(value.ownerUser),
    createdAt: value.createdAt.toISOString(),
    name: value.name,
    slug: value.slug,
    avatarImage: UserProfileDtoAvatarImageToJSON(value.avatarImage),
    internalName: value.internalName,
    description: value.description,
    hasAccess: value.hasAccess,
    hasAccessUntil: value.hasAccessUntil.toISOString(),
    touchedAt: value.touchedAt.toISOString(),
    couches: (value.couches as Array<any>).map(AdminDtoToJSON),
    courses: (value.courses as Array<any>).map(AdminCourseDtoToJSON),
    archived: value.archived,
  };
}
