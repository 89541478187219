import Immutable from "immutable";

declare global {
  interface Set<T> {
    subtract(other: Set<T> | Immutable.Set<T>): Set<T>;

    clone(): Set<T>;
  }
}
Set.prototype.subtract = function (other) {
  return new Set([...this].filter((x) => !other.has(x)));
};

Set.prototype.clone = function () {
  return new Set([...this]);
};

export {};
