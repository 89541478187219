/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MonthPlanAddIncomeRequestDto
 */
export interface MonthPlanAddIncomeRequestDto {
  /**
   *
   * @type {string}
   * @memberof MonthPlanAddIncomeRequestDto
   */
  incomeTypeId: string;
}

export function MonthPlanAddIncomeRequestDtoFromJSON(json: any): MonthPlanAddIncomeRequestDto {
  return MonthPlanAddIncomeRequestDtoFromJSONTyped(json, false);
}

export function MonthPlanAddIncomeRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MonthPlanAddIncomeRequestDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    incomeTypeId: json["incomeTypeId"],
  };
}

export function MonthPlanAddIncomeRequestDtoToJSON(value?: MonthPlanAddIncomeRequestDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    incomeTypeId: value.incomeTypeId,
  };
}
