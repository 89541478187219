/* tslint:disable */
/* eslint-disable */
/**
 * Control Online API
 * Control Online API
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LoansReportFundDto
 */
export interface LoansReportFundDto {
  /**
   *
   * @type {string}
   * @memberof LoansReportFundDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof LoansReportFundDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof LoansReportFundDto
   */
  inDebt: number;
  /**
   *
   * @type {number}
   * @memberof LoansReportFundDto
   */
  othersOwe: number;
}

export function LoansReportFundDtoFromJSON(json: any): LoansReportFundDto {
  return LoansReportFundDtoFromJSONTyped(json, false);
}

export function LoansReportFundDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoansReportFundDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    inDebt: json["inDebt"],
    othersOwe: json["othersOwe"],
  };
}

export function LoansReportFundDtoToJSON(value?: LoansReportFundDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    inDebt: value.inDebt,
    othersOwe: value.othersOwe,
  };
}
